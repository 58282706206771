import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import cn from 'classnames';

import { connect } from 'react-redux';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';

import AdminTableWrapper from '../../../../../components/Admin/AdminTableWrapper';
import getFilteredSubscriptions from './utils/getFilteredSubscriptions';
import sortSubscriptions from './utils/sortSubscriptions';
import { getExpirationDateDiff } from './utils/dateUtils';
import { MAX_DATE } from '../../../../../components/Admin/SubscriptionForm/SubscriptionForm';

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    paddingRight: '9.8rem',
  },
  loadingContainer: {
    height: 'calc(100% + 200px)',
  },
  row: { height: '4.9rem' },
  rowCell: {
    cursor: 'pointer',
    fontSize: '1.1rem',
    color: theme.palette.custom.black,
  },
  cell: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    color: theme.palette.custom.gray5,
  },
  largeCell: { width: '50%' },
  mediumCell: { width: '16.6%' },
  disabled: {
    color: theme.palette.custom.gray4,
  },
  bold: {
    fontWeight: 'bold',
  },
  emptyResults: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.4rem',
    color: theme.palette.custom.gray4,
    height: '8.1rem',
  },
});

const Subscriptions = ({ classes, data, textSearch, onClick }) => {
  const [headers, setHeaders] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState('desc');

  useEffect(() => {
    setHeaders([
      {
        label: 'Resource',
        orderByKey: 'resource',
        objectKey: ['databoard.title', 'dataanalystaccess.title', 'report.title', 'briefing.title'],
        formatter: (data, objectKey) =>
          data.databoard
            ? get(data, objectKey[0])
            : data.dataanalystaccess
            ? get(data, objectKey[1])
            : data.report
            ? get(data, objectKey[2])
            : data.briefing
            ? get(data, objectKey[3])
            : null,
        classes: cn(classes.rowCell, classes.largeCell),
      },
      {
        label: 'Started',
        orderByKey: 'startDate',
        objectKey: 'startDate',
        formatter: (subscription, objectKey) =>
          moment(get(subscription, objectKey)).format('DD/MM/YYYY'),
        classes: cn(classes.rowCell, classes.mediumCell),
      },
      {
        label: 'Expiring in',
        orderByKey: 'expirationDate',
        objectKey: 'expirationDate',
        // Checking for indefinite MAX_DATE
        formatter: (subscription, objectKey) =>
          new Date(get(subscription, objectKey)).getFullYear() === MAX_DATE.getFullYear()
            ? 'Indefinite'
            : moment(get(data, objectKey)).format('DD/MM/YYYY'),
        classes: cn(classes.rowCell, classes.mediumCell),
      },
      {
        label: 'Time left',
        orderByKey: 'timeLeft',
        objectKey: 'expirationDate',
        formatter: (subscription, objectKey) => getExpirationDateDiff(get(subscription, objectKey)),
        classes: cn(classes.rowCell, classes.mediumCell),
      },
      {
        label: 'Type',
        orderByKey: 'type',
        formatter: (data) =>
          data.databoard
            ? 'Dashboard'
            : data.dataanalystaccess
            ? 'DAA'
            : data.report
            ? 'Report'
            : data.briefing
            ? 'Briefing'
            : 'N/A',
        classes: cn(classes.rowCell, classes.largeCell, classes.type),
      },
    ]);
  }, []);

  const handleOrderBy = useCallback(
    (field) => {
      if (field === orderBy) {
        setOrder(order === 'asc' ? 'desc' : 'asc');
      } else {
        setOrderBy(field);
        setOrder('asc');
      }
    },
    [orderBy, order],
  );

  let subscriptions = getFilteredSubscriptions(data, textSearch);

  if (isEmpty(subscriptions))
    return <div className={classes.emptyResults}>No subscriptions found</div>;

  subscriptions = sortSubscriptions(subscriptions, orderBy, order);

  return (
    <div className={classes.root}>
      <AdminTableWrapper
        classes={classes}
        data={subscriptions}
        columns={headers}
        orderBy={orderBy}
        order={order}
        onColumnClick={(orderByKey) => handleOrderBy(orderByKey)}
        onRowClick={(dataItem) => onClick(dataItem)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
  userId: get(state, ['user', 'userId'], []),
});

export default connect(mapStateToProps)(withStyles(styles)(Subscriptions));
