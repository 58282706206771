import orderBy from 'lodash/orderBy';

const sortByEmail = (users, order) => orderBy(users, ['email'], order);
const sortByFirstName = (users, order) => orderBy(users, ['firstName'], order);
const sortByLastName = (users, order) => orderBy(users, ['lastName'], order);
const sortByStatus = (users, order) => orderBy(users, ['status'], order);
const sortByRole = (users, order) => orderBy(users, ['role'], order);
const sortByOrganisation = (users, order) => orderBy(users, ['organisation.name'], order);

const sortUsers = (users, orderByField, order) => {
  switch (orderByField) {
    case 'email':
      return sortByEmail(users, order);
    case 'firstName':
      return sortByFirstName(users, order);
    case 'lastName':
      return sortByLastName(users, order);
    case 'status':
      return sortByStatus(users, order);
    case 'role':
      return sortByRole(users, order);
    case 'organisation':
      return sortByOrganisation(users, order);
    default:
      return users;
  }
};

export default sortUsers;
