import { clone, concat, remove } from 'lodash';
import orderBy from 'lodash/orderBy';
import { MAX_DATE } from '../../../../../../components/Admin/SubscriptionForm/SubscriptionForm';

const sortByDataboard = (subscriptions, order) => orderBy(subscriptions, ['title'], order);
const sortByStartedDate = (subscriptions, order) => orderBy(subscriptions, ['startDate'], order);
const sortByExpiringDate = (subscriptions, order) => {
  // Handeling the indefinite MAX_DATE case by removing them then sorting and re-concatenating them
  const clonedSubscriptions = clone(subscriptions);
  const indefiniteSubscriptions = remove(clonedSubscriptions, (subscription) => {
    return new Date(subscription.expirationDate).getFullYear() === MAX_DATE.getFullYear();
  });
  const orderedFiniteSubscriptions = orderBy(clonedSubscriptions, ['expirationDate'], order);
  if (order === 'asc') {
    return concat(orderedFiniteSubscriptions, indefiniteSubscriptions);
  }
  return concat(indefiniteSubscriptions, orderedFiniteSubscriptions);
};

const sortSubscriptions = (subscriptions, orderByField, order) => {
  switch (orderByField) {
    case 'databoard':
      return sortByDataboard(subscriptions, order);
    case 'startDate':
      return sortByStartedDate(subscriptions, order);
    case 'expirationDate':
      return sortByExpiringDate(subscriptions, order);
    case 'timeLeft':
      return sortByExpiringDate(subscriptions, order);
    default:
      return subscriptions;
  }
};

export default sortSubscriptions;
