import React, { useEffect, useState, useMemo } from 'react';
import cn from 'classnames';
import { API } from 'aws-amplify';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { Table, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AppLoader from '../../../../components/AppLoader';
import { setSearchKey, setUserSubscriptions } from '../../../../state/user/actions';
import { TYPE_BRIEFINGS } from '../../data';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch';
import TableBody from './components/TableBody';

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    paddingRight: '3rem',
    paddingLeft: '3rem',
    paddingBottom: '5rem',
  },
  loadingContainer: {
    height: 'calc(100% + 200px)',
  },
  cell: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    padding: '0.6rem 0',
    color: theme.palette.custom.gray5,
  },
  emptyResults: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.3rem',
    color: theme.palette.custom.gray4,
    height: '8.1rem',
  },
});

const Reports = ({
  classes,
  userId,
  searchCriteria,
  textSearch,
  jwtToken,
  searchKey,
  onSetUserSubscriptions,
  onSetSearchKey,
  setTextSearch,
}) => {
  const [allReports, setAllReports] = useState([]);
  const [allBriefings, setAllBriefings] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState('desc');
  const [loader, setLoader] = useState(false);
  const [, setError] = useState(null);

  useEffect(() => {
    const fetchReports = async () => {
      setLoader(true);

      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };

        const results = await Promise.all([
          API.get('users', `/users/${userId}/subscriptions`, params),
          API.get('users', `/users/${userId}/reports`, params),
          API.get('users', `/users/${userId}/briefings`, params),
        ]);
        const [userSubscriptions, reports, briefings] = results;

        onSetUserSubscriptions(userSubscriptions);
        setAllReports(reports);
        setAllBriefings(briefings);

        setLoader(false);
      } catch (e) {
        const message = get(e, ['response', 'data', 'message'], e.message);
        setError(message);
        setLoader(false);
      }
    };

    fetchReports();
  }, []);

  useEffect(() => {
    (async () => {
      if (searchKey) return;

      const { key } = await API.get('users', `/users/${userId}/reports/search/key`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      onSetSearchKey(key);
    })();
  }, [searchKey, jwtToken]);

  const searchClient = useMemo(() => {
    if (!searchKey) return null;

    return algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, searchKey);
  }, [searchKey]);

  const handleOrderBy = (field) => {
    if (field === orderBy) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(field);
      setOrder('asc');
    }
  };

  if (loader) return <AppLoader show={loader} />;

  if (isEmpty(allReports) && !loader && get(searchCriteria, 'category') !== TYPE_BRIEFINGS.TYPE)
    return <div className={classes.emptyResults}>No reports found</div>;

  if (isEmpty(allBriefings) && !loader && get(searchCriteria, 'category') === TYPE_BRIEFINGS.TYPE)
    return <div className={classes.emptyResults}>No briefings found</div>;

  return (
    <div className={cn(classes.root, { [classes.loadingContainer]: loader })}>
      <Table>
        <TableHead>
          <TableRow>
            {searchCriteria.category !== TYPE_BRIEFINGS.TYPE && <TableCell />}
            <TableCell
              className={classes.cell}
              padding="none"
              sortDirection={orderBy === 'title' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'title'}
                direction={orderBy === 'title' ? order : 'asc'}
                onClick={() => handleOrderBy('title')}
              >
                Title
              </TableSortLabel>
            </TableCell>

            {searchCriteria.category !== TYPE_BRIEFINGS.TYPE && (
              <TableCell
                className={classes.cell}
                padding="none"
                sortDirection={orderBy === 'areas' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'areas'}
                  direction={orderBy === 'areas' ? order : 'asc'}
                  onClick={() => handleOrderBy('areas')}
                >
                  Area
                </TableSortLabel>
              </TableCell>
            )}

            {searchCriteria.category !== TYPE_BRIEFINGS.TYPE && (
              <TableCell
                className={classes.cell}
                padding="none"
                sortDirection={orderBy === 'period' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'period'}
                  direction={orderBy === 'period' ? order : 'asc'}
                  onClick={() => handleOrderBy('period')}
                >
                  Survey Period
                </TableSortLabel>
              </TableCell>
            )}

            <TableCell
              className={classes.cell}
              padding="none"
              sortDirection={orderBy === 'published' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'published'}
                direction={orderBy === 'published' ? order : 'asc'}
                onClick={() => handleOrderBy('published')}
              >
                Date Published
              </TableSortLabel>
            </TableCell>

            {searchCriteria.category !== TYPE_BRIEFINGS.TYPE && (
              <TableCell
                className={classes.cell}
                padding="none"
                sortDirection={orderBy === 'type' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'type'}
                  direction={orderBy === 'type' ? order : 'asc'}
                  onClick={() => handleOrderBy('type')}
                >
                  Type
                </TableSortLabel>
              </TableCell>
            )}
          </TableRow>
        </TableHead>

        {searchClient && (
          <>
            <InstantSearch
              searchClient={searchClient}
              indexName={searchCriteria.category !== TYPE_BRIEFINGS.TYPE ? "reports" : "briefings"}
              future={{
                preserveSharedStateOnUnmount: true,
              }}
            >
              <TableBody
                allReports={allReports}
                allBriefings={allBriefings}
                searchCriteria={searchCriteria}
                orderBy={orderBy}
                order={order}
                textSearch={textSearch}
                setTextSearch={setTextSearch}
              />
            </InstantSearch>
          </>
        )}
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
  userId: get(state, ['user', 'userId'], []),
  searchKey: get(state, ['user', 'searchKey'], ''),
});

const mapDispatchToProps = (dispatch) => ({
  onSetUserSubscriptions: (subscriptions) => dispatch(setUserSubscriptions(subscriptions)),
  onSetSearchKey: (searchKey) => dispatch(setSearchKey(searchKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Reports));
