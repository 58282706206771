import moment from 'moment';

export const getDateDiff = (startDate, expirationDate) => {
  const starting = moment(startDate);
  const expiring = moment(expirationDate);
  const now = moment();

  if (starting.isAfter(now)) {
    return 'not started yet';
  }

  if (expiring.isAfter(now)) {
    return `${parseFloat(expiring.diff(now, 'days', true).toFixed(0))} days`;
  }

  if (expiring.isBefore(now)) {
    return 'expired';
  }
};

export default getDateDiff;
