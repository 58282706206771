import React, { useRef } from 'react';

import { connect } from 'react-redux';
// import ReactResizeDetector from 'react-resize-detector';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import keys from 'lodash/keys';
import get from 'lodash/get';
import pick from 'lodash/pick';

import { withStyles } from '@material-ui/core/styles';

import { removeFilter } from '../../../state/filtersData/actions';

import FilterTag from '../FilterTag';

// import Container from './components/containers';
import { DATABOARD_SOURCES_FILTER } from '../../../state/filtersData/types';
import { trackFilterUsage } from '../../../state/analytics/actions';
import ClearFiltersButton from '../IconButtons/ClearFiltersButton';
import LoadFiltersButton from '../IconButtons/LoadFiltersButton';

const styles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '0.8rem',
    width: 'auto',
  },
  wrapper: {
    display: 'inline-flex',
    alignItems: 'flex-start',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
});

const FilterTagManager = ({
  classes,
  values,
  filters,
  savedViews,
  loading,
  onRemoveFilter,
  onClearFilters,
  onDeleteFilter,
  onUpdateFilter,
  onCreateFilter,
  onRenameFilter,
  onLoadView,
}) => {
  const rootRef = useRef(null);

  if (isEmpty(values)) return null;

  // return (
  //   <div className={classes.root} ref={rootRef}>
  //     <FiltersIcon className={classes.icon} />
  //     <ReactResizeDetector handleWidth handleHeight refreshMode="debounce" refreshRate={500}>
  //       {size => (
  //         <Container {...size}>
  //           {map(keys(form), filter => {
  //             return (
  //               <Filter
  //                 key={`filter-${filter}`}
  //                 label={filter}
  //                 values={form[filter]}
  //                 onRemove={() => onRemoveFilter(filter)}
  //               />
  //             );
  //           })}
  //         </Container>
  //       )}
  //     </ReactResizeDetector>
  //   </div>
  // );

  const { sources } = values;
  const filterFormValues = omit(values, DATABOARD_SOURCES_FILTER);

  return (
    <div className={classes.root} ref={rootRef}>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <LoadFiltersButton
            loading={loading}
            savedViews={savedViews}
            onDeleteFilter={onDeleteFilter}
            onUpdateFilter={onUpdateFilter}
            onCreateFilter={onCreateFilter}
            onRenameFilter={onRenameFilter}
            onLoadView={onLoadView}
          />
          {!isEmpty(filterFormValues) && <ClearFiltersButton onClick={onClearFilters} />}
          <FilterTag
            _id="sources"
            key="filter-sources"
            label="Sources"
            values={sources}
            isSourcesTag
            isVisible
          />
          {map(keys(filterFormValues), (_id) => {
            return (
              <FilterTag
                _id={_id}
                key={`filter-${_id}`}
                label={get(
                  filters,
                  [_id, 'text'],
                  get(filters, [_id, 'name'], DATABOARD_SOURCES_FILTER),
                )}
                isVisible={get(filters, [_id, 'visible'], true)}
                values={values[_id]}
                onRemove={() => onRemoveFilter(_id)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  values: pick(get(state, ['filtersData', 'byId']), get(state, ['filtersData', 'root'])),
  filters: pick(get(state, ['filtersIndex', 'byId']), get(state, ['filtersData', 'root'])),
  savedViews: get(state, ['databoard', 'savedViews']),
});

const mapDispatchToProps = (dispatch) => ({
  onRemoveFilter: (id) => {
    dispatch(removeFilter(id));
    dispatch(trackFilterUsage({ [id]: [] }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FilterTagManager));
