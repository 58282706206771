import React from 'react';

import { get, map } from 'lodash';

import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LineChart,
  Line,
  Brush,
} from 'recharts';

import { ReactComponent as LineShadow } from './lineShadow.svg';
import { useStyles } from './styles';
import CustomTick from './components/CustomTick/CustomTick';
import CustomTooltip from './components/CustomTooltip';
import { connect } from 'react-redux';
import findLongestString from '../RechartBarChartEngine/utils/findLongestString';
import { getTextDimensions } from '../RechartBarChartEngine/components/CustomLabel/CustomLabel';
import findRectHeight from '../RechartBarChartEngine/utils/findRectHeight';

const X_AXIS_HEIGHT_DEFAULT = 104;

const calculateHeight = ({
  data,
  axisProps: { adjustHeight, customHeight, height, ticksProps },
  labels,
}) => {
  if (customHeight) return height;
  if (!adjustHeight) return X_AXIS_HEIGHT_DEFAULT;

  const longestStr = findLongestString({ data, labels });
  // There is a difference in width when the case differs. Currently, get the worst case of all upper case
  const upperCasedLabel = longestStr.toUpperCase();
  const { width } = getTextDimensions(upperCasedLabel, 16);

  const hypotenuse = width < get(ticksProps, 'width', 0) ? width : get(ticksProps, 'width', 0);
  const angle = -45;

  return findRectHeight(hypotenuse, angle) + 20;
};

const LineChartEngine = ({
  data,
  rootProps,
  cartesianGridProps,
  yAxisProps,
  xAxisProps,
  tooltipProps,
  series,
  legendProps,
  brushProps,
  labels,
  scatter,
  axisOrientation,
}) => {
  const classes = useStyles();

  return (
    <ResponsiveContainer height="100%">
      <LineChart {...rootProps} className={classes.root} data={data}>
        <LineShadow />
        <CartesianGrid {...cartesianGridProps} />
        <XAxis
          {...xAxisProps}
          tick={
            <CustomTick
              labels={labels}
              angle={axisOrientation === 'HORIZONTAL' ? 0 : -45}
              textAnchor={axisOrientation === 'HORIZONTAL' ? 'middle' : 'end'}
            />
          }
          height={calculateHeight({ axisProps: xAxisProps, data, labels })}
        />
        <YAxis {...yAxisProps} />
        <Tooltip formatter={get(tooltipProps, 'formatter')} content={<CustomTooltip />} />
        {series && series.length > 1 && <Legend {...legendProps} />}
        {map(series, (line, index) => (
          <Line
            key={`line-${index}`}
            stroke={scatter ? 'none' : line.stroke}
            type="monotone"
            dataKey={line.dataKey}
            label={false}
            dot={{ stroke: line.stroke, fill: line.stroke, r: 4 }}
          />
        ))}
        {brushProps.visibility && (
          <Brush dataKey={brushProps.dataKey} height={brushProps?.height || 20} />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

const mapStateToProps = (state, { id }) => ({
  axisOrientation: get(state, ['charts', 'byId', id, 'axis', 'orientation'], ''),
});

export default connect(mapStateToProps)(LineChartEngine);
