import { Auth } from 'aws-amplify';

const forgotPassword = async (username) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await Auth.forgotPassword(username);
      // resolve(data.CodeDeliveryDetails);
      resolve(data);
    } catch (error) {
      console.log(`An error ocurred while sending reset code ${error}`);
      console.log(error);
      reject(error);
    }
  });

export default forgotPassword;
