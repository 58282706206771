import concat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';

import getByEmail from './getByEmail';
import getByFirstName from './getByFirstName';
import getByLastName from './getByLastName';
import getByOrganisation from './getByOrganisation';
import getByRole from './getByRole';

const getFilteredUsers = (users, textSearch) => {
  const usersByEmail = getByEmail(users, textSearch);
  const usersByFirstName = getByFirstName(users, textSearch);
  const usersByLastName = getByLastName(users, textSearch);
  const usersByRole = getByRole(users, textSearch);
  const usesByOrganisation = getByOrganisation(users, textSearch);

  const filteredUsers = uniqBy(
    concat(usersByEmail, usersByFirstName, usersByLastName, usersByRole, usesByOrganisation),
    '_id',
  );

  return filteredUsers;
};

export default getFilteredUsers;
