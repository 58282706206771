import React from 'react';
import cn from 'classnames';

import { Field } from 'redux-form';
import { connect } from 'react-redux';

import get from 'lodash/get';
import map from 'lodash/map';
import indexOf from 'lodash/indexOf';

import ListSubheader from '@material-ui/core/ListSubheader';
import DoneIcon from '@material-ui/icons/Done';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import hasAccess from '../../../ContactUs/utils/hasAccess';

const styles = () => ({
  title: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
    color: '#0F70E0',
    fontSize: '1.1rem',
    backgroundColor: 'white',
  },
  chip: {
    margin: '0.2rem',
    fontSize: '1.1rem',
    padding: '1.2rem 0',
    borderRadius: 60,
  },
  avatar: { backgroundColor: 'transparent !important' },
  chipContainer: {
    marginLeft: '2.2rem',
  },
  disabled: {
    cursor: 'not-allowed !important',
    pointerEvents: 'all !important',
  },
});

const Sources = ({ classes, sources, input, hasAccess }) => {
  return (
    <div>
      <ListSubheader component="div" className={classes.title}>
        Sources
      </ListSubheader>
      <div className={classes.chipContainer}>
        {map(sources, ({ name, text }) => (
          <Chip
            key={name}
            label={text}
            avatar={
              indexOf(input.value, name) !== -1 ? (
                <Avatar classes={{ colorDefault: classes.avatar }}>
                  <DoneIcon />
                </Avatar>
              ) : null
            }
            className={cn(classes.chip, { [classes.disabled]: !hasAccess })}
            color={indexOf(input.value, name) !== -1 ? 'primary' : 'default'}
            clickable
            onClick={() => {
              if (hasAccess) {
                const newValue = [...input.value];
                if (indexOf(input.value, name) === -1) {
                  newValue.push(name);
                } else if (input.value.length === 1) {
                  return alert('Not allowed: At least one source must be selected');
                } else {
                  newValue.splice(newValue.indexOf(name), 1);
                }
                return input.onChange(newValue);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

const SOURCES_FIELD_NAME = 'sources';

const SourcesField = (props) => <Field name={SOURCES_FIELD_NAME} component={Sources} {...props} />;

const mapStateToProps = (state) => ({
  sources: get(state, ['filtersIndex', 'byId', SOURCES_FIELD_NAME, 'labels'], []),
  hasAccess: hasAccess(state),
});

export default connect(mapStateToProps)(withStyles(styles)(SourcesField));
