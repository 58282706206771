import moment from 'moment';
import { map, split } from 'lodash';

type DatType = {
  name: string;
  value: number;
};

const weekOfYear = (data: DatType[]) =>
  map(data, (item) => ({
    ...item,
    name: moment().day('Monday').week(parseInt(item.name, 10)).format('MMM DD'),
  }));

export default weekOfYear;
