import get from 'lodash/get';
import reduce from 'lodash/reduce';

const transpose = (data) => {
  const values = get(data, ['values', 0, 'values'], []);

  return reduce(
    values,
    (acc, { name, value }) => {
      if (value === 0) return acc;

      return {
        ...acc,
        [name]: value,
      };
    },
    {},
  );
};

export default transpose;
