import includes from 'lodash/includes';
import filter from 'lodash/filter';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';
import get from 'lodash/get';

const getByDashboard = (dashboards, textSearch) => {
  if (isEmpty(textSearch)) return dashboards;

  return filter(dashboards, (dashboard) =>
    includes(toLower(get(dashboard, 'title')), trim(toLower(textSearch))),
  );
};

export default getByDashboard;
