import { SET_SUBSCRIPTIONS } from './types';

import getSubscriptionByOrganisationId from './utils/getSubscriptionByOrganisationId';

const initialState = {
  subscriptionsByOrganisation: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptionsByOrganisation: {
          ...state.subscriptionsByOrganisation,
          ...getSubscriptionByOrganisationId(action.payload),
        },
      };
    default:
      return state;
  }
};

export default reducer;
