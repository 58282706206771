import React, { useEffect } from 'react';

import { Grid } from '@material-ui/core';

import { handleViewport } from '../../../../ViewportContainer';

const Container = ({
  forwardedRef,
  inViewport,
  chartName,
  updateChartHash,
  children,
  ...restProps
}) => {
  useEffect(() => {
    updateChartHash(chartName, inViewport);
  }, [inViewport]);

  return (
    <Grid ref={forwardedRef} {...restProps}>
      {children}
    </Grid>
  );
};

const ViewportContainer = handleViewport(Container);

export default ViewportContainer;
