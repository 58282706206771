import React from 'react';

import { MultiGrid, GridCellProps } from 'react-virtualized';

interface MultiGridWrapperProps {
  // Number of fixed columns
  fixedColumnCount: number;
  // Number of fixed rows
  fixedRowCount: number;
  // Column index to ensure visible
  scrollToColumn: number;
  // Row index to ensure visible
  scrollToRow: number;
  // Rendering a single cell, given its row and column index
  cellRenderer: (props: GridCellProps) => React.ReactNode;
  // Width of the Grid
  width: number;
  // Height of the Grid
  height: number;
  // Either a fixed number or a function that returns the width of a column give its index
  columnWidth: (params: { index: number }) => number | number;
  // Either a fixed number or a function that returns the height of a row give its index
  rowHeight: (params: { index: number }) => number | number;
  // A pointer to cell measurer cache
  deferredMeasurementCache: any;
  // Number of columns in grid
  columnCount: number;
  // Number of rows in grid
  rowCount: number;
}

const STYLE: React.CSSProperties = {
  border: '1px solid #ddd',
};

const STYLE_BOTTOM_LEFT_GRID: React.CSSProperties = {
  borderRight: '2px solid #aaa',
  backgroundColor: '#f7f7f7',
};
const STYLE_TOP_LEFT_GRID: React.CSSProperties = {
  borderBottom: '2px solid #aaa',
  borderRight: '2px solid #aaa',
  fontWeight: 'bold',
};
const STYLE_TOP_RIGHT_GRID: React.CSSProperties = {
  borderBottom: '2px solid #aaa',
  fontWeight: 'bold',
};

const MultiGridWrapper = (props: MultiGridWrapperProps) => {
  return (
    <MultiGrid
      {...props}
      enableFixedColumnScroll
      enableFixedRowScroll
      hideTopRightGridScrollbar
      hideBottomLeftGridScrollbar
      style={STYLE}
      styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
      styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
      styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
    />
  );
};

export default MultiGridWrapper;
