import React from 'react';
import cn from 'classnames';

import map from 'lodash/map';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { withStyles } from '@material-ui/core/styles';

import StepIcon from './StepIcon';

const Connector = withStyles({
  alternativeLabel: {
    top: '1.7rem',
  },
  active: {
    '& $line': {
      backgroundColor: '#0f70e0',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#0f70e0',
    },
  },
  line: {
    height: '0.2rem',
    border: 0,
    backgroundColor: '#e7e7ef',
    borderRadius: 1,
    margin: '0 0.7rem',
  },
})(StepConnector);

const styles = () => ({
  root: {
    padding: 0,
    marginBottom: '4.3rem',
    marginLeft: '-2.4rem',
    marginRight: '-2.4rem',
  },
  alternativeRoot: {
    marginLeft: '-5.1rem',
    marginRight: '-5.1rem',
  },
  alternativeLabel: {
    flex: 'auto',
    width: '3.4rem',
    height: '3.4rem',
    padding: 0,
  },
});

const StepperWrapper = ({ classes, activeStep, steps = [1, 2, 3] }) => (
  <Stepper
    alternativeLabel
    activeStep={activeStep}
    classes={{
      root: cn(classes.root, { [classes.alternativeRoot]: steps.length < 3 }),
    }}
    connector={<Connector />}
  >
    {map(steps, (label) => (
      <Step key={label} classes={{ alternativeLabel: classes.alternativeLabel }}>
        <StepLabel StepIconComponent={StepIcon} label={label} />
      </Step>
    ))}
  </Stepper>
);

export default withStyles(styles)(StepperWrapper);
