import React from 'react';
import cn from 'classnames';

import { omit, get, find } from 'lodash';
import Text from 'react-svg-text';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 13,
    fill: theme.palette.custom.black,
    fontWeight: 500,
  },
  dialogView: {
    fontSize: 16,
  },
}));

const CustomTick = (props) => {
  const classes = useStyles();
  const { payload, labels, view, width } = props;

  const label = find(labels, { name: payload.value });

  return (
    <Text
      {...omit(props, ['visibleTicksCount', 'dispatch', 'classes'])}
      className={cn(classes.root, { [classes.dialogView]: view === 'dialog' })}
      width={Math.min(width - 35, 200)}
    >
      {get(label, 'text', payload.value)}
    </Text>
  );
};

export default CustomTick;
