import map from 'lodash/map';
import flattenDeep from 'lodash/flattenDeep';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import concat from 'lodash/concat';

const concatRows = ({ data, labels }) => {
  const allNames = uniq(map(flattenDeep(data), 'name'));

  return map(allNames, (name) => {
    const tablesToConcat = filter(flattenDeep(data), (table) => table.name === name);

    return reduce(
      tablesToConcat,
      (result, value, index) => {
        return {
          ...result,
          name,
          values: concat(
            get(result, 'values', []),
            map(get(value, 'values', []), (row) => ({
              ...row,
              name: `${get(labels, [index, 'name'], '')}`,
              text: `${get(labels, [index, 'text'], '')}`,
            })),
          ),
        };
      },
      {},
    );
  });
};

export default concatRows;
