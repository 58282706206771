import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import ReactMarkdown from 'react-markdown';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { withStyles } from '@material-ui/core/styles';

import content from './content.md';

const styles = (theme) => ({
  root: {
    zIndex: `${theme.zIndex.modal} !important`,
  },
  paper: {},
  close: {
    position: 'absolute',
    right: '1.6rem',
    top: '1.6rem',
  },
  title: {
    fontFamily: 'Nunito Sans Black',
    fontSize: '1.5rem',
    color: theme.palette.custom.black,
    minHeight: '2.4rem',
    paddingRight: '4.5rem',
    display: 'flex',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: '1.2rem',
    color: theme.palette.custom.black,
    fontFamily: 'Nunito Sans',
    paddingLeft: 0,
  },
  content: {
    display: 'flex',
  },
  text: {
    fontSize: '1.3rem',
    color: theme.palette.custom.black,
    '& ol': {
      paddingLeft: '1.3rem',
      fontWeight: 'bold',
    },
  },
});

const TnCDialog = ({ classes, open, onClose }) => {
  const [tncText, setTncText] = useState('');

  useEffect(() => {
    fetch(content)
      .then((response) => response.text())
      .then((text) => {
        setTncText(text);
      });
  }, []);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xl"
      classes={{
        root: classes.root,
        container: classes.container,
        paper: classes.paper,
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton className={classes.close} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <div>Terms and conditions</div>
      </DialogTitle>
      <DialogContent className={cn(classes.content)}>
        <ReactMarkdown
          className={classes.text}
          source={tncText}
          escapeHtml={false}
          linkTarget="_blank"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(TnCDialog);
