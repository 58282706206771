export const ACCESS_TYPE_OWNED = 'owned';
export const ACCESS_TYPE_SAMPLE = 'sample';

export const SECTOR_DESK = { TYPE: 'desktop', LABEL: 'Desktop Apps' };
export const SECTOR_MOB = { TYPE: 'mobile', LABEL: 'Mobile Apps' };
export const SECTOR_WEB = { TYPE: 'web', LABEL: 'Web Apps' };
export const SECTOR_CLD = { TYPE: 'cloud', LABEL: 'Cloud / Backend Services' };
export const SECTOR_GAM = { TYPE: 'games', LABEL: 'Games' };
export const SECTOR_IOT = { TYPE: 'iot', LABEL: 'IoT' };
export const SECTOR_ML = { TYPE: 'ml', LABEL: 'ML/AI & Data Science' };
export const SECTOR_ARVR = { TYPE: 'avr', LABEL: 'AR/VR' };
export const SECTOR_EMB = { TYPE: 'emb', LABEL: 'Embedded Systems' };
export const SECTOR_APP = { TYPE: 'app', LABEL: 'App/Extensions for 3rd-Party Platforms' };
export const SECTOR_CORE = { TYPE: 'cross-sector', LABEL: 'Cross-Area' };
export const SECTOR_DEVOPS = { TYPE: 'devops', LABEL: 'DevOps' };

export const PRODUCT_DPB = { CATEGORY: 'dpb', LABEL: 'Developer Program Benchmarking' };
export const PRODUCT_CTL = { CATEGORY: 'ctl', LABEL: 'Competitive Technology Landscape' };
export const PRODUCT_TAT = { CATEGORY: 'tat', LABEL: 'Technology Adoption Tracker' };
export const PRODUCT_INSIGHTS = {
  CATEGORY: '360',
  LABEL: 'Developer Ecosystem Insights',
};
export const PRODUCT_TAM = { CATEGORY: 'tam', LABEL: 'Target Addressable Market Analysis' };
export const PRODUCT_OTHER = { CATEGORY: 'other', LABEL: 'Other' };

export const PRODUCT_REPORT_DPB = {
  CATEGORY: 'Developer Program Benchmarking (DPB)',
  LABEL: 'Developer Program Benchmarking (DPB)',
};
export const PRODUCT_REPORT_DEI = {
  CATEGORY: 'Developer Insights & Trends',
  LABEL: 'Developer Insights & Trends',
};
export const PRODUCT_REPORT_MARKET = {
  CATEGORY: 'Market sizing',
  LABEL: 'Market sizing',
};
export const PRODUCT_REPORT_DEVELOPERS = {
  CATEGORY: 'How and where to reach developers',
  LABEL: 'How and where to reach developers',
};
export const PRODUCT_REPORT_SATISFACTION = {
  CATEGORY: 'Product adoption, satisfaction & competition',
  LABEL: 'Product adoption, satisfaction & competition',
};
export const PRODUCT_REPORT_SEGMENTATION = {
  CATEGORY: 'Developer segmentation & profiling',
  LABEL: 'Developer segmentation & profilings',
};

export const CATEGORY_ALL_ITEMS = { LABEL: 'All Dashboards', CATEGORY: 'ALL_ITEMS' };
export const CATEGORY_ALL_DAA = { LABEL: 'All Data Tables', CATEGORY: 'ALL_ITEMS' };
export const CATEGORY_ALL_REPORTS = { LABEL: 'All Reports', CATEGORY: 'ALL ITEMS' };
export const CATEGORY_SUBSCRIPTIONS = { LABEL: 'Full Access', CATEGORY: 'SUBSCRIPTIONS' };
export const CATEGORY_PREVIEWS = { LABEL: 'Limited Access', CATEGORY: 'PREVIEW_ONLY' };
export const CATEGORY_BOOKMARKED = { LABEL: 'Starred', CATEGORY: 'BOOKMARKED' };

export const TYPE_ALL = { LABEL: 'All', TYPE: 'ALL_ITEMS' };
export const TYPE_FAVOURITE = { LABEL: 'Favourites', TYPE: 'BOOKMARKED' };
export const TYPE_CUSTOM = { LABEL: 'Custom Research', TYPE: 'CUSTOM' };
export const TYPE_SYNDICATED = { LABEL: 'Syndicated Research', TYPE: 'SYNDICATED' };
export const TYPE_FREE = { LABEL: 'Free', TYPE: 'FREE' };
export const TYPE_PREVIEW = { LABEL: 'Preview', TYPE: 'PREVIEW' };
export const TYPE_BRIEFINGS = { LABEL: 'Briefing', TYPE: 'BRIEFING' };

export const TOPIC_PROGRAMMING_LANGUAGES = {
  NAME: 'Programming languages',
  LABEL: 'Programming languages',
};
export const TOPIC_APIS = { NAME: 'APIs', LABEL: 'APIs' };
export const TOPIC_OPEN_SOURCE = { NAME: 'Open source', LABEL: 'Open source' };
export const TOPIC_DEVELOPER_PROGRAMS = { NAME: 'Developer programs', LABEL: 'Developer programs' };
export const TOPIC_NO_LOW_CODE = {
  NAME: 'No-code / low-code tools',
  LABEL: 'No-code / low-code tools',
};
export const TOPIC_EMERGING_TECHNOLOGIES = {
  NAME: 'Emerging technologies',
  LABEL: 'Emerging technologies',
};
export const TOPIC_SECURITY = { NAME: 'Security', LABEL: 'Security' };
export const TOPIC_CLOUD_PROVIDERS = {
  NAME: 'Cloud service providers',
  LABEL: 'Cloud service providers',
};
export const TOPIC_CLOUD_DEVELOPMENT = {
  NAME: 'Cloud-native development',
  LABEL: 'Cloud-native development',
};
export const TOPIC_GENERATIVE_AI = { NAME: 'Generative AI', LABEL: 'Generative AI' };
export const TOPIC_BLOCKCHAIN_APPS = { NAME: 'Blockchain applications', LABEL: 'Blockchain applications'};
export const TOPIC_INFLUENCE = {
  NAME: 'Influence on purchasing decisions',
  LABEL: 'Influence on purchasing decisions',
};
export const TOPIC_DEVELOPER_LEARNING = {
  NAME: 'Developer learning patterns',
  LABEL: 'Developer learning patterns',
};
export const TOPIC_MONETISATION_SALARY = {
  NAME: 'Monetisation / developer salaries',
  LABEL: 'Monetisation / developer salaries',
};

export default [
  {
    name: 'Machine Learning Q2 2018',
    sectors: [SECTOR_ML.LABEL],
    product: PRODUCT_DPB.CATEGORY,
    access: ACCESS_TYPE_OWNED,
    bookmarked: false,
  },
  {
    name: 'CaaS/PaaS Q4 2017',
    sectors: [SECTOR_CLD.LABEL],
    product: PRODUCT_CTL.CATEGORY,
    access: ACCESS_TYPE_SAMPLE,
    bookmarked: false,
  },
  {
    name: 'Voice Platform Q4 2017',
    sectors: [SECTOR_CLD.LABEL, SECTOR_IOT.LABEL],
    product: PRODUCT_INSIGHTS.CATEGORY,
    access: ACCESS_TYPE_OWNED,
    bookmarked: true,
  },
  {
    name: 'AR/VR Q4 2018',
    sectors: [SECTOR_ARVR.LABEL, SECTOR_GAM.LABEL],
    product: PRODUCT_TAT.CATEGORY,
    access: ACCESS_TYPE_OWNED,
  },
];
