import map from 'lodash/map';
import keys from 'lodash/keys';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { SERIES } from './types';
import { head } from 'lodash';

const getParams = ({
  name,
  sources,
  benchmarkFilterExpressions,
  columnExpression,
  rowExpression,
  rejectExpression,
  contextExpression,
  jwtToken,
  computation = SERIES,
  pathURL,
  version,
  query,
}) => {
  if (isEmpty(sources)) return [];

  return map(keys(benchmarkFilterExpressions), (filterName) => {
    if (pathURL && pathURL.includes('/dashboards') && version === 2) {
      query.source = head(sources);
      return {
        body: {
          version: 2,
          query: {
            ...query,
            filter: get(benchmarkFilterExpressions, [filterName, 'expression'], {}),
          },
          queryType: 'dashboard',
        },
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      };
    } else {
      return {
        body: {
          name,
          sources,
          input: { filter: get(benchmarkFilterExpressions, [filterName, 'expression'], {}) },
          output: {
            filter: { col: columnExpression, row: rowExpression },
            reject: rejectExpression,
          },
          context: contextExpression,
          computation,
        },
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      };
    }
  });
};

export default getParams;
