import moment from 'moment';

import {
  SET_SESSION_ID,
  SET_EVENT_GROUP_ID,
  CLEAR_EVENT_GROUP_ID,
  SET_START_DATE_FILTER,
  SET_END_DATE_FILTER,
  SET_EXCLUDED_ACCOUNTS_FILTER,
} from './types';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const initialState = {
  sessionId: null,
  eventGroup: null,
  filters: {
    startDate: moment().subtract(1, 'month').startOf('day').format(DATE_FORMAT),
    endDate: moment().endOf('day').format(DATE_FORMAT),
    excludedAccounts: ['slashdata'],
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.payload,
      };
    case SET_EVENT_GROUP_ID:
      return {
        ...state,
        eventGroup: action.payload,
      };
    case CLEAR_EVENT_GROUP_ID:
      return {
        ...state,
        eventGroup: null,
      };
    case SET_START_DATE_FILTER:
      return {
        ...state,
        filters: { ...state.filters, startDate: action.payload },
      };
    case SET_END_DATE_FILTER:
      return {
        ...state,
        filters: { ...state.filters, endDate: action.payload },
      };
    case SET_EXCLUDED_ACCOUNTS_FILTER:
      return {
        ...state,
        filters: { ...state.filters, excludedAccounts: action.payload },
      };
    default:
      return state;
  }
};

export default reducer;
