export default {
  filters: [
    { name: 'excluded-account', type: 'dropdown' },
    { name: 'start-date', type: 'datepicker' },
    { name: 'end-date', type: 'datepicker' },
  ],
  sections: [
    {
      name: 'user-metrics',
      title: 'User Metrics',
      visibility: true,
      graphs: [
        {
          name: 'daily-users',
          title: 'Unique Daily users',
          type: 'TYPE_COLUMN_CHART_STACKED',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'full' },
            { key: 'xAxisProps.angle', value: -45 },
            { key: 'xAxisProps.textAnchor', value: 'end' },
            { key: 'xAxisProps.interval', value: null },
            { key: 'yAxisProps.domain', value: [0, (dataMax) => dataMax + 2] },
            { key: 'yAxisProps.allowDecimals', value: false },
            { key: 'yAxisProps.axisTitle', value: 'Abs number of users per day' },
            { key: 'sample.offset', value: -999999 },
            { key: 'brush.visibility', value: true },
            { key: 'brush.dataKey', value: 'text' },
            { key: 'series', value: ['paid', 'other'] },
            { key: 'formatter', value: 'population' },
          ],
          query: 'daily-users',
        },
        {
          name: 'monthly-users',
          title: 'Monthly users',
          type: 'TYPE_COLUMN_CHART_STACKED',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'stacked', value: true },
            { key: 'width', value: 'half' },
            { key: 'sample.offset', value: -999999 },
            { key: 'series', value: ['paid', 'other'] },
            { key: 'formatter', value: 'population' },
            { key: 'yAxisProps.domain', value: [0, (dataMax) => dataMax + 2] },
            { key: 'yAxisProps.allowDecimals', value: false },
            { key: 'yAxisProps.axisTitle', value: 'Abs number of users per month' },
          ],
          query: 'monthly-users',
        },
        {
          name: 'daily-accounts',
          title: 'Daily accounts/orgs',
          type: 'TYPE_LINE_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'half' },
            { key: 'series', value: ['value'] },
            { key: 'yAxisProps.domain', value: [0, (dataMax) => dataMax + 2] },
            { key: 'yAxisProps.allowDecimals', value: false },
            { key: 'yAxisProps.axisTitle', value: 'Abs number of accounts per day' },
          ],
          query: 'daily-accounts',
        },
        {
          name: 'monthly-accounts',
          title: 'Monthly accounts/orgs',
          type: 'TYPE_LINE_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'half' },
            { key: 'series', value: ['value'] },
            { key: 'yAxisProps.domain', value: [0, (dataMax) => dataMax + 2] },
            { key: 'yAxisProps.allowDecimals', value: false },
            { key: 'yAxisProps.axisTitle', value: 'Abs number of accounts per month' },
          ],
          query: 'monthly-accounts',
        },
      ],
    },
    {
      name: 'user-activity-metrics',
      title: 'User Activity Metrics',
      visibility: true,
      graphs: [
        {
          name: 'user-activity-distribution',
          title: 'User Activity Distribution',
          type: 'TYPE_COLUMN_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'full' },
            { key: 'export', value: 'user-activity-distribution-export' },
            {
              key: 'csvHeaders',
              value: [
                { key: 'account', label: 'Account' },
                { key: 'name', label: 'Email' },
                { key: 'value', label: 'Number of dashboard visits' },
              ],
            },
            { key: 'series', value: ['0', '1_5', '6_15', '16_30', '31_60', '>60'] },
            { key: 'formatter', value: 'population' },
            { key: 'yAxisProps.domain', value: [0, (dataMax) => dataMax + 2] },
            { key: 'yAxisProps.allowDecimals', value: false },
            { key: 'yAxisProps.axisTitle', value: 'Abs number of users' },
            { key: 'xAxisProps.tick.angle', value: 0 },
            // { key: 'xAxisProps.tick.textAnchor', value: 'middle' },
            { key: 'xAxisProps.label.offset', value: -20 },
            { key: 'xAxisProps.axisTitle', value: 'Abs number of dashboard visits' },
          ],
          query: 'user-activity-distribution',
        },
        {
          name: 'top-10-active-users',
          title: 'Top 10 active users',
          type: 'TYPE_BAR_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'half' },
            { key: 'series', value: ['value'] },
            { key: 'formatter', value: 'population' },
            { key: 'xAxisProps.axisTitle', value: 'Number of dashboard visits' },
          ],
          query: 'top-10-active-users',
        },
        {
          name: 'top-10-non-premium-users',
          title: 'Top 10 non premium users',
          type: 'TYPE_BAR_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'half' },
            { key: 'series', value: ['value'] },
            { key: 'formatter', value: 'population' },
            { key: 'xAxisProps.axisTitle', value: 'Number of dashboard visits' },
          ],
          query: 'top-10-non-premium-users',
        },
      ],
    },
    {
      name: 'account-activity-metrics',
      title: 'Account Activity Metrics',
      visibility: true,
      graphs: [
        {
          name: 'account-activity-distribution',
          title: 'Account Activity Distribution',
          type: 'TYPE_COLUMN_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'full' },
            { key: 'export', value: 'account-activity-distribution-export' },
            {
              key: 'csvHeaders',
              value: [
                { key: 'name', label: 'Account' },
                { key: 'value', label: 'Number of dashboard visits' },
              ],
            },
            { key: 'series', value: ['0', '1_5', '6_15', '16_30', '31_60', '>60'] },
            { key: 'formatter', value: 'population' },
            { key: 'yAxisProps.domain', value: [0, (dataMax) => dataMax + 2] },
            { key: 'yAxisProps.allowDecimals', value: false },
            { key: 'yAxisProps.axisTitle', value: 'Abs number of accounts' },
            { key: 'xAxisProps.tick.angle', value: 0 },
            { key: 'xAxisProps.tick.textAnchor', value: 'middle' },
            { key: 'xAxisProps.label.offset', value: -20 },
            { key: 'xAxisProps.axisTitle', value: 'Abs number of dashboard visits' },
          ],
          query: 'account-activity-distribution',
        },
        {
          name: 'top-10-active-accounts',
          title: 'Top 10 active accounts',
          type: 'TYPE_BAR_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'half' },
            { key: 'series', value: ['value'] },
            { key: 'formatter', value: 'population' },
            { key: 'xAxisProps.axisTitle', value: 'Number of dashboard visits' },
          ],
          query: 'top-10-active-accounts',
        },
        {
          name: 'bottom-10-active-accounts',
          title: 'Bottom 10 active accounts',
          type: 'TYPE_BAR_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'half' },
            { key: 'series', value: ['value'] },
            { key: 'formatter', value: 'population' },
            { key: 'xAxisProps.axisTitle', value: 'Number of dashboard visits' },
          ],
          query: 'bottom-10-active-accounts',
        },
      ],
    },
    {
      name: 'session-metrics',
      title: 'Session Metrics',
      visibility: true,
      graphs: [
        {
          name: 'session-length',
          title: 'Session Length',
          subtitle: 'Amount of time users spent in the DTool',
          type: 'TYPE_COLUMN_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'full' },
            { key: 'export', value: 'session-length-distribution-export' },
            {
              key: 'csvHeaders',
              value: [
                { key: 'sessionId', label: 'Session ID' },
                { key: 'user', label: 'User' },
                { key: 'account', label: 'Account' },
                { key: 'duration', label: 'Duration in minutes' },
              ],
            },
            { key: 'series', value: ['value'] },
            { key: 'formatter', value: 'population' },
            { key: 'xAxisProps.axisTitle', value: 'Minutes' },
            { key: 'xAxisProps.tick.angle', value: 0 },
            { key: 'xAxisProps.label.offset', value: -20 },
            { key: 'yAxisProps.axisTitle', value: 'Number of sessions' },
          ],
          query: 'session-length-distribution',
        },
        {
          name: 'dashboards-per-session',
          title: 'Number of dashboards viewed per session',
          type: 'TYPE_COLUMN_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'full' },
            { key: 'export', value: 'session-dashboard-distribution-export' },
            {
              key: 'csvHeaders',
              value: [
                { key: 'sessionId', label: 'Session ID' },
                { key: 'user', label: 'User' },
                { key: 'account', label: 'Account' },
                { key: 'resource', label: 'Dashboard' },
              ],
            },
            { key: 'series', value: ['value'] },
            { key: 'formatter', value: 'population' },
            { key: 'xAxisProps.axisTitle', value: 'Number of dashboards visited' },
            { key: 'xAxisProps.tick.angle', value: 0 },
            { key: 'xAxisProps.label.offset', value: -20 },
            { key: 'yAxisProps.axisTitle', value: 'Number of sessions' },
          ],
          query: 'session-dashboard-distribution',
        },
      ],
    },
    {
      name: 'dashboard-activity-metrics',
      title: 'Dashboard Activity Metrics',
      visibility: true,
      graphs: [
        {
          name: 'unique-dashboards-visited-per-week',
          title: 'Unique dashboards visited per week',
          type: 'TYPE_LINE_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'full' },
            { key: 'series', value: ['value'] },
            { key: 'formatter', value: 'population' },
            { key: 'xAxisProps.tick.normalizer', value: 'weekOfYear' },
            { key: 'yAxisProps.axisTitle', value: 'Number of unique dashboards' },
          ],
          query: 'unique-dashboard-visits-distribution',
        },
        {
          name: 'total-visits-per-dashboard',
          title: 'Total visits per dashboard',
          type: 'TYPE_BAR_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'half' },
            { key: 'height', value: 'large' },
            { key: 'series', value: ['value'] },
            { key: 'formatter', value: 'population' },
            { key: 'xAxisProps.axisTitle', value: 'Number of visits' },
          ],
          query: 'total-visits-per-dashboard',
        },
        {
          name: 'total-time-spent-per-dashboard',
          title: 'Total time spent per dashboard',
          type: 'TYPE_BAR_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'half' },
            { key: 'series', value: ['value'] },
            { key: 'height', value: 'large' },
            { key: 'formatter', value: 'population' },
            { key: 'xAxisProps.axisTitle', value: 'Number of minutes' },
          ],
          query: 'total-time-spent-per-dashboard',
        },
      ],
    },
    {
      name: 'graphs-activity-metrics',
      title: 'Graphs Activity Metrics',
      visibility: true,
      filters: [{ name: 'dashboards', type: 'dropdown' }],
      graphs: [
        {
          name: 'top-10-graph-popularity-gross',
          title: 'Top 10 graphs seen',
          type: 'TYPE_TABLE_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'half' },
            {
              key: 'columns',
              value: [
                { objectKey: 'graph', label: 'Graph', type: 'string' },
                { objectKey: 'dashboard', label: 'Dashboard', type: 'string' },
                { objectKey: 'value', label: 'Number of times seen', type: 'number' },
              ],
            },
          ],
          query: 'top-10-graph-popularity-gross',
        },
        {
          name: 'bottom-10-graph-popularity-gross',
          title: 'Bottom 10 graphs seen',
          type: 'TYPE_TABLE_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'half' },
            {
              key: 'columns',
              value: [
                { objectKey: 'graph', label: 'Graph', type: 'string' },
                { objectKey: 'dashboard', label: 'Dashboard', type: 'string' },
                { objectKey: 'value', label: 'Number of times seen', type: 'number' },
              ],
            },
          ],
          query: 'bottom-10-graph-popularity-gross',
        },
        {
          name: 'top-10-unique-user-graph-popularity',
          title: 'Top 10 graphs visited by unique users',
          type: 'TYPE_TABLE_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'half' },
            {
              key: 'columns',
              value: [
                { objectKey: 'graph', label: 'Graph', type: 'string' },
                { objectKey: 'dashboard', label: 'Dashboard', type: 'string' },
                { objectKey: 'value', label: 'Number of users', type: 'number' },
              ],
            },
          ],
          query: 'top-10-unique-user-graph-popularity',
        },
        {
          name: 'bottom-10-unique-user-graph-popularity',
          title: 'Bottom 10 graphs visited by unique users',
          type: 'TYPE_TABLE_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'half' },
            {
              key: 'columns',
              value: [
                { objectKey: 'graph', label: 'Graph', type: 'string' },
                { objectKey: 'dashboard', label: 'Dashboard', type: 'string' },
                { objectKey: 'value', label: 'Number of users', type: 'number' },
              ],
            },
          ],
          query: 'bottom-10-unique-user-graph-popularity',
        },
        {
          name: 'top-10-unique-account-graph-popularity',
          title: 'Top 10 graphs visited by unique accounts',
          type: 'TYPE_TABLE_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'half' },
            {
              key: 'columns',
              value: [
                { objectKey: 'graph', label: 'Graph', type: 'string' },
                { objectKey: 'dashboard', label: 'Dashboard', type: 'string' },
                { objectKey: 'value', label: 'Number of accounts', type: 'number' },
              ],
            },
          ],
          query: 'top-10-unique-account-graph-popularity',
        },
        {
          name: 'bottom-10-unique-account-graph-popularity',
          title: 'Bottom 10 graphs visited by unique accounts',
          type: 'TYPE_TABLE_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'half' },
            {
              key: 'columns',
              value: [
                { objectKey: 'graph', label: 'Graph', type: 'string' },
                { objectKey: 'dashboard', label: 'Dashboard', type: 'string' },
                { objectKey: 'value', label: 'Number of accounts', type: 'number' },
              ],
            },
          ],
          query: 'bottom-10-unique-account-graph-popularity',
        },
        {
          name: 'top-10-time-spent-graph-popularity',
          title: 'Top 10 time spent graph popularity',
          type: 'TYPE_TABLE_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'half' },
            {
              key: 'columns',
              value: [
                { objectKey: 'graph', label: 'Graph', type: 'string' },
                { objectKey: 'dashboard', label: 'Dashboard', type: 'string' },
                { objectKey: 'value', label: 'Number of minutes', type: 'minutes' },
              ],
            },
          ],
          query: 'top-10-time-spent-graph-popularity',
        },
        {
          name: 'bottom-10-time-spent-graph-popularity',
          title: 'Bottom 10 time spent graph popularity',
          type: 'TYPE_TABLE_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [
            { key: 'width', value: 'half' },
            {
              key: 'columns',
              value: [
                { objectKey: 'graph', label: 'Graph', type: 'string' },
                { objectKey: 'dashboard', label: 'Dashboard', type: 'string' },
                { objectKey: 'value', label: 'Number of minutes', type: 'minutes' },
              ],
            },
          ],
          query: 'bottom-10-time-spent-graph-popularity',
        },
      ],
    },
    {
      name: 'sharing-metrics',
      title: 'Sharing Metrics',
      visibility: false,
      graphs: [
        {
          name: 'top-10-shared-dashboards',
          title: 'Top 10 dashboards',
          type: 'TYPE_BAR_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [{ key: 'width', value: 'full' }],
          query: 'top-10-shared-dashboards',
        },
        {
          name: 'top-10-shared-graphs',
          title: 'Top 10 graphs',
          type: 'TYPE_BAR_CHART',
          data: [{ key: 'data', value: 'data' }],
          config: [{ key: 'width', value: 'full' }],
          query: 'top-10-shared-graphs',
        },
      ],
    },
  ],
};
