import React, { Fragment } from 'react';

import map from 'lodash/map';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({ link: { textDecoration: 'none', color: theme.palette.custom.black } });

const newTabProps = { target: '_blank', rel: 'noopener noreferrer' };
const links = [
  {
    key: 'cookies',
    label: 'Cookie Settings',
    href: '/cookie-policy',
    ...newTabProps,
  },
  {
    key: 'privacy',
    label: 'Privacy Policy',
    href: '/privacy-policy',
    ...newTabProps,
  },
  {
    key: 'terms',
    label: 'Terms & Conditions',
    href: '/terms',
    ...newTabProps,
  },
];

const PrivacyCookieLinks = ({ classes, separator = ' | ' }) => (
  <Fragment>
    {map(links, ({ label, ...rest }, index) => (
      <Fragment key={rest.key}>
        <a {...rest} className={classes.link}>
          {label}
        </a>
        {`${index < links.length - 1 ? separator : ''}`}
      </Fragment>
    ))}
  </Fragment>
);

export default withStyles(styles)(PrivacyCookieLinks);
