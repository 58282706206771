import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import { ReactComponent as ClearFiltersIcon } from './assets/clear.svg';
import { Tooltip } from '@material-ui/core';

const styles = (theme) => ({
  button: {
    '&:hover .download-path': {
      fill: theme.palette.custom.blue2,
    },
    '& svg': {
      width: '1.6rem',
      height: '1.6rem',
    },
  },
  tooltip: {
    backgroundColor: theme.palette.custom.white,
    boxShadow: '-2px 3px 10px 0 rgba(0, 0, 0, 0.16)',
    border: 'solid 1px #e7e7ef',
    borderRadius: 10,
    padding: '1.1rem',
    color: theme.palette.custom.black,
    fontSize: '0.8rem',
  },
});

const ClearFiltersButton = ({ classes, onClick }) => (
  <Tooltip title="Clear All Filters" classes={{ tooltip: classes.tooltip }} placement="right">
    <IconButton className={classes.button} onClick={onClick}>
      <ClearFiltersIcon />
    </IconButton>
  </Tooltip>
);

export default withStyles(styles)(ClearFiltersButton);
