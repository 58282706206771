import React, { useEffect, useRef } from 'react';

import { connect } from 'react-redux';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import pick from 'lodash/pick';

import Grid from '@material-ui/core/Grid';

import Title from '../SectionTitle';
import Subtitle from '../SectionSubtitle';
import Card from '../Card';
import SectionFiltersForm from '../SectionFiltersForm';
import Text from '../Text';
import hasAccess from '../ContactUs/utils/hasAccess';
import { find } from 'lodash';

const DASHBOARD_STATIC_HEADER_HEIGHT = 150;

const Section = ({
  _id,
  title,
  text,
  cards,
  filters,
  appliedFilters,
  notify,
  benchmarking,
  cardProps,
  id,
  hasAccess,
  index,
}) => {
  const stickyRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const sectionTop =
        stickyRef.current.getBoundingClientRect().top - DASHBOARD_STATIC_HEADER_HEIGHT;
      const sectionBottom = stickyRef.current.getBoundingClientRect().bottom;
      const currentOffset = window.pageYOffset;
      const isScrollingInside = currentOffset > sectionTop && sectionBottom > 0 && sectionTop < 0;

      if (isScrollingInside) {
        notify(_id);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Grid
      xs={12}
      item
      ref={stickyRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      id={`section-item-${index}`}
    >
      <Title> {title} </Title>
      <Subtitle>{text}</Subtitle>
      {!isEmpty(filters) && <SectionFiltersForm items={filters} form={_id} />}
      {!isEmpty(benchmarking) && <SectionFiltersForm items={benchmarking} form={_id} />}
      <Grid container spacing={2} style={{ justifyContent: 'center', maxWidth: 1999 }}>
        {map(cards, (item, index) => (
          <>
            {(item.chart.query.name || item.chartImageUrl || item.authorImageUrl) && (
              <Card
                key={`section-item-${index}`}
                id={`${id}-${index}`}
                {...item}
                {...cardProps}
                sectionFilters={filters}
                appliedFilters={appliedFilters}
                licenseTier={item['license_tier']}
                sampleVisible={get(
                  find(get(item, ['chart', 'config'], []), { key: 'showLocalSamples' }),
                  'value',
                  get(item, ['chart', 'type'], '') === 'TYPE_BAR_CHART_SCORE' ? 'false' : 'true',
                )}
              />
            )}
            {(item.heading || item.content) && (
              <Text
                id={`${id}-${index}`}
                heading={item.heading}
                content={item.content}
                footnote={item.footnote}
                locked={!hasAccess && item['license_tier'] === 'paid'}
              />
            )}
          </>
        ))}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state, { _id: sectionId }) => {
  const globalFiltersAppliedValues = pick(
    get(state, ['filtersData', 'byId']),
    get(state, ['filtersData', 'root']),
  );

  const sectionFiltersAppliedValues = pick(
    get(state, ['filtersData', 'byId']),
    get(state, ['filtersData', 'bySection', sectionId]),
  );

  return {
    appliedFilters: {
      ...globalFiltersAppliedValues,
      ...sectionFiltersAppliedValues,
    },
    hasAccess: hasAccess(state),
  };
};

export default connect(mapStateToProps)(Section);
