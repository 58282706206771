import { indexOf, isEmpty } from 'lodash';

import Subscriptions from '../Admin/Subscriptions';
import OrganisationsView from '../Admin/Organisations';
import OrganisationView from '../Admin/Organisation';
import AnalyticsView from '../Admin/Analytics';
import UsersView from '../Admin/Users';
import DashboardsView from '../Admin/Dashboards';
import ReportsView from '../Admin/Reports';
import BriefingsView from '../Admin/Briefings';

const admin = [
  { menuItem: 'USERS', url: '/admin/users', Component: UsersView },
  { menuItem: 'SUBSCRIPTIONS', url: '/admin/subscriptions', Component: Subscriptions },
  { menuItem: 'ORGANISATIONS', url: '/admin/organisations', Component: OrganisationsView },
  { menuItem: '', url: '/admin/organisations/:organisationId', Component: OrganisationView },
  { menuItem: 'DASHBOARDS', url: '/admin/dashboards', Component: DashboardsView },
  { menuItem: 'REPORTS', url: '/admin/reports', Component: ReportsView },
  { menuItem: 'BRIEFINGS', url: '/admin/briefings', Component: BriefingsView },
  { menuItem: 'ANALYTICS', url: '/admin/analytics', Component: AnalyticsView },
];

const marketing = [
  // { menuItem: 'USERS', url: '/admin/users', Component: UsersView },
  // { menuItem: 'SUBSCRIPTIONS', url: '/admin/subscriptions', Component: Subscriptions },
  // { menuItem: 'ORGANISATIONS', url: '/admin/organisations', Component: OrganisationsView },
  // { menuItem: '', url: '/admin/organisations/:organisationId', Component: OrganisationView },
  { menuItem: 'ANALYTICS', url: '/admin/analytics', Component: AnalyticsView },
];

const sales = [
  { menuItem: 'USERS', url: '/admin/users', Component: UsersView },
  { menuItem: 'SUBSCRIPTIONS', url: '/admin/subscriptions', Component: Subscriptions },
  { menuItem: 'ORGANISATIONS', url: '/admin/organisations', Component: OrganisationsView },
  { menuItem: '', url: '/admin/organisations/:organisationId', Component: OrganisationView },
  { menuItem: 'DASHBOARDS', url: '/admin/dashboards', Component: DashboardsView },
  { menuItem: 'ANALYTICS', url: '/admin/analytics', Component: AnalyticsView },
];

const analyst = [
  // { menuItem: 'USERS', url: '/admin/users', Component: UsersView },
  // { menuItem: 'SUBSCRIPTIONS', url: '/admin/subscriptions', Component: Subscriptions },
  // { menuItem: 'ORGANISATIONS', url: '/admin/organisations', Component: OrganisationsView },
  // { menuItem: '', url: '/admin/organisations/:organisationId', Component: OrganisationView },
  { menuItem: 'ANALYTICS', url: '/admin/analytics', Component: AnalyticsView },
];

const getRoutes = (roles) => {
  if (isEmpty(roles)) return [];

  if (indexOf(roles, 'admin') !== -1) return admin;
  if (indexOf(roles, 'marketing') !== -1) return marketing;
  if (indexOf(roles, 'sales') !== -1) return sales;
  if (indexOf(roles, 'analyst') !== -1) return analyst;

  return [];
};

export default getRoutes;
