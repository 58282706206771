import React from 'react';

import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import MuiAlert, { Color } from '@material-ui/lab/Alert';

interface SnackBarWrapperProps extends SnackbarProps {
  severity: Color;
}

const SnackBarWrapper = ({
  open,
  severity,
  message,
  anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
}: SnackBarWrapperProps) => {
  return (
    <Snackbar anchorOrigin={anchorOrigin} open={open}>
      <MuiAlert elevation={6} variant="filled" severity={severity}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default SnackBarWrapper;
