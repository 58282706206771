import React from 'react';
import cn from 'classnames';

import isEmpty from 'lodash/isEmpty';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: { textAlign: 'left', padding: '5px 0' },
  control: {
    alignItems: 'flex-start',
    marginLeft: 0,
  },
  label: {
    marginLeft: '0.5rem',
    fontSize: '0.9rem',
  },
  checkbox: {
    color: theme.palette.custom.gray4,
    padding: 0,
    '& svg': {
      width: '1.1rem',
      height: '1.1rem',
    },
  },
  error: {
    color: '#eb0b30',
    fontWeight: 'bold',
  },
});

const CheckboxWrapper = ({ classes, name, label, input, meta: { touched, error } }) => {
  return (
    <FormGroup
      classes={{ root: cn(classes.root, { [classes.error]: !isEmpty(error) && touched }) }}
    >
      <FormControlLabel
        classes={{
          root: classes.control,
          label: cn(classes.label, { [classes.error]: !isEmpty(error) && touched }),
        }}
        control={
          <Checkbox
            className={cn(classes.checkbox, { [classes.error]: !isEmpty(error) && touched })}
            name={name}
            color="primary"
            onClick={(e) => input.onChange(e.target.checked)}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

export default withStyles(styles)(CheckboxWrapper);
