import moment from 'moment';
import { MAX_DATE } from '../../../../../../components/Admin/SubscriptionForm/SubscriptionForm';

export const getExpirationDateDiff = (expirationDate) => {
  // Check for indefinite MAX_DATE
  if (new Date(expirationDate).getFullYear() === MAX_DATE.getFullYear()) return 'Indefinite';
  const expiring = moment(expirationDate);
  const now = moment();
  const diff = parseFloat(expiring.diff(now, 'days', true).toFixed(0));

  if (diff > 0) return `${diff} days`;

  return 'expired';
};
