import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';
import get from 'lodash/get';

class SourcesHelper {
  static calculateSources({ sources, additionalSources }) {
    if (isEmpty(sources)) return [];
    if (isEmpty(additionalSources)) return sources;

    const filter = get(additionalSources, 'filter', []);
    const reject = get(additionalSources, 'reject', []);

    if (!isEmpty(filter)) {
      // [de14, de15, de16], [de15, de16] => [de15, de16]
      return intersection(sources, filter);
    }

    if (!isEmpty(reject)) {
      // [de14, de15, de16], [de15, de16] => [de14]
      return difference(sources, reject);
    }

    return sources;
  }

  static getAppliedSources({ sources, chartSources, filterSources }) {
    let result = this.calculateSources({ sources, additionalSources: chartSources });
    result = this.calculateSources({ sources: result, additionalSources: filterSources });

    return result;
  }
}

export default SourcesHelper;
