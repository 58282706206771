import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import isEmpty from 'lodash/isEmpty';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Layout from './components/Layout';
import Dashboards from './components/Dashboards';
import TextSearch from './components/TextSearch';
import DAAs from './components/DAAs';
import Reports from './components/Reports';
import { Alert } from '@material-ui/lab';
import { find, get } from 'lodash';
import { connect } from 'react-redux';

import background from './assets/background.svg';

import { ReactComponent as BriefingsIcon } from './assets/briefings.svg';
import { ReactComponent as CustomIcon } from './assets/custom.svg';
import { ReactComponent as FavouriteIcon } from './assets/favourite.svg';
import { ReactComponent as FreeIcon } from './assets/free.svg';
import { ReactComponent as PreviewIcon } from './assets/preview.svg';
import { ReactComponent as SyndicatedIcon } from './assets/syndicated.svg';
import {
  TYPE_ALL,
  TYPE_BRIEFINGS,
  TYPE_CUSTOM,
  TYPE_FAVOURITE,
  TYPE_FREE,
  TYPE_PREVIEW,
  TYPE_SYNDICATED,
} from './data';

const styles = (theme) => ({
  root: {
    height: '100%',
  },
  dashboardsContainer: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '90%',
  },
  textSearchContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${background})`,
    padding: '0.5rem',
  },
  selectorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 6px 9px 0 rgba(188, 198, 210, 0.15)',
    padding: '0.5rem',
    marginBottom: '2rem',
  },
  infoContainer: {
    fontFamily: 'Nunito Sans',
    fontSize: '1rem',
    color: theme.palette.custom.gray4,
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
  },
  textSearchContent: {
    marginLeft: 4,
    fontWeight: 'bold',
    color: theme.palette.custom.gray4,
  },
  subscriptionAlert: {
    backgroundColor: '#f6a300',
    color: 'white',
    marginLeft: '25.25rem',
    borderRadius: 0,
    '& .MuiAlert-icon': {
      color: 'white',
    },
  },
  moreResearchAlert: {
    backgroundColor: theme.palette.custom.blue2,
    color: 'white',
    marginLeft: '25.25rem',
    borderRadius: 0,
    '& .MuiAlert-icon': {
      color: 'white',
    },
  },
  actionCell: {
    color: '#f6a300',
    backgroundColor: 'white',
    border: `1px solid #f6a300`,
    padding: '0.25rem 1.1rem',
    borderRadius: 50,
    fontSize: '0.8rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginLeft: '1.4rem',
  },
  actionCellBlue: {
    color: theme.palette.custom.blue2,
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.custom.blue2}`,
    padding: '0.25rem 1.1rem',
    borderRadius: 50,
    fontSize: '0.8rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginLeft: '1.4rem',
  },
  pageContainer: {
    height: '100%',
  },
  selectorBorder: {
    borderLeft: `1px solid ${theme.palette.custom.gray4}`,
  },
  selector: {
    padding: '0.5rem 1rem',
    margin: 0,
    cursor: 'pointer',
    fontSize: '0.875rem',
  },
  activeListItem: {
    color: theme.palette.custom.blue2,
    fontWeight: 600,
    '& svg': {
      '& path': {
        fill: `${theme.palette.custom.blue2} !important`,
      },
    },
  },
});

const mailto = 'sales@slashdata.co';
const relations = 'clientrelations@slashdata.co';
const cc = 'technology@slashdata.co';

const Home = ({ classes, subscriptions, email, firstName, lastName, roles }) => {
  const [searchCriteria, setSearchCriteria] = useState({});
  const [textSearch, setTextSearch] = useState('');
  const [type, setType] = useState(TYPE_ALL.TYPE);
  const path = window.location.pathname;

  const expirationAboutToExpire = useCallback(() => {
    const subscriptionsNotification = find(subscriptions, (subscription) => {
      const subscriptionExpiration = new Date(get(subscription, 'expirationDate', new Date()));
      const today = new Date();
      if (
        subscriptionExpiration > today &&
        subscriptionExpiration < new Date(today.setMonth(today.getMonth() + 1))
      ) {
        return true;
      }
    });
    return path === '/dashboards' && !isEmpty(subscriptionsNotification);
  }, [subscriptions]);

  const sendEmail = useCallback(() => {
    window.open(
      `mailto:${mailto}?cc=${cc}&subject=${encodeURIComponent(
        'Dashboard Access Renewal',
      )}&body=${encodeURIComponent(
        `Email: ${email}\nName: ${firstName}\nLast name: ${lastName}\nRole: ${roles}`,
      )}`,
    );
  }, [email, firstName, lastName, roles]);

  const sendRelationsEmail = useCallback(() => {
    window.open(
      `mailto:${relations}?cc=${cc}&subject=${encodeURIComponent(
        'Reports Available',
      )}&body=${encodeURIComponent(
        `Email: ${email}\nName: ${firstName}\nLast name: ${lastName}\nRole: ${roles}`,
      )}`,
    );
  }, [email, firstName, lastName, roles]);

  const changeType = useCallback((newType) => {
    setType(newType);
    setSearchCriteria({ ...searchCriteria, category: newType });
  });

  const changeFilters = useCallback((filters) => {
    setSearchCriteria({ ...filters, category: type });
  });

  return (
    <div className={classes.pageContainer}>
      {expirationAboutToExpire() && (
        <Alert className={classes.subscriptionAlert} severity="warning">
          Some of your subscription will expire soon. Renew to ensure uninterrupted access.
          <span onClick={sendEmail} className={classes.actionCell}>
            Contact Us
          </span>
        </Alert>
      )}
      {(path === '/reports' || path === '/briefings') && (
        <Alert className={classes.moreResearchAlert} severity="warning">
          This page is still under construction. For more information on all available research,
          please contact us.
          <span onClick={sendRelationsEmail} className={classes.actionCellBlue}>
            Contact Us
          </span>
        </Alert>
      )}
      <Layout onChange={changeFilters}>
        <Grid container className={classes.root}>
          <Grid item xs={12} className={classes.textSearchContainer}>
            <TextSearch value={textSearch} onChange={setTextSearch} />
          </Grid>
          <Grid item xs={12} className={classes.selectorContainer}>
            <p
              className={cn(classes.selector, {
                [classes.activeListItem]: type === TYPE_ALL.TYPE,
              })}
              onClick={() => changeType(TYPE_ALL.TYPE)}
            >
              All
            </p>
            <p
              className={cn(classes.selector, classes.selectorBorder, {
                [classes.activeListItem]: type === TYPE_FAVOURITE.TYPE,
              })}
              onClick={() => changeType(TYPE_FAVOURITE.TYPE)}
            >
              <FavouriteIcon /> Favourites
            </p>
            {path !== '/daa' && (
              <p
                className={cn(classes.selector, classes.selectorBorder, {
                  [classes.activeListItem]: type === TYPE_SYNDICATED.TYPE,
                })}
                onClick={() => changeType(TYPE_SYNDICATED.TYPE)}
              >
                <SyndicatedIcon /> Syndicated Research
              </p>
            )}
            {path !== '/daa' && (
              <p
                className={cn(classes.selector, classes.selectorBorder, {
                  [classes.activeListItem]: type === TYPE_CUSTOM.TYPE,
                })}
                onClick={() => changeType(TYPE_CUSTOM.TYPE)}
              >
                <CustomIcon /> Custom Research
              </p>
            )}
            {path !== '/daa' && (
              <p
                className={cn(classes.selector, classes.selectorBorder, {
                  [classes.activeListItem]: type === TYPE_FREE.TYPE,
                })}
                onClick={() => changeType(TYPE_FREE.TYPE)}
              >
                <FreeIcon /> Free {path === '/reports' ? 'Reports' : 'Dashboards'}
              </p>
            )}
            {path !== '/daa' && (
              <p
                className={cn(classes.selector, classes.selectorBorder, {
                  [classes.activeListItem]: type === TYPE_PREVIEW.TYPE,
                })}
                onClick={() => changeType(TYPE_PREVIEW.TYPE)}
              >
                <PreviewIcon /> Preview Only
              </p>
            )}
            {path === '/reports' && (
              <p
                className={cn(classes.selector, classes.selectorBorder, {
                  [classes.activeListItem]: type === TYPE_BRIEFINGS.TYPE,
                })}
                onClick={() => changeType(TYPE_BRIEFINGS.TYPE)}
              >
                <BriefingsIcon /> Briefings
              </p>
            )}
          </Grid>
          {!isEmpty(textSearch) && (
            <Grid item xs={12} className={classes.infoContainer}>
              <i>Showing results for</i>
              <Typography className={classes.textSearchContent}>{textSearch}</Typography>
            </Grid>
          )}
          <Grid item xs={12} className={classes.dashboardsContainer}>
            {path !== '/daa' && path !== '/reports' && path !== '/briefings' && (
              <Dashboards searchCriteria={searchCriteria} textSearch={textSearch} />
            )}
            {path === '/daa' && <DAAs searchCriteria={searchCriteria} textSearch={textSearch} />}
            {path === '/reports' && (
              <Reports
                searchCriteria={searchCriteria}
                textSearch={textSearch}
                setTextSearch={setTextSearch}
              />
            )}
          </Grid>
        </Grid>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  subscriptions: get(state, ['user', 'subscriptions'], []),
  email: get(state, ['user', 'email']),
  firstName: get(state, ['user', 'firstName']),
  lastName: get(state, ['user', 'lastName']),
  roles: get(state, ['user', 'roles']),
});

export default connect(mapStateToProps)(withStyles(styles)(Home));
