import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import isNull from 'lodash/isNull';
import intersection from 'lodash/intersection';

const getNullColumnIndexes = (data) => {
  const nullColumnIndexes = [];

  forEach(data, (row, rowIndex) => {
    if (rowIndex !== 0) {
      const nullPerRow = reduce(
        row,
        (result, value, columnIndex) => {
          if (!isNull(value)) return result;

          return [...result, columnIndex];
        },
        [],
      );

      nullColumnIndexes.push(nullPerRow);
    }
  });

  return intersection(...nullColumnIndexes);
};

export default getNullColumnIndexes;
