import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { API } from 'aws-amplify';
import { map } from 'lodash';

import { makeStyles } from '@material-ui/core';

import { DropDown, DatePicker } from '../../../../../components/UI';
import { ReduxState } from '../../@types/Reducer';
import {
  SET_END_DATE_FILTER,
  SET_EXCLUDED_ACCOUNTS_FILTER,
  SET_START_DATE_FILTER,
} from '../../../../../state/analytics/types';

const useStyles = makeStyles({
  root: {
    padding: 24,
  },
  section: { marginTop: 12 },
  card: { marginTop: 12 },
  filters: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
  dateFilterWrapper: { width: 120, marginRight: 4 },
});

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const GlobalFilters = () => {
  const classes = useStyles();
  const [options, setOptions] = useState([{ name: 'slashdata', text: 'slashdata' }]);
  const dispatch = useDispatch();
  const startDate = useSelector((state: ReduxState) => state.analytics.filters.startDate);
  const endDate = useSelector((state: ReduxState) => state.analytics.filters.endDate);
  const excludedAccounts = useSelector(
    (state: ReduxState) => state.analytics.filters.excludedAccounts,
  );
  const jwtToken = useSelector((state: ReduxState) => state.user.jwtToken);

  const fetchOrganisations = useCallback(async () => {
    try {
      const params = {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      };
      const organisations = await API.get('users', '/organisations', params);

      setOptions(map(organisations, ({ name }) => ({ text: name, name: name.toLowerCase() })));
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchOrganisations();
  }, []);

  return (
    <div className={classes.filters}>
      <div className={classes.dateFilterWrapper}>
        <DatePicker
          value={startDate}
          required={false}
          label="Start Date"
          name="startDate"
          type="text"
          disableFuture={true}
          onChange={(value) =>
            dispatch({
              type: SET_START_DATE_FILTER,
              payload: moment(value).startOf('day').format(DATE_FORMAT),
            })
          }
        />
      </div>
      <div className={classes.dateFilterWrapper}>
        <DatePicker
          value={endDate}
          required={false}
          label="End Date"
          name="endDate"
          type="text"
          disableFuture={true}
          onChange={(value) =>
            dispatch({
              type: SET_END_DATE_FILTER,
              payload: moment(value).endOf('day').format(DATE_FORMAT),
            })
          }
        />
      </div>
      <DropDown
        label="Excluded accounts"
        tooltip={excludedAccounts.join(', ')}
        counter={excludedAccounts.length}
        optionsProps={{
          options,
          selectedOptions: excludedAccounts,
          onChange: (values) => dispatch({ type: SET_EXCLUDED_ACCOUNTS_FILTER, payload: values }),
          selection: 'MULTI',
          withBorder: true,
          withScroll: true,
        }}
      />
    </div>
  );
};

export default GlobalFilters;
