import {
  TYPE_BAR_CHART,
  TYPE_BAR_CHART_BENCHMARK,
  TYPE_BAR_CHART_MULTI,
  TYPE_BAR_CHART_SCORE,
  TYPE_BAR_CHART_STACKED,
  TYPE_BAR_CHART_STACKED_MULTI,
  TYPE_COLUMN_CHART,
  TYPE_COLUMN_CHART_BENCHMARK,
  TYPE_COLUMN_CHART_MULTI,
  TYPE_COLUMN_CHART_STACKED,
  TYPE_COLUMN_CHART_STACKED_LOCAL,
  TYPE_DONUT_CHART,
  TYPE_PIE_CHART,
} from '../../../components/Graphs/GraphsRenderer/types';
import BAR_CHART_DATA from './TYPE_BAR_CHART.json';
import BAR_CHART_MULTI_DATA from './TYPE_BAR_CHART_MULTI.json';
import BAR_CHART_STACKED_DATA from './TYPE_BAR_CHART_STACKED.json';
import BAR_CHART_STACKED_MULTI_DATA from './TYPE_BAR_CHART_STACKED_MULTI.json';
import BAR_CHART_SCORE_DATA from './TYPE_BAR_CHART_SCORE.json';
import BAR_CHART_BENCHMARK_DATA from './TYPE_BAR_CHART_BENCHMARK.json';
import COLUMN_CHART_DATA from './TYPE_COLUMN_CHART.json';
import COLUMN_CHART_MULTI_DATA from './TYPE_COLUMN_CHART_MULTI.json';
import COLUMN_CHART_BENCHMARK_DATA from './TYPE_COLUMN_CHART_BENCHMARK.json';
import COLUMN_CHART_STACKED_DATA from './TYPE_COLUMN_CHART_STACKED.json';
import COLUMN_CHART_STACKED_LOCAL_DATA from './TYPE_COLUMN_CHART_STACKED_LOCAL.json';
import PIE_CHART_DATA from './TYPE_DONUT_CHART.json';
import TABLE_CHART_DATA from './TYPE_TABLE_CHART.json';

export const dummyDataSelector = (type) => {
  switch (type) {
    case TYPE_BAR_CHART:
      return BAR_CHART_DATA;
    case TYPE_BAR_CHART_MULTI:
      return BAR_CHART_MULTI_DATA;
    case TYPE_BAR_CHART_STACKED:
      return BAR_CHART_STACKED_DATA;
    case TYPE_BAR_CHART_STACKED_MULTI:
      return BAR_CHART_STACKED_MULTI_DATA;
    case TYPE_BAR_CHART_SCORE:
      return BAR_CHART_SCORE_DATA;
    case TYPE_BAR_CHART_BENCHMARK:
      return BAR_CHART_BENCHMARK_DATA;
    case TYPE_COLUMN_CHART:
      return COLUMN_CHART_DATA;
    case TYPE_COLUMN_CHART_MULTI:
      return COLUMN_CHART_MULTI_DATA;
    case TYPE_COLUMN_CHART_BENCHMARK:
      return COLUMN_CHART_BENCHMARK_DATA;
    case TYPE_COLUMN_CHART_STACKED:
      return COLUMN_CHART_STACKED_DATA;
    case TYPE_COLUMN_CHART_STACKED_LOCAL:
      return COLUMN_CHART_STACKED_LOCAL_DATA;
    case TYPE_PIE_CHART:
    case TYPE_DONUT_CHART:
      return PIE_CHART_DATA;
    default:
      return TABLE_CHART_DATA;
  }
};
