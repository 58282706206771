import ceil from 'lodash/ceil';
import clamp from 'lodash/clamp';
import floor from 'lodash/floor';

import { FORMATTER_SCORE } from '../RechartBarChartEngine/formatters';
import palette from '../palette';
import { axisLine, verticalCartesianGrid, numericalXAxis } from '../config';
import themePalette from '../../../theme/palette';

const BAR_CHART_LAYOUT_VERTICAL = 'vertical';
const LEGEND_TYPE_DEFAULT = 'DEFAULT';

/**
 * Diff:
 * - layout
 * - swap axis
 */
export default {
  rootProps: {
    layout: BAR_CHART_LAYOUT_VERTICAL,
    margin: {
      top: 10,
      right: 25,
      left: 0,
      bottom: 5,
    },
    stackOffset: 'sign',
  },
  series: [
    {
      dataKey: 'score',
      fill: palette[0],
      stackId: null,
      label: {
        position: 'center',
        formatter: FORMATTER_SCORE,
      },
      // minPointSize: 1,
    },
  ],
  cartesianGridProps: verticalCartesianGrid,
  legendProps: {
    type: LEGEND_TYPE_DEFAULT,
    hide: false,
    verticalAlign: 'top',
    align: 'right',
    wrapperStyle: {
      paddingBottom: '20px',
    },
  },
  tooltipProps: { hide: false, formatter: FORMATTER_SCORE },
  yAxisProps: {
    type: 'category',
    dataKey: 'name',
    height: 30,
    axisLine,
    tickLine: false,
    showSamplesInAxis: true,
    hide: false,
    angle: 0,
    textAnchor: 'end',
    interval: 0,
    // Calc width based on content
    adjustWidth: true,
    ticksProps: {
      type: 'custom',
    },
    responsive: {
      xs: {
        ticksProps: {
          type: 'custom',
          width: 50,
        },
      },
    },
  },
  xAxisProps: {
    ...numericalXAxis,
    domain: [
      (dataMin) => clamp(floor(dataMin, -1), -100, 0),
      (dataMax) => clamp(ceil(dataMax, -1), 0, 100),
    ],
    label: {
      value: '',
      offset: 0,
      angle: 0,
      position: 'insideBottomLeft',
      style: {
        fill: themePalette.custom.black,
        fontSize: 13,
        fontWeight: 'bold',
      },
    },
    scale: 'linear',
  },
};
