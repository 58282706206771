import { get, find, map, reduce, omit, head, concat, isEmpty } from 'lodash';

import normalizeTableData from './normalizeTableData';
import shellSort from './shellSort';
import concatRows from './concatRows';

const normalizeGridRows = ({ data, dataTables, sorting }) => {
  const dataTableName = get(find(dataTables, { key: 'data' }), 'value');
  const sampleTableName = get(find(dataTables, { key: 'sample' }), 'value');
  const intensityTableName = get(find(dataTables, { key: 'intensity' }), 'value');

  const sampleAndData = concatRows({
    data,
    destinationTableName: sampleTableName,
    sourceTables: [dataTableName],
    tableName: 'sampleAndData',
  });
  if (sampleAndData === null) return [];

  // Data table already cleansed
  //const dataTable = normalizeTableData({ data, table });
  const dataTable = normalizeTableData({ data: [sampleAndData], table: 'sampleAndData' });
  const intensityTable = normalizeTableData({ data: data, table: intensityTableName });

  let rowToOrderBy = {};
  let colsOrdered = Object.keys(get(dataTable, 0, {}));

  if (sorting && sorting.by) {
    rowToOrderBy = find(dataTable, { name: head(sorting.by) });

    // Handle col exclusion
    // Excluded cols will be placed on bottom
    if (sorting.exclude) {
      rowToOrderBy = omit(rowToOrderBy, sorting.exclude);
    }

    colsOrdered = shellSort(rowToOrderBy);

    if (sorting.minimum !== null) {
      colsOrdered.forEach((col, index) => {
        if (rowToOrderBy[col] < sorting.minimum) {
          colsOrdered = colsOrdered.slice(0, index);
          return;
        }
      });
    }

    colsOrdered = sorting.exclude ? concat(colsOrdered, sorting.exclude) : colsOrdered;
  }

  //console.log(JSON.stringify(intensityTable, null, 4));

  const appendRow = (rows, currentRow, index) => {
    const rowIntensity =
      index > 0
        ? intensityTable[index - (isEmpty(sampleTableName) ? 0 : 1)]
        : isEmpty(sampleTableName)
        ? intensityTable[index]
        : [];
    const orderedRow = map(colsOrdered, (key) =>
      key !== 'name' ? [currentRow[key], get(rowIntensity, key, 0)] : [currentRow[key]],
    );

    return [...rows, orderedRow];
  };

  const result = reduce(dataTable, appendRow, [colsOrdered]);
  // Replace name with an empty string
  const headers = result.shift();
  headers.shift();
  headers.unshift('');
  result.unshift(headers);

  //console.log(JSON.stringify(result, null, 4));

  return result;
};

export default normalizeGridRows;
