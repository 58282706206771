import { Auth } from 'aws-amplify';

import {
  SET_CURRENT_USER,
  SIGN_OUT,
  CREATE_BOOKMARK,
  REMOVE_BOOKMARK,
  SET_USER_SUBSCRIPTIONS,
  SET_USER_NOTIFICATIONS,
  SET_SEARCH_KEY,
} from './types';

export const signOut = () => async (dispatch) => {
  try {
    console.log('signout');
    await Auth.signOut();
    dispatch({
      type: SIGN_OUT,
    });
  } catch (error) {
    console.log('An error occurred');
    console.log(error);
  }
};

export const setSearchKey = (payload) => ({
  type: SET_SEARCH_KEY,
  payload,
});

export const setCurrentUser = (payload) => ({
  type: SET_CURRENT_USER,
  payload,
});

export const createBookmark = (databoardId) => ({
  type: CREATE_BOOKMARK,
  payload: databoardId,
});

export const removeBookmark = (databoardId) => ({
  type: REMOVE_BOOKMARK,
  payload: databoardId,
});

export const setUserSubscriptions = (subscriptions) => ({
  type: SET_USER_SUBSCRIPTIONS,
  payload: { subscriptions },
});

export const setUserNotifications = (notifications) => ({
  type: SET_USER_NOTIFICATIONS,
  payload: { notifications },
});
