import { isEmpty, map } from 'lodash';
import filter from 'lodash/filter';

import hasDashboardAccess from '../../../../../utils/hasDashboardAccess';

const getHasNewData = (notifications, resourceId, userId) => {
  const today = new Date();
  const notification = filter(notifications, (notification) => {
    return (
      notification.resourceId === resourceId &&
      notification.type === 'NEW_DATA' &&
      new Date(notification.date) < today &&
      new Date(notification.date) < new Date(today.setMonth(today.getMonth() + 1)) &&
      !notification.viewedBy.includes(userId)
    );
  });
  return !isEmpty(notification);
};

const getNewData = (resources, subscriptions, notifications, userId) => {
  const dashboardsUpdated = map(resources, (resource) => {
    const hasNewData =
      hasDashboardAccess(subscriptions, resource._id) &&
      getHasNewData(notifications, resource._id, userId);
    return { ...resource, hasNewData };
  });

  return dashboardsUpdated;
};

export default getNewData;
