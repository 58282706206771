import React from 'react';

import map from 'lodash/map';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const AdminTableWrapper = ({
  classes,
  data,
  columns,
  orderBy,
  order,
  onColumnClick,
  onRowClick,
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {map(columns, ({ label, orderByKey }, colIndex) => (
            <TableCell
              key={`${orderByKey}-${colIndex}`}
              className={classes.cell}
              padding="none"
              sortDirection={orderBy === orderByKey ? order : false}
            >
              <TableSortLabel
                active={orderBy === orderByKey}
                direction={orderBy === orderByKey ? order : 'asc'}
                onClick={() => onColumnClick(orderByKey)}
              >
                {label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {map(data, (dataItem, index) => {
          return (
            <TableRow key={`item-${index}`} className={classes.row}>
              {map(columns, ({ classes: cellClasses, objectKey, formatter }, colIndex) => (
                <TableCell
                  key={`${objectKey}-${colIndex}`}
                  className={cellClasses}
                  padding="none"
                  onClick={() => onRowClick(dataItem)}
                >
                  {formatter(dataItem, objectKey)}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default AdminTableWrapper;
