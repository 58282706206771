import React, { useState, useEffect, useCallback } from 'react';

import { connect } from 'react-redux';

import get from 'lodash/get';
import indexOf from 'lodash/indexOf';

import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import { API } from 'aws-amplify';
import { ReactComponent as EmptyStar } from './assets/emptyStar.svg';
import { ReactComponent as FullStar } from './assets/fullStar.svg';

import { createBookmark, removeBookmark } from '../../../../../../state/user/actions';

const styles = (theme) => ({
  full: {
    fill: theme.palette.custom.blue2,
  },
  container: {
    width: 34,
    height: 33,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'middle',
  },
  loader: {
    width: '10px !important',
    height: '10px !important',
  },
});

const BookmarkButton = ({
  classes,
  userId,
  jwtToken,
  databoardId,
  bookmarks,
  onRemoveBookmark,
  onCreateBookmark,
}) => {
  const [bookmarked, setBookmarked] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const index = indexOf(bookmarks, databoardId);
    setBookmarked(index !== -1);
  }, [databoardId, bookmarks]);

  const toggleBookmark = useCallback(async () => {
    setLoader(true);
    try {
      const result = await API.patch(
        'users',
        `/users/${userId}/databoards/${databoardId}/bookmarks`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        },
      );
      if (result === null) {
        onRemoveBookmark(databoardId);
      } else {
        onCreateBookmark(databoardId);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  }, [databoardId]);

  if (loader)
    return (
      <IconButton>
        <CircularProgress className={classes.loader} />
      </IconButton>
    );

  return (
    <IconButton onClick={toggleBookmark}>
      {bookmarked ? <FullStar className={classes.full} /> : <EmptyStar />}
    </IconButton>
  );
};

const mapStateToProps = (state) => ({
  userId: get(state, ['user', 'userId'], ''),
  jwtToken: get(state, ['user', 'jwtToken'], ''),
  bookmarks: get(state, ['user', 'bookmarks'], []),
});

const mapDispatchToProps = (dispatch) => ({
  onCreateBookmark: (databoardId) => dispatch(createBookmark(databoardId)),
  onRemoveBookmark: (databoardId) => dispatch(removeBookmark(databoardId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BookmarkButton));
