import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import hasDashboardAccess from '../../../../utils/hasDashboardAccess';

const hasAccess = (state) => {
  const databoardId = get(state, ['databoard', 'databoardId']);
  const subscriptions = get(state, ['user', 'subscriptions'], []);

  if (isEmpty(databoardId)) return true;

  return hasDashboardAccess(subscriptions, databoardId);
};

export default hasAccess;
