import React from 'react';

import { Grid } from '@material-ui/core';

import Title from './Title';

interface Props {
  title?: string;
  className?: string;
}

const Section: React.FC<Props> = ({ children, title = '', className = '' }) => {
  return (
    <Grid xs={12} item className={className}>
      <Title>{title}</Title>
      {children}
    </Grid>
  );
};

export default Section;
