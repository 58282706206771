import React, { useRef, useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';

import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import JsPDF from 'jspdf';
import { connect } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import kebabCase from 'lodash/kebabCase';
import get from 'lodash/get';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseButton from '@material-ui/icons/CloseRounded';
import { withStyles } from '@material-ui/core/styles';

import Title from '../CardTitle';
import Subtitle from '../CardSubtitle';
import Chart from '../Chart';
import ExportMenu from './components/ExportMenu';
// import ShareMenu from './components/ShareMenu';
import Copyright from './components/Copyright';
import ChartImage from '../Card/components/ChartImage';
import { GRAPH_DOWNLOAD_IMAGE_EVENT, trackGraphAction } from '../../../state/analytics/actions';

const styles = (theme) => ({
  root: {
    zIndex: `${theme.zIndex.modal} !important`,
  },
  paper: {
    borderRadius: 15,
    maxWidth: 1680,
    width: '95%',
  },
  close: {
    position: 'absolute',
    right: '1.6rem',
    top: '1.6rem',
    color: theme.palette.custom.blue2,
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
  closeIcon: {
    width: '2rem',
    height: '2rem',
  },
  title: {
    paddingLeft: '5rem',
    paddingRight: '5rem',
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'Column',
  },
  subtitle: {
    fontSize: '1.2rem',
    color: theme.palette.custom.black,
    fontFamily: 'Nunito Sans',
    paddingLeft: '5rem',
    paddingRight: '5rem',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  full: { height: '52.8rem' },
  half: { height: '40.6rem' },
  small: { height: '31.5rem' },
  medium: { height: '42rem' },
  large: { height: '63rem' },
  exportableArea: {
    backgroundColor: 'white',
  },
  loader: { display: 'none', justifyContent: 'center', fontSize: '0.9rem' },
  show: {
    visibility: 'visible',
    display: 'flex',
  },
});

const DialogView = ({
  classes,
  open,
  onClose,
  title,
  subtitle,
  cardHeight,
  chartProps,
  chartImageUrl,
  areGlobalFiltersOpen,
  chartName,
  chartQuery,
  trackExport,
}) => {
  const [loader, setLoader] = useState(false);
  const chartRef = useRef(null);

  const handleOnEnter = useCallback(() => {
    if (areGlobalFiltersOpen) {
      document.body.setAttribute('style', 'overflow: hidden;');
    }
  }, [areGlobalFiltersOpen]);

  const handleOnExit = useCallback(() => {
    if (areGlobalFiltersOpen) {
      document.body.setAttribute('style', '');
    }
  }, [areGlobalFiltersOpen]);

  const exportToImage = async (jpeg, callback) => {
    if (isEmpty(chartRef.current)) return false;
    setLoader(true);
    trackExport(chartName, chartQuery);
    // eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(chartRef.current);
    const scale = 2;

    try {
      const blob = await domtoimage.toBlob(node, {
        height: node.offsetHeight * scale,
        style: {
          transform: `scale(${scale}) translate(${node.offsetWidth / 2 / scale}px, ${
            node.offsetHeight / 2 / scale
          }px)`,
        },
        width: node.offsetWidth * scale,
      });
      const postfix = jpeg ? 'jpeg' : 'png';
      saveAs(blob, `${kebabCase(title)}.${postfix}`);
    } catch (error) {
      console.log('Error during exportToPng');
      console.log(error);
    } finally {
      setLoader(false);
      if (isFunction(callback)) callback();
    }
  };

  const exportToPdf = async (callback) => {
    if (isEmpty(chartRef.current)) return false;
    setLoader(true);
    trackExport(chartName, chartQuery);
    // eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(chartRef.current);

    const width = node.offsetWidth;
    const height = node.offsetHeight;
    try {
      const dataUrl = await domtoimage.toPng(node);
      const doc = new JsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [width, height],
      });
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();
      doc.addImage(dataUrl, 'JPEG', 0, 0, pdfWidth, pdfHeight);
      doc.save(kebabCase(title));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('oops, something went wrong!', err);
    } finally {
      setLoader(false);
      if (isFunction(callback)) callback();
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xl"
      classes={{
        root: classes.root,
        container: classes.container,
        paper: classes.paper,
      }}
      onEnter={handleOnEnter}
      onExit={handleOnExit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onClose}
    >
      <IconButton className={classes.close} onClick={onClose}>
        <CloseButton className={classes.closeIcon} />
      </IconButton>
      <div ref={chartRef} className={classes.exportableArea}>
        <DialogTitle id="alert-dialog-title" className={classes.title} disableTypography={true}>
          <Title presentational>{title}</Title>
          <Subtitle className={classes.subtitle}>{subtitle}</Subtitle>
        </DialogTitle>
        <DialogContent
          className={cn(classes.content, { [classes[cardHeight]]: !isEmpty(classes[cardHeight]) })}
        >
          {chartImageUrl ? (
            <ChartImage url={chartImageUrl} />
          ) : (
            <Chart {...chartProps} ref={chartRef} ignoreSeries view="dialog" />
          )}
        </DialogContent>
        <Copyright className={classes.show} />
      </div>
      <div className={cn(classes.loader, { [classes.show]: loader })}>Generating image...</div>
      <DialogActions>
        <ExportMenu exportToImage={exportToImage} exportToPdf={exportToPdf} />
        {/* <ShareMenu /> */}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  areGlobalFiltersOpen: get(state, ['filtersData', 'showGlobalFilters'], false),
});

const mapDispatchToProps = (dispatch) => ({
  trackExport: (chartName, chartQuery) =>
    dispatch(trackGraphAction(GRAPH_DOWNLOAD_IMAGE_EVENT, chartName, chartQuery)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DialogView));
