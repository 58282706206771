import { Auth } from 'aws-amplify';

const resendConfirmationCode = async (username) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await Auth.resendSignUp(username);
      resolve(response);
    } catch (error) {
      console.log('error resending code: ');
      console.log(error);
      reject(error);
    }
  });

export default resendConfirmationCode;
