import React from 'react';
import cn from 'classnames';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.border}`,
    borderTopLeftRadius: '2px',
    borderTopRightRadius: '2px',
    display: 'flex',
    height: '4rem',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    position: 'relative',
  },
  noDivider: {
    borderBottom: 'none',
  },
  noPadding: {
    padding: 0,
  },
});

const PortletHeader = ({ classes, className, noDivider, noPadding, children, ...rest }) => {
  const rootClassName = cn(
    {
      [classes.root]: true,
      [classes.noDivider]: noDivider,
      [classes.noPadding]: noPadding,
    },
    className,
  );

  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  );
};

export default withStyles(styles)(PortletHeader);
