import omit from 'lodash/omit';
import keys from 'lodash/keys';
import flattenDeep from 'lodash/flattenDeep';
import uniq from 'lodash/uniq';
import map from 'lodash/map';

import normalizeTableData from './normalizeTableData';

const normalizeGridColumns = ({ data, table }) => {
  const normalizedData = normalizeTableData({ data, table });
  const allKeys = uniq(flattenDeep(map(normalizedData, (entry) => keys(omit(entry, 'name')))));

  return ['', ...allKeys];
};

export default normalizeGridColumns;
