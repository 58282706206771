import head from 'lodash/head';
import get from 'lodash/get';

import { TYPE_TABLE_CHART } from '../../../components/Graphs/GraphsRenderer/types';
import getMergedSurveySamples from '../../sample/getMergedSurveySamples';

/**
 * Return table chart sample.
 * Currently, it's the only chart for which we need a table for the data and a table for the sample
 */
const getSample = ({ data, type, tableName }) => {
  if (type !== TYPE_TABLE_CHART) return [];

  const mergedSample = getMergedSurveySamples({ data: get(data, 'data'), table: tableName });
  const value = get(head(mergedSample), 'value');

  return [['Sample from survey waves combined', value]];
};

export default getSample;
