import get from 'lodash/get';

export async function errorLogging(graphId, getState, statusCode) {
  const userEmail = get(getState(), ['user', 'email']);
  const userDetaiil = userEmail
    ? userEmail
    : get(getState(), ['user', 'userId']) + ' (email is N/A)';

  const databoardNname = get(getState(), ['databoard', 'title']);
  const graphName = get(getState(), ['charts', 'byId', graphId, 'name']);
  const filterApplied = window.location.search;

  const errorPayload = {
    user: userDetaiil ? userDetaiil : 'N/A',
    databoard: databoardNname ? databoardNname : 'N/A',
    graph: graphName ? graphName : 'N/A',
    filters: filterApplied ? filterApplied : 'none',
    status: '' + statusCode,
    env: process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : 'N/A',
  };
  try {
    await fetch(process.env.REACT_APP_SLACK_LOGS_WEBHOOK_URL, {
      method: 'POST',
      body: JSON.stringify(errorPayload),
    });
  } catch {
    console.log('Error not posted');
  }
}
