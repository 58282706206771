import React, { useCallback } from 'react';

import { connect } from 'react-redux';

import get from 'lodash/get';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import InfoBox from '../InfoBox';
import { ReactComponent as ShareButtonIcon } from './assets/share.svg';
import stringifyFilters, { FILTERS_NOT_READY_FLAG } from '../../../utils/stringifyFilters';
import { trackDashboardShare } from '../../../state/analytics/actions';
import hasAccess from '../ContactUs/utils/hasAccess';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  category: {
    fontWeight: 'bold',
    fontSize: '0.7rem',
    color: theme.palette.custom.gray4,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.5rem',
  },
  shareButton: {
    cursor: 'pointer',
    border: `2px solid ${theme.palette.custom.blue2}`,
    padding: '0.7rem 2.4rem',
    borderRadius: 36,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  shareText: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    color: theme.palette.custom.blue2,
  },
  shareIcon: {
    width: '1.2rem',
    height: '1.2rem',
    marginRight: '0.8rem',
  },
  cta: {
    textDecoration: 'none',
  },
  titleContainer: {
    maxWidth: 'calc(100% - 30rem)',
  },
});

/**
 * Dashboard title
 */
const Title = ({
  classes,
  category,
  title,
  text,
  databoardId,
  queryString,
  trackSharing,
  hasAccess,
}) => {
  const getLinkUrl = useCallback(() => {
    const origin = get(window, ['location', 'origin']);

    const pathURL = window.location.pathname;
    const urlLocation = pathURL.includes('/daa')
      ? 'daa'
      : pathURL.includes('/reports')
      ? 'reports'
      : 'dashboards';

    const url = `${origin}/${urlLocation}/${databoardId}?${queryString}`;

    const body = `Check out the following dashboard\n\n${url}\n\n`;

    return `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(body)}`;
  }, [title, databoardId, queryString]);

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography className={classes.category}>{category}</Typography>
        <Typography variant="h5" className={classes.title}>
          {text && <InfoBox text={text} />}
          {title}
        </Typography>
      </div>
      {hasAccess && (
        <a
          href={getLinkUrl()}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.cta}
          onClick={() => trackSharing()}
        >
          <div className={classes.shareButton}>
            <ShareButtonIcon className={classes.shareIcon} />
            <Typography className={classes.shareText}>Share</Typography>
          </div>
        </a>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const queryString = stringifyFilters(state);

  return {
    title: get(state, ['databoard', 'title'], ''),
    category: get(state, ['databoard', 'category'], ''),
    text: get(state, ['databoard', 'text'], ''),
    databoardId: get(state, ['databoard', 'databoardId'], ''),
    hasAccess: hasAccess(state),
    queryString: queryString === FILTERS_NOT_READY_FLAG ? '' : queryString,
  };
};

const mapDispatchToProps = (dispatch) => ({
  trackSharing: () => dispatch(trackDashboardShare()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Title));
