import get from 'lodash/get';
import concat from 'lodash/concat';

export const getChartLabels = (state, chartId) =>
  concat(get(state, ['labels', chartId]), get(state, ['labels', 'sources']));

export const getSampleLabels = (state) =>
  concat(get(state, ['labels', 'sources']), [
    { name: 'COMPANY_GROUP_1', text: 'Company group 1' },
    { name: 'COMPANY_GROUP_2', text: 'Company group 2' },
    { name: 'bench-comp1', text: 'Company 1' },
    { name: 'bench-comp2', text: 'Company 2' },
  ]);
