import { get, head, split } from 'lodash';
import orderBy from 'lodash/orderBy';

const orderSamples = (samples) => {
  if (!samples) return [];
  // Sorting the samples based on the quarter title ex. Q1 2023, Q3 2022
  if (split(get(head(samples), 'name'), ' ').length == 2) {
    const sorted = samples
      .map((obj, index) => obj.name.split(' ').reverse().concat(index))
      .sort()
      .map((arr) => samples[arr[2]]);
    return sorted.reverse();
  } else {
    return orderBy(samples, ['name'], ['desc']);
  }
};

export default orderSamples;
