const styleSheet = {
  reportTitle: {
    x: 0.0,
    y: '10%',
    w: '100%',
    h: '80%',
    valign: 'middle',
    align: 'center',
    fontSize: 36,
    color: '0088CC',
    fill: 'F1F1F1',
  },
  slideTitle: {
    x: '5%',
    y: '5%',
    w: '90%',
    h: '10%',
    align: 'left',
    valign: 'middle',
    fontSize: 24,
    color: '000000',
  },
  slideText: {
    x: '5%',
    y: '15%',
    w: '90%',
    h: '80%',
    fontSize: 10,
    autoFit: true,
    color: '000000',
  },
  sectionTitle: {
    x: 0.0,
    y: '20%',
    w: '100%',
    h: '30%',
    valign: 'middle',
    align: 'center',
    fontSize: 36,
    color: '0088CC',
    fill: 'F1F1F1',
  },
  sectionText: {
    x: 0.0,
    y: '50%',
    w: '100%',
    h: '50%',
    valign: 'middle',
    align: 'center',
    fontSize: 28,
    color: '000000',
  },
  cardTitle: {
    x: '5%',
    y: '5%',
    w: '90%%',
    h: '10%',
    valign: 'middle',
    align: 'left',
    fontSize: 28,
    color: '000000',
  },
  cardText: {
    x: '5%',
    y: '15%',
    w: '90%',
    h: '10%',
    valign: 'middle',
    align: 'left',
    fontSize: 10,
    autoFit: true,
    color: '000000',
  },
  chartText: {
    x: 0.0,
    y: '25%',
    w: '100%',
    h: '20%',
    valign: 'middle',
    align: 'left',
    fontSize: 22,
    autoFit: true,
    color: '000000',
    fill: 'F1F1F1',
  },
  heading: {
    x: '5%',
    y: '55%',
    w: '40%',
    h: '40%',
    valign: 'middle',
    align: 'center',
    fontSize: 16,
    autoFit: true,
    color: '0088CC',
  },
  content: {
    x: '55%',
    y: '55%',
    w: '40%',
    h: '40%',
    valign: 'middle',
    align: 'center',
    fontSize: 12,
    autoFit: true,
    color: '000000',
  },
  footnote: {
    x: '5%',
    y: '96%',
    w: '80%',
    h: '4%',
    valign: 'middle',
    align: 'center',
    fontSize: 10,
    autoFit: true,
    color: '000000',
  },
};

export default styleSheet;
