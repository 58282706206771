import map from 'lodash/map';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';

import withOr from './operators/withOr';
import withNor from './operators/withNor';
import { filter, find, flattenDeep, forEach, head, includes, keys, split } from 'lodash';

/**
 * @param {*} form The form of the applied filters
 * @param {*} filters All dashboard filtersById
 */
const createFilterExpression = ({
  form,
  filters,
  outputFilterValues,
  filterLinks,
  selectedSources,
}) => {
  const source = head(selectedSources);

  const valuesWithOperator = reduce(
    form,
    (allFilterPayloads, selectedValues, filterId) => {
      const filterModel = get(filters, filterId);
      if (isEmpty(filterModel)) return allFilterPayloads;

      const { operator, labels } = filterModel;

      const actualValues = filter(labels, (label) => {
        return includes(selectedValues, get(label, 'text', ''));
      });

      const values = flattenDeep(
        map(
          actualValues,
          (filterValue) =>
            `${get(filterValue, ['column', source], '')}:${get(
              filterValue,
              ['value', source],
              '',
            )}`,
        ),
      );
      // If either the column or value is not specified we reject the item
      const filteredValues = filter(values, (value) => {
        const splittedValue = split(value, ':');
        return (
          splittedValue.length === 2 && !isEmpty(splittedValue[0]) && !isEmpty(splittedValue[1])
        );
      });

      const filterPayload = operator === 'OR' ? withOr(filteredValues) : withNor(filteredValues);

      return [...allFilterPayloads, filterPayload];
    },
    [],
  );
  // process combine output filters
  const combinedFilterLinks = filter(filterLinks, { type: 'COMPOUND' });
  const combinedFilters = [];
  forEach(combinedFilterLinks, (link) => {
    combinedFilters.push({
      filter1: link.source,
      filter2: head(link.destination),
    });
  });
  const filtersArr = map(keys(filters), (key) => filters[key]);
  const combinedFiltersIds = map(combinedFilters, (filterComb) => {
    return {
      filter1: get(find(filtersArr, { name: filterComb.filter1 }), '_id', ''),
      filter2: get(find(filtersArr, { name: filterComb.filter2 }), '_id', ''),
    };
  });
  let outputCombinedFilters = [];
  forEach(combinedFiltersIds, (combinedFilterIds) => {
    if (
      includes(keys(outputFilterValues), combinedFilterIds.filter1) ||
      includes(keys(outputFilterValues), combinedFilterIds.filter2)
    ) {
      if (head(filters[combinedFilterIds.filter1].labels).column) {
        outputCombinedFilters = map(
          outputFilterValues[combinedFilterIds.filter1],
          (valueColumn) => {
            return map(outputFilterValues[combinedFilterIds.filter2], (valueRow) => {
              const columnFilterName = find(filters[combinedFilterIds.filter1].labels, {
                text: valueColumn,
              });
              const columnFilterValue = find(filters[combinedFilterIds.filter2].labels, {
                text: valueRow,
              });
              return `${get(columnFilterName, ['column', source], '')}:${get(
                columnFilterValue,
                ['value', source],
                '',
              )}`;
            });
          },
        );
      } else {
        outputCombinedFilters = map(
          outputFilterValues[combinedFilterIds.filter2],
          (valueColumn) => {
            return map(outputFilterValues[combinedFilterIds.filter1], (valueRow) => {
              const columnFilterName = find(filters[combinedFilterIds.filter2].labels, {
                text: valueColumn,
              });
              const columnFilterValue = find(filters[combinedFilterIds.filter1].labels, {
                text: valueRow,
              });
              return `${get(columnFilterName, ['column', source], '')}:${get(
                columnFilterValue,
                ['value', source],
                '',
              )}`;
            });
          },
        );
      }
    }
  });

  if (!isEmpty(outputCombinedFilters)) {
    valuesWithOperator.push({ or: head(outputCombinedFilters) });
  }

  return {
    and: valuesWithOperator,
  };

  // return head(valuesWithOperator);
};

export default createFilterExpression;
