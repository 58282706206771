import includes from 'lodash/includes';
import filter from 'lodash/filter';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';
import get from 'lodash/get';

const getByOrganisation = (users, textSearch) => {
  if (isEmpty(textSearch)) return users;

  return filter(users, ({ organisation }) =>
    includes(toLower(get(organisation, 'name', '')), trim(toLower(textSearch))),
  );
};

export default getByOrganisation;
