import { API } from 'aws-amplify';
import { isEmpty } from 'lodash';

import get from 'lodash/get';

import { FETCH_CHART_DATA_SUCCESS, FETCH_CHART_DATA_ERROR } from '../../types';
import getError from './getError';
import getParams from './getParams';
import { dummyDataSelector } from '../../../../utils/xlsx/data/dummyDataSelector';
import { errorLogging } from './logs';

const fetchMergedSurveyChartData =
  ({
    id,
    name,
    sources,
    filterExpression,
    rejectExpression,
    columnExpression,
    rowExpression,
    contextExpression,
    computation,
    type,
    version,
    query,
  }) =>
  async (dispatch, getState) => {
    const userId = get(getState(), ['user', 'userId']);
    const jwtToken = get(getState(), ['user', 'jwtToken']);
    const databoardId = get(getState(), ['databoard', 'databoardId']);
    const pathURL = window.location.pathname;
    const params = getParams({
      name,
      sources,
      filterExpression,
      columnExpression,
      rowExpression,
      rejectExpression,
      contextExpression,
      jwtToken,
      computation,
      pathURL,
      version,
      query,
    });

    try {
      // Preventing sending request if the sources variable is empty
      const data = isEmpty(sources)
        ? !pathURL.includes('/dashboards') || version === 2
          ? await API.post(
              'dashboards',
              `/users/${userId}/databoards/${databoardId}/engine`,
              params,
            )
          : []
        : await API.post('dashboards', `/users/${userId}/databoards/${databoardId}/engine`, params);
      const payload = { id, sources, data };
      dispatch({ type: FETCH_CHART_DATA_SUCCESS, payload });
    } catch (error) {
      if (getError(error).status === 401) {
        const dummydata = dummyDataSelector(type);
        dispatch({
          type: FETCH_CHART_DATA_ERROR,
          error: getError(error),
          payload: { id, sources: dummydata.sources, data: dummydata.data },
        });
      } else {
        errorLogging(id, getState, getError(error).status);
        dispatch({ type: FETCH_CHART_DATA_ERROR, error: getError(error), payload: { id } });
      }
    }
  };

export default fetchMergedSurveyChartData;
