import React, { useEffect, useState } from 'react';

import { API } from 'aws-amplify';
import { connect } from 'react-redux';

import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import indexOf from 'lodash/indexOf';

import DropDownField from '../../DropDownField';
import { concat } from 'lodash';

const DataBoardsField = ({ jwtToken, excludeDataboards, isDisabled, setError, ...rest }) => {
  const [databoards, setDataboards] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchDataboards = async () => {
      setLoader(true);

      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };

        const results = await Promise.all([
          API.get('dashboards', `/databoards`, params),
          API.get('dashboards', `/dataanalystaccess`, params),
          API.get('dashboards', `/reports`, params),
          API.get('dashboards', `/briefings`, params),
        ]);
        const [dashboards, datatables, reports, briefings] = results;

        // setTotal(getTotal(results));
        setDataboards(concat(dashboards, datatables, reports, briefings));
        setLoader(false);
      } catch (e) {
        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );
        setError(message);
        setLoader(false);
      }
    };

    fetchDataboards();
  }, []);

  const filteredDataboards = filter(
    databoards,
    ({ _id }) => indexOf(excludeDataboards, _id) === -1,
  );
  const options = map(filteredDataboards, ({ _id, title }) => ({ value: _id, label: title }));

  return (
    <DropDownField
      suggestions={options}
      {...rest}
      isDisabled={loader || isDisabled}
      placeholder={loader ? 'Loading...' : 'Type or select...'}
    />
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
});

export default connect(mapStateToProps)(DataBoardsField);
