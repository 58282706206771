import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import qs from 'qs';

import { get } from 'lodash';

import { makeStyles } from '@material-ui/core';

import { Paper, CardPaperProps } from '../../../../../components/UI';
import GraphRenderer from '../../../../../components/Graphs/GraphsRenderer/GraphsRenderer';
import { FilterType } from '../../@types/Graph';
import { CustomConfig } from '../../../../../components/Graphs/@types';
import ViewportContainer from '../ViewportContainer';
import AnalyticsTableChart from '../AnalyticsTableChart';
import weekOfYear from './utils/weekOfYear';

const useStyles = makeStyles({
  emptyMessage: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

interface GraphContainer {
  type: string;
  className: string;
  query: string;
  customConfig: CustomConfig;
  jwtToken: string;
  filters?: FilterType;
  paperProps?: CardPaperProps;
}

const GraphContainer = ({
  type,
  className,
  query,
  customConfig,
  jwtToken,
  filters,
  paperProps,
}: GraphContainer) => {
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [, setError] = useState('');
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      //if (!query) return;
      setLoader(true);

      let result = [];

      const params = {
        body: {
          name: query,
          filters,
        },
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      };

      try {
        result = await API.post('dashboards', '/analytics/engine', params);
        if (customConfig['xAxisProps.tick.normalizer'] === 'weekOfYear') {
          result = weekOfYear(result);
        }
        setData({ data: result, series: customConfig.series, formatter: customConfig.formatter });
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setLoader(false);
      }

      if (process.env.REACT_APP_DATABOARD_STATUS === 'draft') {
        try {
          if (result.length === 0) {
            result = await import(`../../screens/data/basic-kpi/${query}.json`);
            setData(result.default);
          } else {
            setData({
              data: result,
              series: customConfig.series,
              formatter: customConfig.formatter,
            });
          }
        } catch (error) {
          setError(error);
        } finally {
          setLoader(false);
        }
      }
    };

    fetchData();
  }, [query, filters]);

  if (loader)
    return (
      <Paper
        className={className}
        height={customConfig.height === 'large' ? 900 : 600}
        {...paperProps}
      >
        <div>Loading...</div>
      </Paper>
    );

  const dataLength = get(data, 'data', []).length;

  return (
    <ViewportContainer
      onEnterViewport={() => {
        if (window && window.location) {
          // parse the query string into an object
          const q = qs.parse(window.location.search, {
            comma: true,
            ignoreQueryPrefix: true,
          });

          q.graph = query;

          const refresh = `${window.location.protocol}//${window.location.host}${
            window.location.pathname
          }?${qs.stringify(q)}`;
          window.history.pushState({ path: refresh }, '', refresh);
        }
      }}
    >
      <Paper
        className={className}
        height={customConfig.height === 'large' ? 900 : 600}
        {...paperProps}
      >
        {dataLength === 0 && <div className={classes.emptyMessage}>Query returned empty data</div>}
        {dataLength > 0 && type !== 'TYPE_TABLE_CHART' && (
          <GraphRenderer type={type} customConfig={customConfig} {...data} />
        )}
        {dataLength > 0 && type === 'TYPE_TABLE_CHART' && (
          <AnalyticsTableChart customConfig={customConfig} data={get(data, 'data', [])} {...data} />
        )}
      </Paper>
    </ViewportContainer>
  );
};

export default GraphContainer;
