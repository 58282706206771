import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import indexOf from 'lodash/indexOf';

import { RESET_DATABOARD, SET_DATABOARD } from '../databoard/types';
import { SET_SERIES, REJECT_SERIES } from './types';

export const initialState = {
  byId: {},
  all: [],
};

const appendChartConfiguration = (dictionary, chart) => ({
  ...dictionary,
  [get(chart, '_id')]: { ...chart, series: [], reject: [] },
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_DATABOARD:
      return {
        ...state,
        ...initialState,
      };
    case SET_DATABOARD: {
      const databoard = get(action, 'payload', {});
      const sections = get(databoard, 'sections', []);
      const cards = flatten(map(sections, 'cards'));
      const charts = flatten(map(cards, 'chart'));
      const all = map(charts, '_id');
      const byId = reduce(charts, appendChartConfiguration, {});

      return {
        ...state,
        byId,
        all,
      };
    }
    case REJECT_SERIES: {
      const chartId = get(action, ['payload', 'chartId'], '');
      const series = get(action, ['payload', 'series'], '');
      if (isEmpty(chartId) || isEmpty(series)) return { ...state };

      let reject = [];
      const curRejected = get(state, ['byId', chartId, 'reject'], []);
      const seriesIndex = indexOf(curRejected, series);
      if (seriesIndex === -1) {
        reject = [...curRejected, series];
      } else {
        reject = [...curRejected];
        reject.splice(seriesIndex, 1);
      }

      return {
        ...state,
        byId: {
          ...state.byId,
          [chartId]: {
            ...get(state, ['byId', chartId], {}),
            reject,
          },
        },
      };
    }
    case SET_SERIES: {
      const chartId = get(action, ['payload', 'chartId'], '');
      const series = get(action, ['payload', 'series'], '');

      return {
        ...state,
        byId: {
          ...state.byId,
          [chartId]: {
            ...get(state, ['byId', chartId], {}),
            series,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
