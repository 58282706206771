import React from 'react';
import cn from 'classnames';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const Portlet = ({ classes, className, children, ...rest }) => {
  const rootClassName = cn(classes.root, className);

  return (
    <Paper {...rest} className={rootClassName} elevation={0}>
      {children}
    </Paper>
  );
};

export default withStyles(styles)(Portlet);
