import includes from 'lodash/includes';
import filter from 'lodash/filter';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';

const getByLastName = (users, textSearch) => {
  if (isEmpty(textSearch)) return users;

  return filter(users, ({ lastName }) => includes(toLower(lastName), trim(toLower(textSearch))));
};

export default getByLastName;
