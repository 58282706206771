import React from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '2rem',
    marginRight: '2rem',
  },
});

const CardFooter = ({ children, classes }) => <div className={classes.root}>{children}</div>;

export default withStyles(styles)(CardFooter);
