import { API } from 'aws-amplify';
import qs from 'qs';
import Bowser from 'bowser';

import get from 'lodash/get';

import { SET_SESSION_ID, SET_EVENT_GROUP_ID, CLEAR_EVENT_GROUP_ID } from './types';
import hasDashboardAccess from '../../utils/hasDashboardAccess';
import { concat, find, flattenDeep, indexOf, keys, reduce, toArray, uniq } from 'lodash';
import isPremium from './utils/isPremium';
import isFree from './utils/isFree';
import subscriptionCount from './utils/subscriptionCount';

// User related events
export const LANDING_PAGE_VISIT_EVENT = 'LANDING_PAGE_VISIT_EVENT';
export const USER_SIGN_IN_EVENT = 'USER_SIGN_IN_EVENT';
export const USER_SIGN_UP_EVENT = 'USER_SIGN_UP_EVENT';
export const USER_REFRESH_TOKEN_EVENT = 'USER_REFRESH_TOKEN_EVENT';
export const USER_VERIFIED_EVENT = 'USER_VERIFIED_EVENT';

// Dashboard related events
export const DASHBOARD_VISIT_EVENT = 'DASHBOARD_VISIT_EVENT';
export const DATACUT_VISIT_EVENT = 'DATACUT_VISIT_EVENT';
export const REPORT_VISIT_EVENT = 'REPORT_VISIT_EVENT';
export const BRIEFING_VISIT_EVENT = 'BRIEFING_VISIT_EVENT';

export const FILTER_USAGE_EVENT = 'FILTER_USAGE_EVENT';
export const GRAPH_VISIT_EVENT = 'GRAPH_VISIT_EVENT';
export const GRAPH_SHARE_EVENT = 'GRAPH_SHARE_EVENT';
export const DASHBOARD_SHARE_EVENT = 'DASHBOARD_SHARE_EVENT';
export const GRAPH_DOWNLOAD_DATA_EVENT = 'GRAPH_DOWNLOAD_DATA_EVENT';
export const GRAPH_DOWNLOAD_IMAGE_EVENT = 'GRAPH_DOWNLOAD_IMAGE_EVENT';

// shared props, userAgent + utmParams
const userAgent = Bowser.parse(get(window, ['navigator', 'userAgent'], {}));
const utmParams = qs.parse(get(window, ['location', 'search'], {}), {
  comma: true,
  ignoreQueryPrefix: true,
  encode: true,
});

// Get viewport width and height
const getViewport = () => ({
  width: get(window, 'innerWidth', 0),
  height: get(window, 'innerHeight', 0),
});

const getUserAgentObject = () => ({
  ...userAgent,
  viewport: getViewport(),
});

export const trackSignIn = (userId, email) => async (dispatch, getState) => {
  const { sessionId } = get(getState(), 'analytics', {});

  const params = {
    body: {
      sessionId,
      eventName: USER_SIGN_IN_EVENT,
      userId,
      user: email,
      context: { ...utmParams },
      userAgent: getUserAgentObject(),
    },
  };

  try {
    await API.post('dashboards', '/trace', params);
  } catch (error) {
    console.log(error);
  }
};

export const trackSignUp = (email) => async (dispatch, getState) => {
  // const utmParams = qs.parse(get(window, ['location', 'search'], {}), {
  //   comma: true,
  //   ignoreQueryPrefix: true,
  //   encode: true,
  // });
  const { sessionId } = get(getState(), 'analytics', {});

  const params = {
    body: {
      sessionId,
      eventName: USER_SIGN_UP_EVENT,
      user: email,
      context: { ...utmParams },
      userAgent: getUserAgentObject(),
    },
  };

  try {
    await API.post('dashboards', '/trace', params);
  } catch (error) {
    console.log(error);
  }
};

export const trackVerified = (email) => async (dispatch, getState) => {
  const { sessionId } = get(getState(), 'analytics', {});

  const params = {
    body: {
      sessionId,
      eventName: USER_VERIFIED_EVENT,
      user: email,
      context: { ...utmParams },
      userAgent: getUserAgentObject(),
    },
  };

  try {
    await API.post('dashboards', '/trace', params);
  } catch (error) {
    console.log(error);
  }
};

export const trackRefreshToken = (userId, email) => async (dispatch) =>
  new Promise(async (resolve, reject) => {
    // const utmParams = qs.parse(get(window, ['location', 'search'], {}), {
    //   comma: true,
    //   ignoreQueryPrefix: true,
    //   encode: true,
    // });

    const params = {
      body: {
        eventName: USER_REFRESH_TOKEN_EVENT,
        userId,
        user: email,
        context: { ...utmParams },
        userAgent: getUserAgentObject(),
      },
    };

    try {
      const response = await API.post('dashboards', '/trace', params);
      const sessionId = get(response, 'sessionId');

      dispatch({ type: SET_SESSION_ID, payload: sessionId });
      resolve();
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

export const trackDashboardVisit = (dashboardId, dashboardName) => async (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    const { userId, email, organisation, subscriptions, allOrganisations } = get(
      getState(),
      'user',
      {},
    );
    const { sessionId } = get(getState(), 'analytics', {});

    const params = {
      body: {
        sessionId,
        eventName: DASHBOARD_VISIT_EVENT,
        subscription: hasDashboardAccess(subscriptions, dashboardId),
        userId,
        user: email,
        account: organisation,
        resource: dashboardName,
        resourceId: dashboardId,
        query: dashboardName,
        isPremium: isPremium(subscriptions, allOrganisations),
        userAgent: getUserAgentObject(),
        subscriptionId: get(find(subscriptions, { databoardId: dashboardId }), '_id', ''),
        isFree: isFree(subscriptions, allOrganisations, dashboardId),
        subscriptionCount: subscriptionCount(subscriptions, allOrganisations),
        // utms can be inferred from the session
        // context: { ...utmParams },
      },
    };

    try {
      const response = await API.post('dashboards', '/trace', params);
      const eventGroup = get(response, 'eventGroup');

      dispatch({ type: SET_EVENT_GROUP_ID, payload: eventGroup });
      resolve();
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

  export const trackDataCutVisit = (dashboardId, dashboardName) => async (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    const { userId, email, organisation, subscriptions, allOrganisations } = get(
      getState(),
      'user',
      {},
    );
    const { sessionId } = get(getState(), 'analytics', {});

    const params = {
      body: {
        sessionId,
        eventName: DATACUT_VISIT_EVENT,
        subscription: hasDashboardAccess(subscriptions, dashboardId),
        userId,
        user: email,
        account: organisation,
        resource: dashboardName,
        resourceId: dashboardId,
        query: dashboardName,
        isPremium: isPremium(subscriptions, allOrganisations),
        userAgent: getUserAgentObject(),
        subscriptionId: get(find(subscriptions, { databoardId: dashboardId }), '_id', ''),
        isFree: isFree(subscriptions, allOrganisations, dashboardId),
        subscriptionCount: subscriptionCount(subscriptions, allOrganisations),
        // utms can be inferred from the session
        // context: { ...utmParams },
      },
    };

    try {
      const response = await API.post('dashboards', '/trace', params);
      const eventGroup = get(response, 'eventGroup');

      dispatch({ type: SET_EVENT_GROUP_ID, payload: eventGroup });
      resolve();
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

  export const trackReportVisit = (dashboardId, dashboardName) => async (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    const { userId, email, organisation, subscriptions, allOrganisations } = get(
      getState(),
      'user',
      {},
    );
    const { sessionId } = get(getState(), 'analytics', {});

    const params = {
      body: {
        sessionId,
        eventName: REPORT_VISIT_EVENT,
        subscription: hasDashboardAccess(subscriptions, dashboardId),
        userId,
        user: email,
        account: organisation,
        resource: dashboardName,
        resourceId: dashboardId,
        query: dashboardName,
        // isPremium: isPremium(subscriptions, allOrganisations),
        userAgent: getUserAgentObject(),
        subscriptionId: get(find(subscriptions, { databoardId: dashboardId }), '_id', ''),
        // isFree: isFree(subscriptions, allOrganisations, dashboardId),
        subscriptionCount: subscriptionCount(subscriptions, allOrganisations),
        // utms can be inferred from the session
        // context: { ...utmParams },
      },
    };

    try {
      const response = await API.post('dashboards', '/trace', params);
      const eventGroup = get(response, 'eventGroup');

      dispatch({ type: SET_EVENT_GROUP_ID, payload: eventGroup });
      resolve();
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

  export const trackBriefingVisit = (dashboardId, dashboardName) => async (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    const { userId, email, organisation, subscriptions, allOrganisations } = get(
      getState(),
      'user',
      {},
    );
    const { sessionId } = get(getState(), 'analytics', {});

    const params = {
      body: {
        sessionId,
        eventName: BRIEFING_VISIT_EVENT,
        subscription: hasDashboardAccess(subscriptions, dashboardId),
        userId,
        user: email,
        account: organisation,
        resource: dashboardName,
        resourceId: dashboardId,
        query: dashboardName,
        // isPremium: isPremium(subscriptions, allOrganisations),
        userAgent: getUserAgentObject(),
        subscriptionId: get(find(subscriptions, { databoardId: dashboardId }), '_id', ''),
        // isFree: isFree(subscriptions, allOrganisations, dashboardId),
        subscriptionCount: subscriptionCount(subscriptions, allOrganisations),
        // utms can be inferred from the session
        // context: { ...utmParams },
      },
    };

    try {
      const response = await API.post('dashboards', '/trace', params);
      const eventGroup = get(response, 'eventGroup');

      dispatch({ type: SET_EVENT_GROUP_ID, payload: eventGroup });
      resolve();
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

export const clearEventGroup = () => ({
  type: CLEAR_EVENT_GROUP_ID,
});

export const trackLandingPage = () => async (dispatch) => {
  // const utmParams = qs.parse(get(window, ['location', 'search'], {}), {
  //   comma: true,
  //   ignoreQueryPrefix: true,
  //   encode: true,
  // });

  const params = {
    body: {
      eventName: LANDING_PAGE_VISIT_EVENT,
      context: { ...utmParams },
      userAgent: getUserAgentObject(),
    },
  };

  try {
    const response = await API.post('dashboards', '/trace', params);
    const sessionId = get(response, 'sessionId');

    dispatch({ type: SET_SESSION_ID, payload: sessionId });
  } catch (error) {
    console.log(error);
  }
};

export const trackGraphVisit =
  (chartName, chartQuery, duration, idleTime, loadingTime) => async (dispatch, getState) => {
    const { userId, email, organisation } = get(getState(), 'user', {});
    const { sessionId, eventGroup } = get(getState(), 'analytics', {});
    const { databoardId: dashboardId, name: dashboard } = get(getState(), 'databoard', {});

    const params = {
      body: {
        sessionId,
        userId,
        user: email,
        eventName: GRAPH_VISIT_EVENT,
        eventGroup,
        dashboard,
        dashboardId,
        account: organisation,
        resource: chartName,
        query: chartQuery,
        context: { duration, idleTime, loadingTime },
        userAgent: getUserAgentObject(),
      },
    };

    try {
      await API.post('dashboards', `/trace/eventGroups/${eventGroup}`, params);
    } catch (error) {
      console.log(error);
    }
  };

export const trackGraphAction =
  (graphAction, chartName, chartQuery) => async (dispatch, getState) => {
    const { userId, email, organisation } = get(getState(), 'user', {});
    const { sessionId, eventGroup } = get(getState(), 'analytics', {});
    const { databoardId: dashboardId, name: dashboard } = get(getState(), 'databoard', {});

    const params = {
      body: {
        sessionId,
        userId,
        user: email,
        eventName: graphAction,
        eventGroup,
        dashboard,
        dashboardId,
        account: organisation,
        resource: chartName,
        query: chartQuery,
        userAgent: getUserAgentObject(),
        context: {
          utm_source: '',
          utm_medium: '',
          utm_campaign: '',
        },
      },
    };

    try {
      await API.post('dashboards', `/trace/eventGroups/${eventGroup}`, params);
    } catch (error) {
      console.log(error);
    }
  };

export const trackDashboardShare = () => async (dispatch, getState) => {
  const { userId, email, organisation } = get(getState(), 'user', {});
  const { sessionId, eventGroup } = get(getState(), 'analytics', {});
  const { databoardId: dashboardId, name: dashboard } = get(getState(), 'databoard', {});

  const params = {
    body: {
      sessionId,
      userId,
      user: email,
      eventName: DASHBOARD_SHARE_EVENT,
      eventGroup,
      account: organisation,
      resource: dashboard,
      resourceId: dashboardId,
      query: dashboard,
      userAgent: getUserAgentObject(),
      context: {
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
      },
    },
  };

  try {
    await API.post('dashboards', `/trace/eventGroups/${eventGroup}`, params);
  } catch (error) {
    console.log(error);
  }
};

export const trackFilterUsage = (data) => async (dispatch, getState) => {
  const {
    filtersData: { root: globalFilters, bySection },
    filtersIndex,
    analytics: { sessionId, eventGroup },
    user: { userId, email, organisation },
    databoard: { databoardId: dashboardId, name: dashboard },
  } = getState();

  const sectionFilters = uniq(flattenDeep(toArray(bySection)));

  const generateRequestsOperation = (allRequests, filterId) => {
    const model = get(filtersIndex, ['byId', filterId], {});
    const isVisible = get(model, 'visible', true);
    if (!isVisible) return allRequests;

    const resource = get(model, 'name', 'sources');
    const query = get(model, 'query', '');
    const table = get(model, 'table', '');
    const operator = get(model, 'operator', 'OR');
    const scope =
      indexOf(globalFilters, filterId) !== -1
        ? 'global'
        : indexOf(sectionFilters, filterId) !== -1
        ? 'section'
        : 'card';

    const params = {
      body: {
        sessionId,
        userId,
        user: email,
        eventName: FILTER_USAGE_EVENT,
        eventGroup,
        dashboard,
        dashboardId,
        account: organisation,
        resource,
        query,
        context: {
          operator,
          values: data[filterId],
          scope,
          query,
          table,
        },
        userAgent: getUserAgentObject(),
      },
    };

    const request = API.post('dashboards', `/trace/eventGroups/${eventGroup}`, params);
    return concat(allRequests, request);
  };

  const requests = reduce(keys(data), generateRequestsOperation, []);

  try {
    await Promise.all(requests);
  } catch (error) {
    console.log(error);
  }
};
