import React, { Component } from 'react';

import { connect } from 'react-redux';

import {
  flattenDeep,
  uniq,
  isEmpty,
  map,
  get,
  omit,
  head,
  keys,
  dropWhile,
  concat,
  find,
} from 'lodash';

import { withStyles } from '@material-ui/core/styles';

import ChartLoader from '../ChartLoader';
import GraphsRenderer from '../../Graphs/GraphsRenderer';
import {
  TYPE_BAR_CHART,
  TYPE_BAR_CHART_MULTI,
  TYPE_BAR_CHART_STACKED,
  TYPE_BAR_CHART_STACKED_MULTI,
  TYPE_BAR_CHART_SCORE,
  TYPE_BAR_CHART_SIDE_BY_SIDE,
  TYPE_COLUMN_CHART,
  TYPE_COLUMN_CHART_MULTI,
  TYPE_COLUMN_CHART_STACKED,
  TYPE_COLUMN_CHART_STACKED_LOCAL,
  TYPE_COLUMN_CHART_LINE,
  TYPE_PIE_CHART,
  TYPE_DONUT_CHART,
  TYPE_TABLE_CHART,
  TYPE_HEATMAP_CHART,
  TYPE_BAR_CHART_BENCHMARK,
  TYPE_COLUMN_CHART_BENCHMARK,
  TYPE_LINE_CHART,
  TYPE_SCATTER_CHART,
} from '../../Graphs/GraphsRenderer/types';
import SnackbarError from '../SnackbarError';
import {
  normalizeMultiSurveyData,
  normalizeCategoricalData,
  normalizeMultiSurveyTransposedData,
  normalizeScoreData,
  normalizeGridRows,
  normalizeHeatmap,
  normalizeGridColumns,
  getChartSources,
  deepEqual,
  getMultiSurveySamples,
  getMergedSurveySamples,
  getCategoricalSamples,
  getLocalSamples,
  isLocked,
} from './helpers';
import { fetchChartData } from '../../../state/data/actions';
import { setSeries } from '../../../state/charts/actions';
import { DATABOARD_SOURCES_FILTER } from '../../../state/filtersData/types';
import { ReactComponent as LockIcon } from './assets/lock.svg';
import getStackedData from '../../../utils/data/getStackedData';
import getBenchmarkedData from '../../../utils/data/getBenchmarkedData';
import getBenchmarkedSamples from '../../../utils/sample/getBenchmarkedSamples';
import { Typography } from '@material-ui/core';
import normalizeCombinedlData from './helpers/recharts/normalizeCombinedData';

const styles = (theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '85%',
    alignSelf: 'center',
  },
  empty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    fontFamily: 'Nunito Sans Black',
    color: theme.palette.custom.blue1,
  },
  lockContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
  },
  lock: {
    position: 'absolute',
    zIndex: 100,
  },
  blurChart: {
    filter: 'blur(5px)',
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  dummyDataLabel: {
    right: 0,
    bottom: 0,
    position: 'absolute',
    fontWeight: 600,
    padding: '1rem',
    color: 'white',
    backgroundColor: '#0f70e0',
    borderRadius: '23px',
  },
});

/**
 * NOTE: This component can't be functional because useEffect doesn't perform deep equals
 */
class Chart extends Component {
  state = {
    error: '',
    showError: false,
  };

  componentDidMount() {
    if (isEmpty(this.props.data)) {
      this.fetchData();
    } else {
      this.formatData(this.props.ignoreSeries);
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    return prevProps;
  }

  /**
   * @see https://reactjs.org/docs/react-component.html#componentdidupdate
   */
  componentDidUpdate(prevProps) {
    // doc: Check deepEqual tests for examples on values
    const filtersHaveChanged = !deepEqual(prevProps.appliedFilters, this.props.appliedFilters);
    const rejectionsHaveChanged = !deepEqual(prevProps.reject, this.props.reject);

    if (filtersHaveChanged || rejectionsHaveChanged) {
      this.fetchData();
    } else {
      const dataHasChanged = !deepEqual(prevProps.data, this.props.data);
      const ignoreSeries = !dataHasChanged;

      if (dataHasChanged && !isEmpty(this.props.data)) {
        this.formatData(ignoreSeries);
      }
    }
  }

  formatData = (ignoreSeries) => {
    const {
      type,
      data,
      sources,
      selectedSources,
      chartSources,
      graph: DATA_TABLE,
      sample: SAMPLE_TABLE,
      series: allSeries,
      sorting,
      onSetSeries,
      id,
      rejectedSeries,
      dataTables,
      extraGraphs,
      configuration,
      sourcesLookup,
    } = this.props;

    let normalizedData = [];
    let series = allSeries;
    let samples = [];

    switch (type) {
      case TYPE_BAR_CHART:
      case TYPE_LINE_CHART:
      case TYPE_SCATTER_CHART:
      case TYPE_COLUMN_CHART: {
        normalizedData = normalizeCategoricalData({
          key: 'value',
          data,
          table: DATA_TABLE,
          sorting,
          omitZeros: true,
        });
        series = keys(omit(head(normalizedData), ['name', 'hint']));
        samples = getCategoricalSamples({ data, table: SAMPLE_TABLE });
        break;
      }
      case TYPE_BAR_CHART_SIDE_BY_SIDE: {
        normalizedData = extraGraphs.map((item) => {
          return normalizeCategoricalData({
            key: 'value',
            data,
            table: item,
            sorting,
            omitZeros: true,
          });
        });
        series = normalizedData.map((dataItem) => {
          return keys(omit(head(dataItem), ['name', 'hint']));
        });
        samples = getCategoricalSamples({ data, table: SAMPLE_TABLE });
        break;
      }
      case TYPE_COLUMN_CHART_LINE: {
        normalizedData = normalizeCombinedlData({
          key: 'value',
          data,
          table: DATA_TABLE,
          extraGraphs,
          sorting,
          omitZeros: true,
        });
        series = keys(omit(head(normalizedData), ['name', 'hint']));
        samples = getCategoricalSamples({ data, table: SAMPLE_TABLE });
        break;
      }
      case TYPE_BAR_CHART_STACKED:
      case TYPE_COLUMN_CHART_STACKED:
      case TYPE_COLUMN_CHART_STACKED_LOCAL: {
        normalizedData = getStackedData({ data, table: DATA_TABLE, sorting, omitZeros: true });
        if (!ignoreSeries) {
          series = concat(map(get(head(normalizedData), 'series'), 'name'), rejectedSeries);
          onSetSeries(id, series);
        }
        samples = getLocalSamples({ data, table: SAMPLE_TABLE });
        break;
      }
      case TYPE_PIE_CHART:
      case TYPE_DONUT_CHART: {
        normalizedData = normalizeCategoricalData({
          key: 'value',
          data,
          table: DATA_TABLE,
          sorting,
          omitZeros: true,
        });

        if (!ignoreSeries) {
          series = concat(map(normalizedData, 'name'), rejectedSeries);
          onSetSeries(id, series);
        }
        samples = getMergedSurveySamples({ data, table: SAMPLE_TABLE });
        break;
      }
      case TYPE_BAR_CHART_SCORE: {
        normalizedData = normalizeScoreData({
          data,
          table: DATA_TABLE,
        });
        samples =
          get(find(configuration, { key: 'showLocalSamples' }), 'value', 'false') === 'true'
            ? getLocalSamples({ data, table: SAMPLE_TABLE })
            : getMergedSurveySamples({ data, table: SAMPLE_TABLE });
        break;
      }
      case TYPE_HEATMAP_CHART: {
        const tabularData = normalizeHeatmap({ data, dataTables, sorting });

        normalizedData = Array.from(tabularData);
        series = normalizedData.shift();

        onSetSeries(id, series);

        break;
      }
      case TYPE_TABLE_CHART: {
        normalizedData = normalizeGridRows({
          data,
          table: DATA_TABLE,
        });
        samples = getMergedSurveySamples({ data, table: SAMPLE_TABLE });
        if (!ignoreSeries) {
          series = normalizeGridColumns({
            data,
            table: DATA_TABLE,
          });
          onSetSeries(id, series);
        }

        break;
      }
      case TYPE_BAR_CHART_MULTI:
      case TYPE_COLUMN_CHART_MULTI: {
        normalizedData = normalizeMultiSurveyData({
          sources,
          data,
          table: DATA_TABLE,
          sorting,
          sourcesLookup,
          questionLabelsSurvey: get(
            find(configuration, { key: 'questionLabelsSurvey' }),
            'value',
            'OLD',
          ),
        });
        series = getChartSources({
          sources: selectedSources,
          chartSources,
          data,
          table: DATA_TABLE,
          sourcesLookup,
        });
        samples = getMultiSurveySamples({ data, sources, table: SAMPLE_TABLE, sourcesLookup });

        break;
      }
      case TYPE_BAR_CHART_BENCHMARK:
      case TYPE_COLUMN_CHART_BENCHMARK: {
        normalizedData = getBenchmarkedData({
          data,
          table: DATA_TABLE,
          sorting,
        });

        if (!ignoreSeries) {
          series = uniq(
            concat(
              flattenDeep(
                map(normalizedData, (dataOfTheSource) =>
                  keys(omit(dataOfTheSource, ['name', 'text'])),
                ),
              ),
              rejectedSeries,
            ),
          );
          onSetSeries(id, series);
        }
        samples = getBenchmarkedSamples({ data, table: SAMPLE_TABLE });

        break;
      }
      case TYPE_BAR_CHART_STACKED_MULTI: {
        normalizedData = normalizeMultiSurveyTransposedData({
          sources,
          data,
          table: DATA_TABLE,
          sourcesLookup,
        });
        if (!ignoreSeries) {
          series = uniq(
            concat(
              flattenDeep(
                map(normalizedData, (dataOfTheSource) => keys(omit(dataOfTheSource, 'name'))),
              ),
              rejectedSeries,
            ),
          );
          onSetSeries(id, series);
        }

        samples = getMultiSurveySamples({ data, sources, table: SAMPLE_TABLE, sourcesLookup });
        break;
      }
      default:
        break;
    }

    this.setState({ raw: data, normalizedData, series, samples });
  };

  fetchData = async () => {
    const { onFetchChartData, id: chartId } = this.props;
    onFetchChartData(chartId);
  };

  render() {
    const {
      classes,
      type,
      data,
      loader,
      id,
      domain,
      axisTitle,
      graphLabels,
      formatter,
      view,
      showLock,
      configuration,
      sorting,
      sampleVisible,
    } = this.props;
    const { normalizedData, series, samples } = this.state;

    // normalizing the dummy data in order to make sure graphs are visible
    const normalizedDummyData = (type, data, sorting) => {
      switch (type) {
        case TYPE_BAR_CHART:
        case TYPE_COLUMN_CHART: {
          return normalizeCategoricalData({
            key: 'value',
            data,
            table: 'percentage',
            sorting,
            omitZeros: true,
          });
        }
        case TYPE_BAR_CHART_STACKED:
        case TYPE_COLUMN_CHART_STACKED:
        case TYPE_COLUMN_CHART_STACKED_LOCAL: {
          return getStackedData({ data, table: 'percentage', sorting, omitZeros: true });
        }
        case TYPE_PIE_CHART:
        case TYPE_DONUT_CHART: {
          return normalizeCategoricalData({
            key: 'value',
            data,
            table: 'name',
            sorting,
            omitZeros: true,
          });
        }
        case TYPE_BAR_CHART_SCORE: {
          return normalizeScoreData({
            data,
            table: 'score',
          });
        }
        case TYPE_TABLE_CHART: {
          return normalizeGridRows({
            data,
            table: 'score',
          });
        }
        case TYPE_BAR_CHART_MULTI:
        case TYPE_COLUMN_CHART_MULTI: {
          return normalizeMultiSurveyData({
            sources: ['de18', 'de17'],
            data,
            table: 'percentage',
            sorting,
          });
        }
        case TYPE_BAR_CHART_BENCHMARK:
        case TYPE_COLUMN_CHART_BENCHMARK: {
          return getBenchmarkedData({
            data,
            table: 'percentage',
            sorting,
          });
        }
        case TYPE_BAR_CHART_STACKED_MULTI: {
          return normalizeMultiSurveyTransposedData({
            sources: ['de18', 'de17', 'de16'],
            data,
            table: 'percentage',
          });
        }
        default:
          break;
      }
    };

    return (
      <div className={classes.root}>
        {showLock && (
          <div className={classes.lockContainer}>
            <LockIcon className={classes.lock} />
            <div className={classes.blurChart}>
              <GraphsRenderer
                type={type}
                series={series}
                data={
                  type === TYPE_TABLE_CHART
                    ? normalizedData
                    : normalizedDummyData(type, data, sorting)
                }
                samples={samples}
                axisTitle={axisTitle}
                id={id}
                domain={domain}
                view={view}
                formatter={formatter}
                configuration={configuration}
              />
              <Typography className={classes.dummyDataLabel}>This is Dummy Data</Typography>
            </div>
          </div>
        )}
        <ChartLoader show={loader} />
        {!showLock && !isEmpty(dropWhile(data, isEmpty)) && (
          <GraphsRenderer
            type={type}
            series={series}
            data={normalizedData}
            samples={samples}
            axisTitle={axisTitle}
            graphLabels={graphLabels}
            id={id}
            domain={domain}
            view={view}
            formatter={formatter}
            configuration={configuration}
            sampleVisible={sampleVisible}
          />
        )}
        {!showLock && !loader && isEmpty(dropWhile(data, isEmpty)) && (
          <div className={classes.empty}>Your query returned no results</div>
        )}
        <SnackbarError {...this.state} onClose={() => this.setState({ showError: false })} />
      </div>
    );
  }
}

const mapStateToProps = (state, { id }) => ({
  selectedSources: get(state, ['filtersData', 'byId', DATABOARD_SOURCES_FILTER], []),
  sources: get(state, ['data', 'byChart', id, DATABOARD_SOURCES_FILTER]),
  data: get(state, ['data', 'byChart', id, 'data']),
  loader: get(state, ['data', 'byChart', id, 'loader']),
  error: get(state, ['data', 'byChart', id, 'error']),
  query: get(state, ['charts', 'byId', id, 'query'], {}),
  type: get(state, ['charts', 'byId', id, 'type'], ''),
  // This property will replace graph and sample in the future, currently works only for heatmap
  dataTables: get(state, ['charts', 'byId', id, 'data']),
  graph: get(state, ['charts', 'byId', id, 'graph'], ''),
  sample: get(state, ['charts', 'byId', id, 'sample'], ''),
  extraGraphs: get(state, ['charts', 'byId', id, 'extraGraphs']),
  // This property will replace props of configuration in the future, currently works only for heatmap
  configuration: get(state, ['charts', 'byId', id, 'config']),
  series: get(state, ['charts', 'byId', id, 'series'], ''),
  reject: get(state, ['charts', 'byId', id, 'reject'], ''),
  sorting: get(state, ['charts', 'byId', id, 'sorting']),
  domain: get(state, ['charts', 'byId', id, 'domain']),
  axisTitle: get(state, ['charts', 'byId', id, 'axis', 'label'], ''),
  graphLabels: get(state, ['charts', 'byId', id, 'graphLabels']),
  chartSources: get(state, ['charts', 'byId', id, 'sources']),
  formatter: get(state, ['charts', 'byId', id, 'formatter']),
  rejectedSeries: get(state, ['charts', 'byId', id, 'reject'], []),
  sourcesLookup: get(state, ['filtersIndex', 'byId', 'sources', 'labels'], []),
  showLock: isLocked(state, id),
  // ...get(state, ['charts', 'byId', id], {}),
  // ...get(state, ['data', 'byChart', id]),
});

const mapDispatchToProps = (dispatch) => ({
  onSetSeries: (id, series) => dispatch(setSeries(id, series)),
  onFetchChartData: (id) => dispatch(fetchChartData(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Chart));
