import React, { useCallback } from 'react';

import { connect } from 'react-redux';

import { isEmpty, get } from 'lodash';

import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as ShareButtonIcon } from './assets/share.svg';
import stringifyFilters, { FILTERS_NOT_READY_FLAG } from '../../../../utils/stringifyFilters';
import { GRAPH_SHARE_EVENT, trackGraphAction } from '../../../../state/analytics/actions';
import { Tooltip } from '@material-ui/core';

const ShareButton = ({
  chartName,
  chartQuery,
  chartTitle,
  databoardId,
  queryString,
  trackSharing,
}) => {
  const getLinkUrl = useCallback(() => {
    const origin = get(window, ['location', 'origin']);

    const pathURL = window.location.pathname;
    const urlLocation = pathURL.includes('/daa')
      ? 'daa'
      : pathURL.includes('/reports')
      ? 'reports'
      : 'dashboards';
    const url = isEmpty(chartName)
      ? `${origin}/${urlLocation}/${databoardId}?${queryString}`
      : `${origin}/${urlLocation}/${databoardId}/charts/${chartName}?${queryString}`;

    const body = `Check out the following chart\n\n${url}\n\n`;

    return `mailto:?subject=${encodeURIComponent(chartTitle)}&body=${encodeURIComponent(body)}`;
  }, [chartName, chartTitle, databoardId, queryString]);

  return (
    <a
      href={getLinkUrl()}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => trackSharing(chartName, chartQuery)}
    >
      <Tooltip title="Share Graph">
        <IconButton>
          <ShareButtonIcon />
        </IconButton>
      </Tooltip>
    </a>
  );
};

const mapStateToProps = (state) => {
  const queryString = stringifyFilters(state);

  return {
    databoardId: get(state, ['databoard', 'databoardId'], ''),
    queryString: queryString === FILTERS_NOT_READY_FLAG ? '' : queryString,
  };
};

const mapDispatchToProps = (dispatch) => ({
  trackSharing: (chartName, chartQuery) =>
    dispatch(trackGraphAction(GRAPH_SHARE_EVENT, chartName, chartQuery)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareButton);
