import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';

const isDirty = (filterModels, appliedGlobalFilters, filtersById) => {
  const dirtyFilters = filter(filterModels, ({ _id }) => {
    const selectedValues = get(appliedGlobalFilters, _id, []);
    const defaultValues = get(filtersById, [_id, 'defaultValues'], []);

    return !isEmpty(difference(selectedValues, defaultValues));
  });

  return !isEmpty(dirtyFilters);
};

export default isDirty;
