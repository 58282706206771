import map from 'lodash/map';
import get from 'lodash/get';

const normalizeBookmarks = (action) => {
  const bookmarks = get(action, ['payload', 'bookmarks'], []);

  return map(bookmarks, 'databoardId');
};

export default normalizeBookmarks;
