import React from 'react';

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  search: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 7,
    width: '40rem',
    height: '2.6rem',
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  searchIcon: {
    width: '1.3rem',
    height: '1.3rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    fill: '#FFFFFF',
  },
  inputRoot: {
    fontSize: '1.1rem',
    marginLeft: '1rem',
    color: theme.palette.custom.black,
    width: '100%',
    '& input::placeholder': {
      color: theme.palette.custom.gray4,
      opacity: 1,
    },
  },
  iconContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.custom.blue2,
    cursor: 'pointer',
  },
});

const TextSearch = ({ classes, value, onChange }) => {
  const path = window.location.pathname;

  return (
    <div className={classes.search}>
      <InputBase
        placeholder={
          path === '/dashboards'
            ? 'Search Dashboards'
            : path === '/reports'
            ? 'Search Reports'
            : path === '/daa'
            ? 'Search Data Tables'
            : 'Search Briefings'
        }
        classes={{ root: classes.inputRoot, input: classes.inputInput }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <div className={classes.iconContainer}>
        <SearchIcon className={classes.searchIcon} />
      </div>
    </div>
  );
};

export default withStyles(styles)(TextSearch);
