import React from 'react';

import { connect } from 'react-redux';

import get from 'lodash/get';
import find from 'lodash/find';
import head from 'lodash/head';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import { getChartLabels } from '../../../../../state/labels/selectors';

const styles = () => ({
  root: {
    padding: 24,
  },
  label: {
    textAlign: 'center',
  },
  value: { fontWeight: 'bold' },
  color: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const CustomTooltip = ({ classes, active, labels, formatter, payload: payloadArr }) => {
  if (active) {
    const payload = head(payloadArr);
    const label = get(find(labels, { name: payload.name }), 'text', payload.name);
    const { value } = payload;

    return (
      <Paper className={classes.root}>
        <div>
          <p className={classes.value}>{`${label} : ${formatter(value)}`}</p>
          <div className={classes.color}>
            <svg
              className="recharts-surface"
              width="14"
              height="14"
              viewBox="0 0 32 32"
              version="1.1"
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                marginRight: '4px',
              }}
            >
              <path
                stroke="none"
                fill={get(payload, ['payload', 'fill'])}
                d="M0,4h32v24h-32z"
                className="recharts-legend-icon"
              />
            </svg>
          </div>
        </div>
      </Paper>
    );
  }

  return null;
};

const mapStateToProps = (state, { id }) => ({
  labels: getChartLabels(state, id),
});

export default connect(mapStateToProps)(withStyles(styles)(CustomTooltip));
