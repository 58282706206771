import includes from 'lodash/includes';
import filter from 'lodash/filter';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';

const getByName = (organisations, textSearch) =>
  isEmpty(textSearch)
    ? organisations
    : filter(organisations, ({ name }) => includes(toLower(name), trim(toLower(textSearch))));

export default getByName;
