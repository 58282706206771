import { Auth } from 'aws-amplify';

const confirmSignUp = async (username, code) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await Auth.confirmSignUp(username, code);
      resolve(response);
    } catch (error) {
      console.log('error confirming sign up');
      console.log(error);
      reject(error);
    }
  });

export default confirmSignUp;
