import {
  FORMATTER_PERCENTAGE,
  FORMATTER_PERCENTAGE_NO_SIGN,
} from '../RechartBarChartEngine/formatters';
import palette from '../palette';
import { axisLine, horizontalCartesianGrid, numericalYAxis } from '../config';

const BAR_CHART_LAYOUT_HORIZONTAL = 'horizontal';
const LEGEND_TYPE_DEFAULT = 'DEFAULT';

/**
 * Diff:
 * - stackId
 * - cartesian
 */
export default {
  rootProps: {
    layout: BAR_CHART_LAYOUT_HORIZONTAL,
    margin: {
      top: 10,
      right: 0,
      left: 0,
      bottom: 20,
    },
  },
  series: [
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[0],
      stackId: 'a',
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[1],
      stackId: 'a',
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[2],
      stackId: 'a',
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[3],
      stackId: 'a',
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[4],
      stackId: 'a',
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[5],
      stackId: 'a',
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[6],
      stackId: 'a',
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[7],
      stackId: 'a',
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
  ],
  cartesianGridProps: horizontalCartesianGrid,
  legendProps: {
    type: LEGEND_TYPE_DEFAULT,
    hide: false,
    verticalAlign: 'top',
    align: 'right',
    wrapperStyle: {
      paddingBottom: '20px',
    },
  },
  tooltipProps: { hide: false, formatter: FORMATTER_PERCENTAGE },
  xAxisProps: {
    type: 'category',
    dataKey: 'name',
    adjustHeight: false,
    axisLine,
    tickLine: false,
    angle: 0,
    textAnchor: 'middle',
    interval: 0,
    showSamplesInAxis: false,
    hide: false,
    ticksProps: {
      type: 'localSamples',
      width: 120,
    },
    responsive: {
      lg: {
        ticksProps: {
          type: 'localSamples',
          width: 70,
        },
      },
      md: {
        ticksProps: {
          type: 'localSamples',
          width: 70,
        },
      },
      xs: {
        ticksProps: {
          type: 'localSamples',
          width: 50,
        },
      },
    },
  },
  yAxisProps: {
    ...numericalYAxis,
    domain: [0, 100],
    tickFormatter: (value) => `${Math.floor(value)} %`,
    scale: 'linear',
  },
};
