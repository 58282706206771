import head from 'lodash/head';
import map from 'lodash/map';

const removeColumn = (data, index) => {
  if (head(data).length < index) return data;

  return map(data, (row) => {
    row.splice(index, 1);

    return [...row];
  });
};

export default removeColumn;
