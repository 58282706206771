import { get, split } from 'lodash';

const getQuarter = (date) => {
  if (!date) return '';
  const splittedDate = split(date, '-');
  const month = get(splittedDate, 1);
  let quarter = '';
  switch (month) {
    case '01':
    case '02':
    case '03':
      quarter = 'Q1';
      break;
    case '04':
    case '05':
    case '06':
      quarter = 'Q2';
      break;
    case '07':
    case '08':
    case '09':
      quarter = 'Q3';
      break;
    default:
      quarter = 'Q4';
      break;
  }
  return quarter + ' ' + get(splittedDate, 0);
};

export default getQuarter;
