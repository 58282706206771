import React from 'react';

import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { navigate } from '@reach/router';
import Footer from '../../components/Footer';

import { ReactComponent as FirstIllustration } from './assets/img1.svg';
import { ReactComponent as SecondIllustration } from './assets/img2.svg';
import { ReactComponent as Dashboards } from './assets/dashboards.svg';
import { ReactComponent as Reports } from './assets/reports.svg';
import { ReactComponent as DAAs } from './assets/daa.svg';

const styles = (theme) => ({
  root: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  leftSection: {
    minWidth: '28%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '5%',
    height: '100%',
  },
  middleSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '5%',
    paddingRight: '5%',
  },
  rightSection: {
    minWidth: '28%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '5%',
    height: '100%',
  },
  specialWord: {
    fontFamily: 'Nunito Sans Black',
    color: theme.palette.custom.blue2,
  },
  firstSection: {
    backgroundColor: theme.palette.custom.blue2,
    height: '25rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    fontSize: '2.25rem',
    color: 'white',
    fontFamily: 'Nunito Sans Black',
    textAlign: 'center',
    width: '100%',
  },
  text: {
    color: 'white',
    fontSize: '1rem',
    textAlign: 'center',
  },
  specialWord3: {
    color: theme.palette.custom.blue4,
    fontFamily: 'Nunito Sans Black',
  },
  secondSection: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingLeft: '30%',
    paddingRight: '30%',
    marginTop: '-3rem',
  },
  text4: {
    fontSize: '1rem',
  },
  heading2: {
    color: theme.palette.custom.blue2,
    fontFamily: 'Nunito Sans Black',
    fontSize: '1.5rem',
    marginTop: '1rem',
    textAlign: 'center',
  },
  button: {
    backgroundColor: theme.palette.custom.blue2,
    borderRadius: 20,
    color: 'white',
    fontFamily: 'Nunito Sans Black',
    padding: '0.5rem 2.5rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    fontSize: '0.7rem',
    '&:hover': {
      backgroundColor: theme.palette.custom.blue2,
    },
  },
  card: {
    backgroundColor: 'white',
    minWidth: '21rem',
    minHeight: '21rem',
    borderRadius: 20,
    boxShadow: '-18px 20.1px 62px 0 rgba(27, 58, 139, 0.16)',
    display: 'flex',
    padding: '3rem 3rem 0 3rem',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  text2: {
    color: theme.palette.custom.gray5,
    fontSize: '1rem',
    textAlign: 'center',
  },
  thirdSection: {
    height: '15rem',
  },
});

const HomePage = ({ classes }) => {
  return (
    <Grid className={classes.root}>
      <Grid>
        <Grid className={classes.firstSection}>
          <Grid className={classes.leftSection}>
            <FirstIllustration />
          </Grid>
          <Grid className={classes.middleSection}>
            <Typography className={classes.heading}>Welcome!</Typography>
            <Typography className={classes.text}>
              Ready to access a world of developer insights? Dive into interactive dashboards,
              insights reports and custom analysis and start your journey into understanding the
              developer ecosystem.
            </Typography>
          </Grid>
          <Grid className={classes.rightSection}>
            <SecondIllustration />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.secondSection}>
        <Grid className={classes.card}>
          <Dashboards />
          <Typography className={classes.heading2}>Dashboards</Typography>
          <Typography className={classes.text2}>
            Interactive data dashboards. Use the filters to narrow results to your audience.
          </Typography>
          <Button className={classes.button} onClick={() => navigate(`/dashboards`)}>
            Visit
          </Button>
        </Grid>
        <Grid className={classes.card}>
          <Reports />
          <Typography className={classes.heading2}>Reports</Typography>
          <Typography className={classes.text2}>
            In-depth analysis and insights on key developer-focused topics and trends.
          </Typography>
          <Button className={classes.button} onClick={() => navigate(`/reports`)}>
            Browse
          </Button>
        </Grid>
        <Grid className={classes.card}>
          <DAAs />
          <Typography className={classes.heading2}>Custom Data Cuts</Typography>
          <Typography className={classes.text2}>
            Bespoke data analysis to answer your questions on the developer ecosystem.
          </Typography>
          <Button className={classes.button} onClick={() => navigate(`/daa`)}>
            Access
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.thirdSection} />
      <Footer />
    </Grid>
  );
};

export default withStyles(styles)(HomePage);
