import React, { useEffect } from 'react';

import { Grid } from '@material-ui/core';

import { handleViewport } from '../../../../../components/ViewportContainer';

const Container = ({ forwardedRef, children, inViewport, ...restProps }) => {
  useEffect(() => {
    if (inViewport) {
      // set your code here
    }
  }, []);

  return (
    <Grid ref={forwardedRef} {...restProps}>
      {children}
    </Grid>
  );
};

const ViewportContainer = handleViewport(Container);

export default ViewportContainer;
