import React, { useState } from 'react';

import * as Yup from 'yup';
import { compose } from 'redux';
// import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import TextField from '../../components/TextField';
import PasswordField from '../../components/PasswordField';
import Container from '../../components/Container';
// import Checkbox from '../../components/Checkbox';
import FormTitle from '../../components/FormTitle/FormTitle';
import SubmitButton from '../../components/SubmitButton';
import TextButton from '../../components/TextButton';
import Stepper from '../../components/Stepper';
import TnC from './TnC';

import validator from '../../validator';

import { VERIFY_EMAIL_SCREEN, SIGN_IN_SCREEN, EXISTING_MEMBER_SCREEN } from '../../types';

import signUp from '../../actions/signUp';
import { trackSignUp } from '../../../../state/analytics/actions';
import { connect } from 'react-redux';
import GoogleButton from '../../components/GoogleButton';

const styles = () => ({});

const NewAccount = ({
  handleSubmit,
  submitting,
  setScreen,
  setEmail,
  setPassword,
  doTrackSignUp,
}) => {
  const [error, setError] = useState(null);

  const handleSignUp = async ({
    firstName,
    lastName,
    company,
    role,
    email,
    password /* newsletter */,
  }) => {
    try {
      const user = {
        username: email,
        password,
        attributes: {
          email,
          'custom:firstname': firstName,
          'custom:lastname': lastName,
          'custom:company': company,
          'custom:role': role,
        },
      };
      await signUp(user);

      setEmail(email);
      setPassword(password);
      doTrackSignUp(email);

      setScreen(VERIFY_EMAIL_SCREEN);
    } catch (e) {
      if (e.code === 'UsernameExistsException') {
        setScreen(EXISTING_MEMBER_SCREEN);
      }

      setError(e);
    }
  };

  return (
    <Container error={error}>
      <Stepper activeStep={0} />
      <FormTitle>New Account</FormTitle>
      <form onSubmit={handleSubmit(handleSignUp)}>
        <Field autoComplete="given-name" name="firstName" label="Name" component={TextField} />
        <Field autoComplete="family-name" name="lastName" label="Surname" component={TextField} />
        <Field autoComplete="organization" name="company" label="Company" component={TextField} />
        <Field autoComplete="organization-title" name="role" label="Role" component={TextField} />
        <Field autoComplete="email" name="email" type="email" label="Email" component={TextField} />
        <Field
          autoComplete="new-password"
          name="password"
          label="Create Password"
          component={PasswordField}
        />
        <Field name="tnc" component={TnC} />
        {/* <Field
          name="newsletter"
          label="I wish to receive email notifications about new product features and dashboards (you can unsubscribe any time)"
          component={Checkbox}
        /> */}
        {!submitting && <SubmitButton label="Sign up" />}

        {!submitting && <GoogleButton label="Sign in with Google" />}

        {submitting && <CircularProgress />}
        <TextButton
          text="Already a member?"
          label="Sign in"
          onClick={() => setScreen(SIGN_IN_SCREEN)}
          disabled={submitting}
        />
      </form>
    </Container>
  );
};

const schema = Yup.object().shape({
  firstName: Yup.string().trim().required('First name is required'),
  lastName: Yup.string().trim().required('Last name is required'),
  company: Yup.string().trim().required('Company is required'),
  role: Yup.string().trim().required('Role is required'),
  email: Yup.string().email().trim().required('Email is required'),
  password: Yup.string()
    .matches(/^[\S]+.*[\S]{6,}$/, {
      message:
        'Password must have length greater than or equal to 6, contain at least a number and no whitespace',
    })
    .required(),
  tnc: Yup.bool().oneOf([true], 'Field must be checked').required(),
});

const mapDispatchToProps = (dispatch) => ({
  doTrackSignUp: (email) => dispatch(trackSignUp(email)),
});

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: 'newAccountForm',
    asyncValidate: validator(schema),
  }),
)(withStyles(styles)(NewAccount));
