import includes from 'lodash/includes';
import filter from 'lodash/filter';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';

const getByFirstName = (users, textSearch) => {
  if (isEmpty(textSearch)) return users;

  return filter(users, ({ firstName }) => includes(toLower(firstName), trim(toLower(textSearch))));
};

export default getByFirstName;
