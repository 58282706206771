import includes from 'lodash/includes';
import filter from 'lodash/filter';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';

const getByName = (dashboards, textSearch) =>
  isEmpty(textSearch)
    ? dashboards
    : filter(dashboards, ({ title }) => includes(toLower(title), trim(toLower(textSearch))));

export default getByName;
