import React from 'react';

const PrismicLinkComponent = ({ href, ...props }) => {
  const onClickLink = (e, href) => {
    if (href.startsWith('https://section-')) {
      e.preventDefault();
      const sectionId = href.replace('https://', '');
      const sectionScrolled = document.getElementById(sectionId);
      if (sectionScrolled) {
        window.scrollTo(0, sectionScrolled.offsetTop - 100);
      }
    }
  };

  return (
    <a
      href={href.startsWith('https://section-') ? '#' : href}
      target="_blank"
      onClick={(e) => onClickLink(e, href)}
      rel="noreferrer"
    >
      {props.children}
    </a>
  );
};

export default PrismicLinkComponent;
