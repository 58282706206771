import get from 'lodash/get';
import find from 'lodash/find';

import getAxisDomain from '../helpers/getAxisDomain';
import getAxisTickFormatter from '../helpers/getAxisTickFormatter';
import getTooltipFormatter from '../helpers/getTooltipFormatter';
import getFormattedSeries from '../helpers/getFormattedSeries';
import { LOW_SAMPLE_SIZE } from '../../../utils/hasLowSampleSize';

const generateConfig = (
  defaultConfig,
  formatter,
  axisTitle = '',
  domain,
  customConfig,
  configuration,
) => ({
  ...defaultConfig,
  series: getFormattedSeries(formatter, defaultConfig.series),
  tooltipProps: {
    ...get(defaultConfig, 'tooltipProps', {}),
    formatter: getTooltipFormatter(formatter),
  },
  xAxisProps: {
    ...get(defaultConfig, 'xAxisProps', {}),
    label: {
      ...get(defaultConfig, ['xAxisProps', 'label'], {}),
      value: get(customConfig, 'xAxisProps.axisTitle', axisTitle),
    },
    domain: getAxisDomain(domain, formatter),
    tickFormatter: getAxisTickFormatter(formatter),
  },
  chartThreshold: get(find(configuration, { key: 'threshold' }), 'value', LOW_SAMPLE_SIZE),
});

export default generateConfig;
