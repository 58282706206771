import get from 'lodash/get';

import formatters, { FORMATTER_POPULATION } from '../RechartBarChartEngine/formatters';

const getAxisTickFormatter = (formatter) => {
  if (formatter === 'percentage') return (value) => `${Math.floor(value)} %`;
  if (formatter === 'score') return null;
  if (formatter === 'population') {
    const populationTickFormatter = get(formatters, FORMATTER_POPULATION);
    return (value) => populationTickFormatter(value);
  }

  return (value) => `${Math.floor(value)} %`;
};

export default getAxisTickFormatter;
