import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    fontSize: '1rem',
  },
  text: {
    color: '#636666',
    marginTop: '1.2rem',
  },
  label: {
    textTransform: 'none !important',
    color: '#0f70e0',
    fontWeight: 'bold',
  },
});

const TextButton = ({ classes, text, label, onClick, loading, ...rest }) => (
  <div className={classes.root}>
    <div className={classes.text}>{text}</div>
    {loading && <CircularProgress size={32.3} />}
    {!loading && (
      <Button
        {...rest}
        onClick={onClick}
        classes={{
          label: classes.label,
        }}
      >
        {label}
      </Button>
    )}
  </div>
);

export default withStyles(styles)(TextButton);
