import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import isNull from 'lodash/isNull';
import indexOf from 'lodash/indexOf';
import isNumber from 'lodash/isNumber';

import normalizeGridColumns from './normalizeGridColumns';

const normalizeGridRows = ({ data, table }) => {
  const dataTable = get(find(data, { name: table }), 'values', []);
  const headers = normalizeGridColumns({ data, table });

  const allRows = map(dataTable, ({ name: rowName, values }) => {
    const rowValues = reduce(
      values,
      (acc, { name, value }) => {
        const transformedValue = indexOf(headers, name) !== -1 && isNull(value) ? 'NA_NAN' : value;

        return [...acc, transformedValue];
      },
      [],
    );

    return [rowName, ...rowValues];
  });

  // Clean up rows with all values equal to null
  const cleanRows = filter(allRows, (row) => {
    const numericValues = filter(row, (rowItem) => !isNull(rowItem) && isNumber(rowItem));

    return numericValues.length > 0;
  });

  // Clean up columns with all values equal to null
  return map(cleanRows, (series) => filter(series, (value) => !isNull(value)));
};

export default normalizeGridRows;
