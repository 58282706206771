import get from 'lodash/get';
import head from 'lodash/head';
import find from 'lodash/find';
import map from 'lodash/map';

const getBenchmarkedSamples = ({ data, table }) => {
  const sampleMatrix = find(data, { name: table });
  const rows = get(sampleMatrix, 'values', []);

  return map(rows, (row) => {
    const rowName = get(row, 'name', '');
    // const rowText = get(row, 'text', '');
    const colValue = get(head(get(row, 'values', [])), 'value');

    return { name: rowName, value: colValue };
  });
};

export default getBenchmarkedSamples;
