import clamp from 'lodash/clamp';
import ceil from 'lodash/ceil';

import {
  FORMATTER_PERCENTAGE,
  FORMATTER_PERCENTAGE_NO_SIGN,
} from '../RechartBarChartEngine/formatters';
import palette from '../palette';
import { axisLine, horizontalCartesianGrid, numericalYAxis } from '../config';

const BAR_CHART_LAYOUT_HORIZONTAL = 'horizontal';
const LEGEND_TYPE_DEFAULT = 'DEFAULT';

export default {
  rootProps: {
    layout: BAR_CHART_LAYOUT_HORIZONTAL,
    margin: {
      top: 10,
      right: 0,
      left: 0,
      bottom: 20,
    },
  },
  series: [
    {
      dataKey: 'value',
      fill: palette[0],
      stackId: null,
      label: {
        position: 'top',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
  ],
  cartesianGridProps: horizontalCartesianGrid,
  legendProps: {
    type: LEGEND_TYPE_DEFAULT,
    hide: true,
    verticalAlign: 'top',
    align: 'right',
    wrapperStyle: {
      paddingBottom: '20px',
    },
  },
  tooltipProps: { hide: false, formatter: FORMATTER_PERCENTAGE },
  xAxisProps: {
    type: 'category',
    dataKey: 'name',
    adjustHeight: true,
    axisLine,
    tickLine: false,
    angle: -45,
    textAnchor: 'end',
    interval: 0,
    showSamplesInAxis: false,
    hide: false,
    ticksProps: {
      type: 'custom',
      width: 180,
    },
    responsive: {
      xs: {
        ticksProps: {
          type: 'custom',
          width: 50,
        },
      },
    },
  },
  yAxisProps: {
    ...numericalYAxis,
    domain: [0, (dataMax) => clamp(ceil(dataMax, -1), 0, 100)],
    tickFormatter: (value) => `${Math.floor(value)} %`,
    scale: 'linear',
  },
};
