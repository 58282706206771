import React from 'react';

import { makeStyles } from '@material-ui/core';

type Padding = {
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
};

interface Props {
  text?: string;
  styles?: Padding;
}

const useStyles = makeStyles({
  root: {
    fontWeight: 'bold',
    fontFamily: 'Nunito Sans',
    fontSize: 12,
  },
});

const AxisTitle = ({ text, styles }: Props) => {
  const classes = useStyles();
  if (!text) return null;

  return (
    <div className={classes.root} style={styles}>
      {text}
    </div>
  );
};

export default AxisTitle;
