const colors = [
  '#2c55c6',
  '#1db8f0',
  '#34dbe2',
  '#f6a300',
  '#1b3a8b',
  '#8427d7',
  '#bd49c3',
  '#dc80d9',
  '#f6d600',
  '#f65200',
  '#e10404',
  '#0d5f53',
  '#0f8b79',
  '#6eb39c',
  '#a3cbbe',
  '#4e483e',
  '#2c55c6',
  '#1db8f0',
  '#34dbe2',
  '#f6a300',
  '#1b3a8b',
  '#8427d7',
  '#bd49c3',
  '#dc80d9',
  '#f6d600',
  '#f65200',
  '#e10404',
  '#0d5f53',
  '#0f8b79',
  '#6eb39c',
  '#a3cbbe',
  '#4e483e',
];

export default colors;
