import React from 'react';
import cn from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import { ReactComponent as TickIcon } from './assets/tick.svg';

const styles = () => ({
  root: {
    position: 'relative',
    width: '3.4rem',
    height: '3.4rem',
    borderRadius: '50%',
    fontFamily: 'Nunito Sans Black',
    fontSize: '1.5rem',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inactive: { backgroundColor: '#e7e7ef' },
  active: { backgroundColor: '#0f70e0' },
  completed: { backgroundColor: '#0f70e0' },
  badge: {
    width: '1.4rem',
    height: '1.4rem',
    borderRadius: '50%',
    backgroundColor: '#1ce8f0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: '-0.6rem',
  },
});

const StepIcon = ({ classes, active, completed, icon }) => {
  return (
    <div
      className={cn(
        classes.root,
        { [classes.inactive]: !active && !completed },
        { [classes.active]: active && !completed },
        { [classes.completed]: !active && completed },
      )}
    >
      {completed && (
        <div className={classes.badge}>
          <TickIcon />
        </div>
      )}
      {icon}
    </div>
  );
};
export default withStyles(styles)(StepIcon);
