import React, { useState, useCallback } from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';
import { navigate } from '@reach/router';

import { map, get, isEmpty, some, find } from 'lodash';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import { ReactComponent as Notification } from './assets/notification.svg';
import { ReactComponent as NotificationsUnread } from './assets/notificationUnread.svg';
import { ReactComponent as Arrow } from './assets/arrow.svg';

import Logo from './assets/logo.svg';
import { setUserNotifications, signOut } from '../../../../state/user/actions';
import { resetData } from '../../../../state/data/actions';
import getRoutes from '../../../../screens/routes/getRoutes';
import { Grid, Typography } from '@material-ui/core';
import { API } from 'aws-amplify';

const styles = (theme) => ({
  root: {
    boxShadow: '0px 6px 9px 0 rgba(188, 198, 210, 0.15)',
    minHeight: '4rem',
    justifyContent: 'center',
  },
  logo: {
    width: '9.4rem',
  },
  navigator: { cursor: 'pointer' },
  grow: {
    flexGrow: 1,
  },
  email: {
    color: theme.palette.custom.gray5,
    fontSize: '1rem',
    fontWeight: 'bold',
    textTransform: 'lowercase',
    marginLeft: 4,
  },
  accountIcon: {
    color: theme.palette.custom.blue2,
  },
  notificationsIcon: {
    width: '1.3rem',
    height: '1.4rem',
  },
  menuItem: {
    fontSize: '1rem',
  },
  notificationMenu: {
    padding: '1.5rem',
    maxWidth: '30rem',
  },
  notificationTitle: {
    color: theme.palette.custom.gray6,
    fontSize: '1rem',
    fontFamily: 'Nunito Sans Black',
    marginBottom: '0.5rem',
  },
  notificationText: {
    fontSize: '0.7rem',
    fontFamily: 'Nunito Sans Black',
    flex: 1,
    textAlign: 'left',
    maxWidth: '90%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  notificationButton: {
    fontSize: '1rem',
    fontFamily: 'Nunito Sans Black',
    color: theme.palette.custom.blue2,
    textAlign: 'center',
  },
  notificationButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.5rem',
  },
  subscriptionNotification: {
    backgroundColor: '#f6a300',
  },
  newDataNotification: {
    backgroundColor: theme.palette.custom.blue2,
  },
  readNotificationIcon: {
    backgroundColor: theme.palette.custom.gray3,
  },
  readNotificationText: {
    color: theme.palette.custom.gray6,
    fontFamily: 'Nunito Sans',
  },
  notificationDot: {
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: 60,
    marginRight: '0.3rem',
  },
  notificationItem: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1rem',
    borderBottom: '2px solid #ececf2',
    paddingBottom: '1rem',
  },
  arrow: {
    marginLeft: '0.3rem',
  },
  button: {
    fontFamily: 'Nunito Sans Black',
    color: theme.palette.custom.blue2,
    fontSize: '0.75rem',
    textTransform: 'none',
  },
});

const Topbar = ({
  classes,
  user,
  jwtToken,
  userId,
  doSignOut,
  doResetDataState,
  onSetUserNotifications,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);

  const openProfileMenu = useCallback((event) => setAnchorEl(event.currentTarget));
  const closeProfileMenu = useCallback(() => setAnchorEl(null));
  const openNotificationMenu = useCallback((event) => setNotificationAnchorEl(event.currentTarget));
  const closeNotificationMenu = useCallback(() => setNotificationAnchorEl(null));
  const logout = useCallback(() => {
    doSignOut();
    doResetDataState();
    setAnchorEl(null);
    navigate('/');
  });

  const goToNotifications = useCallback(() => {
    setNotificationAnchorEl(null);
    navigate('/notifications');
  });

  const mailto = 'sales@slashdata.co';
  const cc = 'technology@slashdata.co';

  const openNotification = useCallback(async (notification) => {
    setNotificationAnchorEl(null);
    const title = get(find([], { _id: notification.resourceId }), 'title', '');
    const params = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    if (notification.type === 'NEW_DATA') {
      navigate(`/dashboards/${notification.resourceId}`);
    } else {
      window.open(
        `mailto:${mailto}?cc=${cc}&subject=${encodeURIComponent(
          `Dashboard Access Renewal: ${title}`,
        )}&body=${encodeURIComponent(
          `Dashboard: ${title}\nEmail: ${user.email}\nName: ${user.firstName}\nLast name: ${user.lastName}\nRole: ${user.roles}`,
        )}`,
      );
    }
    await API.put('users', `/users/${userId}/notifications/${notification._id}`, params);
    const notifications = await API.get('users', `/users/${userId}/notifications`, params);
    onSetUserNotifications(notifications);
  });

  const handleLogoClick = useCallback(() => navigate('/'));

  return (
    <AppBar position="fixed" className={classes.root}>
      <Toolbar>
        <img
          src={Logo}
          className={cn(classes.logo, classes.navigator)}
          alt="logo"
          onClick={handleLogoClick}
        />
        <div className={classes.grow} />
        {!isEmpty(user.userId) && (
          <>
            {map(getRoutes(user.roles), (route) =>
              !isEmpty(route.menuItem) ? (
                <Button
                  className={classes.menuItem}
                  key={route.menuItem}
                  onClick={() => navigate(route.url)}
                >
                  {route.menuItem}
                </Button>
              ) : null,
            )}

            <IconButton onClick={openNotificationMenu}>
              {some(
                user.notifications,
                (notification) => !notification.viewedBy.includes(user.userId),
              ) ? (
                <NotificationsUnread className={classes.notificationsIcon} />
              ) : (
                <Notification className={classes.notificationsIcon} />
              )}
            </IconButton>

            <Button onClick={openProfileMenu}>
              <AccountCircle className={classes.accountIcon} />
              <span className={classes.email}>{user.email}</span>
            </Button>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(anchorEl)}
              onClose={closeProfileMenu}
            >
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
            <Menu
              anchorEl={notificationAnchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={Boolean(notificationAnchorEl)}
              onClose={closeNotificationMenu}
            >
              <Grid className={classes.notificationMenu}>
                <Typography className={classes.notificationTitle}>Notifications</Typography>
                {user.notifications.length > 0 ? (
                  user.notifications.slice(0, 5).map((notification) => (
                    <MenuItem
                      key={notification._id}
                      className={classes.notificationItem}
                      onClick={() => openNotification(notification)}
                    >
                      <Grid
                        className={cn(
                          classes.notificationDot,
                          {
                            [classes.readNotificationIcon]: notification.viewedBy.includes(
                              user.userId,
                            ),
                          },
                          {
                            [classes.newDataNotification]:
                              !notification.viewedBy.includes(user.userId) &&
                              notification.type === 'NEW_DATA',
                          },
                          {
                            [classes.subscriptionNotification]:
                              !notification.viewedBy.includes(user.userId) &&
                              notification.type === 'SUBSCRIPTION_EXPIRE',
                          },
                        )}
                      />
                      <Typography
                        className={cn(classes.notificationText, {
                          [classes.readNotificationText]: notification.viewedBy.includes(
                            user.userId,
                          ),
                        })}
                      >
                        {notification.type === 'NEW_DATA'
                          ? 'New Data available to a dashboard you are subscribed to.'
                          : 'Your subscription is about to expire. Contact Us'}
                      </Typography>
                      {!notification.viewedBy.includes(user.userId) && (
                        <Arrow className={classes.arrow} />
                      )}
                    </MenuItem>
                  ))
                ) : (
                  <Typography className={classes.notificationText}>
                    No notifications to show.
                  </Typography>
                )}
                <Grid className={classes.notificationButtonContainer}>
                  <MenuItem className={classes.notificationButton} onClick={goToNotifications}>
                    See All
                  </MenuItem>
                </Grid>
              </Grid>
            </Menu>
          </>
        )}
        {isEmpty(user.userId) && (
          <Grid>
            <Button className={classes.button} onClick={() => navigate('/login')}>
              Log in
            </Button>
            <Button className={classes.button} onClick={() => navigate('/signup')}>
              Sign up
            </Button>
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  user: get(state, 'user'),
  jwtToken: get(state, ['user', 'jwtToken'], ''),
  userId: get(state, ['user', 'userId'], []),
});

const mapDispatchToProps = (dispatch) => ({
  doSignOut: () => dispatch(signOut()),
  doResetDataState: () => dispatch(resetData()),
  onSetUserNotifications: (notifications) => dispatch(setUserNotifications(notifications)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Topbar));
