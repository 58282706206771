import { filter, flatten, forEach, get, head, isEmpty, map, union } from 'lodash';

const NORMAL_FILTER_LINK = 'NORMAL';

const applyLinks = (filterGroups, filterName, selection, links, selected, formValues, change) => {
  if (isEmpty(links)) return;
  const sourceLinks = filter(
    links,
    (link) => link.source === filterName && link.type === NORMAL_FILTER_LINK,
  );
  const destinationLinks = filter(
    links,
    (link) => link.destination.includes(filterName) && link.type === NORMAL_FILTER_LINK,
  );
  // If the filter option is deselected we only check the links where the current filter is in the source and deselect the options in the destination filters
  if (!selected) {
    forEach(sourceLinks, (link) => {
      forEach(link.destination, (destination) => {
        const destinationFilter = head(
          filter(flatten(map(filterGroups, 'values')), {
            name: destination,
          }),
        );
        const destinationValues = get(formValues, destinationFilter._id, []);
        if (isEmpty(link.mapping)) {
          if (destinationValues.indexOf(selection) !== -1) {
            destinationValues.splice(destinationValues.indexOf(selection), 1);
          }
        } else {
          forEach(link.mapping, (mapping) => {
            if (mapping.values.includes('*') || mapping.values.includes(selection)) {
              if (destinationValues.indexOf(mapping.name) !== -1) {
                destinationValues.splice(destinationValues.indexOf(mapping.name), 1);
              }
            }
          });
        }
        change('globalFilters', destinationFilter._id, destinationValues);
      });
    });
  } else {
    // In the else part, the value is being selected; So we only check the links where the current filter is in The destination, to select the same value in the source.
    forEach(destinationLinks, (link) => {
      const sourceFilter = head(
        filter(flatten(map(filterGroups, 'values')), { name: link.source }),
      );
      const sourceValues = get(formValues, sourceFilter._id, []);
      let updatedValues = sourceValues;
      if (isEmpty(link.mapping)) {
        updatedValues = union(updatedValues, [selection]);
      } else {
        forEach(link.mapping, (mapping) => {
          if (mapping.name === '*' || mapping.name === selection) {
            updatedValues = union(updatedValues, mapping.values);
          }
        });
      }
      change('globalFilters', sourceFilter._id, updatedValues);
    });
  }
};

export default applyLinks;
