import filter from 'lodash/filter';

import hasDashboardAccess from '../../../../../utils/hasDashboardAccess';

const getSubscribed = (dashboards, subscriptions) => {
  const filteredDashboards = filter(dashboards, (dashboard) => {
    return hasDashboardAccess(subscriptions, dashboard._id);
  });

  return filteredDashboards;
};

export default getSubscribed;
