import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import findIndex from 'lodash/findIndex';
import reverse from 'lodash/reverse';
import head from 'lodash/head';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import omit from 'lodash/omit';

export const getRawData = ({ data, table, source }) => {
  const dataTable = find(data, { name: table });
  const values = get(dataTable, ['values', 0, 'values'], []);
  // NOTE: we need both name and text as the export relies on text and sorting on name
  const rawData = map(values, ({ value, ...rest }) => ({
    [source]: value,
    ...rest,
  }));

  return rawData;
};

export const sortData = ({ rawData, sorting }) => {
  const sortedResults = reverse(
    sortBy(rawData, (valuesToSortBy) =>
      reduce(omit(valuesToSortBy, ['name', 'text', 'hint']), (result, value) => result + value, 0),
    ),
  );
  const index = findIndex(sortedResults, { name: head(get(sorting, 'exclude')) });

  if (index === -1) return sortedResults;

  const item = head(sortedResults.splice(index, 1));

  sortedResults.push(item);

  return sortedResults;
};

export const omitZeroValues = ({ data }) =>
  filter(data, (values) =>
    reduce(omit(values, ['name', 'text', 'hint']), (sum, value) => sum + value, 0),
  );

/**
 * Single source distribution data transformer
 */
const getSourceData = ({ source, data, table, sorting, omitZeros }) => {
  const rawData = getRawData({ source, data, table });

  if (isEmpty(sorting)) return rawData;

  const sortedResults = sortData({ rawData, sorting });

  if (!omitZeros) return sortedResults;

  const nonZeroData = omitZeroValues({ data: sortedResults });

  return nonZeroData;
};

export default getSourceData;
