import React from 'react';

import DropDownField from '../../DropDownField';

export const ONE_MONTH = '1m';
export const SIX_MONTHS = '6m';
export const ONE_YEAR = '1y';
export const INDEFINITE = 'in';
export const CUSTOM = 'c';

const DurationField = ({ ...rest }) => {
  const options = [
    { label: '1 Month', value: ONE_MONTH },
    { label: '6 Months', value: SIX_MONTHS },
    { label: '1 Year', value: ONE_YEAR },
    { label: 'Indefinite', value: INDEFINITE },
    { label: 'Custom', value: CUSTOM },
  ];

  return <DropDownField suggestions={options} {...rest} placeholder="Duration" />;
};

export default DurationField;
