import React from 'react';
import cn from 'classnames';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import Topbar from './components/Topbar';

const styles = (theme) => ({
  root: {},
  content: {
    height: '100vh',
    flexGrow: 1,
    paddingTop: '4rem',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
});

const Layout = ({ classes, children }) => {
  return (
    <Grid container className={classes.root}>
      <Topbar />
      <Grid
        container
        className={cn(classes.content, {
          'animated fadeIn': true,
        })}
      >
        <Grid item xs={12} className={classes.item}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Layout);
