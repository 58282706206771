import React from 'react';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    width: '9.5rem',
    height: '2.2rem',
    borderRadius: 100,
    '&:hover': {
      boxShadow: 'none',
    },
  },
  label: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    textTransform: 'capitalize !important',
    color: theme.palette.custom.blue2,
  },
});

const ResetFiltersBtn = ({ classes, onClick }) => (
  <Button onClick={onClick} classes={classes}>
    Reset Filters
  </Button>
);

export default withStyles(styles)(ResetFiltersBtn);
