import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';
import { API } from 'aws-amplify';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';

import { setFilterLabels } from '../../../state/filtersIndex/actions';
import {
  normalizeFilterValues,
  extractFilterValues,
  extractFilterDefaultAdapter,
} from '../../../utils/filterValues';
import FilterOptions from '../FilterOptions';
import { ReactComponent as FilterIcon } from './assets/filter.svg';
import getValueLabels from '../shared/utils/getValueLabels';
import getFilterTooltip from '../shared/utils/getFilterTooltip';
import { map } from 'lodash';

// In-sync with mongo model
const FILTER_MULTI_SELECTION = 'MULTI';
const FILTER_SINGLE_SELECTION = 'SINGLE';

const styles = (theme) => ({
  button: {
    fontSize: '1rem',
    color: theme.palette.custom.black,
    display: 'flex',
    justifyContent: 'space-between',
  },
  options: {
    height: '22.1rem',
    width: '15.2rem',
    flexWrap: 'nowrap',
  },
  badge: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '50%',
    backgroundColor: theme.palette.custom.purple,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    marginRight: '0.3rem',
  },
  tooltip: {
    backgroundColor: theme.palette.custom.white,
    boxShadow: '-2px 3px 10px 0 rgba(0, 0, 0, 0.16)',
    border: 'solid 1px #e7e7ef',
    borderRadius: 10,
    padding: '1.1rem',
    color: theme.palette.custom.black,
    fontSize: '0.8rem',
  },
  paper: {
    borderRadius: 20,
  },
  popover: {
    marginTop: '0.4rem',
  },
});

const DropdownFilter = ({
  _id: filterId,
  classes,
  className,
  query,
  table,
  reject, // Which labels to reject
  filter, // Which labels to filter
  adapter,
  input,
  jwtToken,
  hide,
  filterTooltip,
  onSetFilterLabels,
  selection,
  version,
  userId,
  databoardId,
  allFilters,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [, setLoader] = useState(false);
  const [, setError] = useState(null);

  useEffect(() => {
    const fetchValues = async () => {
      setLoader(true);

      const params = {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      };

      try {
        if (version === 1) {
          const data = await API.get(
            'dashboards',
            `/dataqueries/name/${query}/filters/name/${table}`,
            params,
          );

          const labelArray = extractFilterValues({ adapter, data });
          const labels = normalizeFilterValues({ filter, reject, labels: labelArray });
          const defaultAdapter = extractFilterDefaultAdapter({ adapter, data });
          const allOptions = get(data, 'rows', []);

          onSetFilterLabels(filterId, labels, defaultAdapter, allOptions);
        } else {
          const sourceLabelArray = get(allFilters, ['sources', 'labels'], []);
          const filterObj = get(allFilters, filterId, {});
          params.body = {
            version: 2,
            query: {
              source: map(sourceLabelArray, (item) => get(item, 'id', 0)),
              filterQuestion: query,
              rejectedLabels: get(filterObj, 'reject', []),
              renamedLabels: get(filterObj, 'replaceLabels', []),
              displayLabels: get(filterObj, 'filter', []),
            },
          };
          const data = await API.post(
            'dashboards',
            `/users/${userId}/databoards/${databoardId}/engine`,
            params,
          );
          const allOptions = get(data, 'rows', []);
          onSetFilterLabels(filterId, allOptions);
        }
      } catch (e) {
        setError(e);
      } finally {
        setLoader(false);
      }
    };

    if (
      (!isEmpty(query) && !isEmpty(table) && version === 1) ||
      (version === 2 && !isEmpty(query))
    ) {
      fetchValues();
    }
  }, [query, table]);

  const counter = get(input, ['value', 'length'], 0);

  if (hide) return null;

  return (
    <>
      <Tooltip title={filterTooltip} classes={{ tooltip: classes.tooltip }}>
        <Grid
          className={cn(classes.button, className)}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <div>
            <FilterIcon />
            {filterTooltip}
          </div>
          {counter > 0 && selection !== FILTER_SINGLE_SELECTION && (
            <div className={classes.badge}>{counter}</div>
          )}
        </Grid>
      </Tooltip>
      <Popover
        id="render-props-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ root: classes.popover, paper: classes.paper }}
      >
        <FilterOptions
          _id={filterId}
          input={input}
          className={classes.options}
          withScroll
          withBorder
        />
      </Popover>
    </>
  );
};

const mapStateToProps = (state, { _id, input, label }) => ({
  filterTooltip: getFilterTooltip(label, getValueLabels(state, _id, input.value)),
  jwtToken: get(state, ['user', 'jwtToken']),
  selection: get(state, ['filtersIndex', 'byId', _id, 'selection'], FILTER_MULTI_SELECTION),
  version: get(state, ['databoard', 'version'], 1),
  userId: get(state, ['user', 'userId']),
  databoardId: get(state, ['databoard', 'databoardId']),
  allFilters: get(state, ['filtersIndex', 'byId'], {}),
});

const mapDispatchToProps = (dispatch) => ({
  onSetFilterLabels: (id, labels, defaultAdapter, allOptions) =>
    dispatch(setFilterLabels(id, labels, defaultAdapter, allOptions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DropdownFilter));
