import React, { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';

import { API } from 'aws-amplify';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';

import map from 'lodash/map';
import join from 'lodash/join';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/core/styles';

import AppLoader from '../../../../../components/AppLoader';
// import BookmarkButton from './components/BookmarkButton';
import getFilteredOrganisations from './utils/getFilteredOrganisations';
import sortOrganisations from './utils/sortOrganisations';
import { SnackBar } from '../../../../../components/UI';

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    paddingRight: '9.8rem',
  },
  loadingContainer: {
    height: 'calc(100% + 200px)',
  },
  row: { height: '4.9rem' },
  rowCell: {
    cursor: 'pointer',
    fontSize: '1.1rem',
    color: theme.palette.custom.black,
  },
  cell: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    color: theme.palette.custom.gray5,
  },
  largeCell: { width: '50%' },
  mediumCell: { width: '16.6%' },
  disabled: {
    color: theme.palette.custom.gray4,
  },
  bold: {
    fontWeight: 'bold',
  },
  emptyResults: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.4rem',
    color: theme.palette.custom.gray4,
    height: '8.1rem',
  },
  numericValues: {
    paddingRight: '2rem',
  },
});

const Organisations = ({ classes, textSearch, jwtToken, forceUpdate }) => {
  const [headers, setHeaders] = useState([]);
  const [allOrganisations, setAllOrganisations] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState('desc');
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  useState(() => {
    setHeaders([
      {
        label: 'Company',
        orderByKey: 'name',
        objectKey: 'name',
        formatter: (data, objectKey) => get(data, objectKey),
        classes: cn(classes.rowCell, classes.mediumCell),
      },
      {
        label: 'Domains',
        orderByKey: 'domains',
        objectKey: 'domains',
        formatter: (data, objectKey) => join(get(data, objectKey), ', '),
        classes: cn(classes.rowCell, classes.largeCell),
      },
      {
        label: 'No. Users',
        orderByKey: 'users',
        objectKey: 'usersCount',
        formatter: (data, objectKey) => get(data, objectKey),
        classes: cn(classes.rowCell, classes.mediumCell, classes.numericValues),
      },
      {
        label: 'No. Subscriptions',
        orderByKey: 'subscriptions',
        objectKey: 'subscriptionsCount',
        formatter: (data, objectKey) => get(data, objectKey),
        classes: cn(classes.rowCell, classes.mediumCell, classes.numericValues),
      },
    ]);
  }, []);

  useEffect(() => {
    const fetchOrganisations = async () => {
      setLoader(true);

      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };

        const results = await API.get('users', `/organisations`, params);

        setAllOrganisations(results);
        setLoader(false);
      } catch (e) {
        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );
        setError(message);
        setLoader(false);
      }
    };

    fetchOrganisations();
  }, []);

  useEffect(() => {
    const fetchOrganisations = async () => {
      setLoader(true);

      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };

        const results = await API.get('users', `/organisations`, params);

        setAllOrganisations(results);
        setLoader(false);
      } catch (e) {
        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );
        setError(message);
        setLoader(false);
      }
    };

    if (forceUpdate) {
      fetchOrganisations();
    }
  }, [forceUpdate]);

  const handleOrderBy = useCallback(
    (field) => {
      if (field === orderBy) {
        setOrder(order === 'asc' ? 'desc' : 'asc');
      } else {
        setOrderBy(field);
        setOrder('asc');
      }
    },
    [orderBy, order],
  );

  if (loader) return <AppLoader show={loader} />;

  let organisations = getFilteredOrganisations(allOrganisations, textSearch);

  if (isEmpty(organisations) && !loader)
    return (
      <>
        <div className={classes.emptyResults}>No organisations found</div>{' '}
        <SnackBar open={Boolean(error)} message={error} severity="warning" />
      </>
    );

  organisations = sortOrganisations(organisations, orderBy, order);

  return (
    <div className={cn(classes.root, { [classes.loadingContainer]: loader })}>
      <Table>
        <TableHead>
          <TableRow>
            {map(headers, ({ label, orderByKey }) => (
              <TableCell
                key={orderByKey}
                className={classes.cell}
                padding="none"
                sortDirection={orderBy === orderByKey ? order : false}
              >
                <TableSortLabel
                  active={orderBy === orderByKey}
                  direction={orderBy === orderByKey ? order : 'asc'}
                  onClick={() => handleOrderBy(orderByKey)}
                >
                  {label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(organisations, (organisation, index) => {
            return (
              <TableRow key={`item-${index}`} className={classes.row}>
                {map(headers, ({ classes: cellClasses, objectKey, formatter }) => (
                  <TableCell
                    key={objectKey}
                    className={cellClasses}
                    padding="none"
                    onClick={() => navigate(`/admin/organisations/${get(organisation, '_id')}`)}
                  >
                    {formatter(organisation, objectKey)}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
  userId: get(state, ['user', 'userId'], []),
});

export default connect(mapStateToProps)(withStyles(styles)(Organisations));
