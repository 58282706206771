import map from 'lodash/map';
import get from 'lodash/get';
import flattenDeep from 'lodash/flattenDeep';
import concat from 'lodash/concat';

const getRows = (tableData, formatter) =>
  map(get(tableData, 'values'), ({ text, values }) =>
    flattenDeep(
      concat(
        [text],
        map(map(values, 'value'), (item) => {
          if (formatter === 'percentage') {
            if (!item || item === '-') return 'NaN';
            return { v: item / 100, t: 'n', z: '0.00%' };
          }
          return item;
        }),
      ),
    ),
  );

export default getRows;
