import React from 'react';
import cn from 'classnames';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    height: '90%',
  },
  fullHeight: {
    height: '100%',
  },
  flex: {
    display: 'flex',
  },
});

const ChartWrapper = ({ classes, fullHeight, children, noFlex }) => (
  <div className={cn(classes.root, { [classes.fullHeight]: fullHeight, [classes.flex]: !noFlex })}>
    {children}
  </div>
);

export default withStyles(styles)(ChartWrapper);
