import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';

import { ReactComponent as RefreshIcon } from './assets/refresh.svg';

const RefreshButton = ({ onClick }) => (
  <Tooltip title="Refresh">
    <IconButton onClick={onClick}>
      <RefreshIcon />
    </IconButton>
  </Tooltip>
);

export default RefreshButton;
