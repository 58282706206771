import { get, head, map } from 'lodash';
import getColumnIndex from './utils/getColumnIndex';
import getNumericalIndex from './utils/getNumericalIndex';

// Holding a general data for easy modifications
const FONT_FAMILY = 'Avenir';
const NO_BORDER = {
  bottom: {
    style: 'medium',
    color: { rgb: 'FFFFFFFF' },
  },
  top: {
    style: 'medium',
    color: { rgb: 'FFFFFFFF' },
  },
  left: {
    style: 'medium',
    color: { rgb: 'FFFFFFFF' },
  },
  right: {
    style: 'medium',
    color: { rgb: 'FFFFFFFF' },
  },
};
const BOTTOM_LEFT_BORDER = {
  bottom: {
    style: 'medium',
    color: { rgb: 'FFFFFFFF' },
  },
  left: {
    style: 'medium',
    color: { rgb: 'FFFFFFFF' },
  },
};
const LEFT_BORDER = {
  left: {
    style: 'medium',
    color: { rgb: 'FFFFFFFF' },
  },
};

// Checking which border style to apply according to the position
const setBorder = (row, col, maxRow) => {
  if (row === +maxRow && col === 65) return BOTTOM_LEFT_BORDER;
  if (col === 65) return LEFT_BORDER;
  return NO_BORDER;
};

const styleWorkSheet = (worksheet, data) => {
  const startLetter = worksheet['!ref'].split(':')[0];
  const endLetter = worksheet['!ref'].split(':')[1];
  const endNumber = worksheet['!ref'].split(':')[1].replace(/\D/g, '');

  // Styling for the title
  if (worksheet['A2']) {
    worksheet['A2'].s = {
      font: {
        sz: 18,
        name: FONT_FAMILY,
      },
      border: NO_BORDER,
    };
  }

  // Styling for the sources
  if (worksheet['A3']) {
    worksheet['A3'].s = {
      font: {
        sz: 18,
        name: FONT_FAMILY,
      },
      border: NO_BORDER,
    };
  }

  // Styling for the filters if they are present
  if (worksheet['A4']) {
    worksheet['A4'].s = {
      font: {
        sz: 12,
        name: FONT_FAMILY,
      },
      border: NO_BORDER,
    };
  }

  // Styling for the names
  let i = 6;
  map(data, (chart) => {
    if (worksheet[`A${i}`]) {
      worksheet[`A${i}`].s = {
        font: {
          sz: 16,
          name: FONT_FAMILY,
        },
        fill: {
          fgColor: { rgb: 'FFD0CECE' },
        },
        border: NO_BORDER,
      };
    }
    i += 7 + get(chart, ['data', 'data'], []).length;
  });

  // Stylling of the subtitles if they exists
  i = 7;
  map(data, (chart) => {
    if (worksheet[`A${i}`]) {
      worksheet[`A${i}`].s = {
        font: {
          sz: 12,
          name: FONT_FAMILY,
        },
        border: NO_BORDER,
      };
    }
    i += 7 + get(chart, ['data', 'data'], []).length;
  });

  // Styling for the legend
  i = 9;
  map(data, (chart) => {
    if (worksheet[`B${i}`]) {
      worksheet[`B${i}`].s = {
        font: {
          name: FONT_FAMILY,
          color: { rgb: 'FFFFFFFF' },
        },
        fill: {
          fgColor: { rgb: 'FF3F3F3F' },
        },
        alignment: {
          horizontal: 'center',
        },
      };
    }
    i += 7 + get(chart, ['data', 'data'], []).length;
  });

  // Styling for the survey wave header
  i = 10;
  map(data, (chart) => {
    for (
      let j = 1;
      head(get(chart, ['data', 'data'])) && j < head(get(chart, ['data', 'data'])).length;
      j++
    ) {
      if (worksheet[`${getColumnIndex(j)}${i}`]) {
        worksheet[`${getColumnIndex(j)}${i}`].s = {
          font: {
            name: FONT_FAMILY,
            color: { rgb: 'FFFFFFFF' },
          },
          fill: {
            fgColor: { rgb: 'FF7F7F7F' },
          },
          alignment: {
            horizontal: 'center',
          },
        };
      }
    }
    i += 7 + get(chart, ['data', 'data'], []).length;
  });

  // Styling for the count Only if it contains a sample value not actual results
  i = 11;
  map(data, (chart) => {
    for (
      let j = 1;
      head(get(chart, ['data', 'data'])) && j < head(get(chart, ['data', 'data'])).length;
      j++
    ) {
      if (
        worksheet[`${getColumnIndex(j)}${i}`] &&
        (worksheet[`${getColumnIndex(j)}${i}`].v + '').startsWith('n=')
      ) {
        worksheet[`${getColumnIndex(j)}${i}`].s = {
          font: {
            sz: 11,
            name: FONT_FAMILY,
          },
          fill: {
            fgColor: { rgb: 'FFD8D8D8' },
          },
          alignment: {
            horizontal: 'center',
          },
        };
      }
    }
    i += 7 + get(chart, ['data', 'data'], []).length;
  });

  // Syling the A13 cell if it contains option label
  i = 11;
  map(data, (chart) => {
    if (worksheet[`A${i}`] && worksheet[`A${i}`].v !== '') {
      worksheet[`A${i}`].s = {
        font: {
          name: FONT_FAMILY,
        },
        fill: {
          fgColor: { rgb: 'FFD0CECE' },
        },
        alignment: {
          horizontal: 'right',
        },
        border: setBorder(i, 65, endNumber),
      };
    }
    i += 7 + get(chart, ['data', 'data'], []).length;
  });

  // Styling for the options
  i = 10;
  map(data, (chart) => {
    for (
      let j = 2;
      get(chart, ['data', 'data']) && j < get(chart, ['data', 'data'], []).length;
      j++
    ) {
      if (worksheet[`A${i + j}`]) {
        worksheet[`A${i + j}`].s = {
          font: {
            name: FONT_FAMILY,
          },
          fill: {
            fgColor: { rgb: 'FFD0CECE' },
          },
          alignment: {
            horizontal: 'right',
          },
          border: setBorder(i + j, 65, endNumber),
        };
      }
    }
    i += 7 + get(chart, ['data', 'data'], []).length;
  });

  // Applying some cell merges for the headings to spread over the row
  i = 5;
  const merges = [];
  map(data, (chart) => {
    if (head(get(chart, ['data', 'data']))) {
      merges.push({
        s: { r: i, c: 0 },
        e: { r: i, c: head(get(chart, ['data', 'data'])).length - 1 },
      });
      merges.push({
        s: { r: i + 3, c: 1 },
        e: { r: i + 3, c: head(get(chart, ['data', 'data'])).length - 1 },
      });
    }
    i += 7 + get(chart, ['data', 'data'], []).length;
  });
  worksheet['!merges'] = merges;

  // Specifying larger widths for the cells
  // Larger width for the column holding the options
  let widths = [{ width: 70 }];

  // Smaller width for the columns holding the data
  for (let j = getNumericalIndex(startLetter); j <= getNumericalIndex(endLetter); j++) {
    widths.push({ width: 20 });
  }
  worksheet['!cols'] = widths;

  // Setting heights for the rows
  let heights = [{ hpt: 15 }, { hpt: 28 }, { hpt: 28 }, { hpt: 19 }, { hpt: 15 }];
  map(data, (chart) => {
    heights.push({ hpt: 25 }, { hpt: 19 }, { hpt: 15 }, { hpt: 19 });
    for (let j = 0; j < get(chart, ['data', 'data'], []).length; j++) {
      heights.push({ hpt: 19 });
    }
    heights.push({ hpt: 15 }, { hpt: 15 }, { hpt: 15 });
  });
  worksheet['!rows'] = heights;

  // // Removing borders
  for (let i = getNumericalIndex(startLetter); i <= getNumericalIndex(endLetter); i++) {
    for (let j = 1; j <= endNumber; j++) {
      if (!worksheet[`${getColumnIndex(i)}${j}`] && (i === 65 || j !== 11)) {
        worksheet[`${getColumnIndex(i)}${j}`] = {
          v: '',
          t: 's',
          s: {
            border: NO_BORDER,
          },
        };
      } else {
        if (worksheet[`${getColumnIndex(i)}${j}`] && !worksheet[`${getColumnIndex(i)}${j}`].s) {
          worksheet[`${getColumnIndex(i)}${j}`].s = {
            border: NO_BORDER,
          };
        }
      }
    }
  }

  return worksheet;
};

export default styleWorkSheet;
