import map from 'lodash/map';
import reduce from 'lodash/reduce';

const getSubscriptionByOrganisationId = (organisations) => {
  return reduce(
    organisations,
    (acc, { _id, subscriptions }) => {
      return {
        ...acc,
        [_id]: map(subscriptions, 'databoardId'),
      };
    },
    {},
  );
};

export default getSubscriptionByOrganisationId;
