import React, { useState } from 'react';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';

import TextField from '../TextField';

const styles = () => ({
  icon: {
    width: '1.2rem',
    height: '1rem',
    fill: '#bcc6d2',
  },
  adornment: { paddingRight: 0 },
});

const PasswordField = ({ classes, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <TextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="end">
          <IconButton aria-label="Toggle password visibility" onClick={handleClickShowPassword}>
            {showPassword ? (
              <VisibilityOff className={classes.icon} />
            ) : (
              <Visibility className={classes.icon} />
            )}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default withStyles(styles)(PasswordField);
