import React, { useState, useCallback } from 'react';

import * as Yup from 'yup';
import { compose } from 'redux';
// import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import CircularProgress from '@material-ui/core/CircularProgress';

import Container from '../../components/Container';
import FormTitle from '../../components/FormTitle';
import TextField from '../../components/TextField';
import SubmitButton from '../../components/SubmitButton';
import TextButton from '../../components/TextButton';
import Stepper from '../../components/Stepper';
import FormText from '../../components/FormText';

import validator from '../../validator';

import { FORGOT_PASSWORD_SCREEN, SUCCESS_SCREEN } from '../../types';

import confirmSignUp from '../../actions/confirmSignUp';
import resendConfirmationCode from '../../actions/resendConfirmationCode';
import { isEmpty } from 'lodash';
import { trackVerified } from '../../../../state/analytics/actions';
import { connect } from 'react-redux';

const VerifyEmail = ({
  email,
  handleSubmit,
  submitting,
  setScreen,
  previousError,
  doTrackVerified,
}) => {
  const [error, setError] = useState(previousError);
  const [resendInProgress, setResendInProgress] = useState(false);

  const handleVerifyEmail = async ({ code }) => {
    try {
      await confirmSignUp(email, code);
      // TODO: subscribe to mailchimp
      // Redirect to the forgot password page if user came from it because they were unverified
      if (!isEmpty(previousError)) {
        setScreen(FORGOT_PASSWORD_SCREEN);
      } else {
        // Verified email
        doTrackVerified(email);
        setScreen(SUCCESS_SCREEN);
      }
    } catch (e) {
      setError(e);
    }
  };

  const handleResendConfirmationCode = useCallback(async () => {
    setResendInProgress(true);
    try {
      await resendConfirmationCode(email);
    } catch (e) {
      setError(e);
    } finally {
      setResendInProgress(false);
    }
  }, [email]);

  return (
    <Container error={error}>
      <Stepper activeStep={1} />
      <form onSubmit={handleSubmit(handleVerifyEmail)}>
        <FormTitle label="Verify Email" />
        <FormText>Please check your inbox and enter the code you received to get started</FormText>
        <Field
          autoComplete="one-time-code"
          name="code"
          label="Verification Code"
          component={TextField}
        />
        <TextButton
          text="Not there yet?"
          label="Resend code"
          onClick={handleResendConfirmationCode}
          disabled={submitting}
          loading={resendInProgress}
        />
        {!submitting && <SubmitButton label="Verify me" />}
        {submitting && <CircularProgress />}
      </form>
    </Container>
  );
};

const schema = Yup.object().shape({
  code: Yup.string().trim().required(),
});

const mapDispatchToProps = (dispatch) => ({
  doTrackVerified: (email) => dispatch(trackVerified(email)),
});

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: 'verifyEmailForm',
    asyncValidate: validator(schema),
  }),
)(VerifyEmail);
