import map from 'lodash/map';
// import keys from 'lodash/keys';
import find from 'lodash/find';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

import withOr from './operators/withOr';
import findAdapterFilters from './adapter/findAdapterFilters';
import findPlugFilters from './adapter/findPlugFilters';
import getAdapterValues from './adapter/getAdapterValues';
import getExpressionValues from './values/getExpressionValues';

/**
 * @param {*} form The form of the applied filters
 * @param {*} filters All dashboard filtersById
 */
const createFilterExpression = ({ form, filters, contextFilters }) => {
  // [{_id: 'filter-id', ..., adapter: 'SOLUTION'}}, otherAdapters]
  const adapters = findAdapterFilters(filters);
  // ['filter-id-2', ...]
  const plugFiltersId = findPlugFilters({ adapters, filters });
  // {'filter-id': ['currently_using', 'aware']}
  const adapterForm = pick(form, map(adapters, '_id'));
  const formWithoutAdapterFilters = omit(form, map(adapters, '_id'));

  const valuesWithOperator = reduce(
    formWithoutAdapterFilters,
    (allFilterPayloads, selectedValues, filterId) => {
      const filterModel = get(filters, filterId);
      if (isEmpty(filterModel)) return allFilterPayloads;

      const { _id, query, table, /* operator, */ name, allOptions, visible } = filterModel;
      // In case we need to use the cols property then
      // we've to fetch the filter values and read from there for any filter with defaultValues
      // const actualValues = form[name];

      const adapterValues = getAdapterValues({
        filterModel,
        adapters,
        adapterForm,
        plugFiltersId,
        filterId: _id,
        name,
      });

      const filterMode = get(find(contextFilters, { name }), 'mode', '');
      let actualValues = [];
      if (filterMode === 'APPLIED') {
        actualValues = selectedValues;
      } else if (filterMode === 'ALL' || visible === true) {
        actualValues = map(allOptions, 'name');
      } else if (visible === false) {
        actualValues = selectedValues;
      }

      const values = getExpressionValues({ actualValues, adapterValues, query, table });
      // const filterPayload = operator === 'OR' ? withOr(values) : withNor(values);
      const filterPayload = withOr(values);

      return [...allFilterPayloads, filterPayload];
    },
    [],
  );

  return {
    and: valuesWithOperator,
  };

  // return head(valuesWithOperator);
};

export default createFilterExpression;
