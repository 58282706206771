import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { PrismicRichText } from '@prismicio/react';
import PrismicLinkComponent from '../PrismicLinkComponent';

const styles = () => ({
  root: {
    marginBottom: '2.4rem',
    maxWidth: 1050,
    width: '55%',
    textAlign: 'center',
  },
  prismicText: {
    fontSize: '1rem',
    color: '#636666',
    width: '55%',
    maxWidth: 1050,
  },
});

const Subtitle = ({ classes, children }) => {
  const pathURL = window.location.pathname;
  const isReport = pathURL.includes('/reports');

  return isReport ? (
    <div className={classes.prismicText}>
      <PrismicRichText
        field={children}
        externalLinkComponent={({ href, ...props }) => (
          <PrismicLinkComponent href={href} {...props} />
        )}
      />
    </div>
  ) : (
    <div className={classes.root}>{children}</div>
  );
};

export default withStyles(styles)(Subtitle);
