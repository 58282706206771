import React from 'react';

import { Container, ChartWrapper } from '../Container';
import BarChartEngine from '../RechartBarChartEngine';
import defaultConfig from './config';
import generateConfig from './generateConfig';

const BarChartStacked = ({
  data,
  samples,
  axisTitle,
  formatter,
  id,
  domain,
  view,
  series,
  configuration,
  sampleVisible,
}) => {
  const { rootProps } = defaultConfig;

  if (view === 'dialog') {
    rootProps.margin = {
      top: 26,
      right: 26,
      left: 50,
      bottom: 26,
    };
  }

  // const mergedConfig = {
  //   ...config,
  //   series: filter(
  //     map(config.series, (item, index) => ({
  //       ...item,
  //       dataKey: get(series, index, ''),
  //     })),
  //     ({ dataKey }) => !isEmpty(dataKey),
  //   ),
  //   xAxisProps: {
  //     ...config.xAxisProps,
  //     label: { ...config.xAxisProps.label, value: axisTitle },
  //   },
  // };
  const mergedConfig = generateConfig({
    defaultConfig,
    series,
    domain,
    axisTitle,
    formatter,
    configuration,
  });

  return (
    <Container>
      <ChartWrapper fullHeight>
        <BarChartEngine
          id={id}
          {...mergedConfig}
          data={data}
          samples={samples}
          view={view}
          sampleVisible={sampleVisible}
        />
      </ChartWrapper>
    </Container>
  );
};

export default BarChartStacked;
