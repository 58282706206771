import get from 'lodash/get';
import indexOf from 'lodash/indexOf';
import filter from 'lodash/filter';
import head from 'lodash/head';

const getAdapterValues = ({
  filterModel,
  adapters,
  adapterForm,
  plugFiltersId,
  filterId,
  name,
}) => {
  let adapterValues = [get(filterModel, 'defaultAdapter', 'default')];
  const hasAdapter = indexOf(plugFiltersId, filterId) !== -1;
  if (hasAdapter) {
    const adapterId = get(head(filter(adapters, { adapter: name })), '_id');
    adapterValues = get(adapterForm, adapterId, ['default']);
  }

  return adapterValues;
};

export default getAdapterValues;
