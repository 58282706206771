import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';

import {
  SECTOR_DESK,
  SECTOR_MOB,
  SECTOR_WEB,
  SECTOR_CLD,
  SECTOR_GAM,
  SECTOR_IOT,
  SECTOR_ML,
  SECTOR_ARVR,
  SECTOR_EMB,
  SECTOR_APP,
  SECTOR_CORE,
  PRODUCT_DPB,
  PRODUCT_CTL,
  PRODUCT_TAT,
  PRODUCT_INSIGHTS,
  PRODUCT_REPORT_DEI,
  PRODUCT_REPORT_DPB,
  PRODUCT_TAM,
  PRODUCT_OTHER,
  TOPIC_APIS,
  TOPIC_BLOCKCHAIN_APPS,
  TOPIC_CLOUD_DEVELOPMENT,
  TOPIC_CLOUD_PROVIDERS,
  TOPIC_DEVELOPER_LEARNING,
  TOPIC_DEVELOPER_PROGRAMS,
  TOPIC_EMERGING_TECHNOLOGIES,
  TOPIC_GENERATIVE_AI,
  TOPIC_INFLUENCE,
  TOPIC_MONETISATION_SALARY,
  TOPIC_NO_LOW_CODE,
  TOPIC_OPEN_SOURCE,
  TOPIC_PROGRAMMING_LANGUAGES,
  TOPIC_SECURITY,
  PRODUCT_REPORT_MARKET,
  PRODUCT_REPORT_DEVELOPERS,
  PRODUCT_REPORT_SATISFACTION,
  PRODUCT_REPORT_SEGMENTATION,
  SECTOR_DEVOPS,
} from '../../../../data';

import FilterGroup from './components/FilterGroup';

const styles = (theme) => ({
  root: {},
  drawerPaper: {
    paddingTop: '4rem',
    backgroundColor: theme.palette.custom.gray1,
    boxShadow: '4px 0px 18px 0 rgba(188, 198, 210, 0.15)',
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2.3rem 0',
  },
  item: {
    paddingLeft: 0,
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.custom.blue2,
      color: theme.palette.custom.white,
      fill: theme.palette.custom.white,
      '& $icon': {
        '& path': {
          fill: theme.palette.custom.white,
        },
      },
    },
  },
  activeListItem: {
    color: theme.palette.custom.white,
    fill: theme.palette.custom.white,
    backgroundColor: theme.palette.custom.blue3,
    '& $listText span': {
      fontWeight: 'bold',
    },
    '& $icon': {
      '& path': {
        fill: theme.palette.custom.white,
      },
    },
  },
  listIcon: {
    margin: 0,
    color: 'inherit',
  },
  icon: {
    width: '1.1rem',
    height: '1.1rem',
    '& path': {
      fill: theme.palette.custom.blue2,
    },
    marginLeft: '2.2rem',
    marginRight: '1.3rem',
  },
  listText: {
    '& span': {
      color: 'inherit',
      fontSize: '1.1rem',
    },
  },
});

const data = {
  sectors: {
    [SECTOR_WEB.TYPE]: { checked: true, label: SECTOR_WEB.LABEL, name: SECTOR_WEB.TYPE },
    [SECTOR_MOB.TYPE]: { checked: true, label: SECTOR_MOB.LABEL, name: SECTOR_MOB.TYPE },
    [SECTOR_DESK.TYPE]: { checked: true, label: SECTOR_DESK.LABEL, name: SECTOR_DESK.TYPE },
    [SECTOR_CLD.TYPE]: { checked: true, label: SECTOR_CLD.LABEL, name: SECTOR_CLD.TYPE },
    [SECTOR_ARVR.TYPE]: { checked: true, label: SECTOR_ARVR.LABEL, name: SECTOR_ARVR.TYPE },
    [SECTOR_GAM.TYPE]: { checked: true, label: SECTOR_GAM.LABEL, name: SECTOR_GAM.TYPE },
    [SECTOR_IOT.TYPE]: { checked: true, label: SECTOR_IOT.LABEL, name: SECTOR_IOT.TYPE },
    [SECTOR_ML.TYPE]: { checked: true, label: SECTOR_ML.LABEL, name: SECTOR_ML.TYPE },
    [SECTOR_EMB.TYPE]: { checked: true, label: SECTOR_EMB.LABEL, name: SECTOR_EMB.TYPE },
    [SECTOR_APP.TYPE]: { checked: true, label: SECTOR_APP.LABEL, name: SECTOR_APP.TYPE },
    [SECTOR_DEVOPS.TYPE]: { checked: true, label: SECTOR_DEVOPS.LABEL, name: SECTOR_DEVOPS.TYPE },
    [SECTOR_CORE.TYPE]: { checked: true, label: SECTOR_CORE.LABEL, name: SECTOR_CORE.TYPE },
  },
};

const productsDashboard = {
  [PRODUCT_DPB.CATEGORY]: { checked: true, label: PRODUCT_DPB.LABEL, name: PRODUCT_DPB.CATEGORY },
  [PRODUCT_CTL.CATEGORY]: { checked: true, label: PRODUCT_CTL.LABEL, name: PRODUCT_CTL.CATEGORY },
  [PRODUCT_TAT.CATEGORY]: { checked: true, label: PRODUCT_TAT.LABEL, name: PRODUCT_TAT.CATEGORY },
  [PRODUCT_INSIGHTS.CATEGORY]: {
    checked: true,
    label: PRODUCT_INSIGHTS.LABEL,
    name: PRODUCT_INSIGHTS.CATEGORY,
  },
  [PRODUCT_TAM.CATEGORY]: {
    checked: true,
    label: PRODUCT_TAM.LABEL,
    name: PRODUCT_TAM.CATEGORY,
  },
  [PRODUCT_OTHER.CATEGORY]: {
    checked: true,
    label: PRODUCT_OTHER.LABEL,
    name: PRODUCT_OTHER.CATEGORY,
  },
};

const productsReport = {
  [PRODUCT_REPORT_DEI.CATEGORY]: {
    checked: true,
    label: PRODUCT_REPORT_DEI.LABEL,
    name: PRODUCT_REPORT_DEI.CATEGORY,
  },
  [PRODUCT_REPORT_MARKET.CATEGORY]: {
    checked: true,
    label: PRODUCT_REPORT_MARKET.LABEL,
    name: PRODUCT_REPORT_MARKET.CATEGORY,
  },
  [PRODUCT_REPORT_DEVELOPERS.CATEGORY]: {
    checked: true,
    label: PRODUCT_REPORT_DEVELOPERS.LABEL,
    name: PRODUCT_REPORT_DEVELOPERS.CATEGORY,
  },
  [PRODUCT_REPORT_DPB.CATEGORY]: {
    checked: true,
    label: PRODUCT_REPORT_DPB.LABEL,
    name: PRODUCT_REPORT_DPB.CATEGORY,
  },
  [PRODUCT_REPORT_SATISFACTION.CATEGORY]: {
    checked: true,
    label: PRODUCT_REPORT_SATISFACTION.LABEL,
    name: PRODUCT_REPORT_SATISFACTION.CATEGORY,
  },
  [PRODUCT_REPORT_SEGMENTATION.CATEGORY]: {
    checked: true,
    label: PRODUCT_REPORT_SEGMENTATION.LABEL,
    name: PRODUCT_REPORT_SEGMENTATION.CATEGORY,
  },
};

const topics = {
  [TOPIC_PROGRAMMING_LANGUAGES.NAME]: {
    checked: true,
    label: TOPIC_PROGRAMMING_LANGUAGES.LABEL,
    name: TOPIC_PROGRAMMING_LANGUAGES.NAME,
  },
  [TOPIC_APIS.NAME]: {
    checked: true,
    label: TOPIC_APIS.LABEL,
    name: TOPIC_APIS.NAME,
  },
  [TOPIC_OPEN_SOURCE.NAME]: {
    checked: true,
    label: TOPIC_OPEN_SOURCE.LABEL,
    name: TOPIC_OPEN_SOURCE.NAME,
  },
  [TOPIC_DEVELOPER_PROGRAMS.NAME]: {
    checked: true,
    label: TOPIC_DEVELOPER_PROGRAMS.LABEL,
    name: TOPIC_DEVELOPER_PROGRAMS.NAME,
  },
  [TOPIC_NO_LOW_CODE.NAME]: {
    checked: true,
    label: TOPIC_NO_LOW_CODE.LABEL,
    name: TOPIC_NO_LOW_CODE.NAME,
  },
  [TOPIC_EMERGING_TECHNOLOGIES.NAME]: {
    checked: true,
    label: TOPIC_EMERGING_TECHNOLOGIES.LABEL,
    name: TOPIC_EMERGING_TECHNOLOGIES.NAME,
  },
  [TOPIC_SECURITY.NAME]: {
    checked: true,
    label: TOPIC_SECURITY.LABEL,
    name: TOPIC_SECURITY.NAME,
  },
  [TOPIC_CLOUD_PROVIDERS.NAME]: {
    checked: true,
    label: TOPIC_CLOUD_PROVIDERS.LABEL,
    name: TOPIC_CLOUD_PROVIDERS.NAME,
  },
  [TOPIC_CLOUD_DEVELOPMENT.NAME]: {
    checked: true,
    label: TOPIC_CLOUD_DEVELOPMENT.LABEL,
    name: TOPIC_CLOUD_DEVELOPMENT.NAME,
  },
  [TOPIC_GENERATIVE_AI.NAME]: {
    checked: true,
    label: TOPIC_GENERATIVE_AI.LABEL,
    name: TOPIC_GENERATIVE_AI.NAME,
  },
  [TOPIC_BLOCKCHAIN_APPS]: {
    checked: true,
    label: TOPIC_BLOCKCHAIN_APPS.LABEL,
    name: TOPIC_BLOCKCHAIN_APPS.NAME,
  },
  [TOPIC_INFLUENCE.NAME]: {
    checked: true,
    label: TOPIC_INFLUENCE.LABEL,
    name: TOPIC_INFLUENCE.NAME,
  },
  [TOPIC_DEVELOPER_LEARNING.NAME]: {
    checked: true,
    label: TOPIC_DEVELOPER_LEARNING.LABEL,
    name: TOPIC_DEVELOPER_LEARNING.NAME,
  },
  [TOPIC_MONETISATION_SALARY.NAME]: {
    checked: true,
    label: TOPIC_MONETISATION_SALARY.LABEL,
    name: TOPIC_MONETISATION_SALARY.NAME,
  },
};

const DataboardCategory = ({ classes, className, user, onChange }) => {
  const [filters, setFilters] = useState(data);

  const path = window.location.pathname;
  if (path === '/reports') {
    data.products = productsReport;
    data.topics = topics;
  } else {
    data.products = productsDashboard;
  }

  useEffect(() => {
    onChange({
      filters,
    });
  }, [filters]);

  const onChangeSectors = (sectors) => setFilters({ ...filters, sectors: { ...sectors } });
  const onChangeProducts = (products) => setFilters({ ...filters, products: { ...products } });
  const onChangeTopics = (topics) => setFilters({ ...filters, topics: { ...topics } });

  if (isEmpty(user.userId)) return null;

  return (
    <Drawer
      variant="permanent"
      open={true}
      classes={{
        root: cn(classes.root, className),
        paper: cn(classes.drawerPaper, className),
      }}
    >
      <Scrollbars>
        <FilterGroup
          title="Software Development Area"
          allLabel="Select All"
          className={classes.item}
          data={filters.sectors}
          onChange={onChangeSectors}
        />
        {path !== '/daa' && (
          <FilterGroup
            title="Focus"
            allLabel="Select All"
            className={classes.item}
            data={filters.products}
            onChange={onChangeProducts}
          />
        )}
        {path === '/reports' && (
          <FilterGroup
            title="Topic"
            allLabel="Select All"
            className={classes.item}
            data={filters.topics}
            onChange={onChangeTopics}
          />
        )}
      </Scrollbars>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  user: get(state, 'user'),
  category: get(state, 'category'),
});

export default connect(mapStateToProps)(withStyles(styles)(DataboardCategory));
