// TODO: Ask steve to provide us with typography measurements

import { pxToRem } from './utils';
import palette from './palette';

export default {
  useNextVariants: true,
  fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
  h1: {
    fontFamily: "'Nunito Sans Black', sans-serif, serif",
    fontWeight: 500,
    fontSize: pxToRem(38),
    color: palette.custom.black,
  },
  h2: {
    fontFamily: "'Nunito Sans Black', sans-serif, serif",
    fontWeight: 500,
    fontSize: pxToRem(36),
    color: palette.custom.black,
  },
  h3: {
    fontFamily: "'Nunito Sans Black', sans-serif, serif",
    fontWeight: 500,
    fontSize: pxToRem(32),
    color: palette.custom.black,
  },
  h4: {
    fontFamily: "'Nunito Sans Black', sans-serif, serif",
    fontSize: pxToRem(28),
    color: palette.custom.black,
  },
  h5: {
    fontFamily: "'Nunito Sans Black', sans-serif, serif",
    fontSize: pxToRem(24),
    color: palette.custom.black,
  },
  h6: {
    fontFamily: "'Nunito Sans Black', sans-serif, serif",
    fontSize: pxToRem(20),
    color: palette.custom.black,
  },
  subtitle1: {
    fontSize: pxToRem(16),
    color: palette.custom.black,
  },
  subtitle2: {
    fontSize: pxToRem(14),
    color: palette.custom.black,
  },
  body1: {
    fontSize: pxToRem(14),
    color: palette.custom.black,
  },
  // body2: {
  //   fontSize: pxToRem(12),
  // },
  // button: {
  //   fontSize: pxToRem(14),
  // },
  // caption: {
  //   fontSize: pxToRem(12),
  // },
};
