import get from 'lodash/get';

import {
  SET_CURRENT_USER,
  SIGN_OUT,
  CREATE_BOOKMARK,
  REMOVE_BOOKMARK,
  SET_USER_SUBSCRIPTIONS,
  SET_USER_NOTIFICATIONS,
  SET_SEARCH_KEY,
} from './types';
import removeBookmark from './utils/removeBookmark';
import normalizeBookmarks from './utils/normalizeBookmarks';

const initialState = {
  userId: '',
  email: '',
  roles: [],
  jwtToken: '',
  firstName: '',
  lastName: '',
  bookmarks: [],
  subscriptions: [],
  notifications: [],
  organisation: '',
  allOrganisations: [],
  searchKey: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        ...action.payload,
        bookmarks: normalizeBookmarks(action),
      };
    case SIGN_OUT:
      return {
        ...state,
        ...initialState,
      };
    case CREATE_BOOKMARK:
      return {
        ...state,
        bookmarks: [...state.bookmarks, action.payload],
      };
    case REMOVE_BOOKMARK:
      return {
        ...state,
        bookmarks: removeBookmark(state, action),
      };
    case SET_USER_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: get(action, ['payload', 'subscriptions'], []),
      };
    case SET_USER_NOTIFICATIONS:
      return {
        ...state,
        notifications: get(action, ['payload', 'notifications'], []),
      };
    case SET_SEARCH_KEY:
      return {
        ...state,
        searchKey: get(action, ['payload'], null),
      };
    default:
      return state;
  }
};

export default reducer;
