import React, { useState, useCallback, useEffect } from 'react';

import { API } from 'aws-amplify';
import { Field, FieldArray, reduxForm } from 'redux-form';
import * as Yup from 'yup';
import { compose } from 'redux';
import { connect } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import CircularProgress from '@material-ui/core/CircularProgress';

import DomainFields from './components/DomainFields';
import TextField from '../TextField';
import SubmitButton from '../SubmitButton';
// import TextButton from '../TextButton';
import reduxFormValidator from '../../../utils/reduxFormValidator';
import { Portlet, PortletHeader, PortletLabel, PortletContent, PortletFooter } from '../../Portlet';
import { SnackBar } from '../../UI';

const UPDATE_MODE = 'UPDATE_MODE';
const CREATE_MODE = 'CREATE_MODE';

const OrganisationForm = ({
  organisationId,
  jwtToken,
  handleSubmit,
  onSuccess = (model) => model,
  // onDelete,
}) => {
  const [mode, setMode] = useState('');
  const [formTitle, setFormTitle] = useState('');
  const [submitButtonTitle, setSubmitButtonTitle] = useState('');
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setMode(isEmpty(organisationId) ? CREATE_MODE : UPDATE_MODE);
    setFormTitle(isEmpty(organisationId) ? 'New Organisation' : 'Edit Organisation');
    setSubmitButtonTitle(isEmpty(organisationId) ? 'CREATE' : 'UPDATE');
  }, [organisationId]);

  const updateOrganisation = useCallback(
    async ({ name, domains }) => {
      setLoader(true);
      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
          body: { name, domains },
        };
        const result = await API.patch('users', `/organisations/${organisationId}`, params);
        onSuccess(result);
      } catch (e) {
        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );
        setError(message);
      } finally {
        setLoader(false);
      }
    },
    [organisationId],
  );

  const saveOrganisation = useCallback(async ({ name, domains }) => {
    setLoader(true);
    try {
      const params = {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: { name, domains },
      };
      const result = await API.post('users', `/organisations`, params);
      onSuccess(result);
    } catch (e) {
      const message = get(
        e,
        ['response', 'data', 'message'],
        'You do not have permission to access',
      );
      setError(message);
    } finally {
      setLoader(false);
    }
  }, []);

  // const deleteOrganisation = useCallback(
  //   async ({ name, domains }) => {
  //     setLoader(true);
  //     try {
  //       const params = {
  //         headers: {
  //           Authorization: `Bearer ${jwtToken}`,
  //         },
  //         body: { name, domains },
  //       };
  //       await API.del('users', `/organisations/${organisationId}`, params);
  //       onDelete();
  //     } catch (error) {
  //       console.log('error', error);
  //     } finally {
  //       setLoader(false);
  //     }
  //   },
  //   [organisationId],
  // );

  const saveOrUpdate = useCallback(
    (props) => {
      if (mode === CREATE_MODE) {
        saveOrganisation(props);
      } else if (mode === UPDATE_MODE) {
        updateOrganisation(props);
      }
    },
    [mode],
  );

  return (
    <Portlet>
      <PortletHeader>
        <PortletLabel title={formTitle} />
      </PortletHeader>
      <form onSubmit={handleSubmit(saveOrUpdate)}>
        <PortletContent>
          <Field name="name" label="Name" component={TextField} />
          <FieldArray name="domains" component={DomainFields} />
        </PortletContent>
        <PortletFooter>
          {!loader && <SubmitButton label={submitButtonTitle} />}
          {loader && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )}
          {/* {mode === UPDATE_MODE && (
            <TextButton label="Delete" onClick={deleteOrganisation} disabled={loader} />
          )} */}
        </PortletFooter>
      </form>
      <SnackBar open={Boolean(error)} message={error} severity="warning" />
    </Portlet>
  );
};

const schema = Yup.object().shape({
  name: Yup.string().trim().required(),
  domains: Yup.array().of(Yup.string().min(1)),
});

const mapStateToProps = (state, { name, domains, _id }) => ({
  initialValues: { name, domains },
  organisationId: _id,
  jwtToken: get(state, ['user', 'jwtToken'], ''),
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'organisationForm',
    enableReinitialize: true,
    asyncValidate: reduxFormValidator(schema),
  }),
)(OrganisationForm);
