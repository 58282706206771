import React, { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';

import { API } from 'aws-amplify';
import { connect } from 'react-redux';

import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import slice from 'lodash/slice';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles } from '@material-ui/core/styles';

import AppLoader from '../../../../../components/AppLoader';
// import BookmarkButton from './components/BookmarkButton';
import getFilteredUsers from './utils/getFilteredUsers';
import sortUsers from './utils/sortUsers';
import { SnackBar } from '../../../../../components/UI';

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    paddingRight: '9.8rem',
  },
  loadingContainer: {
    height: 'calc(100% + 200px)',
  },
  row: { height: '4.9rem' },
  rowCell: {
    cursor: 'pointer',
    fontSize: '1.1rem',
    color: theme.palette.custom.black,
  },
  cell: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    color: theme.palette.custom.gray5,
  },
  // largeCell: { width: '50%' },
  mediumCell: { width: '16.6%' },
  disabled: {
    color: theme.palette.custom.gray4,
  },
  bold: {
    fontWeight: 'bold',
  },
  emptyResults: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.4rem',
    color: theme.palette.custom.gray4,
    height: '8.1rem',
  },
});

const Users = ({ classes, forceUpdate, textSearch, jwtToken, onClick }) => {
  const [headers, setHeaders] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState('desc');
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setHeaders([
      {
        label: 'Email',
        orderByKey: 'email',
        objectKey: 'email',
        formatter: (data, objectKey) => get(data, objectKey),
        classes: cn(classes.rowCell, classes.mediumCell),
      },
      {
        label: 'Firstname',
        orderByKey: 'firstName',
        objectKey: 'firstName',
        formatter: (data, objectKey) => get(data, objectKey),
        classes: cn(classes.rowCell, classes.mediumCell),
      },
      {
        label: 'Lastname',
        orderByKey: 'lastName',
        objectKey: 'lastName',
        formatter: (data, objectKey) => get(data, objectKey),
        classes: cn(classes.rowCell, classes.mediumCell),
      },
      {
        label: 'Role',
        orderByKey: 'role',
        objectKey: 'role',
        formatter: (data, objectKey) => get(data, objectKey),
        classes: cn(classes.rowCell, classes.mediumCell),
      },
      {
        label: 'Organisation',
        orderByKey: 'organisation',
        objectKey: 'organisation.name',
        formatter: (data, objectKey) => get(data, objectKey),
        classes: cn(classes.rowCell, classes.mediumCell),
      },
      {
        label: 'Status',
        orderByKey: 'status',
        objectKey: 'status',
        formatter: (data, objectKey) => get(data, objectKey),
        classes: cn(classes.rowCell, classes.mediumCell),
      },
    ]);
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoader(true);

      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };

        const results = await API.get('users', `/users`, params);

        setAllUsers(results);
        setLoader(false);
      } catch (e) {
        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );
        setError(message);
        setLoader(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoader(true);

      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };

        const results = await API.get('users', `/users`, params);

        setAllUsers(results);
        setLoader(false);
      } catch (e) {
        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );
        setError(message);
        setLoader(false);
      }
    };

    if (forceUpdate) {
      fetchUsers();
    }
  }, [forceUpdate]);

  const handleOrderBy = useCallback(
    (field) => {
      if (field === orderBy) {
        setOrder(order === 'asc' ? 'desc' : 'asc');
      } else {
        setOrderBy(field);
        setOrder('asc');
      }
    },
    [orderBy, order],
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loader) return <AppLoader show={loader} />;

  let users = getFilteredUsers(allUsers, textSearch);

  if (isEmpty(users) && !loader)
    return <div className={classes.emptyResults}>No subscriptions found</div>;

  users = sortUsers(users, orderBy, order);
  const slicedUsers = slice(users, page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div className={cn(classes.root, { [classes.loadingContainer]: loader })}>
      <Table>
        <TableHead>
          <TableRow>
            {map(headers, ({ label, orderByKey }) => (
              <TableCell
                key={orderByKey}
                className={classes.cell}
                padding="none"
                sortDirection={orderBy === orderByKey ? order : false}
              >
                <TableSortLabel
                  active={orderBy === orderByKey}
                  direction={orderBy === orderByKey ? order : 'asc'}
                  onClick={() => handleOrderBy(orderByKey)}
                >
                  {label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(slicedUsers, (user, index) => {
            return (
              <TableRow key={`item-${index}`} className={classes.row}>
                {map(headers, ({ classes: cellClasses, objectKey, formatter }) => (
                  <TableCell
                    key={objectKey}
                    className={cellClasses}
                    padding="none"
                    onClick={() => onClick(user)}
                  >
                    {formatter(user, objectKey)}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <SnackBar open={Boolean(error)} message={error} severity="warning" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
  userId: get(state, ['user', 'userId'], []),
});

export default connect(mapStateToProps)(withStyles(styles)(Users));
