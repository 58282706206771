import React, { useState } from 'react';
import cn from 'classnames';

import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';

import { MenuItem, Tooltip } from '@material-ui/core';
import { ReactComponent as ExportIcon } from './assets/export.svg';

const styles = () => ({
  button: {
    cursor: 'pointer',
    marginBottom: '1.2rem',
    marginRight: '2.2rem',
  },
  icon: {
    width: 20,
    height: 20,
  },
});

const openTerms = () => {
  window.open('/terms', '_blank', 'noopener,noreferrer');
};

const ExportMenu = ({ classes, className, exportToImage, exportToPdf }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Tooltip title="Export Graph">
        <Grid
          className={cn(classes.button, className)}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <div>
            <ExportIcon className={classes.icon} />
          </div>
        </Grid>
      </Tooltip>
      <Popover
        id="render-props-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => exportToPdf(() => setAnchorEl(null))}>Export as PDF</MenuItem>
        <MenuItem onClick={() => exportToImage(false, () => setAnchorEl(null))}>
          Export as PNG
        </MenuItem>
        <MenuItem onClick={() => exportToImage(true, () => setAnchorEl(null))}>
          Export as JPEG
        </MenuItem>
        <MenuItem onClick={openTerms}>Review Sharing Policy</MenuItem>
      </Popover>
    </>
  );
};

export default withStyles(styles)(ExportMenu);
