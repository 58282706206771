import React from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';

import get from 'lodash/get';
import find from 'lodash/find';

import { withStyles } from '@material-ui/core/styles';

import { getSampleLabels } from '../../../state/labels/selectors';

const styles = () => ({
  root: {
    color: '#979797',
    fontWeight: 'bold',
    fontSize: 16,
    marginRight: '1.5rem',
  },
  lowSampleText: {
    color: 'red',
  },
});

const Sample = ({ classes, name, labels, value = 0, chartThreshold }) => {
  if (value === 0 || value === '-' || value === '') return null;

  const label = get(find(labels, { name }), 'text', name);

  return (
    <div
      className={cn(classes.root, {
        [classes.lowSampleText]: value < chartThreshold,
      })}
    >
      {`${label} = ${value.toLocaleString()}`}
    </div>
  );
};

const mapStateToProps = (state) => ({
  labels: getSampleLabels(state),
});

export default connect(mapStateToProps)(withStyles(styles)(Sample));
