import filter from 'lodash/filter';
import toArray from 'lodash/toArray';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';

// 1-1 relationship
const findAdapterFilters = (filters) =>
  filter(
    toArray(filters),
    (filterModel) => has(filterModel, 'adapter') && !isEmpty(filterModel.adapter),
  );

export default findAdapterFilters;
