import React, { useEffect } from 'react';

import { Field } from 'redux-form';
import { connect } from 'react-redux';

import map from 'lodash/map';
import get from 'lodash/get';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import { withStyles } from '@material-ui/core/styles';

import TextField from '../../../TextField';

const styles = () => ({
  root: { display: 'flex' },
});

const DomainFields = ({ classes, fields, asyncErrors, meta }) => {
  useEffect(() => {
    if (meta.dirty === false) {
      fields.push();
    }
  }, []);

  return (
    <>
      {map(fields, (domain, index) => {
        const error = get(asyncErrors, `domains[${index}]`);
        return (
          <div key={`domains[${index}]`} className={classes.root}>
            <Field
              name={`domains[${index}]`}
              type="text"
              label={`Domain #${index + 1}`}
              meta={{ ...meta, error, touched: meta.dirty }}
              component={TextField}
            />
            <IconButton onClick={() => fields.remove(index)}>
              <DeleteOutlined />
            </IconButton>
          </div>
        );
      })}
      <Button onClick={() => fields.push()}>Add Domain</Button>
    </>
  );
};

const mapStateToProps = (state) => ({
  asyncErrors: get(state, ['form', 'organisationForm', 'asyncErrors']),
});

export default connect(mapStateToProps)(withStyles(styles)(DomainFields));
