export const FORMATTER_PERCENTAGE = 'FORMATTER_PERCENTAGE';
export const FORMATTER_SCORE = 'FORMATTER_SCORE';

export const percentage = (value) => {
  /**
   * 1 digit number => precision 2
   * rest => precision 1
   */
  const powerOfTen = value < 10 ? 100 : 10;

  return `${Math.round((value + Number.EPSILON) * powerOfTen) / powerOfTen} %`;
};

const score = (value) => {
  if (value === 0) return '';

  return Math.floor(value);
};

export default {
  [FORMATTER_PERCENTAGE]: percentage,
  [FORMATTER_SCORE]: score,
};
