import React, { useState } from 'react';
import cn from 'classnames';

import isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';

import Checkbox from '../../../components/Checkbox';
import TnCDialog from '../../../components/TnCDialog';

const styles = (theme) => ({
  link: {
    color: theme.palette.custom.blue2,
    textDecoration: 'none',
  },
  error: {
    color: 'red',
  },
});

const TnC = ({ classes, meta: { error, touched, ...restMeta }, ...rest }) => {
  const [openTnC, setOpenTnC] = useState(false);

  return (
    <>
      <Checkbox
        {...rest}
        meta={{ error, touched, ...restMeta }}
        label={
          <span>
            * I agree with{' '}
            <a
              href="/#"
              className={cn(classes.link, {
                [classes.error]: !isEmpty(error) && touched,
              })}
              onClick={() => setOpenTnC(true)}
            >
              Terms & Conditions
            </a>
          </span>
        }
      />
      <TnCDialog open={openTnC} onClose={() => setOpenTnC(false)} />
    </>
  );
};

export default withStyles(styles)(TnC);
