import React from 'react';
import cn from 'classnames';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: theme.zIndex.appLoader,
  },
  backdrop: {
    position: 'absolute',
  },
  transparentBackdrop: {
    backgroundColor: 'transparent',
  },
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loaderMessage: {
    marginTop: '1rem',
    fontFamily: 'Nunito Sans',
    color: '#fefefe',
  },
});

const Loader = ({ classes, show, message, transparent }) => {
  if (!show) return null;

  return (
    <div className={classes.root}>
      <Backdrop
        open
        className={cn(classes.backdrop, { [classes.transparentBackdrop]: transparent })}
      />
      <div className={classes.loaderContainer}>
        <CircularProgress />
        <span className={classes.loaderMessage}>{message}</span>
      </div>
    </div>
  );
};

export default withStyles(styles)(Loader);
