import React from 'react';

import { connect } from 'react-redux';

import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import { AutoSizer, MultiGrid, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

import deepEqual from '../../../utils/deepEqual';

const styles = () => ({
  cell: {
    fontFamily: 'Nunito Sans',
    overflow: 'hidden',
    maxWidth: '16.3rem',
    maxHeight: '4.9rem',
  },
  rowHeaderCell: {
    fontFamily: 'Nunito Sans',
    overflow: 'hidden',
    minWidth: '24.4rem',
    maxWidth: '24.4rem',
    maxHeight: '4.9rem',
  },
  root: {
    width: '100%',
    height: '100%',
    padding: '2rem',
    boxSizing: 'border-box',
    '& .ReactVirtualized__Grid': {
      outline: 'none !important',
    },
  },
});

const STYLE = {
  border: '1px solid #ddd',
};
const STYLE_BOTTOM_LEFT_GRID = {
  borderRight: '2px solid #aaa',
  backgroundColor: '#f7f7f7',
};
const STYLE_TOP_LEFT_GRID = {
  borderBottom: '2px solid #aaa',
  borderRight: '2px solid #aaa',
  fontWeight: 'bold',
};
const STYLE_TOP_RIGHT_GRID = {
  borderBottom: '2px solid #aaa',
  fontWeight: 'bold',
};

class TableGrid extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      rows: [],
      fixedColumnCount: 1,
      fixedRowCount: 1,
      scrollToColumn: 0,
      scrollToRow: 0,
    };

    this.cache = new CellMeasurerCache({
      defaultWidth: 390,
    });
    this.cellRenderer = this.cellRenderer.bind(this);
  }

  getSnapshotBeforeUpdate(prevProps) {
    return prevProps;
  }

  componentDidUpdate(prevProps) {
    const rowsHaveChanged = !deepEqual(prevProps.rows, this.props.rows);
    if (rowsHaveChanged) {
      this.populateRowLabels();
    }
  }

  populateRowLabels = () => {
    const rows = get(this.props, 'rows', []);
    const labels = get(this.props, 'labels', []);
    const series = get(this.props, 'series', []);
    const allRows = [series, ...rows];

    const rowsWithLabels = map(allRows, (row) =>
      map(row, (item) => {
        if (typeof item === 'string') {
          const label = find(labels, { name: item });

          return isEmpty(label) ? item : label.text;
        }

        return item;
      }),
    );

    this.setState({ rows: rowsWithLabels });
  };

  cellRenderer({ columnIndex, key, parent, rowIndex, style }) {
    const rows = get(this.state, 'rows', []);
    const series = get(this.props, 'series', []);
    const formatter = get(this.props, 'formatter', '');
    const labels = get(this.props, 'labels', []);
    const classes = get(this.props, 'classes', {});

    const percentage = (value) => {
      if (typeof value === 'string') return value;

      return `${value} %`;
    };

    const formatValue = (value, formatter) => {
      switch (formatter) {
        case 'percentage':
          return percentage(value);
        default:
          return value;
      }
    };

    let content = 0;

    if (rowIndex === 0) {
      const name = series[columnIndex];
      const label = find(labels, { name });
      content = !isEmpty(label) ? label.text : series[columnIndex];
    } else {
      const value = get(rows, [rowIndex, columnIndex], 0);
      content = value === 'NA_NAN' ? '-' : formatValue(value, formatter);
    }

    return (
      <CellMeasurer
        cache={this.cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <TableCell
          component="div"
          className={columnIndex !== 0 ? classes.cell : classes.rowHeaderCell}
          style={style}
          align={columnIndex !== 0 ? 'center' : 'left'}
        >
          {content}
        </TableCell>
      </CellMeasurer>
    );
  }

  render() {
    const rows = get(this.state, 'rows', []);
    const columns = get(this.props, 'series', []);
    const classes = get(this.props, 'classes', {});

    return (
      <div className={classes.root}>
        <AutoSizer>
          {({ height, width }) => (
            <MultiGrid
              {...this.state}
              rows={rows}
              rowCount={rows.length}
              enableFixedColumnScroll
              enableFixedRowScroll
              hideTopRightGridScrollbar
              hideBottomLeftGridScrollbar
              cellRenderer={this.cellRenderer}
              width={width}
              height={height}
              columnWidth={this.cache.columnWidth}
              deferredMeasurementCache={this.cache}
              columnCount={columns.length}
              rowHeight={this.cache.rowHeight}
              style={STYLE}
              styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
              styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
              styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

const mapStateToProps = (state, { id }) => ({
  labels: get(state, ['labels', id], []),
});

export default connect(mapStateToProps)(withStyles(styles)(TableGrid));
