import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import { navigate } from '@reach/router';
import { connect } from 'react-redux';

import get from 'lodash/get';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';

import { ReactComponent as BackIcon } from './assets/back.svg';
import { ReactComponent as GlobalIcon } from './assets/global.svg';
import { ReactComponent as UserIcon } from './assets/user.svg';
import { ReactComponent as SectorIcon } from './assets/sector.svg';
import { ReactComponent as ContentsIcon } from './assets/contents.svg';
import { ReactComponent as DownloadIcon } from './assets/download.svg';
import { ReactComponent as XLSXIcon } from './assets/xlsx.svg';
import { ReactComponent as PPTXIcon } from './assets/pptx.svg';
import { ReactComponent as UpdateIcon } from './assets/update.svg';

import MenuItem from '../SidebarMenuItem';
// import GlobalFiltersForm from '../GlobalFiltersForm';
// import FilterCategories from '../FilterCategories';
import { setActiveBlock } from '../../../state/filtersData/actions';
import { setShowContents } from '../../../state/databoard/actions';
import { exportDataboardData } from '../../../state/dataExport/actions';
import { exportDataboardDataPPTX } from '../../../state/dataExport/actions';
import hasAccess from '../ContactUs/utils/hasAccess';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { API } from 'aws-amplify';
import { SnackBar } from '../../UI';

const styles = (theme) => ({
  root: { flex: '0 0 auto' },
  paper: {
    paddingTop: '4rem',
    backgroundColor: theme.palette.custom.blue2,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: '1 0 auto',
    zIndex: theme.zIndex.sidebar,
    WebkitOverflowScrolling: 'touch', // Add iOS momentum scrolling.
    // temporary style
    position: 'fixed',
    top: 0,
    // We disable the focus ring for mouse, touch and keyboard users.
    // At some point, it would be better to keep it for keyboard users.
    // :focus-ring CSS pseudo-class will help.
    outline: 'none',
    left: 0,
    right: 'auto',
  },
  container: {
    width: '3.75rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.custom.blue4,
    },
  },
  activeListItem: {
    backgroundColor: theme.palette.custom.blue4,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  backdrop: {
    zIndex: 10000,
  },
});

const Icons = {
  global: GlobalIcon,
  contents: ContentsIcon,
  user: UserIcon,
  sector: SectorIcon,
  download: DownloadIcon,
  xlsx: XLSXIcon,
  pptx: PPTXIcon,
  update: UpdateIcon,
};

const Sidebar = ({
  classes,
  className,
  showGlobalFilters,
  showContents,
  pdfUrl,
  onClick,
  onContentsClick,
  product,
  onExportDataboardData,
  onExportDataboardDataPPTX,
  hasAccess,
  roles,
  jwtToken,
  name,
}) => {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [error, setError] = useState(null);
  const handleClick = () => {
    onClick(!showGlobalFilters);
    onContentsClick(false);
  };

  const handleContentClick = () => {
    onContentsClick(!showContents);
    onClick(false);
  };

  const downloadData = useCallback(() => {
    if (product === 'reports') {
      window.open(pdfUrl, '_blank').focus();
    } else {
      onExportDataboardData();
    }
  }, [pdfUrl, product]);

  const exportInternalXlsx = useCallback(() => {
    onExportDataboardData();
  }, []);

  const exportInternalPptx = useCallback(() => {
    onExportDataboardDataPPTX();
  }, []);

  const updateReport = async () => {
    setBackdropOpen(true);
    try {
      const params = {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: {
          name: name,
        },
      };
      await API.put('dashboards', `/reports/name/${name}`, params);
      setBackdropOpen(false);
      window.location.reload();
    } catch (e) {
      const message = get(
        e,
        ['response', 'data', 'message'],
        'You do not have permission to access',
      );
      setError(message);
      setBackdropOpen(false);
    }
  };

  const goBack = () => {
    navigate(product === 'daa' ? '/daa' : product === 'reports' ? '/reports' : '/dashboards');
  };

  return (
    <div className={cn(classes.root, className)}>
      <Paper className={classes.paper} square>
        <div className={classes.container}>
          <List component="ul" disablePadding>
            {product === 'dashboards' && (
              <MenuItem
                Icon={Icons.global}
                name="filters"
                className={cn(classes.menuItem, {
                  [classes.activeListItem]: showGlobalFilters,
                })}
                onClick={handleClick}
              />
            )}
            <MenuItem
              Icon={Icons.contents}
              name="contents"
              className={cn(classes.menuItem, {
                [classes.activeListItem]: showContents,
              })}
              onClick={handleContentClick}
            />
            {hasAccess && (
              <MenuItem
                Icon={Icons.download}
                name={product === 'reports' ? 'download report' : 'download dashboard'}
                className={classes.menuItem}
                onClick={downloadData}
              />
            )}
            {roles.includes('admin') && product === 'reports' && (
              <MenuItem
                Icon={Icons.xlsx}
                name={'export .xlsx'}
                className={classes.menuItem}
                onClick={exportInternalXlsx}
              />
            )}
            {roles.includes('admin') && product === 'reports' && (
              <MenuItem
                Icon={Icons.pptx}
                name={'export .pptx'}
                className={classes.menuItem}
                onClick={exportInternalPptx}
              />
            )}
            {roles.includes('admin') && product === 'reports' && (
              <MenuItem
                Icon={Icons.update}
                name={'update report'}
                className={classes.menuItem}
                onClick={updateReport}
              />
            )}
          </List>
          <MenuItem Icon={BackIcon} name="back" onClick={goBack} />
        </div>
      </Paper>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="primary" />
      </Backdrop>
      <SnackBar open={Boolean(error)} message={error} severity="warning" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
  showGlobalFilters: get(state, ['filtersData', 'showGlobalFilters']),
  showContents: get(state, ['databoard', 'showContents']),
  hasAccess: hasAccess(state),
  pdfUrl: get(state, ['databoard', 'pdfUrl']),
  roles: get(state, ['user', 'roles'], []),
  name: get(state, ['databoard', 'name'], ''),
});

const mapDispatchToProps = (dispatch) => ({
  onClick: (block) => dispatch(setActiveBlock(block)),
  onContentsClick: (showContents) => dispatch(setShowContents(showContents)),
  onExportDataboardData: () => dispatch(exportDataboardData()),
  onExportDataboardDataPPTX: () => dispatch(exportDataboardDataPPTX()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Sidebar));
