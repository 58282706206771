import React from 'react';
import { navigate } from '@reach/router';
import { Typography, withStyles } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { ReactComponent as DownloadIcon } from './Databoard/Sidebar/assets/download.svg';
import SidebarMenuItem from './Databoard/SidebarMenuItem';

const styles = (theme) => ({
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.custom.blue4,
    },
  },
});

const ReportSidebar = ({ downloadFile, classes }) => {
  const pathURL = window.location.pathname;

  return (
    <div
      style={{
        width: '60px',
        backgroundColor: '#0f70e0',
        display: 'flex',
        flexDirection: 'column',
        position: 'sticky',
        top: '64px',
        height: 'calc(100vh - 64px)',
      }}
    >
      {pathURL.includes('/reports') && (
        <SidebarMenuItem
          Icon={DownloadIcon}
          name="Download Report"
          className={classes.menuItem}
          onClick={downloadFile}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: 'white',
          cursor: 'pointer',
          padding: '10px 0',
          marginTop: 'auto',
          gap: '4px',
        }}
        onClick={() => navigate('/reports')}
      >
        <KeyboardBackspaceIcon />

        <Typography
          style={{ color: 'white', fontSize: '10px', fontWeight: 700, textTransform: 'uppercase' }}
        >
          Back
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(ReportSidebar);
