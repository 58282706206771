import reduce from 'lodash/reduce';
import map from 'lodash/map';

export default (filterGroups) =>
  reduce(
    filterGroups,
    (acc, { _id, values }) => {
      const groupFilterIds = map(values, '_id');

      return {
        ...acc,
        [_id]: groupFilterIds,
      };
    },
    {},
  );
