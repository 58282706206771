import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import find from 'lodash/find';
import uniq from 'lodash/uniq';
import merge from 'lodash/merge';
import findIndex from 'lodash/findIndex';

export const getFiltersByChartId = (state, chartId) => {
  const chartFilterArray = uniq([
    ...get(state, ['filtersIndex', 'byChart', chartId], []),
    ...get(state, ['filtersIndex', 'benchmarkingByChart', chartId], []),
  ]);

  const chartFilters = reduce(
    chartFilterArray,
    (result, filterId) => {
      const filterModel = get(state, ['filtersIndex', 'byId', filterId], {});
      const isVisible = get(filterModel, 'visible', false);
      const parent = get(filterModel, 'parent', '');
      const filterData = get(state, ['filtersData', 'byId', filterId], []);

      // Ignore hidden filters without parents or filters with no selected data
      if ((isEmpty(parent) && !isVisible) || isEmpty(filterData)) return result;
      // Ignore filters for which a filter with the same parent has already been included
      const siblingIndex = findIndex(result, { parent });
      if (siblingIndex !== -1 && !isEmpty(parent)) return result;

      const filterText = get(filterModel, 'text');
      const filterOperator = get(filterModel, 'operator');
      const dataLabels = map(filterData, (filterValue) => {
        const label = find(get(filterModel, 'labels', []), { name: filterValue });
        return isEmpty(label) ? filterValue : get(label, 'text');
      });

      return [...result, { text: filterText, operator: filterOperator, data: dataLabels, parent }];
    },
    [],
  );

  return chartFilters;
};

export const getFiltersByChart = (state) => {
  const filterIdsByChart = merge(
    {},
    get(state, ['filtersIndex', 'byChart'], {}),
    get(state, ['filtersIndex', 'benchmarkingByChart'], {}),
  );

  const filtersByChart = reduce(
    filterIdsByChart,
    (result, filterIds, chartId) => {
      return {
        ...result,
        [chartId]: getFiltersByChartId(state, chartId),
      };
    },
    {},
  );

  return filtersByChart;
};
