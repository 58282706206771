import map from 'lodash/map';
import keys from 'lodash/keys';
import get from 'lodash/get';

const getBenchmarkLabels = ({ benchmarkFilterExpressions }) =>
  map(keys(benchmarkFilterExpressions), (filterName) => {
    const filterText = get(benchmarkFilterExpressions, [filterName, 'text'], '');

    return {
      name: filterName,
      text: filterText,
    };
  });

export default getBenchmarkLabels;
