import isFinite from 'lodash/isFinite';

export const FORMATTER_PERCENTAGE = 'percentage';
export const FORMATTER_PERCENTAGE_NO_SIGN = 'percentage_no_sign';
export const FORMATTER_SCORE = 'score';
export const FORMATTER_POPULATION = 'population';

const SECOND_DIGIT_NORMALIZER = 10;
const THOUSAND = 1e3;
const MILLION = 1e6;

const percentageNoSign = (value) => {
  /**
   * 1 digit number => precision 2
   * rest => precision 1
   */
  const powerOfTen = value < 10 ? 100 : 10;

  return `${Math.round((value + Number.EPSILON) * powerOfTen) / powerOfTen}`;
};

const percentage = (value) => `${percentageNoSign(value)} %`;

const score = (value) => `${value}`;

const population = (value) => {
  if (!isFinite(value)) return '0';

  if (value >= MILLION) {
    return `${parseFloat((value / MILLION).toFixed(1)).toString()}M`;
  }

  // round thousand to the second digit
  if (value >= THOUSAND) {
    const normalizedRoundedValue = Math.round(value / THOUSAND / SECOND_DIGIT_NORMALIZER);
    if (normalizedRoundedValue > 0) {
      // A fix for the case when the number is just below 1M resulting in 1000K being displayed
      if (normalizedRoundedValue * SECOND_DIGIT_NORMALIZER.toFixed(0).toString() === '1000')
        return '1M';
      return `${normalizedRoundedValue * SECOND_DIGIT_NORMALIZER.toFixed(0).toString()}K`;
    }

    return `${(value / THOUSAND).toFixed(0).toString()}K`;
  }

  if (value >= 100) {
    return `${
      Math.round(parseFloat(value.toPrecision(3)) / SECOND_DIGIT_NORMALIZER) *
      SECOND_DIGIT_NORMALIZER
    }`;
  }

  return parseFloat(value.toPrecision(3)).toString();
};

export default {
  [FORMATTER_PERCENTAGE]: percentage,
  [FORMATTER_PERCENTAGE_NO_SIGN]: percentageNoSign,
  [FORMATTER_SCORE]: score,
  [FORMATTER_POPULATION]: population,
};
