import React from 'react';
import cn from 'classnames';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.border}`,
    borderBottomLeftRadius: '2px',
    borderBottomRightRadius: '2px',
  },
  noDivider: {
    borderTop: 'none',
  },
});

const PortletFooter = ({ classes, className, noDivider = false, children, ...rest }) => {
  const rootClassName = cn(
    {
      [classes.root]: true,
      [classes.noDivider]: noDivider,
    },
    className,
  );

  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  );
};

export default withStyles(styles)(PortletFooter);
