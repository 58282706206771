import React from 'react';

import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';

import Sample from '../Sample';
import PieChartEngine from '../RechartsPieChartEngine';
import config from './config';
import { LOW_SAMPLE_SIZE } from '../../../utils/hasLowSampleSize';

const DonutChart = ({ data, samples, id, series, configuration }) => {
  const chartThreshold = get(find(configuration, { key: 'threshold' }), 'value', LOW_SAMPLE_SIZE);

  return (
    <>
      <PieChartEngine id={id} {...config} data={data} series={series} />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {map(samples, (sample, index) => (
          <Sample key={`sample-size-${index}`} {...sample} chartThreshold={chartThreshold} />
        ))}
      </div>
    </>
  );
};

export default DonutChart;
