import React from 'react';

import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';

import Sample from '../Sample';
import { Container, ChartWrapper, SampleWrapper } from '../Container';
import orderSamples from '../Sample/orderSamples';
import BarChartEngine from '../RechartBarChartEngine';
import generateConfig from './generateConfig';
import { LOW_SAMPLE_SIZE } from '../../../utils/hasLowSampleSize';

const ColumnChartMulti = ({
  data,
  samples,
  axisTitle,
  series,
  domain,
  id,
  view,
  formatter,
  configuration,
}) => {
  const config = generateConfig({ series, domain, axisTitle, formatter, configuration });

  if (view === 'dialog') {
    config.rootProps.margin = {
      top: 26,
      right: 26,
      left: 0,
      bottom: 26,
    };
  }

  const chartThreshold = get(find(configuration, { key: 'threshold' }), 'value', LOW_SAMPLE_SIZE);

  return (
    <Container>
      <ChartWrapper>
        <BarChartEngine id={id} {...config} data={data} view={view} />
      </ChartWrapper>
      <SampleWrapper>
        {map(orderSamples(samples), (sample, index) => (
          <Sample key={`sample-size-${index}`} {...sample} chartThreshold={chartThreshold} />
        ))}
      </SampleWrapper>
    </Container>
  );
};

export default ColumnChartMulti;
