import React, { useState } from 'react';

import * as Yup from 'yup';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import CircularProgress from '@material-ui/core/CircularProgress';

import Container from '../../components/Container';
import FormTitle from '../../components/FormTitle';
import SubmitButton from '../../components/SubmitButton';
import PasswordField from '../../components/PasswordField';

import validator from '../../validator';

import completeNewPassword from '../../actions/completeNewPassword';
import currentAuthenticatedUser from '../../actions/currentAuthenticatedUser';
import { setCurrentUser } from '../../../../state/user/actions';

const NewPassword = ({ handleSubmit, user, submitting, doSetUser }) => {
  const [error, setError] = useState(null);

  const handleNewPassword = async ({ password1 }) => {
    try {
      await completeNewPassword(user, password1);
      const model = await currentAuthenticatedUser();
      doSetUser(model);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <Container error={error}>
      <form onSubmit={handleSubmit(handleNewPassword)}>
        <FormTitle>Setup New Password</FormTitle>
        <Field
          autoComplete="new-password"
          name="password1"
          label="New password"
          component={PasswordField}
        />
        <Field
          autoComplete="new-password"
          name="password2"
          label="Repeat password"
          component={PasswordField}
        />
        {!submitting && <SubmitButton label="Done" />}
        {submitting && <CircularProgress />}
      </form>
    </Container>
  );
};

const schema = Yup.object().shape({
  password1: Yup.string().trim().required(),
  password2: Yup.string()
    .oneOf([Yup.ref('password1'), null], 'Password must match')
    .required(),
});

const mapDispatchToProps = (dispatch) => ({
  doSetUser: (user) => dispatch(setCurrentUser(user)),
});

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: 'newPasswordForm',
    asyncValidate: validator(schema),
  }),
)(NewPassword);
