import { makeStyles } from '@material-ui/core';

import ITheme from '../../../../theme/@types/Theme';

export const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    fontFamily: 'Nunito Sans',
    '& .recharts-label-list .custom-label-text': {
      fontSize: 11,
    },
    '& .recharts-label-list .custom-label-tiny-text': {
      fontSize: 9,
    },
    '& .recharts-text.recharts-cartesian-axis-tick-value': {
      fontWeight: 800,
      fontSize: 13,
      fill: theme.palette.custom.black,
    },
    '& .recharts-tooltip-cursor': {
      fill: theme.palette.custom.blue3,
      opacity: 0.2,
    },
  },
}));
