import { Auth } from 'aws-amplify';

const signUp = async (user) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await Auth.signUp(user);
      console.log('Successfully processed sign up request');
      resolve(data);
    } catch (error) {
      console.log(`An error occurred while processing sign up request`);
      console.log(error);
      reject(error);
    }
  });

export default signUp;
