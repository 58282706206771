import React from 'react';
import cn from 'classnames';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '1.3rem',
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 500,
  },
  subtitle: {
    fontWeight: 400,
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
});

const PortletLabel = ({ classes, className, icon, title, subtitle, ...rest }) => {
  const rootClassName = cn(classes.root, className);

  return (
    <div {...rest} className={rootClassName}>
      {icon && <span className={classes.icon}>{icon}</span>}
      {title && (
        <Typography className={classes.title} variant="h5">
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography className={classes.subtitle} variant="subtitle2">
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(PortletLabel);
