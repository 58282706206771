import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  paper: {
    maxWidth: '32.5rem',
    border: '1px solid #707070',
    borderRadius: 10,
    boxShadow: '-11px 15px 40px 0 rgba(0, 0, 0, 0.5)',
  },
  title: {
    textAlign: 'center',
    fontFamily: 'Nunito Sans',
    fontWeight: 800,
    marginBottom: 0,
    paddingBottom: 0,
  },
  subtitle: {
    textAlign: 'center',
    color: theme.palette.custom.gray6,
    fontSize: '1.1rem',
  },
  actions: {
    justifyContent: 'center',
    paddingBottom: '2.4rem',
  },
  confirm: {
    padding: '1.1rem 3.3rem',
    borderRadius: 42,
    border: `2px solid ${theme.palette.custom.blue2}`,
    fontSize: '1.1rem',
    fontWeight: 800,
  },
  cancel: {
    borderRadius: 42,
    padding: '1.1rem 3.3rem',
    fontSize: '1.1rem',
    fontWeight: 800,
  },
});

const ConfirmationDialog = ({
  open,
  onClose,
  title,
  description,
  classes,
  onConfirm,
  onCancel,
  confirmButtonLabel = 'confirm',
  cancelButtonLabel = 'cancel',
}) => {
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.subtitle}>{description}</DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button className={classes.cancel} onClick={onCancel} color="primary">
          {cancelButtonLabel}
        </Button>
        <Button className={classes.confirm} onClick={onConfirm} color="primary">
          {confirmButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(ConfirmationDialog);
