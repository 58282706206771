import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: { zIndex: `${theme.zIndex.modal} !important` },
  paper: { padding: '3.3rem 3.3rem 1.6rem 3.3rem', textAlign: 'center' },
  button: {
    width: '10.6rem',
    height: '3.6rem',
    borderRadius: 42,
  },
  label: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    textTransform: 'capitalize !important',
  },
  secondaryLabel: {
    color: '#0f70e0',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    textTransform: 'capitalize !important',
  },
});

const FilterWarning = ({ open, classes, submit, discard }) => {
  return (
    <Dialog
      open={open}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      onEnter={() => document.body.setAttribute('style', 'overflow: hidden;')}
      onExit={() => document.body.setAttribute('style', '')}
      aria-labelledby="alert-dialog-dirty-filters"
      aria-describedby="alert-dialog-dirty-filters-actions"
    >
      <DialogContent>
        <p>
          <b>The filters you selected are not applied to the preview.</b>
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={discard} classes={{ root: classes.button, label: classes.secondaryLabel }}>
          cancel
        </Button>
        <Button
          onClick={submit}
          variant="contained"
          color="primary"
          classes={{ root: classes.button, label: classes.label }}
        >
          apply filters
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(FilterWarning);
