import palette from '../palette';

export default {
  rootProps: {},
  pieProps: {
    outerRadius: 150,
    dataKey: 'value',
    fill: '#8884d8',
    startAngle: 90,
    endAngle: -450,
  },
  colors: palette,
};
