import React from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';

import { get, map, find, isEmpty /*reduce*/ } from 'lodash';

import { AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

import { Container, ChartWrapper } from '../Container';
import AxisTitle from './components/AxisTitle';
import MultiGrid from './components/MultiGrid';
import Legend from './components/Legend';
import deepEqual from '../../../utils/deepEqual';
import { LOW_SAMPLE_SIZE } from '../../../utils/hasLowSampleSize';
import HintTooltip from '../RechartBarChartEngine/components/HintTooltip';

const ROW_HEIGHT = 40;
const ROW_HEADER_COLUMN_WIDTH = 170;
const COLUMN_WIDTH = 110;

const styles = () => ({
  cell: {
    padding: '0.3rem',
    fontFamily: 'Nunito Sans',
    overflow: 'hidden',
    maxHeight: '2.5rem',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    lineHeight: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowHeaderCell: {
    padding: '0.3rem',
    fontFamily: 'Nunito Sans',
    overflow: 'hidden',
    maxHeight: '2.5rem',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    lineHeight: '1rem',
  },
  columnHeaderCell: {
    padding: '0.3rem',
    fontFamily: 'Nunito Sans',
    overflow: 'hidden',
    maxHeight: '7.5rem',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    lineHeight: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    width: '100%',
    height: '100%',
    padding: '2rem 0',
    boxSizing: 'border-box',
    '& .ReactVirtualized__Grid': {
      outline: 'none !important',
    },
  },
  range1: {
    backgroundColor: '#CE211F',
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  range2: {
    backgroundColor: '#FAD017',
    color: '#2b2a29',
    fontWeight: 'bold',
  },
  range3: {
    backgroundColor: '#ECEDEE',
    color: '#2b2a29',
    fontWeight: 'bold',
  },
  range4: {
    backgroundColor: '#13CB6F',
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  range5: {
    backgroundColor: '#0E8C4D',
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  lowSampleText: {
    color: 'red',
  },
});

const getClassName = (difference, classes, inverted) => {
  if (!inverted) {
    if (difference < -5) return get(classes, 'range1');
    if (difference >= -5 && difference <= -2.5) return get(classes, 'range2');
    if (difference > 2.5 && difference <= 5) return get(classes, 'range4');
    if (difference > 5) return get(classes, 'range5');
  }

  if (difference < -5) return get(classes, 'range5');
  if (difference >= -5 && difference <= -2.5) return get(classes, 'range4');
  if (difference > 2.5 && difference <= 5) return get(classes, 'range2');
  if (difference > 5) return get(classes, 'range1');

  return get(classes, 'range3');
};

const percentage = (value) => {
  if (typeof value === 'string') return value;

  return `${Math.round(value)} %`;
};

const formatValue = (value, formatter) => {
  switch (formatter) {
    case 'percentage':
      return percentage(value);
    default:
      return value;
  }
};

//const sumHeadersWidth = (cache) => (acc, value, index) => acc + cache.getWidth(0, index);

const HEADER_ROW_INDEX = 0;
const SAMPLE_ROW_INDEX = 1;

class TableGrid extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      inverted: false,
      legendVariable: '',
      rows: [],
      fixedColumnCount: 1,
      fixedRowCount: isEmpty(get(this.props, 'sampleTable', null)) ? 1 : 2,
      scrollToColumn: 0,
      scrollToRow: 0,
    };

    this.cache = new CellMeasurerCache({
      defaultWidth: 170,
    });
    this.cellRenderer = this.cellRenderer.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    const invertedConfig = get(find(this.props.configuration, { key: 'inverted' }), 'value', false);
    this.setState({
      inverted: invertedConfig === 'true' || invertedConfig === true,
      legendVariable: get(find(this.props.configuration, { key: 'variable' }), 'value', ''),
      chartThreshold: get(
        find(this.props.configuration, { key: 'threshold' }),
        'value',
        LOW_SAMPLE_SIZE,
      ),
      fixedRowCount: isEmpty(get(this.props, 'sampleTable', null)) ? 1 : 2,
    });
  }

  getSnapshotBeforeUpdate(prevProps) {
    return prevProps;
  }

  componentDidUpdate(prevProps) {
    const rowsHaveChanged = !deepEqual(prevProps.data, this.props.data);
    if (rowsHaveChanged) {
      this.cache.clearAll();
      this.populateRowLabels();
    }
  }

  populateRowLabels = () => {
    const rows = get(this.props, 'data', []);
    const labels = get(this.props, 'labels', []);
    const series = get(this.props, 'series', []);
    const allRows = [series, ...rows];

    const rowsWithLabels = map(allRows, (row) =>
      map(row, (item) => {
        if (typeof item[0] === 'string') {
          const label = find(labels, { name: item[0] });

          return isEmpty(label) ? item : [label.text];
        }

        return item;
      }),
    );

    this.setState({ rows: rowsWithLabels });
  };

  cellRenderer({ columnIndex, key, parent, rowIndex, style }) {
    const rows = get(this.state, 'rows', []);
    const series = get(this.props, 'series', []);
    const formatter = get(this.props, 'formatter', '');
    const labels = get(this.props, 'labels', []);
    const classes = get(this.props, 'classes', {});
    const inverted = get(this.state, 'inverted', false);
    const sample = get(this.props, 'sampleTable', null);
    const chartThreshold = get(this.state, 'chartThreshold', LOW_SAMPLE_SIZE);

    let content = 0;
    let heatmapClass = '';
    let labelHint = '';

    if (rowIndex === HEADER_ROW_INDEX) {
      const name = series[columnIndex];
      const label = find(labels, { name });
      content = !isEmpty(label) ? label.text : series[columnIndex];
      labelHint = !isEmpty(label) ? label.hint : '';
    } else if (rowIndex === SAMPLE_ROW_INDEX && !isEmpty(sample)) {
      const value = get(rows, [rowIndex, columnIndex, 0], 0);
      content = typeof value === 'string' ? value : `n = ${value}`;
      if (typeof value !== 'string' && value < chartThreshold) {
        heatmapClass = get(classes, 'lowSampleText');
      }
    } else {
      const value = get(rows, [rowIndex, columnIndex, 0], 0);
      content = value === 'NA_NAN' ? '-' : formatValue(value, formatter);
      heatmapClass =
        columnIndex === 0
          ? ''
          : getClassName(get(rows, [rowIndex, columnIndex, 1], 0), classes, inverted);
      if (columnIndex === 0) {
        const label = find(labels, { text: value });
        labelHint = !isEmpty(label) ? label.hint : '';
      }
    }

    return (
      <CellMeasurer
        cache={this.cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <HintTooltip title={labelHint}>
          <TableCell
            component="div"
            className={cn(
              heatmapClass,
              { [classes.cell]: columnIndex !== 0 },
              { [classes.rowHeaderCell]: columnIndex === 0 },
              { [classes.columnHeaderCell]: rowIndex === 0 },
            )}
            style={style}
            align={columnIndex !== 0 ? 'center' : 'left'}
          >
            {content}
          </TableCell>
        </HintTooltip>
      </CellMeasurer>
    );
  }

  getAxisTitlePadding = () => {
    return (
      this.headerZero &&
      this.headerZero.current &&
      this.headerZero.current.getBoundingClientRect().width
    );
  };

  render() {
    const rows = get(this.state, 'rows', []);
    const columns = get(this.props, 'series', []);
    const classes = get(this.props, 'classes', {});
    const axisTitle = get(this.props, 'axisTitle', '');
    const legendVariable = get(this.state, 'legendVariable', '');
    const inverted = get(this.state, 'inverted', false);
    const columnWidth = get(this.props, 'columnWidth', COLUMN_WIDTH);
    const headerColumnWidth = get(this.props, 'headerColumnWidth', ROW_HEADER_COLUMN_WIDTH);

    // The minimum height could be bigger than the card height
    // in such cases we have to fallback to card height otherwise
    // there is an overflow
    const minimumHeight = ROW_HEIGHT * rows.length + 1.5 * ROW_HEIGHT;

    return (
      <Container>
        <ChartWrapper noFlex>
          <div className={classes.root}>
            <AutoSizer>
              {({ height, width }) => (
                <>
                  <MultiGrid
                    {...this.state}
                    rowCount={rows.length}
                    cellRenderer={this.cellRenderer}
                    width={width}
                    height={minimumHeight < height ? minimumHeight : height}
                    columnWidth={(cell) => {
                      const defaultWidth = cell.index === 0 ? headerColumnWidth : columnWidth;
                      return Math.max(defaultWidth, width / columns.length);
                    }}
                    rowHeight={(cell) => {
                      if (cell.index === 0) {
                        return ROW_HEIGHT * 2.5;
                      } else {
                        return ROW_HEIGHT;
                      }
                    }}
                    deferredMeasurementCache={this.cache}
                    columnCount={columns.length}
                  />
                  <AxisTitle text={axisTitle} styles={{ paddingTop: '0.8rem', width }} />
                </>
              )}
            </AutoSizer>
          </div>
          <Legend variable={legendVariable} inverted={inverted} />
        </ChartWrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state, { id }) => ({
  labels: get(state, ['labels', id], []),
  sampleTable: get(
    state,
    ['charts', 'byId', id, 'sample'],
    get(find(get(state, ['charts', 'byId', id, 'data']), { key: 'sample' }), 'value', ''),
  ),
  columnWidth: +get(
    find(get(state, ['charts', 'byId', id, 'config'], []), { key: 'columnWidth' }),
    'value',
    COLUMN_WIDTH,
  ),
  headerColumnWidth: +get(
    find(get(state, ['charts', 'byId', id, 'config'], []), { key: 'headerColumnWidth' }),
    'value',
    ROW_HEADER_COLUMN_WIDTH,
  ),
});

export default connect(mapStateToProps)(withStyles(styles)(TableGrid));
