import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

const WIDTH_OFFSET = 8;
const HEIGHT_OFFSET = 4;
const LINE_HEIGHT = 1.5;

const LARGE_FONT_SIZE = 16;
const MEDIUM_FONT_SIZE = 16;

export const getTextDimensions = (text, font = LARGE_FONT_SIZE) => {
  // re-use canvas object for better performance
  const canvas =
    getTextDimensions.canvas || (getTextDimensions.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return {
    width: metrics.width + WIDTH_OFFSET,
    height: LARGE_FONT_SIZE * LINE_HEIGHT + HEIGHT_OFFSET,
  };
};

const classes = {
  [LARGE_FONT_SIZE]: 'custom-label-text',
  [MEDIUM_FONT_SIZE]: 'custom-label-tiny-text',
};

// TODO: unit test
const doesNotFitAtAll = (width, dimensions) =>
  Math.abs(width) < dimensions.width && dimensions.width - Math.abs(width) > WIDTH_OFFSET;
const shouldShowNothing = (height, width) => Math.abs(height) < 10 || Math.abs(width) < 10;
const shouldReduceFontSize = (height, width, dimensions) =>
  Math.abs(height) < dimensions.height || Math.abs(width) < dimensions.width;

const CustomLabel = ({ x, y, width, height, value, formatter = (data) => data }) => {
  const [formattedValue, setFormattedValue] = useState('');
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const tempValue = formatter(value);
    const dim = getTextDimensions(tempValue, MEDIUM_FONT_SIZE);
    const textToShow = doesNotFitAtAll(width, dim) ? '...' : tempValue;

    setFormattedValue(textToShow);
    setDimensions(dim);
  }, [value]);

  if (isEmpty(formattedValue) || shouldShowNothing(height, width)) return <g />;

  let className = classes[LARGE_FONT_SIZE];
  if (shouldReduceFontSize(height, width, dimensions)) {
    className = classes[MEDIUM_FONT_SIZE];
  }

  return (
    <g>
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
        className={className}
      >
        {formattedValue}
      </text>
    </g>
  );
};

export default CustomLabel;
