import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';

import LazyLoad from 'react-lazyload';
import moment from 'moment';
import { connect } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import pick from 'lodash/pick';
import find from 'lodash/find';

import Paper from '../CardPaper';
import Title from '../CardTitle';
import Subtitle from '../CardSubtitle';
import Chart from '../Chart';
import CardFiltersForm from '../CardFiltersForm';
import ExpandButton from '../IconButtons/ExpandButton';
import RefreshButton from '../IconButtons/RefreshButton/RefreshButton';
import ShareButton from '../IconButtons/ShareButton/ShareButton';
import DownloadDataButton from '../IconButtons/DownloadDataButton/DownloadDataButton';
import DialogView from '../DialogView';
import DownloadChartData from '../DownloadChartData';
import ViewportContainer from './components/ViewportContainer';
import { fetchByChartId } from '../../../state/data/actions';
import { exportChartData } from '../../../state/dataExport/actions';
import { trackGraphVisit } from '../../../state/analytics/actions';
import CardFooter from '../CardFooter';
import Alert from '@material-ui/lab/Alert';
import hasLowSampleSize from '../../../utils/hasLowSampleSize';
import { isLocked } from '../Chart/helpers';
import ChartImage from './components/ChartImage';
import hasAccess from '../ContactUs/utils/hasAccess';
import { head, tail } from 'lodash';
import AuthorImage from './components/AuthorImage';

const TIME_THRESHOLD = 500;
const FALLBACK_HEIGHT = '45rem';
const HEIGHTS = {
  small: '45rem',
  medium: '53rem',
  large: '73rem',
  // TODO: remove after migration
  half: '40.6rem',
  full: '56.9rem',
};

const Card = ({
  id,
  width,
  height,
  chartImageUrl,
  authorImageUrl,
  hasAccess,
  licenseTier,
  sampleVisible,
  title,
  text,
  filters: cardFilters,
  appliedFilters,
  refreshChartData,
  chartId,
  showLock,
  chartName,
  samples,
  chartData,
  chartSampleLabel,
  chartThreshold,
  chartQuery,
  anchorId,
  chartIsLoading,
  downloadChartData,
  idleTime,
  clearIdleTime,
  doTrackGraphVisit,
  trackerProps,
}) => {
  const currentCard = useRef(null);
  // moment object
  const startUsageTimestamp = useRef(null);
  // moment object
  const startLoadingTimestamp = useRef(null);
  const [openInDialog, setOpenInDialog] = useState(false);
  const [loadingTime, setLoadingTime] = useState(0);
  const dummyTitle = 'This is a dummy title. To get full access please contact us.';
  const pathURL = window.location.pathname;
  const isReport = pathURL.includes('/reports');

  useEffect(() => {
    if (!chartIsLoading && startLoadingTimestamp && startLoadingTimestamp.current) {
      setLoadingTime(moment().diff(startLoadingTimestamp.current));
      startLoadingTimestamp.current = null;
    }
  }, [chartIsLoading]);

  const trackEvent = useCallback(() => {
    if (!chartIsLoading && startUsageTimestamp) {
      const duration = moment().diff(startUsageTimestamp.current);
      startUsageTimestamp.current = null;
      if (duration > TIME_THRESHOLD) {
        doTrackGraphVisit(chartName, chartQuery, duration, idleTime, loadingTime);
        clearIdleTime();
      }
    }
  }, [chartIsLoading, idleTime, chartName, chartQuery, startUsageTimestamp, loadingTime]);

  // Handle tracking of the last item before leaving the dashboard
  useEffect(() => {
    return () => {
      trackEvent();
    };
  }, []);

  // Handle scrolling to card position
  useEffect(() => {
    if (chartName === anchorId && currentCard !== null) {
      window.scrollTo(0, currentCard.current.offsetTop - 100);
    }
  }, [currentCard]);

  const download = useCallback(() => downloadChartData(chartId), [chartId]);

  const startTimer = useCallback(() => {
    if (chartIsLoading) {
      startLoadingTimestamp.current = moment();
    }
    startUsageTimestamp.current = moment();
  }, [chartIsLoading]);

  const customHeight = useMemo(() => get(HEIGHTS, height, FALLBACK_HEIGHT), [height]);

  const gridContainerProps = useMemo(
    () => ({
      xs: 10,
      lg: width === 'full' ? 12 : authorImageUrl ? 4 : 5,
      item: true,
      style: {
        height: authorImageUrl ? '48rem' : customHeight,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: width === 'full' ? 2000 : authorImageUrl ? 500 : 650,
        marginBottom: '3rem',
      },
    }),
    [customHeight, width],
  );

  const observerProps = useMemo(
    () => ({
      onEnterViewport: startTimer,
      onLeaveViewport: trackEvent,
      ...trackerProps,
      // incl trackerProps
      chartName,
    }),
    [startTimer, trackEvent, trackerProps, chartName],
  );

  return (
    <ViewportContainer {...gridContainerProps} {...observerProps}>
      <div ref={currentCard} id={id} />
      <Paper height={height} width={width}>
        <DownloadChartData chartId={chartId} cardTitle={title} cardSubtitle={text} />
        {authorImageUrl && <AuthorImage url={authorImageUrl} />}
        <Title isBlured={!hasAccess && licenseTier === 'paid' && isReport && !authorImageUrl}>
          {authorImageUrl
            ? head(title.split(' - '))
            : !hasAccess && licenseTier === 'paid' && isReport
            ? dummyTitle
            : title}
        </Title>
        {authorImageUrl && (
          <p style={{ fontSize: '1.2rem', color: '#1170e0', marginTop: 0, textAlign: 'center' }}>
            {tail(title.split(' - '))}
          </p>
        )}
        <Subtitle
          authorBio={authorImageUrl}
          isBlured={!hasAccess && licenseTier === 'paid' && isReport && !authorImageUrl}
        >
          {authorImageUrl || !(!hasAccess && licenseTier === 'paid' && isReport)
            ? text
            : dummyTitle}
        </Subtitle>
        {hasLowSampleSize(chartData, chartThreshold, chartSampleLabel) && !showLock && (
          <Alert
            style={{ marginTop: '1rem', marginLeft: '5%', marginRight: '5%', borderRadius: 15 }}
            severity="warning"
          >
            Filters resulted in one or more low sample sizes! (marked in red)
          </Alert>
        )}
        {!isEmpty(cardFilters) && !showLock && !chartIsLoading && (
          <CardFiltersForm items={cardFilters} form={chartId} />
        )}
        <LazyLoad height={customHeight} offset={100} once debounce={200}>
          {chartImageUrl && (hasAccess || licenseTier === 'free') && (
            <ChartImage url={chartImageUrl} />
          )}
          {!((chartImageUrl || authorImageUrl) && (hasAccess || licenseTier === 'free')) &&
            !authorImageUrl && (
              <Chart
                id={chartId}
                appliedFilters={appliedFilters}
                samples={samples}
                sampleVisible={sampleVisible}
              />
            )}
          <DialogView
            open={openInDialog}
            title={title}
            cardHeight={height}
            subtitle={text}
            chartProps={{
              id: chartId,
              appliedFilters,
              samples,
            }}
            chartName={chartName}
            chartQuery={chartQuery}
            chartImageUrl={chartImageUrl}
            onClose={() => setOpenInDialog(false)}
          />
        </LazyLoad>
        {!showLock && !chartIsLoading && !authorImageUrl && (
          <CardFooter>
            <ShareButton chartName={chartName} chartTitle={title} chartQuery={chartQuery} />
            {!chartImageUrl && (
              <DownloadDataButton
                onClick={download}
                chartName={chartName}
                chartQuery={chartQuery}
              />
            )}
            {!chartImageUrl && <RefreshButton onClick={() => refreshChartData(chartId)} />}
            <ExpandButton onClick={() => setOpenInDialog(true)} />
          </CardFooter>
        )}
      </Paper>
    </ViewportContainer>
  );
};

const mapStateToProps = (state, { appliedFilters, chart }) => {
  const chartFilters = pick(
    get(state, ['filtersData', 'byId'], {}),
    get(state, ['filtersIndex', 'byChart', get(chart, '_id', '')]),
  );

  // Pass down applied filters in order to fetch data when they change
  // The fetcher gets the filter from the state.
  return {
    appliedFilters: {
      ...appliedFilters,
      ...chartFilters,
    },
    chartId: get(chart, '_id', ''),
    showLock: isLocked(state, get(chart, '_id', '')),
    chartName: get(chart, 'name', ''),
    chartIsLoading: get(state, ['data', 'byChart', get(chart, '_id', ''), 'loader'], false),
    chartData: get(state, ['data', 'byChart', get(chart, '_id', ''), 'data']),
    chartThreshold: get(
      find(get(state, ['charts', 'byId', get(chart, '_id', ''), 'config']), {
        key: 'threshold',
      }),
      'value',
    ),
    chartSampleLabel: get(state, ['charts', 'byId', get(chart, '_id', ''), 'sample'], ''),
    chartQuery: get(state, ['charts', 'byId', get(chart, '_id', ''), 'query', 'name'], ''),
    hasAccess: hasAccess(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  refreshChartData: (chartId) => dispatch(fetchByChartId(chartId)),
  downloadChartData: (chartId) => dispatch(exportChartData(chartId)),
  doTrackGraphVisit: (chartName, chartQuery, duration, idleTime, loadingTime) =>
    dispatch(trackGraphVisit(chartName, chartQuery, duration, idleTime, loadingTime)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Card);
