import get from 'lodash/get';
import map from 'lodash/map';
import head from 'lodash/head';
import find from 'lodash/find';
import { isNumber } from 'lodash';

const getMultiSurveySamples = ({ data, table, sources, sourcesLookup }) => {
  const samples = map(data, (sourceData, index) => {
    const sample = get(
      head(get(head(get(find(sourceData, { name: table }), 'values', [])), 'values', [])),
      'value',
      0,
    );
    let source = get(sources, index, '');
    if (isNumber(source)) {
      source = get(find(sourcesLookup, { id: source }), 'text', source);
    }
    return { name: source, value: sample };
  });

  return samples;
};

export default getMultiSurveySamples;
