const prefix = '@@filters';

export const SET_ACTIVE_BLOCK = `${prefix}/SET_ACTIVE_BLOCK`;
export const UPDATE_SELECTED_FILTERS = `${prefix}/UPDATE_SELECTED_FILTERS`;
export const UPDATE_APPLIED_GLOBAL_FILTERS = `${prefix}/UPDATE_APPLIED_GLOBAL_FILTERS`;
export const UPDATE_APPLIED_SECTION_FILTERS = `${prefix}/UPDATE_APPLIED_SECTION_FILTERS`;
export const INITIALIZE_APPLIED_SECTION_FILTERS = `${prefix}/INITIALIZE_APPLIED_SECTION_FILTERS`;
export const UPDATE_APPLIED_CHART_FILTERS = `${prefix}/UPDATE_APPLIED_CHART_FILTERS`;
export const INITIALIZE_APPLIED_CHART_FILTERS = `${prefix}/INITIALIZE_APPLIED_CHART_FILTERS`;
export const RESET_GLOBAL_FILTERS = `${prefix}/RESET_GLOBAL_FILTERS`;
export const RESET_FILTERS = `${prefix}/RESET_FILTERS`;
export const REMOVE_FILTER = `${prefix}/REMOVE_FILTER`;

export const INITIALIZE_FILTER_DATA = `${prefix}/INITIALIZE_FILTER_DATA`;
export const UPDATE_FILTER_DATA = `${prefix}/UPDATE_FILTER_DATA`;

/**
 * Special variable, is be used:
 *
 * - chart.query.ignore: []
 * - global filter sources
 */
export const DATABOARD_SOURCES_FILTER = 'sources';
