import { head, map, orderBy, reverse, split, trim } from 'lodash';

const sortByTitle = (dataTables, order) => orderBy(dataTables, ['title'], order);
const sortByRequestedBy = (dataTables, order) => orderBy(dataTables, (doc) => map(split(doc.requestedBy, ','), trim), order);
const sortByRequestedOn = (dataTables, order) => orderBy(dataTables, ['requestedOn'], order);
const sortByDataSource = (dataTables, order) => orderBy(dataTables, ['dataSource'], order);
const sortByDataCollectionPeriod = (dataTables, order) =>
  orderBy(dataTables, (doc) => reverse(split(head(split(doc.dataCollectionPeriod, '-')), ' ')), order);
const sortBySectors = (dataTables, order) => orderBy(dataTables, ['sectors'], order);

const sortDataTables = (dataTables, orderByField, order) => {
  switch (orderByField) {
    case 'title':
      return sortByTitle(dataTables, order);

    case 'requestedBy':
      return sortByRequestedBy(dataTables, order);

    case 'requestedOn':
      return sortByRequestedOn(dataTables, order);

    case 'dataSource':
      return sortByDataSource(dataTables, order);

    case 'dataCollectionPeriod':
      return sortByDataCollectionPeriod(dataTables, order);

    case 'sectors':
      return sortBySectors(dataTables, order);
  
    default:
      return dataTables;
  }
};

export default sortDataTables;
