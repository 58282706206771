import React from 'react';

import BarChartEngine from '../RechartBarChartEngine';
import { Container, ChartWrapper } from '../Container';
import defaultConfig from './config';
import generateConfig from './generateConfig';

const ColumnChartStacked = ({
  data,
  samples,
  axisTitle,
  series,
  id,
  view,
  domain = [],
  formatter,
  customConfig,
  configuration,
}) => {
  const config = generateConfig({
    defaultConfig,
    series,
    domain,
    axisTitle,
    formatter,
    samples,
    customConfig,
    configuration,
  });

  if (view === 'dialog') {
    config.rootProps.margin = {
      top: 26,
      right: 26,
      left: 0,
      bottom: 26,
    };
  }

  return (
    <Container>
      <ChartWrapper fullHeight>
        <BarChartEngine id={id} {...config} data={data} view={view} />
      </ChartWrapper>
    </Container>
  );
};

export default ColumnChartStacked;
