import qs from 'qs';

import get from 'lodash/get';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import flattenDeep from 'lodash/flattenDeep';
import filter from 'lodash/filter';
import indexOf from 'lodash/indexOf';

import getAllFilters from './getAllFilters';

const parseFilters = (databoard, queryParams) => {
  const supportedSources = get(databoard, 'sources', []);
  const filtersArray = getAllFilters(databoard.filterGroups, databoard.sections);

  const defaultFilters = filter(filtersArray, (filterModel) => !isEmpty(filterModel.defaultValues));

  const parsedParams = qs.parse(queryParams, {
    comma: true,
    ignoreQueryPrefix: true,
    encode: true,
  });
  const sources = filter(
    flattenDeep([get(parsedParams, 'CAT_SOURCES', [])]),
    (source) => indexOf(supportedSources, source) !== -1,
  );

  const initialObject = isEmpty(sources) ? {} : { sources };

  const defaultFiltersById = reduce(
    defaultFilters,
    (result, filterModel) => {
      const filterId = get(filterModel, '_id', '');

      if (isEmpty(filterId)) return result;

      return {
        ...result,
        [filterId]: {
          values: get(filterModel, 'defaultValues', []),
          query: get(filterModel, 'query', ''),
          table: get(filterModel, 'table', ''),
          text: get(filterModel, 'text', ''),
          filter: get(filterModel, 'filter', []),
          reject: get(filterModel, 'reject', []),
          adapter: get(filterModel, 'adapter'),
          replaceLabels: get(filterModel, 'replaceLabels'),
        },
      };
    },
    initialObject,
  );

  const appliedFiltersById = reduce(
    omit(parsedParams, 'CAT_SOURCES'),
    (result, value, key) => {
      const filterModel = find(filtersArray, { name: key });
      const childrenFilter = filter(filtersArray, ({ parent }) => parent === key);
      const filterId = get(filterModel, '_id', '');

      if (isEmpty(filterId)) return result;

      const childrenToObject = reduce(
        childrenFilter,
        (childrenResult, childModel) => {
          const childId = get(childModel, '_id');

          return {
            ...childrenResult,
            [childId]: {
              values: flattenDeep([value]),
              query: get(childModel, 'query', get(filterModel, 'query', '')),
              table: get(childModel, 'table', get(filterModel, 'table', '')),
              text: get(childModel, 'text', get(filterModel, 'text', '')),
              filter: get(childModel, 'filter', get(filterModel, 'filter', [])),
              reject: get(childModel, 'reject', get(filterModel, 'reject', [])),
              adapter: get(childModel, 'adapter', get(filterModel, 'adapter')),
            },
          };
        },
        {},
      );

      return {
        ...result,
        [filterId]: {
          values: flattenDeep([value]),
          query: get(filterModel, 'query', ''),
          table: get(filterModel, 'table', ''),
          text: get(filterModel, 'text', ''),
          filter: get(filterModel, 'filter', []),
          reject: get(filterModel, 'reject', []),
          adapter: get(filterModel, 'adapter'),
        },
        ...childrenToObject,
      };
    },
    initialObject,
  );

  return { ...defaultFiltersById, ...appliedFiltersById };
};

export default parseFilters;
