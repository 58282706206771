import styled, { css } from 'styled-components';

const resets = css`
  box-shadow: none;
  color: inherit;
  margin: inherit;
  padding: inherit;
`;

const formStyles = css`
  ${resets};
  box-sizing: border-box;
`;

const SlackFeedback = styled.div`
  position: fixed;
  font-family: Nunito Sans;
  z-index: 99999998;
  margin: 0.4rem;
  top: 50%;
  right: 0;
  text-align: left;
  font-weight: bold;
  display: flex;

  * {
    box-sizing: border-box;
  }

  textarea {
    min-height: 12.2rem;
  }
`;

const Container = styled.div`
  display: none;
  background: #ffffff;
  z-index: 999999999;
  border-radius: 4px;
  width: 30.9rem;
  box-shadow: 0 6px 30px 2px rgba(34, 44, 79, 0.3);
  border-radius: 6px;
  margin-top: -2.9rem;
  margin-left: 0.4rem;
  &.active {
    display: block;
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
  font-weight: bold;
  opacity: 0.7;
  color: #bcc6d2;
  margin-left: auto;
  font-size: 0.9rem;

  &:hover {
    opacity: 1;
    color: #bcc6d2;
  }
`;

const Header = styled.div`
  display: flex;
  color: white;
  background: white;
  padding: 2.6rem 2.6rem 1rem;
  font-weight: 400;
  border-radius: 3px 3px 0 0;
  font-size: 1.1rem;
  align-items: center;

  > img {
    margin-right: 0.5em;
  }
`;

const Content = styled.div`
  padding: 0em 2.6rem 2.6rem;
`;

const Trigger = styled.div`
  display: flex;
  align-items: center;
  align-items: center;
  font-weight: bold;
  background: #1b3a8b;
  color: white;
  border: none;
  white-space: nowrap;
  padding: 0.4rem 0.8rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0 3px 12px 1px rgba(34, 44, 79, 0.1);
  transition: box-shadow 0.3s, transform 0.2s ease-in, color 0.2s;
  transform: rotate(-90deg);
  margin-right: -3.6rem;
  width: 8.2rem;
  height: 2.5rem;

  &:hover {
    box-shadow: 0 6px 16px 2px rgba(0, 0, 0, 0.2);
    background-color: #303d69;
    color: white;
    border-color: none;
  }

  img {
    margin-right: 0.7rem;
  }
`;

const Label = styled.label`
  color: #0f70e0;
  display: block;
  font-size: 1rem;
  margin-bottom: 0.4rem;
  font-family: Nunito Sans;
  font-weight: bold;
`;

const HeaderLabel = styled.label`
  color: #0f70e0;
  display: block;
  font-size: 1.3rem;
  margin-bottom: 0.4rem;
  font-family: Nunito Sans;
  font-weight: bold;
`;

const FormElement = styled.input`
  ${formStyles};

  width: 100%;
  color: #444444;
  border: 1px solid #bcc6d2;
  border-radius: 4px;
  padding: 0.5em;
  font-size: 1rem;
  font-family: Nunito Sans;
  background: white;
  margin-bottom: 1rem;
  outline: none;
  resize: none;

  &:focus {
    border: 1px solid #1b3a8b;
    box-shadow: 0 0 8px rgba(0, 135, 255, 0.3);
  }

  &[disabled],
  &.disabled {
    background: white;
    pointer-events: none;
    color: #444444;
  }
`;

const SubmitButton = styled.button`
  display: block;
  padding: 1.3rem 2.1rem;
  text-align: center;
  background: #1b3a8b;
  color: white;
  font-weight: bold;
  outline: none;
  border: none;
  font-size: 1rem;
  font-family: Nunito Sans;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-radius: 46px;
  cursor: pointer;
  transition: background 0.2s, box-shadow 0.2s;

  &[disabled],
  &.disabled {
    pointer-events: none;
    background: #e7e7ef;
  }

  &.sent {
    background: #3dc86f;
    pointer-events: none;
    opacity: 1;
  }

  &.error {
    background: #ec3c3c;
    pointer-events: none;
    opacity: 1;
  }

  &:hover {
    background: #1b3a8b;
    box-shadow: none;
  }
`;

const Select = styled.div`
  margin-bottom: 0.7rem;
`;

export {
  CloseButton,
  Container,
  Content,
  FormElement,
  Header,
  Label,
  HeaderLabel,
  Select,
  SlackFeedback,
  SubmitButton,
  Trigger,
};
