import React from 'react';
import cn from 'classnames';

import { makeStyles } from '@material-ui/core';

interface LegendProps {
  variable: string;
  inverted: boolean;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
  item: {
    fontFamily: 'Nunito Sans',
    fontSize: 11,
    width: 105,
    marginRight: 10,
    display: 'flex',
  },
  plusMinusSignContainer: {
    width: 10,
    display: 'inline-flex',
    flexDirection: 'column',
    marginTop: -4,
  },
  plusSign: {
    lineHeight: 1,
  },
  minusSign: {
    lineHeight: 0,
  },
  color: { width: 20, height: 10, marginRight: 5 },
  label: { marginTop: -5 },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  // inverseContainer: {
  //   flexDirection: 'row-reverse',
  // },
  range1: { backgroundColor: '#CE211F' },
  range2: { backgroundColor: '#FAD017' },
  range3: { backgroundColor: '#ECEDEE' },
  range4: { backgroundColor: '#13CB6F' },
  range5: { backgroundColor: '#0E8C4D' },
});

const Legend = ({ variable, inverted }: LegendProps) => {
  const classes = useStyles();

  if (!variable) return null;

  return (
    <div className={classes.root}>
      <div className={cn(classes.container /*, { [classes.inverseContainer]: inverted }*/)}>
        <div className={classes.item}>
          <div
            className={cn(
              classes.color,
              { [classes.range1]: !inverted },
              { [classes.range5]: inverted },
            )}
          />
          <span className={classes.label}>{`>5pp below avg. of other ${variable}`}</span>
        </div>
        <div className={classes.item}>
          <div
            className={cn(
              classes.color,
              { [classes.range2]: !inverted },
              { [classes.range4]: inverted },
            )}
          />
          <span className={classes.label}>{`2.5 - 5pp below avg. of other ${variable}`}</span>
        </div>
        <div className={classes.item}>
          <div className={cn(classes.color, classes.range3)} />
          <span className={classes.plusMinusSignContainer}>
            <span className={classes.plusSign}>+</span>
            <span className={classes.minusSign}>-</span>
          </span>
          <span className={classes.label}>{`2.5pp around avg. of other ${variable}`}</span>
        </div>
        <div className={classes.item}>
          <div
            className={cn(
              classes.color,
              { [classes.range4]: !inverted },
              { [classes.range2]: inverted },
            )}
          />
          <span className={classes.label}>{`2.5 - 5pp above avg. of other ${variable}`}</span>
        </div>
        <div className={classes.item}>
          <div
            className={cn(
              classes.color,
              { [classes.range5]: !inverted },
              { [classes.range1]: inverted },
            )}
          />
          <span className={classes.label}>{`>5pp above avg. of other ${variable}`}</span>
        </div>
      </div>
    </div>
  );
};

export default Legend;
