import React from 'react';

import get from 'lodash/get';
import head from 'lodash/head';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

import { Container, ChartWrapper, SampleWrapper } from '../Container';
import Sample from '../Sample';
import BarChartEngine from '../RechartBarChartEngine';

import config from './config';
import { LOW_SAMPLE_SIZE } from '../../../utils/hasLowSampleSize';

// TODO: use the BarChart with the formatter property (dpb related)
const BarChartScore = ({
  data,
  samples,
  axisTitle,
  formatter,
  id,
  configuration,
  sampleVisible,
}) => {
  const mergedConfig = {
    ...config,
    xAxisProps: {
      ...config.xAxisProps,
      label: { ...get(config, ['xAxisProps', 'label'], {}), value: axisTitle },
    },
    series: isEmpty(formatter)
      ? [...config.series]
      : map(config.series, ({ label, ...rest }) => ({
          ...rest,
          label: { ...label, formatter },
        })),
    chartThreshold: get(find(configuration, { key: 'threshold' }), 'value', LOW_SAMPLE_SIZE),
  };

  return (
    <Container>
      <ChartWrapper>
        <BarChartEngine
          id={id}
          {...mergedConfig}
          data={data}
          sampleVisible={sampleVisible}
          samples={samples}
        />
      </ChartWrapper>
      {!(sampleVisible === 'true') && (
        <SampleWrapper>
          <Sample
            name={get(head(samples), 'name', 'n')}
            value={get(head(samples), 'value', 0)}
            chartThreshold={mergedConfig.chartThreshold}
          />
        </SampleWrapper>
      )}
    </Container>
  );
};

export default BarChartScore;
