import React from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
  },
});

const Container = ({ classes, children }) => <div className={classes.root}>{children}</div>;

export default withStyles(styles)(Container);
