// Bar Charts
export const TYPE_BAR_CHART = 'TYPE_BAR_CHART';
export const TYPE_BAR_CHART_MULTI = 'TYPE_BAR_CHART_MULTI';
export const TYPE_BAR_CHART_STACKED = 'TYPE_BAR_CHART_STACKED';
export const TYPE_BAR_CHART_STACKED_MULTI = 'TYPE_BAR_CHART_STACKED_MULTI';
export const TYPE_BAR_CHART_SCORE = 'TYPE_BAR_CHART_SCORE';
export const TYPE_BAR_CHART_BENCHMARK = 'TYPE_BAR_CHART_BENCHMARK';
export const TYPE_BAR_CHART_SIDE_BY_SIDE = 'TYPE_BAR_CHART_SIDE_BY_SIDE';

// Column Charts
export const TYPE_COLUMN_CHART = 'TYPE_COLUMN_CHART';
export const TYPE_COLUMN_CHART_MULTI = 'TYPE_COLUMN_CHART_MULTI';
export const TYPE_COLUMN_CHART_BENCHMARK = 'TYPE_COLUMN_CHART_BENCHMARK';
export const TYPE_COLUMN_CHART_STACKED = 'TYPE_COLUMN_CHART_STACKED';
export const TYPE_COLUMN_CHART_STACKED_LOCAL = 'TYPE_COLUMN_CHART_STACKED_LOCAL';
export const TYPE_COLUMN_CHART_LINE = 'TYPE_COLUMN_CHART_LINE';

// Pie Charts
export const TYPE_PIE_CHART = 'TYPE_PIE_CHART';
export const TYPE_DONUT_CHART = 'TYPE_DONUT_CHART';

// Table Chart
export const TYPE_TABLE_CHART = 'TYPE_TABLE_CHART';
export const TYPE_HEATMAP_CHART = 'TYPE_HEATMAP_CHART';

// Line Chart
export const TYPE_LINE_CHART = 'TYPE_LINE_CHART';

// Scatter Chart
export const TYPE_SCATTER_CHART = 'TYPE_SCATTER_CHART';
