import React from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';

import get from 'lodash/get';
import map from 'lodash/map';
import indexOf from 'lodash/indexOf';
import find from 'lodash/find';

import { withStyles } from '@material-ui/core/styles';

import { percentage } from '../../../helpers/formatters';
import { rejectSeries } from '../../../../../state/charts/actions';
import { getChartLabels } from '../../../../../state/labels/selectors';

const styles = (theme) => ({
  root: {},
  item: {
    marginRight: 10,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    fontSize: 13,
    color: theme.palette.custom.black,
    fontWeight: 500,
  },
  inactive: {
    opacity: 0.5,
  },
});

const CustomLegend = ({ id, classes, labels, payload, series, rejected, onClick }) => {
  return (
    <div className={classes.root}>
      {map(series, (seriesItem, index) => {
        const item = find(payload, { value: seriesItem });
        const value = get(item, ['payload', 'value'], 0);
        const labelKey = get(item, ['item', 'value'], seriesItem);
        const label = get(find(labels, { name: labelKey }), 'text', labelKey);
        const fill = get(item, 'color', '#636666');
        const key = get(item, ['item', 'value'], seriesItem);

        return (
          <div
            key={`legend-item-${index}`}
            className={cn(classes.item, {
              [classes.inactive]: indexOf(rejected, key) !== -1,
            })}
            onClick={() => onClick(id, key)}
          >
            <svg
              className="recharts-surface"
              width="14"
              height="14"
              viewBox="0 0 32 32"
              version="1.1"
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                marginRight: '4px',
              }}
            >
              <path
                stroke="none"
                fill={fill}
                d="M0,4h32v24h-32z"
                className="recharts-legend-icon"
              />
            </svg>
            <span className="recharts-legend-item-text">{`${percentage(value)} ${label}`}</span>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state, { id }) => ({
  rejected: get(state, ['charts', 'byId', id, 'reject'], []),
  labels: getChartLabels(state, id),
});

const mapDispatchToProps = (dispatch) => ({
  onClick: (chartId, series) => dispatch(rejectSeries(chartId, series)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomLegend));
