import React, { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';

import { API } from 'aws-amplify';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';

import map from 'lodash/map';
import join from 'lodash/join';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/core/styles';

import AppLoader from '../../../../components/AppLoader';
import BookmarkButton from './components/BookmarkButton';
import getFilteredDashboards from './utils/getFilteredDashboards';
import sortDashboards from './utils/sortDashboards';
import { setUserSubscriptions } from '../../../../state/user/actions';
import getNewData from './utils/getNewData';
import moment from 'moment';
import {
  SECTOR_APP,
  SECTOR_ARVR,
  SECTOR_CLD,
  SECTOR_CORE,
  SECTOR_DESK,
  SECTOR_DEVOPS,
  SECTOR_EMB,
  SECTOR_GAM,
  SECTOR_IOT,
  SECTOR_ML,
  SECTOR_MOB,
  SECTOR_WEB,
} from '../../data';
import { find, head } from 'lodash';

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    paddingRight: '3rem',
    paddingLeft: '3rem',
    paddingBottom: '5rem',
  },
  loadingContainer: {
    height: 'calc(100% + 200px)',
  },
  rowCell: {
    cursor: 'pointer',
    fontSize: '1.1rem',
    padding: '0.6rem 0',
    color: theme.palette.custom.black,
  },
  cell: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    padding: '0.6rem 0',
    color: theme.palette.custom.gray5,
  },
  largeCell: { width: '50%' },
  mediumCell: { width: '16.6%' },
  sample: {
    padding: '0.3rem 0.8rem',
    marginLeft: '-0.8rem',
    borderRadius: 7,
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
  limited: {
    color: theme.palette.custom.blue2,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.palette.custom.blue2}`,
    padding: '0.25rem 1.1rem',
  },
  preview: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.custom.blue2,
  },
  bold: {
    fontWeight: 'bold',
  },
  emptyResults: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.3rem',
    color: theme.palette.custom.gray4,
    height: '8.1rem',
  },
  access: {
    marginLeft: '-1.8rem',
  },
  newData: {
    color: theme.palette.custom.blue2,
    padding: '0.2rem 0.5rem',
    border: `1px solid ${theme.palette.custom.blue2}`,
    marginLeft: '0.5rem',
    fontSize: '0.7rem',
    borderRadius: 5,
  },
  description: {
    margin: 0,
    color: theme.palette.custom.gray5,
    fontSize: '0.85rem',
    fontWeight: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    lineHeight: '1rem',
    maxHeight: '2rem',
  },
});

const sectors = [
  SECTOR_DESK,
  SECTOR_MOB,
  SECTOR_WEB,
  SECTOR_CLD,
  SECTOR_GAM,
  SECTOR_IOT,
  SECTOR_ML,
  SECTOR_ARVR,
  SECTOR_EMB,
  SECTOR_APP,
  SECTOR_CORE,
  SECTOR_DEVOPS,
];

const Dashboards = ({
  classes,
  userId,
  searchCriteria,
  textSearch,
  jwtToken,
  subscriptions,
  bookmarks,
  onSetUserSubscriptions,
  organisation,
  notifications,
}) => {
  const [allDashboards, setAllDashboards] = useState([]);
  const [orderBy, setOrderBy] = useState('access');
  const [order, setOrder] = useState('desc');
  const [loader, setLoader] = useState(false);
  const [, setError] = useState(null);

  useEffect(() => {
    const fetchDashboards = async () => {
      setLoader(true);

      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };

        const results = await Promise.all([
          API.get('users', `/users/${userId}/subscriptions`, params),
          API.get('users', `/users/${userId}/databoards`, params),
        ]);
        const [userSubscriptions, dashboards] = results;

        /////////////////////////////////////////////////////////////////////////////////////////
        // This is a hack and should be removed once this dashboard is no longer needed
        const filtered =
          organisation === 'SlashData'
            ? dashboards
            : reject(dashboards, (result) => includes(result.title, 'CD.Foundation'));
        /////////////////////////////////////////////////////////////////////////////////////////

        onSetUserSubscriptions(userSubscriptions);
        setAllDashboards(filtered);
        setLoader(false);
      } catch (e) {
        const message = get(e, ['response', 'data', 'message'], e.message);
        setError(message);
        setLoader(false);
      }
    };

    fetchDashboards();
  }, []);

  const handleOrderBy = useCallback(
    (field) => {
      if (field === orderBy) {
        setOrder(order === 'asc' ? 'desc' : 'asc');
      } else {
        setOrderBy(field);
        setOrder('asc');
      }
    },
    [orderBy, order],
  );

  const getResearchType = useCallback((hasAccess, freemium, researchType) => {
    if (hasAccess) {
      if (researchType === 'SYNDICATED') return 'Syndicated Research';
      if (researchType === 'CUSTOM') return 'Custom Research';
      if (researchType === 'FREE') return 'Free Dashboard';
      return 'Full';
    }
    if (freemium) return 'LIMITED';
    return 'PREVIEW';
  });

  const getArea = useCallback((area) => {
    return get(find(sectors, { TYPE: area }), 'LABEL', area);
  });

  if (loader) return <AppLoader show={loader} />;

  let dashboards = getFilteredDashboards(
    allDashboards,
    subscriptions,
    searchCriteria,
    textSearch,
    bookmarks,
  );

  if (isEmpty(dashboards) && !loader)
    return <div className={classes.emptyResults}>No dashboards found</div>;

  dashboards = sortDashboards(dashboards, subscriptions, orderBy, order);
  dashboards = getNewData(dashboards, subscriptions, notifications, userId);

  return (
    <div className={cn(classes.root, { [classes.loadingContainer]: loader })}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell
              className={classes.cell}
              padding="none"
              sortDirection={orderBy === 'title' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'title'}
                direction={orderBy === 'title' ? order : 'asc'}
                onClick={() => handleOrderBy('title')}
              >
                Title
              </TableSortLabel>
            </TableCell>
            <TableCell
              className={classes.cell}
              padding="none"
              sortDirection={orderBy === 'sector' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'sector'}
                direction={orderBy === 'sector' ? order : 'asc'}
                onClick={() => handleOrderBy('sector')}
              >
                Area
              </TableSortLabel>
            </TableCell>
            <TableCell
              className={classes.cell}
              padding="none"
              sortDirection={orderBy === 'sources' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'sources'}
                direction={orderBy === 'sources' ? order : 'asc'}
                onClick={() => handleOrderBy('sources')}
              >
                Survey Period
              </TableSortLabel>
            </TableCell>
            <TableCell
              className={classes.cell}
              padding="none"
              sortDirection={orderBy === 'datePublished' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'datePublished'}
                direction={orderBy === 'datePublished' ? order : 'asc'}
                onClick={() => handleOrderBy('datePublished')}
              >
                Date Published
              </TableSortLabel>
            </TableCell>
            <TableCell
              className={classes.cell}
              padding="none"
              sortDirection={orderBy === 'type' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'type'}
                direction={orderBy === 'type' ? order : 'asc'}
                onClick={() => handleOrderBy('type')}
              >
                Type
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(
            dashboards,
            (
              {
                _id,
                title,
                description,
                sectors,
                updatedAt,
                datePublished,
                sources,
                researchType,
                hasAccess,
                freemium,
                hasNewData,
              },
              index,
            ) => {
              const titleCellClasses = cn(classes.rowCell, classes.bold, classes.largeCell);
              const rowCellClasses = cn(classes.rowCell, classes.mediumCell);

              return (
                <TableRow key={`item-${index}`} className={classes.row}>
                  <TableCell padding="none" size="medium">
                    <BookmarkButton databoardId={_id} />
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`/dashboards/${_id}`)}
                    className={titleCellClasses}
                    padding="none"
                  >
                    {title}
                    {hasNewData && <span className={classes.newData}>New Data</span>}
                    {description && <p className={classes.description}>{description}</p>}
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`/dashboards/${_id}`)}
                    className={rowCellClasses}
                    padding="none"
                  >
                    {join(
                      map(sectors, (sector) => getArea(sector)),
                      ', ',
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`/dashboards/${_id}`)}
                    className={rowCellClasses}
                    padding="none"
                  >
                    {get(head(sources), 'text', '')}
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`/dashboards/${_id}`)}
                    className={rowCellClasses}
                    padding="none"
                  >
                    {datePublished
                      ? moment(datePublished).format('MMMM YYYY')
                      : moment(updatedAt).format('MMMM YYYY')}
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`/dashboards/${_id}`)}
                    className={cn(rowCellClasses)}
                    align="center"
                    padding="none"
                  >
                    <span
                      className={cn(
                        { [classes.access]: hasAccess },
                        { [classes.sample]: !hasAccess },
                        { [classes.limited]: !hasAccess && freemium },
                        { [classes.preview]: !hasAccess && !freemium },
                      )}
                    >
                      {getResearchType(hasAccess, freemium, researchType)}
                    </span>
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
  subscriptions: get(state, ['user', 'subscriptions'], []),
  userId: get(state, ['user', 'userId'], []),
  bookmarks: get(state, ['user', 'bookmarks'], []),
  organisation: get(state, ['user', 'organisation'], ''),
  notifications: get(state, ['user', 'notifications'], []),
});

const mapDispatchToProps = (dispatch) => ({
  onSetUserSubscriptions: (subscriptions) => dispatch(setUserSubscriptions(subscriptions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboards));
