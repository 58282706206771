import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core';

import ITheme from '../../../theme/@types/Theme';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      fontFamily: 'Nunito Sans Black',
      fontSize: 22,
      color: theme.palette.custom.blue2,
      lineHeight: 1.2,
      height: 30,
    },
  }),
);

const Title: React.FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default Title;
