import React, { useRef, forwardRef } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

import useInViewport from '../hooks/useInViewport';

const noop = () => {};

function handleViewport(TargetComponent) {
  const ForwardedRefComponent = forwardRef((props, ref) => {
    const refProps = { forwardedRef: ref };

    return <TargetComponent {...props} {...refProps} />;
  });

  const InViewport = ({ onEnterViewport = noop, onLeaveViewport = noop, ...restProps }) => {
    const node = useRef();
    const { inViewport } = useInViewport(node, {
      onEnterViewport,
      onLeaveViewport,
    });

    return <ForwardedRefComponent {...restProps} inViewport={inViewport} ref={node} />;
  };

  return hoistNonReactStatic(InViewport, ForwardedRefComponent);
}

export default handleViewport;
