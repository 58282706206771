import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import flattenDeep from 'lodash/flattenDeep';
import filter from 'lodash/filter';
import map from 'lodash/map';
import concat from 'lodash/concat';
import find from 'lodash/find';
import get from 'lodash/get';

export const reduceFilters = (filters) =>
  reduce(
    filters,
    (filterAcc, { _id, defaultValues }) => ({
      ...filterAcc,
      [_id]: defaultValues,
    }),
    {},
  );

const extractById = (databoard, appliedFilters) => {
  const filtersArray = flattenDeep(
    concat(
      map(databoard.filterGroups, 'values', []),
      map(databoard.sections, 'filters'),
      map(databoard.sections, (section) => map(section.cards, 'filters')),
    ),
  );

  const allFiltersWithSyncedDefaultValues = map(filtersArray, (filterModel) => {
    const parent = get(filterModel, 'parent');
    if (isEmpty(parent)) return filterModel;

    const parentFilter = find(filtersArray, { name: parent });
    const parentFilterId = get(parentFilter, '_id', '');
    const parentDefaultValues = get(parentFilter, 'defaultValues', []);
    const parentQueryValues = get(appliedFilters, parentFilterId, []);
    const defaultValues = isEmpty(parentQueryValues) ? parentDefaultValues : parentQueryValues;

    return {
      ...filterModel,
      defaultValues,
    };
  });

  const filtersWithDefaultValues = reduceFilters(
    filter(allFiltersWithSyncedDefaultValues, (filterModel) => !isEmpty(filterModel.defaultValues)),
  );

  return { ...filtersWithDefaultValues, ...appliedFilters };
};

export default extractById;
