import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import indexOf from 'lodash/indexOf';

const getValueLabels = (state, filterId, selectedValues) => {
  const labels = get(state, ['filtersIndex', 'byId', filterId, 'labels'], []);
  const selectedLabels = map(
    filter(
      labels,
      ({ name, text }) =>
        indexOf(selectedValues, name) !== -1 || indexOf(selectedValues, text) !== -1,
    ),
    'text',
  );

  return selectedLabels;
};

export default getValueLabels;
