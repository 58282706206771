import React from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    fontFamily: 'Nunito Sans Black',
    fontSize: 28,
    color: theme.palette.custom.blue2,
    textAlign: 'center',
    maxWidth: 1050,
    width: '55%',
    paddingTop: '1rem',
    paddingBottom: '1.6rem',
    lineHeight: '1.5em',
  },
});

const Title = ({ classes, children }) => <div className={classes.root}>{children}</div>;

export default withStyles(styles)(Title);
