import React, { useLayoutEffect, useRef } from 'react';

interface Props {
  scrollHere: boolean;
}

const ScrollableElement = ({ scrollHere }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (scrollHere && ref.current !== null) {
      window.scrollTo(0, ref.current.offsetTop - 100);
    }
  });

  return <div ref={ref} />;
};

export default ScrollableElement;
