import getByDashboard from './getByDashboard';
import getBySector from './getBySector';
import getByProduct from './getByProduct';

const getFilteredDashboards = (dashboards, textSearch, searchCriteria) => {
  let dashboardsSubset = getBySector(dashboards, searchCriteria);
  dashboardsSubset = getByProduct(dashboardsSubset, searchCriteria);
  const filteredDashboards = getByDashboard(dashboardsSubset, textSearch);
  return filteredDashboards;
};

export default getFilteredDashboards;
