import React from 'react';

import InputBase from '@material-ui/core/InputBase';
//import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';

const styles = (theme) => ({
  search: {
    display: 'flex',
    alignItems: 'center',
    width: '40.6rem',
    height: '2.6rem',
    border: 'none',
  },
  inputRoot: {
    fontSize: '1.1rem',
    color: theme.palette.custom.black,
    width: '100%',
    '& input::placeholder': {
      color: theme.palette.custom.gray4,
      opacity: 1,
    },
  },
  clearIcon: {
    width: '1.3rem',
    height: '1.3rem',
    marginRight: '1rem',
    fill: theme.palette.custom.gray4,
    cursor: 'pointer',
  },
  iconContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

const TextSearch = ({ classes, value, placeholder, onChange }) => {
  return (
    <div className={classes.search}>
      <InputBase
        placeholder={placeholder}
        classes={{ root: classes.inputRoot, input: classes.inputInput }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        autoFocus
      />
      {!isEmpty(value) && (
        <div className={classes.iconContainer}>
          <ClearIcon className={classes.clearIcon} onClick={() => onChange('')} />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(TextSearch);
