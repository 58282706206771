import head from 'lodash/head';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const getHeaders = (tableData) => {
  if (isEmpty(tableData)) {
    console.log('empty tableData');
    return '';
  }

  return map(get(head(tableData.values), 'values'), 'text');
};

export default getHeaders;
