import React, { useState, useCallback, useEffect } from 'react';

import { API } from 'aws-amplify';
import { Field, reduxForm } from 'redux-form';
import * as Yup from 'yup';
import { compose } from 'redux';
import { connect } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import CircularProgress from '@material-ui/core/CircularProgress';

import TextField from '../TextField';
import SubmitButton from '../SubmitButton';
// import TextButton from '../TextButton';
import reduxFormValidator from '../../../utils/reduxFormValidator';
import { Portlet, PortletHeader, PortletLabel, PortletContent, PortletFooter } from '../../Portlet';
import { SnackBar } from '../../UI';
import DangerButton from '../DangerButton';
import ConfirmDialog from '../ConfirmationDialog';

const UPDATE_MODE = 'UPDATE_MODE';
const CREATE_MODE = 'CREATE_MODE';

const UserForm = ({ _id, jwtToken, handleSubmit, onSuccess = (model) => model /* onDelete */ }) => {
  const [mode, setMode] = useState('');
  const [formTitle, setFormTitle] = useState('');
  const [submitButtonTitle, setSubmitButtonTitle] = useState('');
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    setMode(isEmpty(_id) ? CREATE_MODE : UPDATE_MODE);
    setFormTitle(isEmpty(_id) ? 'New User' : 'Edit User');
    setSubmitButtonTitle(isEmpty(_id) ? 'CREATE' : 'UPDATE');
  }, [_id]);

  const updateUser = useCallback(
    async ({ firstName, lastName, role }) => {
      setLoader(true);
      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
          body: { firstName, lastName, role },
        };
        const result = await API.put('users', `/users/${_id}`, params);
        onSuccess(result);
      } catch (e) {
        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );
        setError(message);
      } finally {
        setLoader(false);
      }
    },
    [_id],
  );

  // const saveUsers = useCallback(async ({ email, firstName, lastName, role }) => {
  //   setLoader(true);
  //   try {
  //     const params = {
  //       headers: {
  //         Authorization: `Bearer ${jwtToken}`,
  //       },
  //       body: { email, firstName, lastName, role },
  //     };
  //     const result = await API.post('users', `/users`, params);
  //     onSuccess(result);
  //   } catch (error) {
  //     console.log('error', error);
  //   } finally {
  //     setLoader(false);
  //   }
  // }, []);

  const saveOrUpdate = useCallback(
    (props) => {
      if (mode === CREATE_MODE) {
        // saveUsers(props);
      } else if (mode === UPDATE_MODE) {
        updateUser(props);
      }
    },
    [mode],
  );

  const onDeleteConfirm = useCallback(async () => {
    setConfirmDialogOpen(false);
    setLoader(true);
    try {
      const params = {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      };
      const result = await API.del('users', `/users/${_id}`, params);
      onSuccess(result);
    } catch (e) {
      const message = get(
        e,
        ['response', 'data', 'message'],
        'You do not have permission to access',
      );
      setError(message);
    } finally {
      setLoader(false);
    }
  }, [_id]);

  const deleteUser = () => {
    setConfirmDialogOpen(true);
  };

  const onDeleteCancel = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <Portlet>
      <PortletHeader>
        <PortletLabel title={formTitle} />
      </PortletHeader>
      <form onSubmit={handleSubmit(saveOrUpdate)}>
        <PortletContent>
          <Field name="email" label="Email" component={TextField} disabled={mode === UPDATE_MODE} />
          <Field name="firstName" label="First Name" component={TextField} />
          <Field name="lastName" label="Last Name" component={TextField} />
          <Field name="role" label="Role" component={TextField} />
        </PortletContent>
        <PortletFooter>
          {!loader && <SubmitButton label={submitButtonTitle} />}
          {!loader && !isEmpty(_id) && (
            <DangerButton label={'DELETE ACCOUNT'} onClick={deleteUser} />
          )}
          {loader && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )}
        </PortletFooter>
      </form>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={onDeleteCancel}
        title={'Are you sure you want to remove this account ?'}
        description={
          'This action is irreversible. All data related to this account data will be deleted.'
        }
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteCancel}
      />
      <SnackBar open={Boolean(error)} message={error} severity="warning" />
    </Portlet>
  );
};

const schema = Yup.object().shape({
  email: Yup.string().trim().required(),
  firstName: Yup.string().trim().required(),
  lastName: Yup.string().trim().required(),
  role: Yup.string().trim().required(),
});

const mapStateToProps = (state, { _id, email, firstName, lastName, role }) => ({
  initialValues: { firstName, lastName, role, email },
  userId: _id,
  jwtToken: get(state, ['user', 'jwtToken'], ''),
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'userForm',
    enableReinitialize: true,
    asyncValidate: reduxFormValidator(schema),
  }),
)(UserForm);
