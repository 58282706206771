import React from 'react';

import map from 'lodash/map';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

import BarChartEngine from '../RechartBarChartEngine';
import { Container, ChartWrapper } from '../Container';
import config from './config';
import { LOW_SAMPLE_SIZE } from '../../../utils/hasLowSampleSize';

const SAMPLE_OFFSET = 104;

const ColumnChartStacked = ({ data, samples, axisTitle, series, id, view, configuration }) => {
  const { rootProps } = config;

  if (view === 'dialog') {
    rootProps.margin = {
      top: 26,
      right: 26,
      left: 0,
      bottom: 26,
    };
  }

  const mergedConfig = {
    ...config,
    series: filter(
      map(config.series, (item, index) => ({
        ...item,
        dataKey: get(series, index, ''),
      })),
      ({ dataKey }) => !isEmpty(dataKey),
    ),
    xAxisProps: {
      ...config.xAxisProps,
      ticksProps: {
        ...config.xAxisProps.ticksProps,
        samples,
        sampleOffset: SAMPLE_OFFSET,
      },
    },
    yAxisProps: {
      ...config.yAxisProps,
      label: { ...config.yAxisProps.label, value: axisTitle },
    },
    chartThreshold: get(find(configuration, { key: 'threshold' }), 'value', LOW_SAMPLE_SIZE),
  };

  return (
    <Container>
      <ChartWrapper fullHeight>
        <BarChartEngine id={id} {...mergedConfig} data={data} view={view} />
      </ChartWrapper>
    </Container>
  );
};

export default ColumnChartStacked;
