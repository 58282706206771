import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';
import { API } from 'aws-amplify';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';

import { setFilterLabels } from '../../../state/filtersIndex/actions';
import {
  normalizeFilterValues,
  extractFilterValues,
  extractFilterDefaultAdapter,
} from '../../../utils/filterValues';
import FilterOptions from '../FilterOptions';
import hasAccess from '../ContactUs/utils/hasAccess';
import { find, map, some } from 'lodash';

const styles = (theme) => ({
  root: {
    boxShadow: 'none',
    marginBottom: 0,
    '&:before': {
      backgroundColor: 'transparent !important',
    },
  },
  label: {
    fontSize: '1.1rem',
    color: theme.palette.custom.black,
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: '0.3rem',
  },
  counter: {
    borderRadius: '50%',
    backgroundColor: theme.palette.custom.purple,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
  details: {
    backgroundColor: theme.palette.custom.gray2,
    paddingLeft: '3.3rem',
  },
  icon: { fill: theme.palette.custom.gray4 },
  dividerContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  divider: {
    width: '18.2rem',
    backgroundColor: theme.palette.custom.gray3,
  },
  bold: {
    fontWeight: 'bold',
  },
  disabled: {
    color: '#bcc6d2',
  },
  dot: {
    width: '0.5rem',
    height: '0.5rem',
    backgroundColor: theme.palette.custom.blue2,
    borderRadius: 20,
    marginLeft: '0.2rem',
  },
});

const AccordionFilter = ({
  _id,
  classes,
  query,
  table,
  reject, // Which labels to reject
  filter, // Which labels to filter
  adapter,
  input,
  label,
  expanded,
  jwtToken,
  hide,
  onClick,
  filterGroups,
  hasAccess,
  onSetFilterLabels,
  newFilter,
  version,
  userId,
  databoardId,
  allFilters,
}) => {
  const [loader, setLoader] = useState(false);
  const [, setError] = useState(null);

  useEffect(() => {
    const fetchValues = async () => {
      setLoader(true);
      const params = {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      };

      try {
        if (version === 1) {
          const data = await API.get(
            'dashboards',
            `/dataqueries/name/${query}/filters/name/${table}`,
            params,
          );

          const labelArray = extractFilterValues({ adapter, data });
          const labels = normalizeFilterValues({ filter, reject, labels: labelArray });
          const defaultAdapter = extractFilterDefaultAdapter({ adapter, data });
          const allOptions = get(data, 'rows', []);

          onSetFilterLabels(_id, labels, defaultAdapter, allOptions);
        } else {
          const sourceLabelArray = get(allFilters, ['sources', 'labels'], []);
          const filterObj = get(allFilters, _id, {});
          params.body = {
            version: 2,
            query: {
              source: map(sourceLabelArray, (item) => get(item, 'id', 0)),
              filterQuestion: query,
              rejectedLabels: get(filterObj, 'reject', []),
              renamedLabels: get(filterObj, 'replaceLabels', []),
              displayLabels: get(filterObj, 'filter', []),
            },
          };
          const data = await API.post(
            'dashboards',
            `/users/${userId}/databoards/${databoardId}/engine`,
            params,
          );
          const allOptions = get(data, 'rows', []);
          onSetFilterLabels(_id, allOptions);
        }
      } catch (e) {
        setError(e);
      } finally {
        setLoader(false);
      }
    };

    if (
      (!isEmpty(query) && !isEmpty(table) && version === 1) ||
      (version === 2 && !isEmpty(query))
    ) {
      fetchValues();
    }
  }, [query, table]);

  const counter = get(input, ['value', 'length'], 0);

  const disabled = () => {
    // If the user has subscription or the filter group is Free then it is not disabled
    if (hasAccess) return false;
    const notDisabled = find(filterGroups, (filterGroup) => {
      return some(filterGroup.values, { _id: input.name }) && filterGroup.license_tier === 'free';
    });
    return !notDisabled;
  };

  if (hide) return null;

  return (
    <>
      <ExpansionPanel
        expanded={expanded}
        onChange={() => onClick(input.name)}
        classes={{ root: classes.root }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.icon} />}>
          <div className={cn(classes.badge, { [classes.counter]: counter > 0 })}>
            {counter > 0 ? counter : ''}
          </div>
          <Typography
            className={cn(
              classes.label,
              { [classes.bold]: expanded },
              { [classes.disabled]: disabled() },
            )}
          >
            {label}
            {newFilter && <div className={classes.dot}></div>}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.details }}>
          {loader && <div>fetching filters...</div>}
          {!loader && (
            <FilterOptions disabled={disabled()} _id={_id} input={input} forceClear={!expanded} />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <div className={classes.dividerContainer}>
        <Divider className={classes.divider} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  formValues: get(state, ['filtersData', 'values']),
  jwtToken: get(state, ['user', 'jwtToken']),
  filterGroups: get(state, ['databoard', 'filterGroups'], []),
  hasAccess: hasAccess(state),
  version: get(state, ['databoard', 'version'], 1),
  userId: get(state, ['user', 'userId']),
  databoardId: get(state, ['databoard', 'databoardId']),
  allFilters: get(state, ['filtersIndex', 'byId'], {}),
});

const mapDispatchToProps = (dispatch) => ({
  onSetFilterLabels: (id, labels, defaultAdapter, allOptions) =>
    dispatch(setFilterLabels(id, labels, defaultAdapter, allOptions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccordionFilter));
