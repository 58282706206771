import differenceWith from 'lodash/differenceWith';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import getBookmarked from './getBookmarked';
import {
  TYPE_ALL,
  TYPE_BRIEFINGS,
  TYPE_CUSTOM,
  TYPE_FAVOURITE,
  TYPE_FREE,
  TYPE_PREVIEW,
  TYPE_SYNDICATED,
} from '../../../data';
import getBySector from './getBySector';
import getByProduct from './getByProduct';
import getSubscribed from './getSubscribed';
import { filter } from 'lodash';
import getByTopic from './getByTopic';

const getFilteredReports = (reports, subscriptions, searchCriteria, textSearch, bookmarks) => {
  const category = get(searchCriteria, 'category');
  let filteredReports = [];
  switch (category) {
    case TYPE_FAVOURITE.TYPE:
      filteredReports = getBookmarked(reports, bookmarks);
      break;
    case TYPE_CUSTOM.TYPE:
      filteredReports = getSubscribed(reports, subscriptions);
      filteredReports = filter(
        filteredReports,
        (report) => get(report, 'type') === TYPE_CUSTOM.TYPE,
      );
      break;
    case TYPE_SYNDICATED.TYPE:
      filteredReports = getSubscribed(reports, subscriptions);
      filteredReports = filter(
        filteredReports,
        (report) => get(report, 'type') === TYPE_SYNDICATED.TYPE,
      );
      break;
    case TYPE_FREE.TYPE:
      filteredReports = filter(reports, (report) => get(report, 'type') === TYPE_FREE.TYPE);
      break;
    // Getting the difference between all reports and subscribed ones
    case TYPE_PREVIEW.TYPE:
      filteredReports = differenceWith(reports, getSubscribed(reports, subscriptions), isEqual);
      filteredReports = filter(filteredReports, (report) => get(report, 'type') !== TYPE_FREE.TYPE);
      break;
    case TYPE_BRIEFINGS.TYPE:
      filteredReports = reports;
      break;
    case TYPE_ALL.TYPE:
      filteredReports = reports;
      break;
    default:
      throw new Error(`Unknown type: ${category}`);
  }

  // Filter out by sector, product type or topic
  filteredReports = getBySector(filteredReports, searchCriteria);
  filteredReports = getByProduct(filteredReports, searchCriteria);
  filteredReports = getByTopic(filteredReports, searchCriteria);

  return filteredReports;
};

export default getFilteredReports;
