import { filter, flatten, isEmpty } from 'lodash';
import map from 'lodash/map';

export const LOW_SAMPLE_SIZE = 50;

const hasLowSampleSize = (data, chartThreshold = LOW_SAMPLE_SIZE, samplesLabel) => {
  // Only running the check if there is data to operate on
  if (!isEmpty(data) && !isEmpty(samplesLabel)) {
    // Flatten the data
    const flattenedData = flatten(data);
    // Filtering on the sample size - checking the sample label
    const samples = filter(flattenedData, { name: samplesLabel });
    // Getting the datapoints from the samples
    const dataPoints = map(samples, 'values');
    // Flattening the data points
    const flattenedDataPoints = flatten(dataPoints);
    // Getting the values from the data points
    const innerDataPoints = map(flattenedDataPoints, 'values');
    // Flattening the data points
    const flattenedInnerDataPoints = flatten(innerDataPoints);
    // Getting the sample sizes
    const sampleSizes = map(flattenedInnerDataPoints, 'value');
    // Returning true if any of the samples has a size less than the threshold but greater than 0
    // This 0 removal is because the bars with 0 value don't get displayed
    return sampleSizes.some((sampleSize) => {
      return sampleSize > 0 && sampleSize < +chartThreshold;
    });
  }
  return false;
};

export default hasLowSampleSize;
