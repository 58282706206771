import React from 'react';

import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as ExpandIcon } from './assets/expand.svg';
import { Tooltip } from '@material-ui/core';

const ExpandButton = ({ onClick }) => (
  <Tooltip title="Expand">
    <IconButton onClick={onClick}>
      <ExpandIcon />
    </IconButton>
  </Tooltip>
);

export default ExpandButton;
