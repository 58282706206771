import React from 'react';
import cn from 'classnames';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: { flex: '0 0 auto' },
  paper: {
    paddingTop: '5.8rem',
    backgroundColor: theme.palette.custom.blue2,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: '1 0 auto',
    zIndex: theme.zIndex.sidebar,
    WebkitOverflowScrolling: 'touch', // Add iOS momentum scrolling.
    // temporary style
    position: 'fixed',
    top: 0,
    // We disable the focus ring for mouse, touch and keyboard users.
    // At some point, it would be better to keep it for keyboard users.
    // :focus-ring CSS pseudo-class will help.
    outline: 'none',
    left: 0,
    right: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    width: '3.75rem',
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.custom.blue4,
    },
  },
});

const Sidebar = ({ classes, className }) => {
  return (
    <div className={cn(classes.root, className)}>
      <Paper className={classes.paper} square>
        <div className={classes.container} />
      </Paper>
    </div>
  );
};

export default withStyles(styles)(Sidebar);
