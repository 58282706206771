import map from 'lodash/map';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';

import config from './config';
import getAxisDomain from '../helpers/getAxisDomain';
import getAxisTickFormatter from '../helpers/getAxisTickFormatter';
import getFormattedSeries from '../helpers/getFormattedSeries';
import getTooltipFormatter from '../helpers/getTooltipFormatter';
import { LOW_SAMPLE_SIZE } from '../../../utils/hasLowSampleSize';

export const orderSeries = (series) => orderBy(series, [], ['asc']);
export const filterOutNullSeries = (series) => filter(series, ({ dataKey }) => !isEmpty(dataKey));
export const renameSeries = (series) =>
  map(config.series, (item, index) => ({
    ...item,
    dataKey: get(series, index, null),
  }));

export const normalizeSeries = (series) => filterOutNullSeries(renameSeries(orderSeries(series)));

export const overwriteYAxisProps = (domain, axisTitle = '', formatter) => ({
  ...config.yAxisProps,
  domain: getAxisDomain(domain, formatter),
  label: { ...config.yAxisProps.label, value: axisTitle },
  tickFormatter: getAxisTickFormatter(formatter),
});

const generateConfig = ({ series, domain, axisTitle, formatter, configuration }) => ({
  ...config,
  tooltipProps: {
    ...get(config, 'tooltipProps', {}),
    formatter: getTooltipFormatter(formatter),
  },
  series: getFormattedSeries(formatter, normalizeSeries(series)),
  yAxisProps: overwriteYAxisProps(domain, axisTitle, formatter),
  chartThreshold: get(find(configuration, { key: 'threshold' }), 'value', LOW_SAMPLE_SIZE),
});

export default generateConfig;
