import React, { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';

import { API } from 'aws-amplify';
import { connect } from 'react-redux';
import moment from 'moment';

import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import slice from 'lodash/slice';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

import AppLoader from '../../../../../components/AppLoader';
// import BookmarkButton from './components/BookmarkButton';
import getFilteredSubscriptions from './utils/getFilteredSubscriptions';
import sortSubscriptions from './utils/sortSubscriptions';
import { getDateDiff } from './utils/dateUtils';
import toggleSubscription from './utils/toggleSubscription';
import isSelected from './utils/isSelected';
import { SnackBar } from '../../../../../components/UI';
import { MAX_DATE } from '../../../../../components/Admin/SubscriptionForm/SubscriptionForm';

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    paddingRight: '9.8rem',
  },
  loadingContainer: {
    height: 'calc(100% + 200px)',
  },
  row: { height: '4.9rem' },
  rowCell: {
    cursor: 'pointer',
    fontSize: '1.1rem',
    color: theme.palette.custom.black,
  },
  cell: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    color: theme.palette.custom.gray5,
  },
  largeCell: { width: '50%' },
  mediumCell: { width: '12.5%' },
  disabled: {
    color: theme.palette.custom.gray4,
  },
  bold: {
    fontWeight: 'bold',
  },
  emptyResults: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.4rem',
    color: theme.palette.custom.gray4,
    height: '8.1rem',
  },
  checkbox: {
    padding: 0,
    borderRadius: 0,
    '& svg': {
      width: '1.3rem',
      height: '1.3rem',
      marginRight: '1.3rem',
      boxSizing: 'border-box',
    },
  },
  type: {
    padding: '0.3rem 0.8rem',
    marginLeft: '-1.5rem',
    borderRadius: 3,
    fontSize: '0.8rem',
    fontWeight: 'bold',
    // color: '#FFFFFF',
    // backgroundColor: theme.palette.custom.blue2,
  },
});

const Subscriptions = ({
  classes,
  forceUpdate,
  textSearch,
  searchCriteria,
  jwtToken,
  onSelect,
  onClick,
}) => {
  const [headers, setHeaders] = useState([]);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState('desc');
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setSelectedSubscriptions([]);
  }, [searchCriteria]);

  useEffect(() => {
    setHeaders([
      {
        label: 'Company',
        orderByKey: 'organisation',
        objectKey: 'organisation.name',
        formatter: (data, objectKey) => get(data, objectKey),
        classes: cn(classes.rowCell, classes.mediumCell),
      },
      {
        label: 'Resource',
        orderByKey: 'resource',
        objectKey: ['databoard.title', 'dataanalystaccess.title', 'report.title', 'briefing.title'],
        formatter: (data, objectKey) =>
          data.databoard
            ? get(data, objectKey[0])
            : data.dataanalystaccess
            ? get(data, objectKey[1])
            : data.report
            ? get(data, objectKey[2])
            : data.briefing
            ? get(data, objectKey[3])
            : null,
        classes: cn(classes.rowCell, classes.largeCell),
      },
      {
        label: 'Started',
        orderByKey: 'startDate',
        objectKey: 'startDate',
        formatter: (data, objectKey) => moment(get(data, objectKey)).format('DD/MM/YYYY'),
        classes: cn(classes.rowCell, classes.mediumCell),
      },
      {
        label: 'Expiring',
        orderByKey: 'expirationDate',
        objectKey: 'expirationDate',
        // Checking for indefinite MAX_DATE
        formatter: (data, objectKey) =>
          new Date(get(data, objectKey)).getFullYear() === MAX_DATE.getFullYear()
            ? 'Indefinite'
            : moment(get(data, objectKey)).format('DD/MM/YYYY'),
        classes: cn(classes.rowCell, classes.mediumCell),
      },
      {
        label: 'Time left',
        orderByKey: 'timeLeft',
        objectKey: ['startDate', 'expirationDate'],
        // Checking for indefinite MAX_DATE
        formatter: (data, objectKey) =>
          new Date(get(data, objectKey[1])).getFullYear() === MAX_DATE.getFullYear()
            ? 'Indefinite'
            : getDateDiff(get(data, objectKey[0]), get(data, objectKey[1])),
        classes: cn(classes.rowCell, classes.mediumCell),
      },
      {
        label: 'Type',
        orderByKey: 'type',
        formatter: (data) =>
          data.databoard
            ? 'Dashboard'
            : data.dataanalystaccess
            ? 'DAA'
            : data.report
            ? 'Report'
            : data.briefing
            ? 'Briefing'
            : 'N/A',
        classes: cn(classes.rowCell, classes.largeCell, classes.type),
      },
    ]);
  }, []);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setLoader(true);

      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };

        const results = await API.get('users', `/subscriptions`, params);

        setAllSubscriptions(results);
        setLoader(false);
      } catch (e) {
        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );
        setError(message);
        setLoader(false);
      }
    };

    fetchSubscriptions();
  }, []);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setLoader(true);

      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };

        const results = await API.get('users', `/subscriptions`, params);

        setAllSubscriptions(results);
        setLoader(false);
      } catch (e) {
        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );
        setError(message);
        setLoader(false);
      }
    };

    if (forceUpdate) {
      fetchSubscriptions();
      setSelectedSubscriptions([]);
    }
  }, [forceUpdate]);

  useEffect(() => {
    onSelect(selectedSubscriptions);
  }, [selectedSubscriptions]);

  const handleOrderBy = useCallback(
    (field) => {
      if (field === orderBy) {
        setOrder(order === 'asc' ? 'desc' : 'asc');
      } else {
        setOrderBy(field);
        setOrder('asc');
      }
    },
    [orderBy, order],
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loader) return <AppLoader show={loader} />;

  let subscriptions = getFilteredSubscriptions(allSubscriptions, textSearch, searchCriteria);

  if (isEmpty(subscriptions) && !loader)
    return (
      <>
        <div className={classes.emptyResults}>No subscriptions found</div>
        <SnackBar open={Boolean(error)} message={error} severity="warning" />
      </>
    );

  subscriptions = sortSubscriptions(subscriptions, orderBy, order);
  const slicedSubscriptions = slice(
    subscriptions,
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedSubscriptions(map(slicedSubscriptions, '_id'));
    } else {
      setSelectedSubscriptions([]);
    }
  };
  const onSelectItemClick = (subscription) => {
    setSelectedSubscriptions(toggleSubscription(subscription, selectedSubscriptions));
  };

  return (
    <div className={cn(classes.root, { [classes.loadingContainer]: loader })}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                className={classes.checkbox}
                indeterminate={
                  selectedSubscriptions.length > 0 &&
                  selectedSubscriptions.length < slicedSubscriptions.length
                }
                checked={
                  slicedSubscriptions.length > 0 &&
                  selectedSubscriptions.length === slicedSubscriptions.length
                }
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all subscriptions' }}
              />
            </TableCell>

            {map(headers, ({ label, orderByKey }) => (
              <TableCell
                key={orderByKey}
                className={classes.cell}
                padding="none"
                sortDirection={orderBy === orderByKey ? order : false}
              >
                <TableSortLabel
                  active={orderBy === orderByKey}
                  direction={orderBy === orderByKey ? order : 'asc'}
                  onClick={() => handleOrderBy(orderByKey)}
                >
                  {label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(slicedSubscriptions, (subscription, index) => {
            return (
              <TableRow key={`item-${index}`} className={classes.row}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isSelected(subscription, selectedSubscriptions)}
                    onClick={() => onSelectItemClick(subscription)}
                    className={classes.checkbox}
                  />
                </TableCell>
                {map(headers, ({ classes: cellClasses, objectKey, formatter }) => (
                  <TableCell
                    key={objectKey}
                    className={cellClasses}
                    padding="none"
                    onClick={() => onClick(subscription)}
                  >
                    {formatter(subscription, objectKey)}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {subscriptions.length > 5 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={subscriptions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      <SnackBar open={Boolean(error)} message={error} severity="warning" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
  userId: get(state, ['user', 'userId'], []),
});

export default connect(mapStateToProps)(withStyles(styles)(Subscriptions));
