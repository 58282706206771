import React, { useEffect, useState } from 'react';

import { withStyles } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';

import content from './content.md';
import Heading from '../../components/Markdown/renderers/Heading';
import Footer from '../../components/Footer';

const styles = (theme) => ({
  root: {
    height: '100%',
    padding: '2rem',
    position: 'relative',
  },
  // If this styles are permanent and used from TnC as well, then move it components/markdown
  markdown: {
    '& li::marker': { color: theme.palette.custom.blue1 },
    '& a': { textDecoration: 'none', color: theme.palette.custom.blue1 },
  },
});

const CookiePolicy = ({ classes }) => {
  const [policyText, setPolicyText] = useState('');

  useEffect(() => {
    fetch(content)
      .then((response) => response.text())
      .then((text) => {
        setPolicyText(text);
      });
  }, []);

  const renderers = {
    heading: Heading,
  };

  return (
    <div className={classes.root}>
      <ReactMarkdown
        source={policyText}
        renderers={renderers}
        linkTarget="_blank"
        className={classes.markdown}
      />
      <Footer />
    </div>
  );
};

export default withStyles(styles)(CookiePolicy);
