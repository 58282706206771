import omit from 'lodash/omit';
import get from 'lodash/get';

import {
  SET_ACTIVE_BLOCK,
  UPDATE_FILTER_DATA,
  RESET_GLOBAL_FILTERS,
  RESET_FILTERS,
  INITIALIZE_FILTER_DATA,
  DATABOARD_SOURCES_FILTER,
  REMOVE_FILTER,
} from './types';
import {
  extractById,
  extractRootFilters,
  extractSectionFilters,
  extractByParent,
  syncValues,
} from './helpers';
import getResetGlobalFilterState from './helpers/getResetGlobalFilterState';

export const initialState = {
  // All filters values by id
  byId: {},
  // Filters per section
  bySection: {},
  // Root filters
  root: [],
  // Filters per parent
  byParent: {},
  showGlobalFilters: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_BLOCK:
      return {
        ...state,
        showGlobalFilters: action.payload,
      };
    case INITIALIZE_FILTER_DATA: {
      const databoard = get(action, ['payload', 'databoard']);
      const appliedFilters = get(action, ['payload', 'appliedFilters']);
      const sources = get(databoard, 'sources');
      const root = extractRootFilters(databoard);
      const bySection = extractSectionFilters(databoard);
      const byParent = extractByParent(databoard);
      const byId = extractById(databoard, appliedFilters);

      return {
        ...state,
        byId: {
          [DATABOARD_SOURCES_FILTER]: sources,
          ...byId,
        },
        root,
        bySection,
        byParent,
      };
    }
    case UPDATE_FILTER_DATA: {
      return {
        ...state,
        byId: syncValues(state, action),
      };
    }
    case REMOVE_FILTER: {
      const filterId = get(action, ['payload', 'filterId']);
      const byId = omit(state.byId, filterId);

      return {
        ...state,
        byId,
      };
    }
    case RESET_GLOBAL_FILTERS: {
      const sources = get(action, ['payload', 'sources']);
      const hiddenGlobalFilters = get(action, ['payload', 'hiddenGlobalFilters']);
      const byId = getResetGlobalFilterState(state, hiddenGlobalFilters);

      return {
        ...state,
        byId: {
          sources,
          ...byId,
        },
      };
    }
    case RESET_FILTERS:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
