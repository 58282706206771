import reduce from 'lodash/reduce';
import get from 'lodash/get';
import map from 'lodash/map';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';

const getValidAppliedFilters = (
  filterIds,
  filterValues,
  appliedFilters,
  appliedSources,
  version,
) => {
  const initialObject = isEmpty(appliedSources) ? {} : { sources: appliedSources };

  return reduce(
    filterValues,
    (result, curr, index) => {
      const filterId = get(filterIds, index);
      const values =
        version === 2 ? map(get(curr, 'values'), 'text') : map(get(curr, 'values'), 'name');
      const appliedValues = get(appliedFilters, [filterId, 'values']);
      const intersectionOfValues = intersection(appliedValues, values);

      if (isEmpty(intersectionOfValues)) return result;

      return {
        ...result,
        [filterId]: intersection(appliedValues, values),
      };
    },
    initialObject,
  );
};

export default getValidAppliedFilters;
