import React from 'react';

import DonutChart from '../Donut';
import PieChart from '../PieChart';
import BarChart from '../BarChart';
import BarChartMulti from '../BarChartMulti';
import BarChartStacked from '../BarChartStacked';
import BarChartStackedMulti from '../BarChartStackedMulti';
import BarChartScore from '../BarChartScore';
import BarChartSideBySide from '../BarChartSideBySide';
import ColumnChart from '../ColumnChart';
import ColumnChartMulti from '../ColumnChartMulti';
import ColumnChartStacked from '../ColumnChartStacked';
import ColumnChartStackedLocalSamples from '../ColumnChartStackedLocalSamples';
import ColumnChartLine from '../ColumnChartLine';
import TableChart from '../TableChart';
import LineChart from '../LineChart';
import Heatmap from '../Heatmap';

import {
  TYPE_BAR_CHART,
  TYPE_BAR_CHART_MULTI,
  TYPE_BAR_CHART_STACKED,
  TYPE_BAR_CHART_STACKED_MULTI,
  TYPE_BAR_CHART_SCORE,
  TYPE_BAR_CHART_SIDE_BY_SIDE,
  TYPE_COLUMN_CHART,
  TYPE_COLUMN_CHART_MULTI,
  TYPE_COLUMN_CHART_STACKED,
  TYPE_COLUMN_CHART_STACKED_LOCAL,
  TYPE_COLUMN_CHART_LINE,
  TYPE_PIE_CHART,
  TYPE_DONUT_CHART,
  TYPE_TABLE_CHART,
  TYPE_HEATMAP_CHART,
  TYPE_BAR_CHART_BENCHMARK,
  TYPE_COLUMN_CHART_BENCHMARK,
  TYPE_LINE_CHART,
  TYPE_SCATTER_CHART,
} from './types';

const GraphRenderer = ({ type, ...props }) => {
  switch (type) {
    case TYPE_BAR_CHART:
      return <BarChart {...props} />;
    case TYPE_BAR_CHART_MULTI:
    case TYPE_BAR_CHART_BENCHMARK:
      return <BarChartMulti {...props} />;
    case TYPE_BAR_CHART_STACKED_MULTI:
      return <BarChartStackedMulti {...props} />;
    case TYPE_BAR_CHART_STACKED:
      return <BarChartStacked {...props} />;
    case TYPE_BAR_CHART_SCORE:
      return <BarChartScore {...props} />;
    case TYPE_BAR_CHART_SIDE_BY_SIDE:
      return <BarChartSideBySide {...props} />;
    case TYPE_COLUMN_CHART:
      return <ColumnChart {...props} />;
    case TYPE_COLUMN_CHART_MULTI:
    case TYPE_COLUMN_CHART_BENCHMARK:
      return <ColumnChartMulti {...props} />;
    case TYPE_COLUMN_CHART_STACKED:
      return <ColumnChartStacked {...props} />;
    case TYPE_COLUMN_CHART_STACKED_LOCAL:
      return <ColumnChartStackedLocalSamples {...props} />;
    case TYPE_COLUMN_CHART_LINE:
      return <ColumnChartLine {...props} />;
    case TYPE_PIE_CHART:
      return <PieChart {...props} />;
    case TYPE_DONUT_CHART:
      return <DonutChart {...props} />;
    case TYPE_TABLE_CHART:
      return <TableChart {...props} />;
    case TYPE_HEATMAP_CHART:
      return <Heatmap {...props} />;
    case TYPE_LINE_CHART:
      return <LineChart {...props} />;
    case TYPE_SCATTER_CHART:
      return <LineChart {...props} scatter={true} />;
    default:
      return <div>Unknown type</div>;
  }
};

export default GraphRenderer;
