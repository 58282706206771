import get from 'lodash/get';

const normalizeSubscriptionToEdit = (subscription) => {
  return {
    ...subscription,
    databoardId: subscription.databoard
      ? get(subscription, ['databoard', '_id'])
      : subscription.dataanalystaccess
      ? get(subscription, ['dataanalystaccess', '_id'])
      : subscription.report
      ? get(subscription, ['report', '_id'])
      : subscription.briefing
      ? get(subscription, ['briefing', '_id'])
      : null,
    organisationId: get(subscription, ['organisation', '_id']),
  };
};

export default normalizeSubscriptionToEdit;
