import React, { useState, useCallback } from 'react';

import { API } from 'aws-amplify';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';

import get from 'lodash/get';

import CircularProgress from '@material-ui/core/CircularProgress';

import SubmitButton from '../../../../../components/Admin/SubmitButton';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter,
} from '../../../../../components/Portlet';
import DataBoardsField from './DataBoardsField';
import { SnackBar } from '../../../../../components/UI';
import PrivateField from './PrivateField';

const DashboardForm = ({ dashboardId, privateFlag, jwtToken, handleSubmit, onSuccess }) => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const updateSubscription = useCallback(
    async ({ dashboardId, privateFlag }) => {
      setLoader(true);
      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
          body: { private: privateFlag },
        };
        const result = await API.put(
          'dashboards',
          `/databoards/${dashboardId}/config/visibility`,
          params,
        );
        onSuccess(result);
      } catch (e) {
        console.log(e);
        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );
        setError(message);
      } finally {
        setLoader(false);
      }
    },
    [dashboardId, privateFlag],
  );

  return (
    <Portlet>
      <PortletHeader>
        <PortletLabel title="Update Dashboard Config" />
      </PortletHeader>
      <form onSubmit={handleSubmit(updateSubscription)}>
        <PortletContent>
          <Field
            name="dashboardId"
            label="Dashboard"
            component={DataBoardsField}
            isDisabled={true}
            setError={setError}
          />
          <Field name="privateFlag" label="Visibility" component={PrivateField} />
        </PortletContent>
        <PortletFooter>
          {!loader && <SubmitButton label="Update" />}
          {loader && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )}
        </PortletFooter>
      </form>
      <SnackBar open={Boolean(error)} message={error} severity="warning" />
    </Portlet>
  );
};

const mapStateToProps = (state, { dashboardId, privateFlag }) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
  dashboardId: formValueSelector('dashboardForm')(state, 'dashboardId'),
  privateFlag: formValueSelector('dashboardForm')(state, 'privateFlag'),
  initialValues: {
    dashboardId,
    privateFlag,
  },
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'dashboardForm',
  }),
)(DashboardForm);
