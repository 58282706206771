import XLSX from 'sheetjs-style';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import styleWorkSheet from './styleSheet';
import { head, isString, map } from 'lodash';

const addEmptyRow = (tab) =>
  XLSX.utils.sheet_add_aoa(tab, [[]], {
    origin: -1,
  });

const addArrayOfArrays = (tab, aoa) => XLSX.utils.sheet_add_aoa(tab, aoa, { origin: -1 });

const createSheet = (arr) => {
  /**
   * Please note that the structure of the .xlsx document specified here should be consistent with the
   * styling file ./styleSheet.js
   * any changes that are done in this file should also be mapped in the styling
   * */

  // Creating a new sheet and adding a blank line in the beginig
  const tab = XLSX.utils.aoa_to_sheet([['']]);

  // Adding the title of the chart and the sources for the data
  addArrayOfArrays(tab, [get(head(arr), ['data', 'title'], '')]);
  addArrayOfArrays(tab, get(head(arr), ['data', 'sources'], ''));

  // If filters are allowed then we add them, else we add a blank line
  if (!isEmpty(get(get(head(arr), ['data', 'filters'], ''), 0))) {
    addArrayOfArrays(tab, get(head(arr), ['data', 'filters'], ''));
  } else {
    addEmptyRow(tab);
  }

  // Adding an empty row after the filters
  addEmptyRow(tab);

  map(arr, (chart) => {
    // Adding the name of the chart and subtitle if present
    addArrayOfArrays(tab, [get(chart, ['data', 'name'], '')]);
    if (!isEmpty(get(get(chart, ['data', 'subtitle'], ''), 0))) {
      if (isString(get(get(chart, ['data', 'subtitle'], ''), 0))) {
        addArrayOfArrays(tab, [get(chart, ['data', 'subtitle'], '')]);
      } else {
        addArrayOfArrays(tab, [
          [get(head(head(get(chart, ['data', 'subtitle'], []))), 'text', '')],
        ]);
      }
    } else {
      addEmptyRow(tab);
    }

    // Adding empty rows to separate the titles from the data
    addEmptyRow(tab);

    // Adding the data of the chart
    addArrayOfArrays(tab, [get(chart, ['data', 'legend'], '')]);
    addArrayOfArrays(tab, get(chart, ['data', 'data'], ''));

    // Adding sample if exists
    if (!isEmpty(get(chart, ['data', 'sample'], ''))) {
      addEmptyRow(tab);
      addEmptyRow(tab);
      addArrayOfArrays(tab, get(chart, ['data', 'sample'], ''));
    }

    // Adding empty rows to separate different tables
    addEmptyRow(tab);
    addEmptyRow(tab);
    addEmptyRow(tab);
  });

  // Applying styles to the worksheet
  return styleWorkSheet(tab, arr);
};

export default createSheet;
