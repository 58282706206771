import get from 'lodash/get';
import find from 'lodash/find';
import reduce from 'lodash/reduce';

const findLongestString = (params) => {
  const data = get(params, 'data', []);
  const labels = get(params, 'labels', []);

  return reduce(
    data,
    (result, { name }) => {
      const label = get(find(labels, { name }), 'text', name) || '';
      return label.length > result.length ? label : result;
    },
    '',
  );
};

export default findLongestString;
