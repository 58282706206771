import get from 'lodash/get';
import map from 'lodash/map';
import flattenDeep from 'lodash/flattenDeep';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import keys from 'lodash/keys';
import find from 'lodash/find';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import omit from 'lodash/omit';

export const transformData = ({ data }) => {
  const values = map(get(data, 'values', []), (row) => {
    const rowName = get(row, 'name', '');
    // const rowText = get(row, 'text', '');
    const cols = get(row, 'values', []);

    const newRows = map(cols, (col) => {
      const colName = get(col, 'name', '');
      const colText = get(col, 'text', '');
      const colValue = get(col, 'value', 0);

      return {
        name: colName,
        text: colText,
        [rowName]: colValue,
      };
    });

    return newRows;
  });

  const groupData = groupBy(flattenDeep(values), 'name');

  const flattenData = map(
    keys(groupData),
    (groupKey) => {
      const groupValues = get(groupData, groupKey, []);

      return reduce(
        groupValues,
        (result, value) => {
          return {
            ...result,
            ...value,
          };
        },
        {},
      );
    },
    [],
  );

  return flattenData;
};

export const sortMultiSourceData = ({ data }) => {
  const sortedResults = reverse(
    sortBy(data, (valuesToSortBy) =>
      reduce(omit(valuesToSortBy, ['name', 'text', 'hint']), (result, value) => result + value, 0),
    ),
  );

  return sortedResults;
};

export const omitZeroValues = ({ data }) =>
  filter(data, (values) =>
    reduce(omit(values, ['name', 'text', 'hint']), (sum, value) => sum + value, 0),
  );

const getBenchmarkedData = ({ data, table, sorting }) => {
  const dataTable = find(data, { name: table });

  let transformedData = transformData({ data: dataTable });

  if (!isEmpty(sorting)) {
    const sortedData = sortMultiSourceData({ data: transformedData });
    transformedData = sortedData;
  }

  return omitZeroValues({ data: transformedData });
};

export default getBenchmarkedData;
