import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import join from 'lodash/join';

const getAppliedSources = ({ data, sourceLabels, version, sourcesDictionary }) => {
  let sources = Array.from(get(data, 'originalSources', get(data, 'sources', []))).sort();
  if (version === 2) {
    sources = map(sources, (source) =>
      get(find(sourcesDictionary, { id: source }), 'text', source),
    );
  }

  return [
    [
      `Survey waves: ${join(
        map(sources, (source) => get(find(sourceLabels, { name: source }), 'text', source)),
        ', ',
      )}`,
    ],
  ];
};

export default getAppliedSources;
