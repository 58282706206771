import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';
import isNull from 'lodash/isNull';
import omit from 'lodash/omit';

const normalizeTableData = ({ data, table }) => {
  const dataTable = get(find(data, { name: table }), 'values', []);
  const nullColsBucket = [];

  // Clean up rows with all cols equal to null
  const returnNonNullRows = (row) =>
    filter(row.values, ({ value: col }) => col !== null).length > 0;
  const cleansedDataTable = filter(dataTable, returnNonNullRows);

  // Transform cols to dictionary for faster retrieval
  const colsToDictionary = (dictionary, { name: colName, value }) => {
    let colValue = value;
    if (isNull(value)) {
      nullColsBucket.push(colName);
      colValue = 'NA_NAN';
    }

    return {
      ...dictionary,
      [colName]: colValue,
    };
  };

  // Transform row to dictionary for faster retrieval
  const rowToDictionary = ({ name: rowName, values }) => {
    const columns = reduce(values, colsToDictionary, {});

    return {
      name: rowName,
      ...columns,
    };
  };

  const result = map(cleansedDataTable, rowToDictionary);

  // A column with null across all rows should be include as many times as the rows count
  const hasNullsAcrossAllRows = (col) =>
    filter(nullColsBucket, (key) => key === col).length === result.length;

  // Find out the null cols
  const keysWithAllValuesNull = filter(uniq(nullColsBucket), hasNullsAcrossAllRows);

  // Remove null cols from each row
  const resultWithoutNullColumns = map(result, (item) => omit(item, keysWithAllValuesNull));

  return resultWithoutNullColumns;
};

export default normalizeTableData;
