import differenceWith from 'lodash/differenceWith';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import getBookmarked from './getBookmarked';
import {
  TYPE_CUSTOM,
  TYPE_FAVOURITE,
  TYPE_FREE,
  TYPE_PREVIEW,
  TYPE_SYNDICATED,
} from '../../../data';
import getByName from './getByName';
import getBySector from './getBySector';
import getByProduct from './getByProduct';
import getSubscribed from './getSubscribed';
import { filter } from 'lodash';

const getFilteredDashboards = (
  dashboards,
  subscriptions,
  searchCriteria,
  textSearch,
  bookmarks,
) => {
  const category = get(searchCriteria, 'category');
  let filteredDashboards = [];
  switch (category) {
    case TYPE_FAVOURITE.TYPE:
      filteredDashboards = getBookmarked(dashboards, bookmarks);
      break;
    case TYPE_CUSTOM.TYPE:
      filteredDashboards = getSubscribed(dashboards, subscriptions);
      filteredDashboards = filter(
        filteredDashboards,
        (dashboard) => get(dashboard, 'researchType') === TYPE_CUSTOM.TYPE,
      );
      break;
    case TYPE_SYNDICATED.TYPE:
      filteredDashboards = getSubscribed(dashboards, subscriptions);
      filteredDashboards = filter(
        filteredDashboards,
        (dashboard) => get(dashboard, 'researchType') === TYPE_SYNDICATED.TYPE,
      );
      break;
    case TYPE_FREE.TYPE:
      filteredDashboards = getSubscribed(dashboards, subscriptions);
      filteredDashboards = filter(
        filteredDashboards,
        (dashboard) => get(dashboard, 'researchType') === TYPE_FREE.TYPE,
      );
      break;
    // Getting the difference between all dashboards and subscribed ones
    case TYPE_PREVIEW.TYPE:
      filteredDashboards = differenceWith(
        dashboards,
        getSubscribed(dashboards, subscriptions),
        isEqual,
      );
      break;
    default:
      filteredDashboards = dashboards;
  }

  filteredDashboards = getBySector(filteredDashboards, searchCriteria);
  filteredDashboards = getByProduct(filteredDashboards, searchCriteria);
  filteredDashboards = getByName(filteredDashboards, textSearch);

  return filteredDashboards;
};

export default getFilteredDashboards;
