import React from 'react';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  full: {
    width: '75%',
    minWidth: 950,
    maxWidth: 1400,
    height: '100%',
    boxShadow: '-10px 11.1px 50px 0 rgba(188, 198, 210, 0.31)',
    borderRadius: 13,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  half: {
    width: '90%',
    minWidth: 425,
    maxWidth: 640,
    height: '100%',
    boxShadow: '-10px 11.1px 50px 0 rgba(188, 198, 210, 0.31)',
    borderRadius: 13,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
});

const SectionItemPaper = ({ classes, height, width, children }) => (
  <Paper className={width === 'full' ? classes.full : classes.half} style={{ height: height }}>
    {children}
  </Paper>
);

export default withStyles(styles)(SectionItemPaper);
