import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { bindActionCreators } from 'redux';

import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import trim from 'lodash/trim';
import toLower from 'lodash/toLower';
import isEmpty from 'lodash/isEmpty';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';

import { ReactComponent as SearchIcon } from './assets/search.svg';
import { Radio, Typography } from '@material-ui/core';
import disabledByLink from './utils/disabledByLink';
import applyLinks from './utils/applyLinks';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  searchRoot: {
    padding: '1rem',
  },
  search: {
    position: 'relative',
    borderRadius: 12,
    backgroundColor: theme.palette.custom.white,
    marginLeft: `0px !important`,
    width: '15.6rem',
    height: '2.3rem',
    border: 'none',
    paddingLeft: '0.7rem',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: '1rem',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingLeft: '2.1rem',
    color: theme.palette.custom.gray4,
    fontSize: '1rem',
  },
  option: {
    paddingLeft: '2.7rem',
    width: '100%',
    marginRight: 0,
  },
  label: {
    color: theme.palette.custom.gray5,
    fontSize: '1rem',
  },
  active: {
    fontWeight: 'bold',
  },
  checkbox: {
    color: theme.palette.custom.gray4,
    '& svg': {
      width: '1.1rem',
      height: '1.1rem',
    },
  },
  disabled: {
    cursor: 'not-allowed !important',
    pointerEvents: 'all !important',
    color: '#bcc6d2',
  },
  withBorder: {
    border: '1px solid #ececf2',
  },
  selectionText: {
    padding: '0 1rem',
    fontSize: '0.7rem',
    fontStyle: 'italic',
  },
});

// In-sync with mongo model
const FILTER_MULTI_SELECTION = 'MULTI';
const FILTER_SINGLE_SELECTION = 'SINGLE';
const FILTER_LIMITED_SELECTION = 'LIMITED';

const allowSelection = (currentValues, selection, maxSelections) =>
  selection === FILTER_MULTI_SELECTION ||
  (selection === FILTER_LIMITED_SELECTION && currentValues.length < maxSelections) ||
  (selection === FILTER_SINGLE_SELECTION && currentValues.length === 0);

const FilterOptions = ({
  classes,
  labels,
  input,
  className,
  withScroll,
  forceClear,
  withBorder,
  selection,
  maxSelections,
  disabled,
  filterGroups,
  filterName,
  filterLinks,
  formValues,
  change,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [filterToDisplay, setFilterToDisplay] = useState([]);

  const SelectComponent = selection === FILTER_SINGLE_SELECTION ? Radio : Checkbox;

  useEffect(() => {
    setFilterToDisplay(labels);
  }, [labels]);

  // Handle search in filter values
  useEffect(() => {
    const subset = filter(labels, ({ text }) =>
      includes(toLower(text), trim(toLower(searchValue))),
    );
    setFilterToDisplay(subset);
  }, [searchValue]);

  useEffect(() => {
    if (forceClear) {
      setSearchValue('');
    }
  }, [forceClear]);

  const Container = withScroll ? Scrollbars : React.Fragment;

  return (
    <FormGroup className={cn(classes.root, { [className]: !isEmpty(className) })}>
      {!disabled && (
        <div className={classes.searchRoot}>
          <div className={cn(classes.search, { [classes.withBorder]: withBorder })}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>
      )}
      <Container>
        {selection === FILTER_LIMITED_SELECTION && (
          <Typography className={classes.selectionText}>
            Max. Selections: {maxSelections}.<br /> For more than {maxSelections} options, please
            contact us.
          </Typography>
        )}
        {map(filterToDisplay, ({ name, text }, index) => {
          if (!name) name = text;
          const isChecked = input.value.indexOf(name) !== -1;

          return (
            <FormControlLabel
              name={`${input.name}[${index}]`}
              key={`filter-option-${index}`}
              className={classes.option}
              classes={{
                label: cn(
                  classes.label,
                  { [classes.active]: isChecked },
                  { [classes.disabled]: disabled },
                ),
              }}
              control={
                <SelectComponent
                  checked={isChecked}
                  value={name}
                  className={cn(classes.checkbox, { [classes.disabled]: disabled })}
                  color="primary"
                  size="small"
                  disabled={
                    ((selection === FILTER_LIMITED_SELECTION &&
                      maxSelections <= input.value.length) ||
                      disabledByLink(filterGroups, filterName, name, filterLinks, formValues)) &&
                    !isChecked
                  }
                  onClick={(event) => {
                    if (!disabled) {
                      let newValue = [...input.value];
                      applyLinks(
                        filterGroups,
                        filterName,
                        name,
                        filterLinks,
                        event.target.checked,
                        formValues,
                        change,
                      );
                      if (event.target.checked) {
                        if (allowSelection(newValue, selection, maxSelections)) {
                          newValue.push(name);
                        } else {
                          newValue = [name];
                        }
                      } else {
                        newValue.splice(newValue.indexOf(name), 1);
                      }
                      return input.onChange(newValue);
                    }
                  }}
                />
              }
              label={text}
            />
          );
        })}
      </Container>
    </FormGroup>
  );
};

const mapStateToProps = (state, { _id }) => ({
  labels: get(state, ['filtersIndex', 'byId', _id, 'labels'], []),
  selection: get(state, ['filtersIndex', 'byId', _id, 'selection'], FILTER_MULTI_SELECTION),
  maxSelections: get(state, ['filtersIndex', 'byId', _id, 'maxSelections'], 1),
  filterGroups: get(state, ['databoard', 'filterGroups'], []),
  filterName: get(state, ['filtersIndex', 'byId', _id, 'name'], ''),
  filterLinks: get(state, ['databoard', 'filterLinks'], []),
  formValues: get(state, ['form', 'globalFilters', 'values'], []),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FilterOptions));
