import React from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';

import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import split from 'lodash/split';
import join from 'lodash/join';

import { withStyles } from '@material-ui/core/styles';

import { rejectSeries } from '../../../../../state/charts/actions';
import { getChartLabels } from '../../../../../state/labels/selectors';
import HintTooltip from '../HintTooltip';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  item: {
    marginRight: '0.8rem',
    cursor: 'pointer',
    userSelect: 'none',
    fontSize: 16,
    '&:hover': {
      textDecoration: 'underline',
    },
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.custom.black,
    fontWeight: 500,
  },
  inactive: {
    opacity: 0.5,
  },
});

const CustomLegend = ({
  classes,
  series,
  labels,
  id,
  rejected,
  onClick,
  palette,
  chartPalette,
}) => {
  const handleClick = (dataKey) => {
    const isRejected = indexOf(rejected, dataKey) !== -1;
    const hasMoreToReject = Math.abs(series.length - rejected.length) > 1;
    if (hasMoreToReject || isRejected) return onClick(id, dataKey);

    return alert('De-activation of all legend filters is not permitted');
  };

  const getFill = (item) => {
    return get(
      find(chartPalette, { key: item.dataKey }),
      'value',
      get(find(palette, { key: item.dataKey }), 'value', item.fill),
    );
  };

  return (
    <div className={classes.root}>
      {map(series, (item, index) => {
        const label = get(find(labels, { name: item.dataKey }), 'text', item.dataKey);
        const labelDescription = get(find(labels, { name: item.dataKey }), 'hint', '');
        return (
          <HintTooltip title={labelDescription}>
            <div
              key={`legend-item-${index}`}
              className={cn(classes.item, {
                [classes.inactive]: indexOf(rejected, item.dataKey) !== -1,
              })}
              onClick={() => handleClick(item.dataKey)}
            >
              <svg
                className="recharts-surface"
                width="14"
                height="14"
                viewBox="0 0 32 32"
                version="1.1"
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  marginRight: '4px',
                }}
              >
                <path
                  stroke="none"
                  fill={getFill(item)}
                  d="M0,4h32v24h-32z"
                  className="recharts-legend-icon"
                />
              </svg>
              <span dangerouslySetInnerHTML={{ __html: join(split(label, ' '), '&nbsp;') }} />
            </div>
          </HintTooltip>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state, { id }) => ({
  rejected: get(state, ['charts', 'byId', id, 'reject'], []),
  labels: getChartLabels(state, id),
  palette: get(state, ['databoard', 'palette'], []),
  chartPalette: get(state, ['charts', 'byId', id, 'palette'], []),
});

const mapDispatchToProps = (dispatch) => ({
  onClick: (chartId, series) => dispatch(rejectSeries(chartId, series)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomLegend));
