import React, { useEffect, useState } from 'react';
import qs from 'qs';
import moment from 'moment';

import { connect, useSelector } from 'react-redux';

import { isEmpty, map, reduce } from 'lodash';

import { Grid, makeStyles } from '@material-ui/core';

import { CardPaperProps, Section } from '../../../../../components/UI';
import { GraphContainer } from '../../components';

import currentModel from '../models/basic-kpi';
import { Dashboard, KeyValuePair, Config } from '../../@types/Dashboard';
import { ReduxState } from '../../@types/Reducer';
import { ScrollableElement } from '../../../../../components/UI/ScrollableElement';
import exportAction from '../actions/exportAction';

interface Props {
  model?: Dashboard;
  jwtToken: string;
}

const useStyles = makeStyles({
  root: {
    padding: 24,
  },
  section: { marginTop: 12 },
  card: { marginTop: 12 },
});

const config2Dictionary = (config: Array<KeyValuePair>): Config =>
  reduce(
    config,
    (acc, item) => ({
      ...acc,
      [item.key]: item.value,
    }),
    {},
  );

const BasicKPI = ({ model = currentModel as Dashboard, jwtToken }: Props) => {
  const [activeGraph, setActiveGraph] = useState('');
  const classes = useStyles();
  const filters = useSelector((state: ReduxState) => state.analytics.filters);

  useEffect(() => {
    const q = qs.parse(window.location.search, {
      comma: true,
      ignoreQueryPrefix: true,
    });

    setActiveGraph(q.graph as string);
  }, []);

  return (
    <Grid container className={classes.root} spacing={2}>
      {map(model.sections, (section, sectionIndex) => {
        if (!section.visibility && process.env.REACT_APP_DATABOARD_STATUS !== 'draft') return null;

        return (
          <Section
            key={`section-${sectionIndex}`}
            className={classes.section}
            title={section.title}
          >
            <Grid container spacing={2}>
              {map(section.graphs, (graph, graphIndex) => {
                const config = config2Dictionary(graph.config);

                const appliedFilters = {
                  startDate: filters.startDate,
                  endDate: filters.endDate,
                  exclude: { accounts: filters.excludedAccounts },
                };

                let paperProps = { title: graph.title } as CardPaperProps;
                if (!isEmpty(config.export)) {
                  const payload = {
                    body: { name: config.export, filters: appliedFilters },
                    headers: { Authorization: `Bearer ${jwtToken}` },
                  };

                  const filename =
                    `${config.export}-${moment(filters.startDate).format('YYYYMMDD')}-${moment(
                      filters.endDate,
                    ).format('YYYYMMDD')}` || '';

                  paperProps = {
                    ...paperProps,
                    actions: [
                      {
                        type: 'EXPORT',
                        action: () =>
                          exportAction({
                            name: filename,
                            params: payload,
                            headers: config.csvHeaders,
                          }),
                      },
                    ],
                  } as CardPaperProps;
                }

                return (
                  <Grid
                    key={`graph-${sectionIndex}-${graphIndex}`}
                    item
                    xs={config.width === 'full' ? 12 : 6}
                  >
                    <ScrollableElement scrollHere={graph.query === activeGraph} />
                    <GraphContainer
                      key={`graph-${sectionIndex}-${graphIndex}`}
                      className={classes.card}
                      customConfig={config}
                      jwtToken={jwtToken}
                      paperProps={paperProps}
                      filters={appliedFilters}
                      {...graph}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Section>
        );
      })}
    </Grid>
  );
};

const mapStateToProps = (state: { user: { jwtToken: string } }) => ({
  jwtToken: state.user.jwtToken,
});

export default connect(mapStateToProps)(BasicKPI);
