const shellSort = (row) => {
  // Prevent original array from mutations
  const array = Object.keys(row);

  // Define gap distance
  let gap = Math.floor(array.length / 2);

  // Until gap is bigger than zero do elements comparisons and swaps
  while (gap > 0) {
    // Go and compare distant element pairs
    for (let i = 0; i < array.length - gap; i += 1) {
      let currentIndex = i;
      let gapShiftedIndex = gap + i;

      while (currentIndex >= 0) {
        if (row[array[currentIndex]] < row[array[gapShiftedIndex]]) {
          [array[currentIndex], array[gapShiftedIndex]] = [
            array[gapShiftedIndex],
            array[currentIndex],
          ];
        }

        gapShiftedIndex = currentIndex;
        currentIndex -= gap;
      }
    }

    // Shrink the gap
    gap = Math.floor(gap / 2);
  }

  return array;
};

export default shellSort;
