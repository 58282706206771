import qs from 'qs';

import get from 'lodash/get';
import keys from 'lodash/keys';
import filter from 'lodash/filter';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import omit from 'lodash/omit';

export const FILTERS_NOT_READY_FLAG = 'NOT_READY';

const stringifyFilters = (state) => {
  const dataById = get(state, ['filtersData', 'byId'], {});
  const appliedFiltersIdTable = keys(omit(dataById, 'sources'));
  const selectedSources = get(dataById, 'sources', []);

  const dirtyFilters = filter(appliedFiltersIdTable, (filterId) => {
    const defaultValuesHaveChanged = !isEmpty(
      difference(
        get(dataById, filterId, []),
        get(state, ['filtersIndex', 'byId', filterId, 'defaultValues'], []),
      ),
    );

    return defaultValuesHaveChanged;
  });

  const sourcesAreDirty =
    !isEmpty(selectedSources) &&
    !isEmpty(difference(get(state, ['databoard', 'sources'], []), selectedSources));

  const initFilterObject = sourcesAreDirty ? { CAT_SOURCES: selectedSources } : {};

  const objectToExport = reduce(
    dirtyFilters,
    (result, filterId) => {
      const isChild = !isEmpty(get(state, ['filtersIndex', 'byId', filterId, 'parent']));
      const isHidden = !get(state, ['filtersIndex', 'byId', filterId, 'visible'], false);
      if (isChild || isHidden) return result;

      const filterName = get(state, ['filtersIndex', 'byId', filterId, 'name']);
      const filterValues = get(dataById, filterId);

      return {
        ...result,
        [filterName]: filterValues,
      };
    },
    initFilterObject,
  );

  const queryString = qs.stringify(objectToExport, { arrayFormat: 'comma', encode: false });

  if (isEmpty(queryString) && isEmpty(appliedFiltersIdTable)) {
    return FILTERS_NOT_READY_FLAG;
  }

  return queryString;
};

export default stringifyFilters;
