import { map, orderBy, reverse, split } from 'lodash';
import moment from 'moment';

import hasDashboardAccess from '../../../../../utils/hasDashboardAccess';
import { get, head } from 'lodash';

const sortByTitle = (dashboards, order) => orderBy(dashboards, ['title'], order);
const sortBySector = (dashboards, order) => orderBy(dashboards, ['sectors'], order);

// When ordering by sources use the first one in the list (which is expected to be the latest source) and split the text content into Qx YYYY
// For this to work the sources must follow two requirements:
// 1. sources must be ordered in *descending* order in the databoard spec
// 2. text must have the format "Qx YYYY" where Qx defines a quarter (Q1, Q2, etc) and YYYY defines the 4 digits year
const sortBySources = (dashboards, order) =>
  orderBy(dashboards, (dashboard) => {
    const first = get(head(get(dashboard, 'sources', [])), 'text');
    return reverse(split(first, ' '));
  }, order);

  // Covert published date to timestamp in order to be used for comparison
const sortByPublishedAt = (dashboards, order) => orderBy(dashboards, (doc) => moment(doc.datePublished, "MM-DD-YYYY").valueOf(), order);
const sortByType = (dashboards, order) =>
  orderBy(dashboards, ['researchType'], [order, order]);

const sortDashboards = (dashboards, subscriptions, orderByField, order) => {
  const transformedDashboards = map(dashboards, (dashboard) => ({
    ...dashboard,
    hasAccess: hasDashboardAccess(subscriptions, dashboard._id),
  }));

  switch (orderByField) {
    case 'title':
      return sortByTitle(transformedDashboards, order);
    case 'sector':
      return sortBySector(transformedDashboards, order);
    case 'sources':
      return sortBySources(transformedDashboards, order);
    case 'datePublished':
      return sortByPublishedAt(transformedDashboards, order);
    case 'type':
      return sortByType(transformedDashboards, order);
    default:
      return transformedDashboards;
  }
};

export default sortDashboards;
