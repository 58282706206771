import React, { FC } from 'react';
import cn from 'classnames';

import { map, get } from 'lodash';

import { Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';

import { CustomConfig } from '../../../../../components/Graphs/@types';
import ITheme from '../../../../../theme/@types/Theme';

type AnalyticsTableChartProps = {
  customConfig: CustomConfig;
  data: any[];
};

const useStyles = makeStyles((theme: ITheme) => ({
  root: { marginTop: 15 },
  row: { height: 40 },
  rowCell: {
    fontSize: 14,
    color: theme.palette.custom.black,
  },
  cell: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.custom.gray5,
  },
  centeredCell: {
    textAlign: 'center',
  },
}));

const formatter = (value: string | number, type: string) => {
  switch (type) {
    case 'string':
    case 'number':
      return value;
    case 'minutes': {
      return value;
    }
    default:
      return value;
  }
};

const AnalyticsTableChart: FC<AnalyticsTableChartProps> = ({ customConfig, data }) => {
  const classes = useStyles();

  const { columns } = customConfig;

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          {map(columns, ({ label, type }, colIndex) => (
            <TableCell
              key={`${label}-${colIndex}`}
              className={cn(classes.cell, { [classes.centeredCell]: type !== 'string' })}
              padding="none"
            >
              {label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {map(data, (dataItem, index) => {
          return (
            <TableRow key={`item-${index}`} className={classes.row}>
              {map(columns, ({ objectKey, type }, colIndex) => (
                <TableCell
                  key={`${objectKey}-${colIndex}`}
                  padding="none"
                  className={cn(classes.rowCell, { [classes.centeredCell]: type !== 'string' })}
                >
                  {formatter(get(dataItem, objectKey), type)}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default AnalyticsTableChart;
