import get from 'lodash/get';
import indexOf from 'lodash/indexOf';

import {
  FETCH_CHART_DATA_REQUEST,
  FETCH_CHART_DATA_SUCCESS,
  FETCH_CHART_DATA_ERROR,
  RESET_STATE,
} from './types';

export const initialState = {
  byChart: {},
  allCharts: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHART_DATA_REQUEST: {
      const id = get(action, ['payload', 'id'], '');
      return {
        ...state,
        byChart: {
          ...state.byChart,
          [id]: {
            ...get(state, ['byChart', id], {}),
            loader: true,
            error: null,
          },
        },
      };
    }
    case FETCH_CHART_DATA_SUCCESS: {
      // Payload data
      const id = get(action, ['payload', 'id'], '');
      const data = get(action, ['payload', 'data'], []);
      const sources = get(action, ['payload', 'sources'], []);
      const originalSources = get(action, ['payload', 'originalSources']);

      // Handle all indexing
      const allCharts =
        indexOf(state.allCharts, id) === -1 ? [...state.allCharts, id] : state.allCharts;

      // State modification
      return {
        ...state,
        byChart: {
          ...state.byChart,
          [id]: {
            ...get(state, ['byChart', id], {}),
            sources,
            originalSources,
            data,
            loader: false,
            error: null,
          },
        },
        allCharts,
      };
    }
    case FETCH_CHART_DATA_ERROR: {
      const id = get(action, ['payload', 'id'], '');
      const data = get(action, ['payload', 'data'], []);
      const sources = get(action, ['payload', 'sources'], []);
      const originalSources = get(action, ['payload', 'originalSources']);

      // Handle all indexing
      const allCharts =
        indexOf(state.allCharts, id) === -1 ? [...state.allCharts, id] : state.allCharts;

      return {
        ...state,
        byChart: {
          ...state.byChart,
          [id]: {
            ...get(state, ['byChart', id], {}),
            sources,
            originalSources,
            data,
            loader: false,
            error: get(action, 'error', null),
          },
        },
        allCharts,
      };
    }
    case RESET_STATE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
