import React from 'react';
import cn from 'classnames';

import get from 'lodash/get';
import find from 'lodash/find';
import omit from 'lodash/omit';

import { withStyles } from '@material-ui/core/styles';
import CustomTick from '../CustomTick';
import { LOW_SAMPLE_SIZE } from '../../../../../utils/hasLowSampleSize';
import { connect } from 'react-redux';

const styles = () => ({
  text: {
    fill: '#979797',
    fontWeight: 'bold',
    fontSize: 16,
  },
  lowSampleText: {
    fill: 'red',
  },
});

const LocalSamplesAxis = ({
  x,
  y,
  payload,
  axisOrientation,
  samples,
  sampleOffset,
  classes,
  ...rest
}) => {
  const sample = find(samples, { name: payload.value });

  if (!get(sample, 'value', ''))
    return (
      <CustomTick
        {...omit(rest, 'visibleTicksCount')}
        angle={axisOrientation === 'HORIZONTAL' ? 0 : -45}
        textAnchor={axisOrientation === 'HORIZONTAL' ? 'middle' : 'end'}
        x={x}
        y={y}
        payload={payload}
      />
    );

  return (
    <g>
      <CustomTick
        angle={axisOrientation === 'DIAGONAL' ? -45 : 0}
        textAnchor={axisOrientation === 'DIAGONAL' ? 'end' : 'middle'}
        {...omit(rest, 'visibleTicksCount')}
        x={x}
        y={y}
        payload={payload}
      />
      <text
        textAnchor="middle"
        x={x}
        y={y + sampleOffset}
        className={cn(classes.text, {
          [classes.lowSampleText]: get(sample, 'value', '') < LOW_SAMPLE_SIZE,
        })}
      >
        {get(sample, 'value', '') ? `n = ${get(sample, 'value', '').toLocaleString()}` : ''}
      </text>
    </g>
  );
};

const mapStateToProps = (state, { id }) => ({
  axisOrientation: get(state, ['charts', 'byId', id, 'axis', 'orientation'], ''),
});

export default connect(mapStateToProps)(withStyles(styles)(LocalSamplesAxis));
