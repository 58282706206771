const getColumnIndex = (numericalIndex) => {
  let columnIndex = '';
  while (numericalIndex >= 0) {
    // Getting the remainder charachter that should be displayed on the left of the columnIndex
    columnIndex = String.fromCharCode((numericalIndex % 26) + 65) + columnIndex;
    // Calculating the remaining value
    numericalIndex = Math.floor(numericalIndex / 26) - 1;
  }
  return columnIndex;
};

export default getColumnIndex;
