import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import getData from '../../../utils/xlsx/utils/getData';
import getSample from '../../../utils/xlsx/utils/getSample';
import getFilters from '../../../utils/xlsx/utils/getFilters';
import getAppliedSources from '../../../utils/xlsx/utils/getAppliedSources';
import createSheet from '../../../utils/xlsx/exporter/createSheet';
import createWorkbook from '../../../utils/xlsx/exporter/createWorkbook';
import { finalizeChartExport } from '../../../state/dataExport/actions';
import { getFiltersByChartId } from '../../../state/dataExport/selectors';
import { getSampleLabels } from '../../../state/labels/selectors';
import { TYPE_HEATMAP_CHART } from '../../Graphs/GraphsRenderer/types';
import { find } from 'lodash';

const DownloadChartData = ({
  dashboardTitle,
  cardTitle,
  cardSubtitle,
  chart,
  chartFilters,
  isExportingData,
  data,
  sourceLabels,
  onDone,
  version,
  sourcesDictionary,
}) => {
  useEffect(() => {
    if (isExportingData && !data.loader) {
      const chartName = get(chart, 'name', '');
      const type = get(chart, 'type', '');
      let graphTable = '';
      let sampleTable = '';
      if (type === TYPE_HEATMAP_CHART) {
        const chartData = get(chart, 'data', {});
        graphTable = find(chartData, { key: 'data' }).value;
        sampleTable = find(chartData, { key: 'sample' }).value;
      } else {
        graphTable = get(chart, 'graph', '');
        sampleTable = get(chart, 'sample', '');
      }
      const extraGraphs = get(chart, 'extraGraphs', []);
      const sorting = get(chart, 'sorting', {});
      const formatter = get(chart, 'formatter', '');
      const dataquery = get(chart, ['query', 'name'], '');
      const axisTitle = get(chart, ['axis', 'label'], '');
      const localSamples = get(
        find(get(chart, 'config', []), { key: 'showLocalSamples' }),
        'value',
        false,
      );

      // sheet name is uniq and less than 31 chars
      let sheetName = '';
      if (isEmpty(chartName)) {
        sheetName = dataquery.length > 30 ? `${dataquery.substring(0, 30)}` : `${dataquery}`;
      } else {
        sheetName = chartName.length > 30 ? `${chartName.substring(0, 30)}` : `${chartName}`;
      }

      const chartExport = {
        data: {
          title: [dashboardTitle],
          sources: getAppliedSources({ data, sourceLabels, version, sourcesDictionary }),
          name: [cardTitle],
          subtitle: [cardSubtitle],
          // formatter,
          filters: getFilters(chartFilters),
          legend: ['', axisTitle],
          data: getData({
            data,
            dataTable: graphTable,
            sampleTable,
            type,
            sorting,
            sourceLabels,
            formatter,
            extraGraphs,
            localSamples,
          }),
          sample: getSample({ data, tableName: sampleTable, type }),
        },
      };

      const sheets = [{ name: sheetName, data: createSheet([chartExport]) }];

      createWorkbook({ title: cardTitle, sheets });

      onDone();
    }
  }, [data, isExportingData]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={isExportingData}
      // onClose={handleClose}
    >
      <MuiAlert elevation={6} variant="filled" severity="info">
        Exporting chart data
      </MuiAlert>
    </Snackbar>
  );
};

const mapStateToProps = (state, { chartId }) => ({
  dashboardTitle: get(state, ['databoard', 'title'], ''),
  chart: get(state, ['charts', 'byId', chartId], {}),
  data: get(state, ['data', 'byChart', chartId], {}),
  sourcesDictionary: get(state, ['labels', 'sources'], []),
  version: get(state, ['databoard', 'version'], 1),
  chartFilters: getFiltersByChartId(state, chartId),
  sourceLabels: getSampleLabels(state),
  isExportingData: get(state, ['dataExport', 'charts', chartId, 'isExportingData'], false),
});

const mapDispatchToProps = (dispatch, { chartId }) => ({
  onDone: () => dispatch(finalizeChartExport(chartId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadChartData);
