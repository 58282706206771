import React from 'react';
import cn from 'classnames';

import isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';

import { ReactComponent as Logo } from './assets/logo.svg';
import { get } from 'lodash';
import { connect } from 'react-redux';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0rem 5rem',
  },
  text: {
    fontSize: 14,
    color: '#979797',
  },
  logo: {
    width: 60,
    height: 20,
  },
});

const currYear = new Date().getFullYear();

const Copyright = ({ classes, dashboardName, className }) => {
  return (
    <div className={cn(classes.root, { [className]: !isEmpty(className) })}>
      <Logo className={classes.logo} />
      <div className={classes.text}>{`Ⓒ SlashData | ${currYear} | Source: ${dashboardName} `}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboardName: get(state, ['databoard', 'title'], ''),
});

export default connect(mapStateToProps)(withStyles(styles)(Copyright));
