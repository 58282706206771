import concat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';

import getByOrganisation from './getByOrganisation';
import getByDataboard from './getByDataboard';
import getActiveSubscriptions from './getActiveSubscriptions';
import getExpiredSubscriptions from './getExpiredSubscriptions';
import getBySector from './getBySector';
import getByProduct from './getByProduct';

const getFilteredSubscriptions = (subscriptions, textSearch, searchCriteria) => {
  let subscriptionsSubset =
    searchCriteria.category === 'expired'
      ? getExpiredSubscriptions(subscriptions)
      : getActiveSubscriptions(subscriptions);
  subscriptionsSubset = getBySector(subscriptionsSubset, searchCriteria);
  subscriptionsSubset = getByProduct(subscriptionsSubset, searchCriteria);
  const subscriptionsByOrg = getByOrganisation(subscriptionsSubset, textSearch);
  const subscriptionsByDataboard = getByDataboard(subscriptionsSubset, textSearch);

  const filteredSubscriptions = uniqBy(concat(subscriptionsByOrg, subscriptionsByDataboard), '_id');

  return filteredSubscriptions;
};

export default getFilteredSubscriptions;
