import React, { useEffect, useState, Fragment, useCallback } from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';
import { API } from 'aws-amplify';
import moment from 'moment';
import { navigate } from '@reach/router';

import { find, forEach, get, keys, map, omit } from 'lodash';

import Grid from '@material-ui/core/Grid';
// import Snackbar from '@material-ui/core/Snackbar';
// import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { Fab, Tooltip } from '@material-ui/core';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';

import { setDataboard, resetDataboard, setViews } from '../../state/databoard/actions';
import {
  initializeFilterValues,
  resetFilters,
  resetGlobalFilters,
  updateFilterValues,
} from '../../state/filtersData/actions';

import Title from '../../components/Databoard/Title';
import FilterTagManager from '../../components/Databoard/FilterTagManager';
import ContactUs from '../../components/Databoard/ContactUs';
import SectionFilters from '../../components/Databoard/SectionFiltersView';
import Section from '../../components/Databoard/Section';
// import ShareButton from '../../components/Databoard/ShareButton';
import Sidebar from '../../components/Databoard/Sidebar';
import AppLoader from '../../components/AppLoader';
import GlobalFiltersDrawer from '../../components/Databoard/GlobalFiltersDrawer';
import DownloadData from '../../components/Databoard/DownloadData';
import parseFilters from '../../utils/parseFilters';
import { setFilterLabels } from '../../state/filtersIndex/actions';
import getValidAppliedFilters from './utils/getValidAppliedFilters';
import stringifyFilters, { FILTERS_NOT_READY_FLAG } from '../../utils/stringifyFilters';
import {
  normalizeFilterValues,
  extractFilterValues,
  extractFilterDefaultAdapter,
} from '../../utils/filterValues';
import {
  /* clearEventGroup, */
  trackDashboardVisit,
  trackDataCutVisit,
  trackFilterUsage,
} from '../../state/analytics/actions';
import usePageHiddenDetection from './hooks/usePageHiddenDetection';
import { resetData } from '../../state/data/actions';
import ContentsDrawer from '../../components/Databoard/ContentsDrawer';
import ConfirmationDialog from '../../components/Admin/ConfirmationDialog';
// import usePassiveDetection from './hooks/usePassiveDetection';

const drawerWidth = '3.75rem';
const innerDrawerWidth = '18.75rem';

const mailto = 'sales@slashdata.co';
const cc = 'technology@slashdata.co';

const styles = (theme) => ({
  root: {
    marginLeft: 0,
    display: 'flex',
    // height: '100%',
  },
  container: {
    height: '100%',
    position: 'relative',
    flexGrow: 1,
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '3.75rem',
  },
  drawer: {
    width: drawerWidth,
  },
  innerDrawer: {
    width: innerDrawerWidth,
    marginLeft: drawerWidth,
  },
  header: {
    // position: 'sticky',
    top: '4.9rem',
    left: 0,
    right: 0,
    paddingLeft: drawerWidth,
    zIndex: theme.zIndex.appBar,
  },
  main: {
    backgroundColor: 'white',
    paddingLeft: '0.5rem',
    paddingRight: '2.4rem',
    paddingBottom: '1.3rem',
    paddingTop: '1.6rem',
    position: 'relative',
    borderBottom: '1px solid #e7e7ef',
  },
  scrollShadow: {
    boxShadow: '0 5px 29px 0 rgba(188, 198, 210, 0.41)',
  },
  sectionFiltersView: {
    paddingLeft: '4.4rem',
  },
  text: {
    color: theme.palette.custom.gray4,
    fontSize: '1.3rem',
  },
  cta: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  paper: {
    height: '56.9rem',
    margin: '4.1rem 0',
    boxShadow: '-10px 11.1px 50px 0 rgba(188, 198, 210, 0.31)',
    borderRadius: 10,
  },
  charts: {
    margin: '3.3rem 0',
    paddingBottom: '9.8rem',
  },
  subscriptionAlert: {
    backgroundColor: '#f6a300',
    color: 'white',
    '& .MuiAlert-icon': {
      color: 'white',
    },
  },
  moreDataAlert: {
    backgroundColor: theme.palette.custom.blue2,
    color: 'white',
    '& .MuiAlert-icon': {
      color: 'white',
    },
  },
  actionCell: {
    color: '#f6a300',
    backgroundColor: 'white',
    border: `1px solid #f6a300`,
    padding: '0.25rem 1.1rem',
    borderRadius: 50,
    fontSize: '0.8rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginLeft: '1.4rem',
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  fabButton: {
    right: '1rem',
    bottom: '1rem',
    zIndex: 1,
    width: '2.5rem',
    height: '2.5rem',
    position: 'fixed',
    backgroundColor: 'white',
    color: theme.palette.custom.blue2,
    border: `2px solid ${theme.palette.custom.blue2}`,
    boxShadow: 'none',
    borderRadius: 50,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
});

// const allSources = [
//   { name: 'cs_sf1', text: 'Q2 2020' },
//   { name: 'de18', text: 'Q4 2019' },
//   { name: 'de17', text: 'Q2 2019' },
//   { name: 'de16', text: 'Q4 2018' },
//   { name: 'de15', text: 'Q2 2018' },
//   { name: 'de14', text: 'Q4 2017' },
// ];

const Databoard = ({
  classes,
  databoardId,
  userId,
  chartName,
  sections,
  location,
  showGlobalFilters,
  showContents,
  databoardData,
  isProcessingQueryString,
  queryString,
  jwtToken,
  doSetDataboard,
  doInitFilterValues,
  doSetFilterLabels,
  doTrackDashboardVisit,
  doTrackDataCutVisit,
  doUpdateFilter,
  doResetFilters,
  onUnmount,
  doSetViews,
  email,
  firstName,
  lastName,
  roles,
  title,
  subscriptions,
}) => {
  const [loader, setLoader] = useState(false);
  const [scrollClass, setScrollClass] = useState('');
  const [activeSection, setActiveSection] = useState('');
  const [chartInViewportHash, setChartInViewportHash] = useState({});
  const [showClearFiltersDialog, setShowClearFiltersDialog] = useState(false);
  const [showDeleteFiltersDialog, setShowDeleteFiltersDialog] = useState(false);
  const [selectedView, setSelectedView] = useState(null);
  const [viewsLoader, setViewsLoader] = useState(false);
  const idleTimeHandler = usePageHiddenDetection();
  const [moreDataOpen, setMoreDataOpen] = React.useState(true);
  // const passiveTimerHandler = usePassiveDetection();
  const pathURL = window.location.pathname;

  useEffect(() => {
    const handleScroll = () => {
      if (typeof window !== 'undefined' && window.scrollY !== 0) {
        setScrollClass(classes.scrollShadow);
      } else {
        setScrollClass('');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return function cleanup() {
      // TODO: other cleanups
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const resetFilters = () => {
    doResetFilters();
    setShowClearFiltersDialog(false);
  };

  const onDeleteFilter = (viewId) => {
    setSelectedView(viewId);
    setShowDeleteFiltersDialog(true);
  };

  const fetchSavedViews = async () => {
    const params = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    const savedViews = await API.get(
      'users',
      `/users/${userId}/views/databoards/${databoardId}`,
      params,
    );
    doSetViews(savedViews);
  };

  const deleteView = async () => {
    setShowDeleteFiltersDialog(false);
    setViewsLoader(true);
    try {
      const params = {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      };
      await API.del('users', `/users/${userId}/views/${selectedView}`, params);
      await fetchSavedViews();
    } catch (error) {
      console.log('An error occured');
      console.log(error);
    } finally {
      setViewsLoader(false);
    }
  };

  const onUpdateFilter = async (view) => {
    setViewsLoader(true);
    try {
      const params = {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: {
          ...view,
          filters: window.location.search.replace('?', ''),
        },
      };
      await API.put('users', `/users/${userId}/views/${view._id}`, params);
      await fetchSavedViews();
    } catch (error) {
      console.log('An error occured');
      console.log(error);
    } finally {
      setViewsLoader(false);
    }
  };

  const onRenameFilter = async (view, name) => {
    setViewsLoader(true);
    try {
      const params = {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: {
          ...view,
          name,
        },
      };
      await API.put('users', `/users/${userId}/views/${view._id}`, params);
      await fetchSavedViews();
    } catch (error) {
      console.log('An error occured');
      console.log(error);
    } finally {
      setViewsLoader(false);
    }
  };

  const onCreateFilter = async (name) => {
    setViewsLoader(true);
    try {
      const params = {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: {
          userId,
          databoardId,
          name,
          filters: window.location.search.replace('?', ''),
        },
      };
      await API.post('users', `/users/${userId}/views`, params);
      await fetchSavedViews();
    } catch (error) {
      console.log('An error occured');
      console.log(error);
    } finally {
      setViewsLoader(false);
    }
  };

  const onLoadView = (filterValue) => {
    const loadFilter = async () => {
      try {
        setLoader(true);
        const appliedFilters = parseFilters(databoardData, filterValue);
        let appliedFiltersValues = { sources: get(databoardData, 'sources', []) };
        keys(appliedFilters).forEach((filterName) => {
          appliedFiltersValues[filterName] = appliedFilters[filterName].values;
        });
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };
        const appliedSources = get(appliedFilters, 'sources', {});
        const filterIds = keys(omit(appliedFilters, 'sources'));
        let filterDataModels = await Promise.all(
          map(filterIds, (filterId) => {
            const query = get(appliedFilters, [filterId, 'query'], '');
            const table = get(appliedFilters, [filterId, 'table'], '');
            if (get(databoardData, 'version', 1) === 1) {
              return API.get(
                'dashboards',
                `/dataqueries/name/${query}/filters/name/${table}`,
                params,
              );
            } else {
              const sourceLabelArray = map(get(databoardData, 'sources'), (source) => ({
                name: get(source, 'name', ''),
                text: get(source, 'text', ''),
                id: get(source, 'id', -1),
              }));
              params.body = {
                version: 2,
                query: {
                  source: map(sourceLabelArray, (item) => get(item, 'id', 0)),
                  filterQuestion: query,
                  rejectedLabels: get(appliedFilters, [filterId, 'reject'], []),
                  renamedLabels: get(appliedFilters, [filterId, 'replaceLabels'], []),
                  displayLabels: get(appliedFilters, [filterId, 'filter'], []),
                },
              };
              return API.post(
                'dashboards',
                `/users/${userId}/databoards/${databoardId}/engine`,
                params,
              );
            }
          }),
        );
        filterDataModels = map(filterDataModels, (filterDataModel, index) => {
          const filterId = get(filterIds, index);
          const adapter = get(appliedFilters, [filterId, 'adapter'], '');

          const values = normalizeFilterValues({
            filter: get(appliedFilters, [filterId, 'filter'], ''),
            reject: get(appliedFilters, [filterId, 'reject'], ''),
            labels: extractFilterValues({ adapter, data: filterDataModel }),
            version: get(databoardData, 'version', 1),
          });
          const defaultAdapter = extractFilterDefaultAdapter({ adapter, data: filterDataModel });

          return {
            ...filterDataModel,
            values,
            defaultAdapter,
          };
        });
        const validAppliedFilters = getValidAppliedFilters(
          filterIds,
          filterDataModels,
          appliedFilters,
          appliedSources,
          get(databoardData, 'version', 1),
        );
        forEach(
          filterDataModels,
          (curr, index) => {
            const filterId = get(filterIds, index);
            const filterLabels = get(curr, 'values');
            const defaultAdapter = get(curr, 'defaultAdapter');
            const allOptions = get(curr, 'rows');

            doSetFilterLabels(filterId, filterLabels, defaultAdapter, allOptions);
          },
          {},
        );
        doInitFilterValues(validAppliedFilters);
        doUpdateFilter(appliedFiltersValues);
      } catch (error) {
        console.log('An error occured');
        console.log(error);
      } finally {
        setLoader(false);
      }
    };
    loadFilter();
  };

  useEffect(() => {
    /**
     * - Fetch dashboard spec
     * - Track the DASHBOARD_VISIT_EVENT
     * - Extract sources // TODO: refactor this, not necessary anymore as the model is the same
     * - Parse applied filters from the URL and the databoard definition
     * - Destruct filters to appliedSources and appliedFilters
     * - Fetch the dataquery model for all the applied filters
     * - Keep the filter values which are valid
     *    - Valid filter value = exists in the dataquery AND when applying the filter & reject it remains in the available values
     * - Set the redux store
     */
    const fetchDataboard = async () => {
      setLoader(true);

      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };

        const data = pathURL.includes('daa')
          ? await API.get('dashboards', `/dataanalystaccess/${databoardId}`, params)
          : pathURL.includes('reports')
          ? await API.get('dashboards', `/reports/${databoardId}`, params)
          : await API.get('dashboards', `/databoards/${databoardId}`, params);
        // This function execs in sync in order to avoid race conditions
        // It writes the eventGroup, which it is read from graph analytic actions

        if (pathURL.includes('daa')) {
          await doTrackDataCutVisit(data._id, data.name);
        } else {
          await doTrackDashboardVisit(data._id, data.name);
        }

        if (pathURL.includes('/dashboards')) {
          const sourceLabelArray = map(get(data, 'sources'), (source) => ({
            name: get(source, 'name', ''),
            text: get(source, 'text', ''),
            id: get(source, 'id', -1),
          }));
          const appliedFilters = parseFilters(data, location.search);
          const appliedSources = get(appliedFilters, 'sources', {});
          const filterIds = keys(omit(appliedFilters, 'sources'));
          let filterDataModels = await Promise.all(
            map(filterIds, (filterId) => {
              const query = get(appliedFilters, [filterId, 'query'], '');
              const table = get(appliedFilters, [filterId, 'table'], '');
              if (get(data, 'version', 1) === 1) {
                return API.get(
                  'dashboards',
                  `/dataqueries/name/${query}/filters/name/${table}`,
                  params,
                );
              } else {
                params.body = {
                  version: 2,
                  query: {
                    source: map(sourceLabelArray, (item) => get(item, 'id', 0)),
                    filterQuestion: query,
                    rejectedLabels: get(appliedFilters, [filterId, 'reject'], []),
                    renamedLabels: get(appliedFilters, [filterId, 'replaceLabels'], []),
                    displayLabels: get(appliedFilters, [filterId, 'filter'], []),
                  },
                };
                return API.post(
                  'dashboards',
                  `/users/${userId}/databoards/${databoardId}/engine`,
                  params,
                );
              }
            }),
          );
          let savedViews = await API.get(
            'users',
            `/users/${userId}/views/databoards/${databoardId}`,
            params,
          );
          filterDataModels = map(filterDataModels, (filterDataModel, index) => {
            const filterId = get(filterIds, index);
            const adapter = get(appliedFilters, [filterId, 'adapter'], '');

            const values = normalizeFilterValues({
              filter: get(appliedFilters, [filterId, 'filter'], ''),
              reject: get(appliedFilters, [filterId, 'reject'], ''),
              labels: extractFilterValues({ adapter, data: filterDataModel }),
              version: get(data, 'version', 1),
            });
            const defaultAdapter = extractFilterDefaultAdapter({ adapter, data: filterDataModel });

            return {
              ...filterDataModel,
              values,
              defaultAdapter,
            };
          });

          const validAppliedFilters = getValidAppliedFilters(
            filterIds,
            filterDataModels,
            appliedFilters,
            appliedSources,
            get(data, 'version', 1),
          );

          doSetDataboard({ ...data, labels: sourceLabelArray, savedViews });
          forEach(
            filterDataModels,
            (curr, index) => {
              const filterId = get(filterIds, index);
              const filterLabels = get(curr, 'values');
              const defaultAdapter = get(curr, 'defaultAdapter');
              const allOptions = get(curr, 'rows');

              doSetFilterLabels(filterId, filterLabels, defaultAdapter, allOptions);
            },
            {},
          );

          doInitFilterValues(validAppliedFilters);
        } else {
          doSetDataboard({ ...data, labels: [] });
        }
      } catch (error) {
        console.log('An error occured');
        console.log(error);
      } finally {
        setLoader(false);
      }
    };

    fetchDataboard();

    return () => onUnmount();
  }, [databoardId]);

  useEffect(() => {
    if (!isProcessingQueryString) {
      const refresh = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${queryString}`;
      window.history.pushState({ path: refresh }, '', refresh);
    }
  }, [queryString]);

  const expirationAboutToExpire = useCallback(
    () => {
      const subscription = find(subscriptions, (subscription) => {
        return subscription.databoardId === databoardId;
      });
      const subscriptionExpiration = new Date(get(subscription, 'expirationDate', new Date()));
      const today = new Date();
      if (
        subscriptionExpiration > today &&
        subscriptionExpiration < new Date(today.setMonth(today.getMonth() + 1))
      ) {
        return subscriptionExpiration;
      }
      return false;
    },
    subscriptions,
    databoardId,
  );

  const cardProps = {
    ...idleTimeHandler,
    anchorId: chartName,
    trackerProps: {
      updateChartHash: (key, value) =>
        setChartInViewportHash({ ...chartInViewportHash, [key]: value }),
    },
  };

  const sendEmail = useCallback(() => {
    window.open(
      `mailto:${mailto}?cc=${cc}&subject=${encodeURIComponent(
        `Dashboard Access Renewal: ${title}`,
      )}&body=${encodeURIComponent(
        `Dashboard: ${title}\nEmail: ${email}\nName: ${firstName}\nLast name: ${lastName}\nRole: ${roles}`,
      )}`,
    );
  }, [email, firstName, lastName, roles, title]);

  const sendMoreDataEmail = useCallback(() => {
    window.open(
      `mailto:${mailto}?cc=${cc}&subject=${encodeURIComponent(
        `Access More Data`,
      )}&body=${encodeURIComponent(
        `Email: ${email}\nName: ${firstName}\nLast name: ${lastName}\nRole: ${roles}`,
      )}`,
    );
  }, [email, firstName, lastName, roles]);

  return (
    <Grid container className={classes.root}>
      <Tooltip title="Back to top">
        <Fab className={classes.fabButton} onClick={() => window.scrollTo(0, 0)}>
          <ExpandLessRoundedIcon />
        </Fab>
      </Tooltip>
      <AppLoader show={loader} />
      <DownloadData />
      {/* <ClearFiltersDialog /> */}
      <ConfirmationDialog
        open={showClearFiltersDialog}
        onClose={() => {
          setShowClearFiltersDialog(false);
        }}
        title={'You are about to clear all applied filters'}
        description={
          'This action cannot be undone, but you can save the current filtered view from the funnel icon on the left. Are you sure you want to delete them?'
        }
        onConfirm={resetFilters}
        onCancel={() => {
          setShowClearFiltersDialog(false);
        }}
        confirmButtonLabel={'clear filters'}
        cancelButtonLabel={'cancel'}
      />
      <ConfirmationDialog
        open={showDeleteFiltersDialog}
        onClose={() => {
          setShowDeleteFiltersDialog(false);
        }}
        title={'You are trying to delete a saved filter selection'}
        description={'This action cannot be undone. Are you sure you want to delete it?'}
        onConfirm={deleteView}
        onCancel={() => {
          setShowDeleteFiltersDialog(false);
        }}
        confirmButtonLabel={'delete'}
        cancelButtonLabel={'cancel'}
      />
      <Sidebar
        className={classes.drawer}
        product={
          pathURL.includes('/daa') ? 'daa' : pathURL.includes('/reports') ? 'reports' : 'dashboards'
        }
      />
      {pathURL.includes('/dashboards') && (
        <GlobalFiltersDrawer
          open={showGlobalFilters}
          className={classes.innerDrawer}
          titleInView={scrollClass === ''}
        />
      )}
      <ContentsDrawer
        open={showContents}
        className={classes.innerDrawer}
        titleInView={scrollClass === ''}
      />
      <Grid item xs={12} className={classes.header}>
        {expirationAboutToExpire() && (
          <Alert className={classes.subscriptionAlert} severity="warning">
            {`Your subscription will expire soon. Renew before ${moment(
              expirationAboutToExpire(),
            ).format('DD MMM YYYY')}, to ensure
            uninterrupted access`}
            <span onClick={sendEmail} className={classes.actionCell}>
              Contact Us
            </span>
          </Alert>
        )}
        {pathURL.includes('/daa') && moreDataOpen && (
          <Alert
            icon={false}
            className={classes.moreDataAlert}
            onClose={() => {
              setMoreDataOpen(false);
            }}
          >
            You are viewing a Data Cut requested by someone in your Organisation. Our research
            covers more areas and topics. You can view{' '}
            <span className={classes.link} onClick={() => navigate('/dashboards')}>
              here
            </span>{' '}
            a selection of available insights.{' '}
            <span className={classes.link} onClick={sendMoreDataEmail}>
              Get in touch
            </span>{' '}
            to access more datasets or for tailored solutions.
          </Alert>
        )}
        <div className={cn(classes.main, scrollClass)}>
          {/* <ShareButton /> */}
          <Title />
          {!pathURL.includes('/daa') && <ContactUs />}
          {pathURL.includes('/dashboards') && (
            <FilterTagManager
              loading={viewsLoader}
              onClearFilters={() => setShowClearFiltersDialog(true)}
              onDeleteFilter={onDeleteFilter}
              onUpdateFilter={onUpdateFilter}
              onCreateFilter={onCreateFilter}
              onRenameFilter={onRenameFilter}
              onLoadView={onLoadView}
            />
          )}
        </div>
        {pathURL.includes('/dashboards') && (
          <SectionFilters
            className={classes.sectionFiltersView}
            activeSectionId={activeSection}
            notify={() => setScrollClass('')}
          />
        )}
      </Grid>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.charts}>
          <Grid container spacing={2}>
            {map(sections, (section, index) => {
              return (
                <Fragment key={`section-item-${index}`}>
                  <Section
                    {...section}
                    notify={setActiveSection}
                    cardProps={cardProps}
                    id={`section-item-${index}`}
                    index={index}
                  />
                </Fragment>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      {/* <Snackbar {...viewportSnackbarProps}>
        <MuiAlert {...alertProps}>{viewportMessage}</MuiAlert>
      </Snackbar>
      <Snackbar {...passiveTimerSnackbarProps}>
        <MuiAlert {...alertProps} severity="warning">
          {passiveTimerHandler.delta}
        </MuiAlert>
      </Snackbar> */}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken']),
  sections: get(state, ['databoard', 'sections'], ''),
  userId: get(state, ['user', 'userId'], []),
  showGlobalFilters: get(state, ['filtersData', 'showGlobalFilters']),
  showContents: get(state, ['databoard', 'showContents']),
  queryString: stringifyFilters(state),
  isProcessingQueryString: stringifyFilters(state) === FILTERS_NOT_READY_FLAG,
  databoardData: get(state, ['databoard']),
  email: get(state, ['user', 'email']),
  firstName: get(state, ['user', 'firstName']),
  lastName: get(state, ['user', 'lastName']),
  roles: get(state, ['user', 'roles']),
  title: get(state, ['databoard', 'title'], ''),
  subscriptions: get(state, ['user', 'subscriptions'], []),
});

const mapDispatchToProps = (dispatch) => ({
  doInitFilterValues: (appliedFilters) => {
    dispatch(initializeFilterValues(appliedFilters));
    dispatch(trackFilterUsage(appliedFilters));
  },
  doSetFilterLabels: (id, labels, defaultAdapter, allOptions) =>
    dispatch(setFilterLabels(id, labels, defaultAdapter, allOptions)),
  doSetDataboard: (databoard) => dispatch(setDataboard(databoard)),
  doSetViews: (savedViews) => dispatch(setViews(savedViews)),
  doUpdateFilter: (data) => dispatch(updateFilterValues(data)),
  doTrackDashboardVisit: (dashboardId, dashboardName) =>
    dispatch(trackDashboardVisit(dashboardId, dashboardName)),
  doTrackDataCutVisit: (dashboardId, dashboardName) =>
    dispatch(trackDataCutVisit(dashboardId, dashboardName)),
  doResetFilters: () => dispatch(resetGlobalFilters()),
  onUnmount: () => {
    dispatch(resetDataboard());
    dispatch(resetFilters());
    dispatch(resetData());
    // dispatch(clearEventGroup());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Databoard));
