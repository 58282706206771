import reduce from 'lodash/reduce';
import get from 'lodash/get';
import omitBy from 'lodash/omitBy';
import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import omit from 'lodash/omit';

import { toArray, map, pick, values, isEmpty, head } from 'lodash';
import createAllInputFilterExpression from './createAllInputFilterExpression';
import createInputFilterExpression from './createInputFilterExpression';

const createContextExpression = ({ context, appliedFilters, filters }) => {
  let form = {
    ...omit(appliedFilters, 'benchmark'),
    ...get(appliedFilters, 'benchmark', []),
  };

  return reduce(
    context,
    (acc, definition) => {
      const staticContext = get(definition, 'static', false);
      if (staticContext) {
        const contextFilters = get(definition, 'filters', []);
        if (!isEmpty(contextFilters)) {
          const contextFilter = head(contextFilters);
          const filterValues = get(contextFilter, 'values', []);
          const filterDefinition = find(filters, { name: contextFilter.name });
          const appliedValues = get(appliedFilters, filterDefinition._id, []);
          if (!isEmpty(filterValues)) {
            let filterValue = get(find(filterValues, { name: 'default' }, {}), 'value', '');
            if (appliedValues.length === 1) {
              filterValue = get(
                find(filterValues, { name: head(appliedValues) }, {}),
                'value',
                filterValue,
              );
            }
            return {
              ...acc,
              [get(definition, 'name')]: {
                static: true,
                value: filterValue,
              },
            };
          }
          const binaryVector = [{ name: 'scaled', value: appliedValues.length > 0 ? 1 : 0 }];
          binaryVector.push({ name: 'core', value: appliedValues.length > 0 ? 0 : 1 });
          return {
            ...acc,
            [get(definition, 'name')]: {
              static: true,
              table: get(definition, 'name'),
              values: [
                [
                  {
                    name: get(definition, 'name'),
                    data: [
                      {
                        name: 'default',
                        values: binaryVector,
                      },
                    ],
                  },
                ],
              ],
            },
          };
        }
      }

      const contextFilters = get(definition, 'filters', []);
      const filterMode = get(find(contextFilters, { name: 'default' }), 'mode', 'ALL');
      const mergedFilters = get(find(contextFilters, { name: 'default' }), 'merge', {});
      const rejectedFilters = get(definition, ['filters', 'reject'], []);

      if (!isEmpty(mergedFilters)) {
        const rootFilterName = get(mergedFilters, 'root', '');
        const rootFilterId = get(find(toArray(filters), { name: rootFilterName }), '_id');
        const childrenFilterNames = get(mergedFilters, 'nodes', []);
        const childrenFilterIds = map(childrenFilterNames, (filterName) =>
          get(find(toArray(filters), { name: filterName }), '_id'),
        );

        const childrenFilterValues = values(pick(form, childrenFilterIds));
        form = {
          ...omit(form, childrenFilterIds),
          [rootFilterId]: [get(form, rootFilterId), ...childrenFilterValues],
        };
      }

      const activeFilters = omitBy(
        { ...filters },
        (value) => indexOf(rejectedFilters, get(value, 'name')) !== -1,
      );

      let inputFilter = [];
      if (filterMode === 'ALL') {
        inputFilter = createAllInputFilterExpression({
          form,
          filters: activeFilters,
          contextFilters,
        });
      } else if (filterMode === 'APPLIED') {
        inputFilter = createInputFilterExpression({ form, filters: activeFilters, contextFilters });
      }

      return {
        ...acc,
        [get(definition, 'name')]: {
          table: get(definition, 'table'),
          input: {
            filter: inputFilter,
          },
        },
      };
    },
    {},
  );
};

export default createContextExpression;
