import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { withStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Admin/Layout';
import TextSearch from '../../../components/Admin/TextSearch';
import UserFrom from '../../../components/Admin/UserForm';
import Users from './components/Users';

const styles = (theme) => ({
  root: {
    height: '100%',
  },
  dashboardsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    color: theme.palette.custom.gray4,
    fontSize: '1.4rem',
  },
  textSearchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2.9rem',
  },
  infoContainer: {
    fontFamily: 'Nunito Sans',
    fontSize: '1rem',
    color: theme.palette.custom.gray4,
    marginBottom: '0.7rem',
    display: 'flex',
    alignItems: 'center',
  },
  textSearchContent: {
    marginLeft: '0.3rem',
    fontWeight: 'bold',
    color: theme.palette.custom.gray4,
  },
  paperAnchorRight: { width: '40.6rem' },
  button: {
    textTransform: 'none',
    color: theme.palette.custom.blue2,
    fontWeight: 'bold',
  },
});

const UsersView = ({ classes }) => {
  const [textSearch, setTextSearch] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [userToEdit, setUserToEdit] = useState({});
  const [forceUpdate, setForceUpdate] = useState(false);

  return (
    <Layout>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.textSearchContainer}>
          <TextSearch
            value={textSearch}
            onChange={setTextSearch}
            placeholder="Type to search by any attribute"
          />
          {/* <Button
            onClick={() => {
              setShowForm(true);
              setUserToEdit({});
            }}
            className={classes.button}
          >
            New user
          </Button> */}
        </Grid>
        {!isEmpty(textSearch) && (
          <Grid item xs={12} className={classes.infoContainer}>
            <i>Showing results for</i>
            <Typography className={classes.textSearchContent}>{textSearch}</Typography>
          </Grid>
        )}
        <Grid item xs={12} className={classes.dashboardsContainer}>
          <Users
            textSearch={textSearch}
            onClick={(model) => {
              setUserToEdit(model);
              setShowForm(true);
              setForceUpdate(false);
            }}
            forceUpdate={forceUpdate}
          />
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawerPaper, paperAnchorRight: classes.paperAnchorRight }}
        onClose={() => {
          setShowForm(false);
          setForceUpdate(false);
        }}
        open={showForm}
        variant="temporary"
      >
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            onClick={() => {
              setShowForm(false);
              setForceUpdate(false);
            }}
            variant="text"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <UserFrom
          {...userToEdit}
          onSuccess={() => {
            setShowForm(false);
            setForceUpdate(true);
          }}
          onDelete={() => {
            setShowForm(false);
            setForceUpdate(true);
          }}
        />
      </Drawer>
    </Layout>
  );
};

export default withStyles(styles)(UsersView);
