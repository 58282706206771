import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import map from 'lodash/map';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/core/styles';

import Checkbox from '../Checkbox';
import isDirty from '../utils/isDirty';

const styles = (theme) => ({
  root: { width: '100%', marginTop: '2.4rem' },
  legend: {
    color: theme.palette.custom.blue2,
    fontSize: '1.1rem',
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
    marginLeft: '2.2rem',
    marginBottom: '1.6rem',
  },
});

const Sectors = ({ classes, title, allLabel, className, data, onChange }) => {
  const [selectAll, setSelectAll] = useState(true);

  useEffect(() => {
    setSelectAll(!isDirty(data));
  }, [data]);

  const handleChange = (checked, name) => {
    const item = data[name];
    item.checked = checked;

    onChange({ ...data, [item.name]: item });
  };

  const handleSelectAll = (checked) => {
    const updatedData = reduce(
      map(keys(data), (name) => {
        const item = data[name];
        item.checked = checked;

        return item;
      }),
      (acc, sector) => ({ ...acc, [sector.name]: sector }),
      {},
    );

    onChange(updatedData);
  };

  return (
    <FormControl component="fieldset" className={cn(classes.root, className)}>
      <FormLabel component="legend" className={classes.legend}>
        {title}
      </FormLabel>
      <FormGroup>
        <Checkbox checked={selectAll} label={allLabel} onChange={handleSelectAll} />
        {map(keys(data), (name) => {
          const { label, checked } = data[name];

          return (
            <Checkbox
              key={name}
              name={name}
              label={label}
              checked={checked}
              onChange={handleChange}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export default withStyles(styles)(Sectors);
