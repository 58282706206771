import get from 'lodash/get';
import map from 'lodash/map';
import head from 'lodash/head';
import find from 'lodash/find';

const getMultiSurveySamples = ({ data, table, sources }) => {
  const samples = map(data, (sourceData, index) => {
    const sample = get(
      head(get(head(get(find(sourceData, { name: table }), 'values', [])), 'values', [])),
      'value',
      0,
    );

    return { name: get(sources, index, ''), value: sample };
  });

  return samples;
};

export default getMultiSurveySamples;
