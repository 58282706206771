import React from 'react';

import { connect } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import { getChartLabels } from '../../../../../state/labels/selectors';
import { get } from 'lodash';

const styles = () => ({
  root: {
    padding: 24,
  },
  label: {
    textAlign: 'center',
  },
  value: { fontWeight: 'bold' },
  color: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const foramt = (value, formatter) => {
  if (formatter === 'percentage') {
    const powerOfTen = value < 10 ? 100 : 10;

    return `${Math.round((value + Number.EPSILON) * powerOfTen) / powerOfTen} %`;
  } else {
    if (value === 0) return '';

    return Math.floor(value);
  }
};

const CustomTooltip = ({ classes, active, formatter, payload: payloadArr, graphLabels }) => {
  if (active) {
    const payload = payloadArr;
    return (
      <Paper className={classes.root}>
        {payload.map(({ stroke, fill, dataKey, value }, index) => (
          // eslint-disable-next-line react/jsx-key
          <p className={classes.value}>
            <svg
              className="recharts-surface"
              width="14"
              height="14"
              viewBox="0 0 32 32"
              version="1.1"
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                marginRight: '4px',
              }}
            >
              <path
                stroke="none"
                fill={stroke || fill}
                d="M0,4h32v24h-32z"
                className="recharts-legend-icon"
              />
            </svg>
            {`${get(graphLabels, index, dataKey)} : ${foramt(value, formatter)}`}
          </p>
        ))}
      </Paper>
    );
  }

  return null;
};

const mapStateToProps = (state, { id }) => ({
  labels: getChartLabels(state, id),
});

export default connect(mapStateToProps)(withStyles(styles)(CustomTooltip));
