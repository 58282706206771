import filter from 'lodash/filter';

import hasDashboardAccess from '../../../../../utils/hasDashboardAccess';

const getSubscribed = (reports, subscriptions) => {
  const filteredReports = filter(reports, (report) => {
    return hasDashboardAccess(subscriptions, report._id);
  });

  return filteredReports;
};

export default getSubscribed;
