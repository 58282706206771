import includes from 'lodash/includes';
import filter from 'lodash/filter';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';

const getByName = (dataTables, textSearch) =>
  isEmpty(textSearch)
    ? dataTables
    : filter(
        dataTables,
        ({ title, requestedBy }) =>
          includes(toLower(title), trim(toLower(textSearch))) ||
          includes(toLower(requestedBy), trim(toLower(textSearch))),
      );

export default getByName;
