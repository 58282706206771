import { destroy, initialize } from 'redux-form';

import { reduce, keys, forEach, get, set, map, filter, find } from 'lodash';

import {
  SET_ACTIVE_BLOCK,
  INITIALIZE_FILTER_DATA,
  UPDATE_FILTER_DATA,
  REMOVE_FILTER,
  RESET_GLOBAL_FILTERS,
  RESET_FILTERS,
} from './types';
import getGlobalHiddenFilters from './helpers/getGlobalHiddenFilters';
import { trackFilterUsage } from '../analytics/actions';

const NORMAL_FILTER_LINK = 'NORMAL';

export const setActiveBlock = (block) => ({
  type: SET_ACTIVE_BLOCK,
  payload: block,
});

export const removeFilter = (filterId) => ({
  type: REMOVE_FILTER,
  payload: { filterId },
});

export const updateFilterValues = (form) => (dispatch, getState) => {
  const filterLinks = get(getState(), ['databoard', 'filterLinks'], []);
  const filters = get(getState(), ['filtersIndex', 'byId'], {});
  const filtersArr = map(keys(filters), (key) => filters[key]);
  const formValsArr = map(keys(form), (key) => {
    return { key, value: form[key] };
  });
  forEach(formValsArr, (formVal) => {
    const updatedFilter = get(filters, get(formVal, 'key', ''));
    const sourceLinks = filter(
      filterLinks,
      (link) => link.source === updatedFilter.name && link.type === NORMAL_FILTER_LINK,
    );
    forEach(sourceLinks, (link) => {
      forEach(link.destination, (destination) => {
        const destinationFilter = get(find(filtersArr, { name: destination }), '_id', '');
        set(form, destinationFilter, formVal.value);
      });
    });
  });
  dispatch({
    type: UPDATE_FILTER_DATA,
    payload: { form },
  });
};

/**
 * The state of the filters is handled by redux-form forms.
 * Therefore the pre-selection of values takes place in this action.
 * Since we're initializing the filter values from the query string there is a prioritization issue.
 * Clearly, the values from query params must overwrite the preselected values.
 *
 * @param {*} appliedFilters - Applied filters from query param
 */
export const initializeFilterValues = (appliedFilters) => (dispatch, getState) => {
  const databoard = get(getState(), 'databoard');

  dispatch({
    type: INITIALIZE_FILTER_DATA,
    payload: { databoard, appliedFilters },
  });
};

/**
 * The state of the filters is handled by redux-form forms.
 * Therefore to reset the state, all redux-form forms must be destroyed.
 * Currently, there isn't a provided action for that so we've to iterate.
 */
export const resetFilters = () => (dispatch, getState) => {
  const formNames = keys(get(getState(), 'form'));

  forEach(formNames, (name) => dispatch(destroy(name)));

  return dispatch({
    type: RESET_FILTERS,
  });
};

/**
 * Clear the values of the selected filters
 * @param {*} state App state
 * @param {*} sources Sources to reset the sources filter
 */
const getResetForm = (state, sources) =>
  reduce(
    get(state, ['filtersData', 'byId'], {}),
    (acc, filterValue, filterId) => {
      if (filterId === 'sources') return { ...acc, sources };

      return {
        ...acc,
        [filterId]: [],
      };
    },
    {},
  );

export const resetGlobalFilters = () => (dispatch, getState) => {
  const sources = get(getState(), ['databoard', 'sources']);
  const currentForm = getResetForm(getState(), sources);
  const hiddenGlobalFilters = getGlobalHiddenFilters(getState());

  dispatch(trackFilterUsage(currentForm));
  dispatch(initialize('globalFilters', { sources }));
  dispatch({
    type: RESET_GLOBAL_FILTERS,
    payload: { sources, hiddenGlobalFilters },
  });
};
