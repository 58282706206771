export default {
  custom: {
    white: '#ffffff',
    gray1: '#f9f9fd',
    gray2: '#f5f6fa',
    gray3: '#e7e7ef',
    gray4: '#bcc6d2',
    gray5: '#636666',
    gray6: '#818989',
    black: '#2b2a29',
    purple: '#ca12d4',
    blue1: '#2c55c6',
    blue2: '#0f70e0',
    blue3: '#1db8f0',
    blue4: '#1ce8f0',
  },
  primary: {
    main: '#0f70e0',
  },
  auth: {
    error: '#e40000',
    main: '#b0c0da',
    title: {
      font: "'Nunito Sans Black', sans- serif",
    },
    cta: '#6a27e8',
    readonly: '#b0c0da',
    tnc: '#2b2a28',
  },
};
