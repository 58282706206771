import get from 'lodash/get';
import filter from 'lodash/filter';
import pick from 'lodash/pick';
import map from 'lodash/map';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import flattenDeep from 'lodash/flattenDeep';
import indexOf from 'lodash/indexOf';

export const getChartFiltersByType = (state, chartId, filterType = 'INPUT') => {
  const benchmarkingFilters = get(state, ['filtersIndex', 'benchmarkingByChart', chartId], []);

  const filtersOfTypeIds = map(
    filter(
      pick(get(state, ['filtersIndex', 'byId']), get(state, ['filtersIndex', 'byChart', chartId])),
      ({ type, _id: filterId }) =>
        type === filterType && indexOf(benchmarkingFilters, filterId) === -1,
    ),
    '_id',
  );

  const benchmarkingFilterOfTypeIds = filter(benchmarkingFilters, (filterId) => {
    const filterModel = get(state, ['filtersIndex', 'byId', filterId]);
    const type = get(filterModel, 'type', '');

    return type === filterType;
  });

  return {
    ...pick(get(state, ['filtersData', 'byId']), filtersOfTypeIds),
    benchmark: { ...pick(get(state, ['filtersData', 'byId']), benchmarkingFilterOfTypeIds) },
  };
};

export const getFiltersMutatingSources = (state, chartId, filterType = 'INPUT') =>
  filter(
    filter(
      pick(get(state, ['filtersIndex', 'byId']), get(state, ['filtersIndex', 'byChart', chartId])),
      ({ type }) => type === filterType,
    ),
    (filterItem) => !isEmpty(get(filterItem, 'sources')),
  );

export const getChartFilterSources = (state, chartId, filterType = 'INPUT') => {
  const filtersOfTypeSources = map(getFiltersMutatingSources(state, chartId, filterType), '_id');

  const values = pick(get(state, ['filtersData', 'byId']), filtersOfTypeSources);
  const filterIds = keys(values);
  const sources = map(pick(get(state, ['filtersIndex', 'byId']), filterIds), 'sources');

  const allUniqRejects = uniq(
    flattenDeep(filter(map(sources, 'reject'), (source) => !isEmpty(source))),
  );
  const allUniqFilters = uniq(
    flattenDeep(filter(map(sources, 'filter'), (source) => !isEmpty(source))),
  );

  return {
    reject: allUniqRejects,
    filter: allUniqFilters,
  };
};

export default getChartFiltersByType;
