import { API } from 'aws-amplify';

import get from 'lodash/get';
import map from 'lodash/map';

import { FETCH_CHART_DATA_SUCCESS, FETCH_CHART_DATA_ERROR } from '../../types';
import concatRows from '../concatRows';
import getError from './getError';
import getBenchmarkParams from './getBenchmarkParams';
import getBenchmarkLabels from './getBenchmarkLabels';
import { isEmpty } from 'lodash';
import { dummyDataSelector } from '../../../../utils/xlsx/data/dummyDataSelector';
import { errorLogging } from './logs';

const fetchBenchmarkedMergedSurveyChartData =
  ({
    id,
    name,
    sources,
    benchmarkFilterExpressions,
    rejectExpression,
    columnExpression,
    rowExpression,
    contextExpression,
    computation,
    type,
    version,
    query,
  }) =>
  async (dispatch, getState) => {
    const userId = get(getState(), ['user', 'userId']);
    const jwtToken = get(getState(), ['user', 'jwtToken']);
    const databoardId = get(getState(), ['databoard', 'databoardId']);
    const pathURL = window.location.pathname;

    const params = getBenchmarkParams({
      name,
      sources,
      benchmarkFilterExpressions,
      columnExpression,
      rowExpression,
      rejectExpression,
      contextExpression,
      jwtToken,
      computation,
      pathURL,
      version,
      query,
    });

    // preventing sending requests if the sources variable is empty
    const requests = isEmpty(sources)
      ? []
      : map(params, (payload) =>
          API.post('dashboards', `/users/${userId}/databoards/${databoardId}/engine`, payload),
        );
    const labels = getBenchmarkLabels({ benchmarkFilterExpressions });

    try {
      const allData = await Promise.all(requests);
      const data = concatRows({
        data: allData,
        labels,
      });

      const payload = { id, sources, data };
      dispatch({ type: FETCH_CHART_DATA_SUCCESS, payload });
    } catch (error) {
      if (getError(error).status === 401) {
        const dummydata = dummyDataSelector(type);
        dispatch({
          type: FETCH_CHART_DATA_ERROR,
          error: getError(error),
          payload: { id, sources: dummydata.sources, data: dummydata.data },
        });
      } else {
        errorLogging(id, getState, getError(error).status);
        dispatch({ type: FETCH_CHART_DATA_ERROR, error: getError(error), payload: { id } });
      }
    }
  };

export default fetchBenchmarkedMergedSurveyChartData;
