import React, { useState } from 'react';

import * as Yup from 'yup';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import CircularProgress from '@material-ui/core/CircularProgress';

import Container from '../../components/Container';
import TextField from '../../components/TextField';
import PasswordField from '../../components/PasswordField';
import SubmitButton from '../../components/SubmitButton';
import TextButton from '../../components/TextButton';
import FormTitle from '../../components/FormTitle';
import FormText from '../../components/FormText';

import validator from '../../validator';

import { ReactComponent as Illustration } from './assets/illustration.svg';
import {
  NEW_ACCOUNT_SCREEN,
  FORGOT_PASSWORD_SCREEN,
  RESET_PASSWORD_SCREEN,
  NEW_PASSWORD_SCREEN,
  VERIFY_EMAIL_SCREEN,
} from '../../types';

import signIn from '../../actions/signIn';
import currentAuthenticatedUser from '../../actions/currentAuthenticatedUser';
import { setCurrentUser } from '../../../../state/user/actions';

const ExistingMember = ({
  doSetUser,
  handleSubmit,
  submitting,
  setEmail,
  setPassword,
  setUser,
  setScreen,
}) => {
  const [error, setError] = useState(null);

  const onSignIn = async ({ email, password }) => {
    try {
      await signIn(email, password);
      const user = await currentAuthenticatedUser();
      doSetUser(user);
    } catch (e) {
      if (e.code === 'PasswordResetRequiredException') {
        setEmail(email);
        setScreen(RESET_PASSWORD_SCREEN);
      } else if (e.code === 'NewPasswordRequiredException') {
        setUser(e.user);
        setScreen(NEW_PASSWORD_SCREEN);
      } else if (e.code === 'UserNotConfirmedException') {
        setUser(e.user);
        setEmail(email);
        setPassword(password);
        setScreen(VERIFY_EMAIL_SCREEN);
      }

      setError(e);
    }
  };

  return (
    <Container error={error}>
      <div>
        <Illustration />
      </div>
      <FormTitle>Already Registered</FormTitle>
      <FormText>Your email is already in our database</FormText>
      <form onSubmit={handleSubmit(onSignIn)}>
        <Field autoComplete="email" name="email" label="Email" component={TextField} />
        <Field
          autoComplete="current-password"
          name="password"
          label="Password"
          component={PasswordField}
        />
        <TextButton
          onClick={() => setScreen(FORGOT_PASSWORD_SCREEN)}
          label="Forgot password?"
          disabled={submitting}
        />
        {!submitting && <SubmitButton label="Sign in" />}
        {submitting && <CircularProgress />}
        <TextButton
          text="Not a member yet?"
          onClick={() => setScreen(NEW_ACCOUNT_SCREEN)}
          label="Sign up"
          disabled={submitting}
        />
        <div />
      </form>
    </Container>
  );
};

const schema = Yup.object().shape({
  email: Yup.string().email().trim().required(),
  password: Yup.string().trim().required(),
});

const mapDispatchToProps = (dispatch) => ({
  doSetUser: (user) => dispatch(setCurrentUser(user)),
});

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: 'existingMemberForm',
    asyncValidate: validator(schema),
  }),
)(ExistingMember);
