import { API } from 'aws-amplify';

import export2Csv from '../../../../../utils/export2Csv';
import { CsvHeader } from '../../@types/Dashboard';

interface ExportAction {
  name: string;
  params: any;
  headers?: CsvHeader[];
}

const defaultHeaders = [
  <CsvHeader>{ key: 'account', label: 'Account' },
  <CsvHeader>{ key: 'name', label: 'Email' },
  <CsvHeader>{ key: 'value', label: 'Number of dashboard visits' },
];

const exportAction = ({ name, params, headers = defaultHeaders }: ExportAction) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await API.post('dashboards', '/analytics/engine', params);
      export2Csv(data, headers, `${name}.csv`);
      resolve(name);
    } catch (error) {
      reject(error);
    }
  });

export default exportAction;
