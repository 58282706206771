import filter from 'lodash/filter';
import toArray from 'lodash/toArray';
import get from 'lodash/get';
import map from 'lodash/map';
import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';

const getByProduct = (dashboards, searchCriteria) => {
  const productFilters = toArray(get(searchCriteria, ['filters', 'products'], []));
  const products = filter(productFilters, ({ checked }) => checked);

  if (productFilters.length === products.length) return dashboards;
  if (products.length === 0) return filter(dashboards, (dashboard) => isEmpty(dashboard.type));

  const selectedProducts = map(products, 'name');

  return filter(dashboards, (dashboard) => indexOf(selectedProducts, dashboard.type) !== -1);
};

export default getByProduct;
