import React from 'react';

import { connect } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import { getChartLabels } from '../../../../../state/labels/selectors';

const styles = () => ({
  root: {
    padding: 24,
  },
  label: {
    textAlign: 'center',
  },
  value: { fontWeight: 'bold' },
  color: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const UnstackedBarContent = ({
  classes,
  fill,
  dataKey,
  labels,
  formatter,
  value,
  palette,
  chartPalette,
  labelKey,
}) => {
  const label = get(find(labels, { name: dataKey }), 'text', dataKey);

  const getFill = () => {
    return get(
      find(chartPalette, { key: labelKey }),
      'value',
      get(
        find(palette, { key: labelKey }),
        'value',
        get(
          find(chartPalette, { key: label }),
          'value',
          get(
            find(palette, { key: label }),
            'value',
            get(
              find(chartPalette, { key: dataKey }),
              'value',
              get(find(palette, { key: dataKey }), 'value', fill),
            ),
          ),
        ),
      ),
    );
  };

  return (
    <p className={classes.value}>
      <svg
        className="recharts-surface"
        width="14"
        height="14"
        viewBox="0 0 32 32"
        version="1.1"
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginRight: '4px',
        }}
      >
        <path stroke="none" fill={getFill()} d="M0,4h32v24h-32z" className="recharts-legend-icon" />
      </svg>
      {`${label} : ${formatter(value)}`}
    </p>
  );
};

const StackedBarContent = ({ classes, bar, labels, formatter, data, palette, chartPalette }) => {
  const { dataKey } = bar;

  if (isEmpty(dataKey)) return null;

  const label = get(find(labels, { name: dataKey }), 'text', dataKey);

  const getFill = (bar) => {
    return get(
      find(chartPalette, { key: bar.dataKey }),
      'value',
      get(find(palette, { key: bar.dataKey }), 'value', bar.fill),
    );
  };

  return (
    <div>
      <p className={classes.value}>{`${label} : ${formatter(data[dataKey])}`}</p>
      <div className={classes.color}>
        <svg
          className="recharts-surface"
          width="14"
          height="14"
          viewBox="0 0 32 32"
          version="1.1"
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginRight: '4px',
          }}
        >
          <path
            stroke="none"
            fill={getFill(bar)}
            d="M0,4h32v24h-32z"
            className="recharts-legend-icon"
          />
        </svg>
      </div>
    </div>
  );
};

const CustomTooltip = ({
  classes,
  active,
  label: labelKey,
  labels,
  bar,
  data,
  formatter,
  payload,
  palette,
  chartPalette,
}) => {
  if (active) {
    if (isEmpty(bar)) return null;

    const label = get(find(labels, { name: labelKey }), 'text', labelKey);

    return (
      <Paper className={classes.root}>
        <p className={classes.label}>{label}</p>
        {isEmpty(get(bar, 'stackId', '')) &&
          map(payload, ({ fill, dataKey, value }) => (
            <UnstackedBarContent
              key={dataKey}
              labelKey={labelKey}
              classes={classes}
              fill={fill}
              dataKey={dataKey}
              value={value}
              formatter={formatter}
              labels={labels}
              palette={palette}
              chartPalette={chartPalette}
            />
          ))}
        {!isEmpty(get(bar, 'stackId', '')) && (
          <StackedBarContent
            classes={classes}
            bar={bar}
            formatter={formatter}
            data={data}
            labels={labels}
            palette={palette}
            chartPalette={chartPalette}
          />
        )}
      </Paper>
    );
  }

  return null;
};

const mapStateToProps = (state, { id }) => ({
  labels: getChartLabels(state, id),
  palette: get(state, ['databoard', 'palette'], []),
  chartPalette: get(state, ['charts', 'byId', id, 'palette'], []),
});

export default connect(mapStateToProps)(withStyles(styles)(CustomTooltip));
