import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import Welcome from './screens/Welcome';
import SignIn from './screens/SignIn';
import NewAccount from './screens/NewAccount';
import VerifyEmail from './screens/VerifyEmail';
import SuccessScreen from './screens/SuccessScreen';
import ExistingMember from './screens/ExistingMember';
import ForgotPassword from './screens/ForgotPassword';
import ResetPassword from './screens/ResetPassword';
import NewPassword from './screens/NewPassword';
import Footer from '../Footer';

import {
  WELCOME_SCREEN,
  SIGN_IN_SCREEN,
  NEW_ACCOUNT_SCREEN,
  VERIFY_EMAIL_SCREEN,
  SUCCESS_SCREEN,
  EXISTING_MEMBER_SCREEN,
  FORGOT_PASSWORD_SCREEN,
  RESET_PASSWORD_SCREEN,
  NEW_PASSWORD_SCREEN,
} from './types';

const screenManager = (screen) => {
  switch (screen) {
    case WELCOME_SCREEN:
      return Welcome;
    case SIGN_IN_SCREEN:
      return SignIn;
    case NEW_ACCOUNT_SCREEN:
      return NewAccount;
    case VERIFY_EMAIL_SCREEN:
      return VerifyEmail;
    case SUCCESS_SCREEN:
      return SuccessScreen;
    case EXISTING_MEMBER_SCREEN:
      return ExistingMember;
    case FORGOT_PASSWORD_SCREEN:
      return ForgotPassword;
    case RESET_PASSWORD_SCREEN:
      return ResetPassword;
    case NEW_PASSWORD_SCREEN:
      return NewPassword;
    default:
      return React.Fragment;
  }
};

const styles = (theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
  },
  container: {
    paddingTop: 177,
    [theme.breakpoints.down('lg')]: { paddingTop: 50 },
    backgroundColor: 'white',
  },
});

const Authentication = ({ classes, initialScreen }) => {
  const [screen, setScreen] = useState(initialScreen);
  const [codeDeliveryDetails, setCodeDeliveryDetails] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState({});
  const [previousError, setPreviousError] = useState({});

  const ScreenComponent = screenManager(screen);
  const signInProps = screen === SIGN_IN_SCREEN ? { setUser, setEmail, setPassword } : {};
  const existingMemberProps =
    screen === EXISTING_MEMBER_SCREEN ? { setUser, setEmail, setPassword } : {};
  const newPasswordProps = screen === NEW_PASSWORD_SCREEN ? { user } : {};
  const forgotProps =
    screen === FORGOT_PASSWORD_SCREEN ? { setCodeDeliveryDetails, setEmail, setPreviousError } : {};
  const resetProps =
    screen === RESET_PASSWORD_SCREEN ? { codeDeliveryDetails, email, setPassword } : {};
  const newAccountProps = screen === NEW_ACCOUNT_SCREEN ? { setEmail, setPassword } : {};
  const verifyEmailProps = screen === VERIFY_EMAIL_SCREEN ? { email, password, previousError } : {};
  const successScreenProps = screen === SUCCESS_SCREEN ? { email, password } : {};
  const screenProps = {
    setScreen,
    ...forgotProps,
    ...resetProps,
    ...newAccountProps,
    ...verifyEmailProps,
    ...successScreenProps,
    ...signInProps,
    ...newPasswordProps,
    ...existingMemberProps,
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item align="center" sm={12} className={classes.item}>
          <ScreenComponent {...screenProps} />
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default withStyles(styles)(Authentication);
