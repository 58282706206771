import reduce from 'lodash/reduce';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import flattenDeep from 'lodash/flattenDeep';
import filter from 'lodash/filter';
import difference from 'lodash/difference';
import indexOf from 'lodash/indexOf';

const extractBenchmarkingByChart = (sections) => {
  return reduce(
    sections,
    (dictionary, section) => {
      const sectionBenchmarkingFilters = flattenDeep(map(get(section, 'benchmarking', []), '_id'));
      const sectionCharts = map(get(section, 'cards', []), (card) => get(card, 'chart', {}));
      const rejectedBenchmarkingFiltersPerChart = reduce(
        sectionCharts,
        (result, chart) => {
          const chartId = get(chart, '_id', '');
          const rejectedFilterNames = get(chart, ['benchmarking', 'reject'], []);
          const rejectedFilterIds = map(
            filter(
              get(section, 'benchmarking', []),
              (filterModel) => indexOf(rejectedFilterNames, filterModel.name) !== -1,
            ),
            '_id',
          );

          return {
            ...result,
            [chartId]: rejectedFilterIds,
          };
        },
        {},
      );

      if (isEmpty(sectionBenchmarkingFilters)) return dictionary;

      const currentSection = reduce(
        sectionCharts,
        (result, chart) => {
          const chartId = get(chart, '_id', '');

          return {
            ...result,
            [chartId]: difference(
              sectionBenchmarkingFilters,
              get(rejectedBenchmarkingFiltersPerChart, chartId, []),
            ),
          };
        },
        {},
      );

      return {
        ...dictionary,
        ...currentSection,
      };
    },
    {},
  );
};

export default extractBenchmarkingByChart;
