import React from 'react';
import cn from 'classnames';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    fontFamily: 'Nunito Sans Black',
    fontSize: '1.5rem',
    color: theme.palette.custom.black,
    textAlign: 'center',
    marginTop: '3.75rem',
    width: '75%',
    alignSelf: 'center',
  },
  isBlured: {
    filter: 'blur(8px)',
    userSelect: 'none',
  },
});

const Title = ({ classes, children, isBlured }) => {
  return (
    <div className={cn(classes.root, { [classes.isBlured]: isBlured })}>
      <div>{children}</div>
    </div>
  );
};

export default withStyles(styles)(Title);
