import includes from 'lodash/includes';
import filter from 'lodash/filter';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';
import get from 'lodash/get';

const getByDataboard = (subscriptions, textSearch) => {
  if (isEmpty(textSearch)) return subscriptions;

  return filter(
    subscriptions,
    ({ databoard, dataanalystaccess, report, briefing }) =>
      includes(toLower(get(databoard, 'title')), trim(toLower(textSearch))) ||
      includes(toLower(get(dataanalystaccess, 'title')), trim(toLower(textSearch))) ||
      includes(toLower(get(report, 'title')), trim(toLower(textSearch))) ||
      includes(toLower(get(briefing, 'title')), trim(toLower(textSearch))),
  );
};

export default getByDataboard;
