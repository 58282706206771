import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import isNull from 'lodash/isNull';
import { tail } from 'lodash';

const getNullRowIndexes = (data) => {
  const nullRowIndexes = [];

  forEach(data, (row, rowIndex) => {
    if (rowIndex > 0) {
      const nonNullItems = filter(tail(row), (rowItem) => !isNull(rowItem));
      if (nonNullItems.length === 0) {
        nullRowIndexes.push(rowIndex);
      }
    }
  });

  return nullRowIndexes;
};

export default getNullRowIndexes;
