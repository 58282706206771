import React, { useState } from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';

import map from 'lodash/map';
import get from 'lodash/get';
import some from 'lodash/some';
import indexOf from 'lodash/indexOf';

import {
  BarChart,
  Bar,
  Brush,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  Cell,
} from 'recharts';

import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';

import formatters from './formatters';
import CustomTick from './components/CustomTick';
import CustomLegend from './components/CustomLegend';
import LocalAxisSamples from './components/LocalAxisSamples';
import CustomLabel from './components/CustomLabel';
import { getTextDimensions } from './components/CustomLabel/CustomLabel';
import CustomTooltip from './components/CustomTooltip';
import findLongestString from './utils/findLongestString';
import { getChartLabels } from '../../../state/labels/selectors';
import YAxisSample from './components/YAxisSample';
import findRectHeight from './utils/findRectHeight';
import { find, omit } from 'lodash';

const styles = (theme) => ({
  chart: {
    fontFamily: 'Nunito Sans',
    '& .recharts-label-list .custom-label-text': {
      fontSize: 16,
    },
    '& .recharts-label-list .custom-label-tiny-text': {
      fontSize: '0.6rem',
      fontFamily: 'Nunito Sans Black',
    },
    '& .recharts-text.recharts-cartesian-axis-tick-value': {
      fontSize: 16,
      fill: theme.palette.custom.black,
    },
    '& .recharts-tooltip-cursor': {
      color: theme.palette.custom.blue3,
      fill: theme.palette.custom.blue3,
      opacity: 0.2,
    },
  },
  dialogView: {
    '& .recharts-text.recharts-cartesian-axis-tick-value': {
      fontSize: '1.2rem',
    },
  },

  sampleItem: {
    fontFamily: 'Nunito Sans',
  },
});

const generateAxisTicks = ({ axisOrientation, type, ...xAxisTicks }) => {
  switch (type) {
    case 'custom':
      if (axisOrientation === 'DIAGONAL') {
        return { tick: <CustomTick angle={-45} textAnchor="end" {...xAxisTicks} /> };
      }
      if (axisOrientation === 'HORIZONTAL') {
        return {
          tick: (
            <CustomTick
              {...omit(xAxisTicks, ['angle, textAnchor'])}
              angle={0}
              textAnchor="middle"
            />
          ),
        };
      }
      return { tick: <CustomTick {...xAxisTicks} /> };
    case 'localSamples':
      return { tick: <LocalAxisSamples {...xAxisTicks} /> };
    default:
      return {};
  }
};

const generateLegend = ({ type, ...rest }) => {
  switch (type) {
    case 'custom':
      return { content: <CustomLegend {...rest} /> };
    default:
      return { content: <CustomLegend {...rest} /> };
  }
};

const Y_AXIS_WIDTH_DEFAULT = 91;

const calculateWidth = ({ data, axisProps: { adjustWidth }, labels }) => {
  if (!adjustWidth) return Y_AXIS_WIDTH_DEFAULT;

  const longestStr = findLongestString({ data, labels });

  const upperCasedLabel = longestStr.toUpperCase();
  const { width } = getTextDimensions(upperCasedLabel, 16);
  if (width < Y_AXIS_WIDTH_DEFAULT) return Y_AXIS_WIDTH_DEFAULT;

  return Math.min(Y_AXIS_WIDTH_DEFAULT + width, 425);
};

const X_AXIS_HEIGHT_DEFAULT = 104;

const calculateHeight = ({
  data,
  axisProps: { adjustHeight, customHeight, height, ticksProps },
  labels,
}) => {
  if (customHeight) return height;
  if (!adjustHeight) return X_AXIS_HEIGHT_DEFAULT;

  const longestStr = findLongestString({ data, labels });
  // There is a difference in width when the case differs. Currently, get the worst case of all upper case
  const upperCasedLabel = longestStr.toUpperCase();
  const { width } = getTextDimensions(upperCasedLabel, 16);

  const hypotenuse = width < get(ticksProps, 'width', 0) ? width : get(ticksProps, 'width', 0);
  const angle = -45;

  return findRectHeight(hypotenuse, angle) + 20;
};

const defaultBrush = { visibility: false, dataKey: null };

const BarChartWrapper = ({
  id,
  classes,
  width: mediaQueryWidth,
  view,
  data,
  samples,
  series,
  tooltipProps,
  rootProps,
  legendProps,
  cartesianGridProps,
  labels,
  xAxisProps,
  yAxisProps,
  chartThreshold,
  brush = defaultBrush,
  palette,
  chartPalette,
  rejected,
  axisOrientation,
  sampleVisible,
}) => {
  const [inactiveSeries, setInactiveSeries] = useState([]);
  const [activeEntry, setActiveEntry] = useState(null);
  const [activeBar, setActiveBar] = useState(null);

  const { ticksProps: xAxisLabelResponsiveProps, ...xAxisResponsiveProps } = get(
    xAxisProps,
    ['responsive', mediaQueryWidth],
    {},
  );

  const handleInactiveSeries = ({ dataKey }) => {
    const index = indexOf(inactiveSeries, dataKey);

    if (index === -1) {
      setInactiveSeries([...inactiveSeries, dataKey]);
    } else {
      const temp = [...inactiveSeries];
      temp.splice(index, 1);
      setInactiveSeries(temp);
    }
  };

  const updateTooltipValues = (entry, bar) => {
    setActiveBar(bar);
    setActiveEntry(entry);
  };

  const getFill = (entry, bar) => {
    return get(
      find(chartPalette, { key: entry.name }),
      'value',
      get(
        find(chartPalette, { key: bar.dataKey }),
        'value',
        get(
          find(palette, { key: entry.name }),
          'value',
          get(find(palette, { key: bar.dataKey }), 'value', bar.fill),
        ),
      ),
    );
  };

  return (
    <ResponsiveContainer height="100%">
      <BarChart
        {...rootProps}
        data={data}
        className={cn(classes.chart, { [classes.dialogView]: view === 'dialog' })}
      >
        {!get(cartesianGridProps, 'hide') && <CartesianGrid {...cartesianGridProps} />}
        <XAxis
          {...xAxisProps}
          {...xAxisResponsiveProps}
          {...generateAxisTicks({
            ...get(xAxisProps, 'ticksProps', {}),
            axisOrientation,
            ...xAxisLabelResponsiveProps,
            id,
            view,
          })}
          height={calculateHeight({ axisProps: xAxisProps, data, labels })}
        />
        <YAxis
          {...yAxisProps}
          // {...yAxisResponsiveProps}
          {...generateAxisTicks({
            ...get(yAxisProps, 'ticksProps', {}),
            id,
            view,
            // ...yAxisLabelResponsiveProps,
          })}
          width={calculateWidth({ axisProps: yAxisProps, data, labels })}
        />
        {yAxisProps.showSamplesInAxis && sampleVisible === 'true' && (
          <YAxis
            yAxisId="right"
            orientation="right"
            type="category"
            width={104}
            axisLine={false}
            tickLine={false}
            angle={0}
            textAnchor="middle"
            interval={0}
            tick={<YAxisSample samples={samples} data={data} chartThreshold={chartThreshold} />}
          />
        )}
        {!get(tooltipProps, 'hide') && (
          <Tooltip
            // cursor={false}
            formatter={get(formatters, get(tooltipProps, 'formatter'))}
            content={<CustomTooltip id={id} bar={activeBar} data={activeEntry} />}
          />
        )}
        {!get(legendProps, 'hide') && (
          <Legend
            {...legendProps}
            {...generateLegend({ type: get(legendProps, 'type'), samples, id })}
            series={series}
            inactiveSeries={inactiveSeries}
            onClick={handleInactiveSeries}
          />
        )}
        {brush.visibility && <Brush dataKey={brush.dataKey} height={26} />}
        {map(series, ({ _id, label, ...bar }, index) => {
          if (!some(data, bar.dataKey)) {
            return null;
          }

          if (
            indexOf(rejected, bar.dataKey) !== -1 ||
            indexOf(inactiveSeries, bar.dataKey) !== -1
          ) {
            return null;
          }

          return (
            <Bar key={`bar-${index}-${_id}`} {...bar}>
              {data.map((entry, cellIndex) => {
                return (
                  <Cell
                    fill={getFill(entry, bar)}
                    key={`cell-${cellIndex}`}
                    {...entry}
                    onMouseEnter={() => updateTooltipValues(entry, bar)}
                  />
                );
              })}
              <LabelList
                dataKey={bar.dataKey}
                // position={get(label, 'position')}
                formatter={get(formatters, get(label, 'formatter'))}
                content={CustomLabel}
              />
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

const mapStateToProps = (state, { id }) => ({
  labels: getChartLabels(state, id),
  rejected: get(state, ['charts', 'byId', id, 'reject'], []),
  palette: get(state, ['databoard', 'palette'], []),
  chartPalette: get(state, ['charts', 'byId', id, 'palette'], []),
  axisOrientation: get(state, ['charts', 'byId', id, 'axis', 'orientation'], ''),
});

export default connect(mapStateToProps)(withStyles(styles)(withWidth()(BarChartWrapper)));
