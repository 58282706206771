import FileSaver from 'file-saver';

/**
 * Simple safari detection based on user agent test
 */
const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const isJsons = (array) =>
  Array.isArray(array) && array.every((row) => typeof row === 'object' && !(row instanceof Array));

const isArrays = (array) => Array.isArray(array) && array.every((row) => Array.isArray(row));

const jsonsHeaders = (array) =>
  Array.from(array.map((json) => Object.keys(json)).reduce((a, b) => new Set([...a, ...b]), []));
const getHeaderValue = (property, obj) => {
  const foundValue = property
    .replace(/\[([^\]]+)]/g, '.$1')
    .split('.')
    .reduce((o, p, i, arr) => {
      // if at any point the nested keys passed do not exist, splice the array so it doesnt keep reducing
      if (o[p] === undefined) {
        arr.splice(1);
      } else {
        return o[p];
      }
    }, obj);

  return foundValue === undefined ? '' : foundValue;
};

const jsons2arrays = (jsons, headers) => {
  const curHeaders = headers || jsonsHeaders(jsons);

  // allow headers to have custom labels, defaulting to having the header data key be the label
  let headerLabels = curHeaders;
  let headerKeys = curHeaders;
  if (isJsons(curHeaders)) {
    headerLabels = curHeaders.map((header) => header.label);
    headerKeys = curHeaders.map((header) => header.key);
  }

  const data = jsons.map((object) => headerKeys.map((header) => getHeaderValue(header, object)));
  return [headerLabels, ...data];
};

const elementOrEmpty = (element) => (element || element === 0 ? element : '');

const joiner = (data, separator = ',', enclosingCharacter = '"') => {
  return data
    .filter((e) => e)
    .map((row) =>
      row
        .map((element) => elementOrEmpty(element))
        .map((column) => `${enclosingCharacter}${column}${enclosingCharacter}`)
        .join(separator),
    )
    .join(`\n`);
};

const arrays2csv = (data, headers, separator, enclosingCharacter) =>
  joiner(headers ? [headers, ...data] : data, separator, enclosingCharacter);

const jsons2csv = (data, headers, separator, enclosingCharacter) =>
  joiner(jsons2arrays(data, headers), separator, enclosingCharacter);

const string2csv = (data, headers, separator) =>
  headers ? `${headers.join(separator)}\n${data}` : data;

const toCSV = (data, headers, separator, enclosingCharacter) => {
  if (isJsons(data)) return jsons2csv(data, headers, separator, enclosingCharacter);
  if (isArrays(data)) return arrays2csv(data, headers, separator, enclosingCharacter);
  if (typeof data === 'string') return string2csv(data, headers, separator);
  throw new TypeError(`Data should be a "String", "Array of arrays" OR "Array of objects" `);
};

/**
 * This file is based on https://github.com/react-csv/react-csv/blob/master/src/core.js
 * but it's customized for our needs:
 *
 * - Async data fetching and then export
 * - File name
 */
const exportData = (
  data,
  headers,
  filename = 'generated-file.csv',
  separator,
  enclosingCharacter,
) => {
  const csv = toCSV(data, headers, separator, enclosingCharacter);
  const type = isSafari() ? 'application/csv' : 'text/csv';
  const blob = new Blob(['\uFEFF', csv], { type });

  return FileSaver.saveAs(blob, filename);
};

export default exportData;
