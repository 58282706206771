import moment from 'moment';

import filter from 'lodash/filter';

const getExpiredSubscriptions = (subscriptions) => {
  const now = moment();
  return filter(subscriptions, ({ expirationDate }) => {
    return moment(expirationDate).isBefore(now);
  });
};

export default getExpiredSubscriptions;
