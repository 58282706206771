import { API } from 'aws-amplify';

import get from 'lodash/get';
import map from 'lodash/map';

import { FETCH_CHART_DATA_SUCCESS, FETCH_CHART_DATA_ERROR } from '../../types';
import concatCols from '../concatCols';
import getError from './getError';
import getBenchmarkParams from './getBenchmarkParams';
import getBenchmarkLabels from './getBenchmarkLabels';
import { dummyDataSelector } from '../../../../utils/xlsx/data/dummyDataSelector';
import { errorLogging } from './logs';

const fetchBenchmarkedMultiSurveyChartData =
  ({
    id,
    name,
    sources,
    benchmarkFilterExpressions,
    rejectExpression,
    columnExpression,
    rowExpression,
    contextExpression,
    computation,
    type,
    version,
    query,
  }) =>
  async (dispatch, getState) => {
    const userId = get(getState(), ['user', 'userId']);
    const jwtToken = get(getState(), ['user', 'jwtToken']);
    const databoardId = get(getState(), ['databoard', 'databoardId']);
    const pathURL = window.location.pathname;

    const requests = map(sources, (source) => {
      const params = getBenchmarkParams({
        name,
        // Get params for the current source
        sources: [source],
        benchmarkFilterExpressions,
        columnExpression,
        rowExpression,
        rejectExpression,
        contextExpression,
        jwtToken,
        computation,
        pathURL,
        version,
        query,
      });

      return map(params, (payload) =>
        API.post('dashboards', `/users/${userId}/databoards/${databoardId}/engine`, payload),
      );
    });
    const labels = getBenchmarkLabels({ benchmarkFilterExpressions });

    try {
      const data = await Promise.all(
        map(requests, async (requestGroup) => {
          const group = await Promise.all(requestGroup);

          return concatCols({
            data: group,
            labels,
          });
        }),
      );

      const payload = { id, sources, data };
      dispatch({ type: FETCH_CHART_DATA_SUCCESS, payload });
    } catch (error) {
      if (getError(error).status === 401) {
        const dummydata = dummyDataSelector(type);
        dispatch({
          type: FETCH_CHART_DATA_ERROR,
          error: getError(error),
          payload: { id, sources: dummydata.sources, data: dummydata.data },
        });
      } else {
        errorLogging(id, getState, getError(error).status);
        dispatch({ type: FETCH_CHART_DATA_ERROR, error: getError(error), payload: { id } });
      }
    }
  };

export default fetchBenchmarkedMultiSurveyChartData;
