export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_SUBSCRIPTIONS = 'SET_USER_SUBSCRIPTIONS';
export const SET_USER_NOTIFICATIONS = 'SET_USER_NOTIFICATIONS';
export const SIGN_OUT = 'SIGN_OUT';

export const SIGN_OUT_USER_SUCCESS = 'SIGN_OUT_USER_SUCCESS';

export const CREATE_BOOKMARK = 'CREATE_BOOKMARK';
export const REMOVE_BOOKMARK = 'REMOVE_BOOKMARK';

export const SET_SEARCH_KEY = 'SET_SEARCH_KEY';
