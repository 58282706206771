import React from 'react';

import isEmpty from 'lodash/isEmpty';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = (/*theme*/) => ({
  root: {
    marginBottom: '1.2rem',
    width: '32.5rem',
    // '& p': {
    //   position: 'absolute',
    //   transform: 'translate(250px, 17px)',
    //   fontWeight: 'bold',
    //   fontStyle: 'italic',
    //   fontSize: 11,
    //   color: '#eb0b30',
    // },
  },
  field: {
    // textAlign: 'left',
    // borderColor: '#bcc6d2',
    // margin: '5px 0',
    // height: 46,
    // borderRadius: 23,
  },
  label: {
    // fontSize: 12,
    // color: '#636666',
    // transform: 'translate(25px, 22px) scale(1)',
  },
  labelFocused: {
    // color: theme.palette.custom.blue2,
    // fontSize: 6,
    // fontWeight: 'bold',
    // transform: 'translate(25px, 11px) !important',
  },
  labelShrink: {
    // fontSize: 6,
    // transform: 'translate(25px, 11px) !important',
  },
  fieldFocused: {
    // borderColor: theme.palette.custom.blue2,
  },
  icon: {
    // color: theme.palette.custom.blue2,
  },
  input: {
    // paddingLeft: 25,
  },
});

const TextFieldWrapper = ({
  label,
  classes,
  name = 'text',
  type = 'text',
  required = true,
  autoComplete,
  endAdornment,
  disabled,
  input,
  meta: { touched, error },
}) => {
  return (
    <TextField
      error={!isEmpty(error) && touched}
      color="primary"
      label={label}
      type={type}
      name={name}
      value={input.value}
      onChange={input.onChange}
      onBlur={input.onBlur}
      helperText={!isEmpty(error) && touched ? error : ''}
      autoComplete={autoComplete}
      margin="none"
      // variant="outlined"
      required={required}
      InputLabelProps={{
        classes: {
          // root: classes.label,
          // focused: classes.labelFocused,
          // shrink: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          // root: classes.field,
          // focused: classes.fieldFocused,
          // input: classes.input,
        },
        // notched: false,
        endAdornment,
      }}
      classes={{ root: classes.root, error: classes.error }}
      disabled={disabled}
    />
  );
};

export default withStyles(styles)(TextFieldWrapper);
