import React from 'react';
import cn from 'classnames';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import Sidebar from '../Sidebar';
import Footer from '../../../../components/Footer';

const drawerWidth = '3.75rem';
const innerDrawerWidth = '21.5rem';

const styles = (theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: drawerWidth,
    marginBottom: 8,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: innerDrawerWidth,
  },
  drawer: {
    width: drawerWidth,
  },
  innerDrawer: {
    width: innerDrawerWidth,
    left: drawerWidth,
  },
});

const Layout = ({ classes, children, onChange }) => {
  return (
    <div className={classes.root}>
      <Sidebar
        InnerDrawerProps={{
          className: classes.innerDrawer,
          onChange,
        }}
      />
      <Grid
        container
        className={cn(classes.content, {
          [classes.contentShift]: true,
          'animated fadeIn': true,
        })}
      >
        {children}
      </Grid>
      <Footer />
    </div>
  );
};

export default withStyles(styles)(Layout);
