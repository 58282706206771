import filter from 'lodash/filter';

import moment from 'moment';

const hasDashboardAccess = (subscriptions, databoardId) => {
  // This will return an array with all subscriptions to a particular databoard
  const filteredSubscriptions = filter(subscriptions, { databoardId });

  // Will return true if one of the subscriptions is valid
  return filteredSubscriptions.some((subscription) => {
    return (
      moment().isAfter(subscription.startDate) && moment().isBefore(subscription.expirationDate)
    );
  });
};

export default hasDashboardAccess;
