import getByReport from './getByReport';
import getBySector from './getBySector';
import getByProduct from './getByProduct';

const getFilteredReports = (reports, textSearch, searchCriteria) => {
  let reportsSubset = getBySector(reports, searchCriteria);
  reportsSubset = getByProduct(reportsSubset, searchCriteria);
  const filteredDashboards = getByReport(reportsSubset, textSearch);
  return filteredDashboards;
};

export default getFilteredReports;
