import map from 'lodash/map';
import reduce from 'lodash/reduce';
import concat from 'lodash/concat';
import get from 'lodash/get';
import flattenDeep from 'lodash/flattenDeep';

const extractSectionFilters = (databoard) =>
  reduce(
    databoard.sections,
    (acc, section) => ({
      ...acc,
      [section._id]: concat(
        map(section.filters, '_id'),
        flattenDeep(map(get(section, 'benchmarking', []), (filter) => get(filter, '_id', ''))),
      ),
    }),
    {},
  );

export default extractSectionFilters;
