import { get } from 'lodash';
import { SET_INITIAL_REPORTS_SEARCH } from './types';

const initialState = {
  initialReportsSearch: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_REPORTS_SEARCH:
      return {
        ...state,
        initialReportsSearch: get(action, ['payload'], ''),
      };
    default:
      return state;
  }
};

export default reducer;
