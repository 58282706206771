import get from 'lodash/get';
import forEach from 'lodash/forEach';

import {
  EXPORT_DATABOARD_DATA_REQUEST,
  EXPORT_DATABOARD_DATA_SUCCESS,
  EXPORT_DATABOARD_DATA_PPTX,
  EXPORT_CHART_DATA_REQUEST,
  EXPORT_CHART_DATA_SUCCESS,
} from './types';

import { fetchByChartId } from '../data/actions';

export const exportDataboardData = () => (dispatch, getState) => {
  dispatch({ type: EXPORT_DATABOARD_DATA_REQUEST });

  const chartIds = get(getState(), ['charts', 'all'], []);

  forEach(chartIds, (chartId) => dispatch(fetchByChartId(chartId)));
};

export const exportDataboardDataPPTX = () => (dispatch) => {
  dispatch({ type: EXPORT_DATABOARD_DATA_PPTX });
};

export const finalizeDataboardExport = () => ({
  type: EXPORT_DATABOARD_DATA_SUCCESS,
});

export const exportChartData = (chartId) => (dispatch) => {
  dispatch({ type: EXPORT_CHART_DATA_REQUEST, payload: chartId });

  fetchByChartId(chartId);
};

export const finalizeChartExport = (chartId) => ({
  type: EXPORT_CHART_DATA_SUCCESS,
  payload: chartId,
});
