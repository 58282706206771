import React, { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';

import { API } from 'aws-amplify';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';

import map from 'lodash/map';
import join from 'lodash/join';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/core/styles';

import AppLoader from '../../../../components/AppLoader';
import getFilteredDataTables from './utils/getFilteredDataTables';
import sortDataTables from './utils/sortDataTables';
import BookmarkButton from '../Dashboards/components/BookmarkButton';
import moment from 'moment';

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    paddingRight: '3rem',
    paddingLeft: '3rem',
    paddingBottom: '5rem',
  },
  loadingContainer: {
    height: 'calc(100% + 200px)',
  },
  rowCell: {
    cursor: 'pointer',
    fontSize: '1.1rem',
    padding: '0.6rem 0',
    color: theme.palette.custom.black,
  },
  cell: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    padding: '0.6rem 0',
    color: theme.palette.custom.gray5,
  },
  // largeCell: { width: '50%' },
  // mediumCell: { width: '16.6%' },
  bold: {
    fontWeight: 'bold',
  },
  emptyResults: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.3rem',
    color: theme.palette.custom.gray4,
    height: '8.1rem',
  },
});

const DataTables = ({ classes, searchCriteria, textSearch, jwtToken, bookmarks, userId }) => {
  const [allDataTables, setAllDataTables] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('desc');
  const [loader, setLoader] = useState(false);
  const [, setError] = useState(null);

  useEffect(() => {
    const fetchDataTables = async () => {
      setLoader(true);

      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };

        const dataTables = await API.get('users', `/users/${userId}/dataanalystaccess`, params);

        setAllDataTables(dataTables);
        setLoader(false);
      } catch (e) {
        const message = get(e, ['response', 'data', 'message'], e.message);
        setError(message);
        setLoader(false);
      }
    };

    fetchDataTables();
  }, []);

  const handleOrderBy = useCallback(
    (field) => {
      if (field === orderBy) {
        setOrder(order === 'asc' ? 'desc' : 'asc');
      } else {
        setOrderBy(field);
        setOrder('asc');
      }
    },
    [orderBy, order],
  );

  if (loader) return <AppLoader show={loader} />;

  let dataTables = getFilteredDataTables(allDataTables, searchCriteria, textSearch, bookmarks);

  if (isEmpty(dataTables) && !loader)
    return <div className={classes.emptyResults}>No data tables found</div>;

  dataTables = sortDataTables(dataTables, orderBy, order);

  return (
    <div className={cn(classes.root, { [classes.loadingContainer]: loader })}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell
              className={classes.cell}
              padding="none"
              sortDirection={orderBy === 'title' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'title'}
                direction={orderBy === 'title' ? order : 'asc'}
                onClick={() => handleOrderBy('title')}
              >
                Data Table
              </TableSortLabel>
            </TableCell>
            <TableCell
              className={classes.cell}
              padding="none"
              sortDirection={orderBy === 'requestedBy' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'requestedBy'}
                direction={orderBy === 'requestedBy' ? order : 'asc'}
                onClick={() => handleOrderBy('requestedBy')}
              >
                Requested By
              </TableSortLabel>
            </TableCell>
            <TableCell
              className={classes.cell}
              padding="none"
              sortDirection={orderBy === 'requestedOn' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'requestedOn'}
                direction={orderBy === 'requestedOn' ? order : 'asc'}
                onClick={() => handleOrderBy('requestedOn')}
              >
                Requested On
              </TableSortLabel>
            </TableCell>
            <TableCell
              className={classes.cell}
              padding="none"
              sortDirection={orderBy === 'dataSource' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'dataSource'}
                direction={orderBy === 'dataSource' ? order : 'asc'}
                onClick={() => handleOrderBy('dataSource')}
              >
                Data Source
              </TableSortLabel>
            </TableCell>
            <TableCell
              className={classes.cell}
              padding="none"
              sortDirection={orderBy === 'dataCollectionPeriod' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'dataCollectionPeriod'}
                direction={orderBy === 'dataCollectionPeriod' ? order : 'asc'}
                onClick={() => handleOrderBy('dataCollectionPeriod')}
              >
                Data Collection Period
              </TableSortLabel>
            </TableCell>
            <TableCell
              className={classes.cell}
              padding="none"
              sortDirection={orderBy === 'sectors' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'sectors'}
                direction={orderBy === 'sectors' ? order : 'asc'}
                onClick={() => handleOrderBy('sectors')}
              >
                Sectors
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(
            dataTables,
            (
              { _id, title, sectors, requestedBy, requestedOn, dataSource, dataCollectionPeriod },
              index,
            ) => {
              const titleCellClasses = cn(classes.rowCell, classes.bold);
              const rowCellClasses = cn(classes.rowCell);

              return (
                <TableRow key={`item-${index}`} className={classes.row}>
                  <TableCell padding="none" size="medium">
                    <BookmarkButton databoardId={_id} />
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`/daa/${_id}`)}
                    className={titleCellClasses}
                    padding="none"
                  >
                    {title}
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`/daa/${_id}`)}
                    className={titleCellClasses}
                    padding="none"
                  >
                    {requestedBy}
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`/daa/${_id}`)}
                    className={titleCellClasses}
                    padding="none"
                  >
                    {moment(new Date(requestedOn)).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`/daa/${_id}`)}
                    className={titleCellClasses}
                    padding="none"
                  >
                    {dataSource}
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`/daa/${_id}`)}
                    className={titleCellClasses}
                    padding="none"
                  >
                    {dataCollectionPeriod}
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`/daa/${_id}`)}
                    className={rowCellClasses}
                    padding="none"
                  >
                    {join(sectors, ', ')}
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
  userId: get(state, ['user', 'userId'], []),
  bookmarks: get(state, ['user', 'bookmarks'], []),
  organisation: get(state, ['user', 'organisation'], ''),
});

export default connect(mapStateToProps)(withStyles(styles)(DataTables));
