import React from 'react';

import get from 'lodash/get';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  containedRoot: {
    width: '100%',
    height: '3.7rem',
    borderRadius: 60,
    marginTop: '1.7rem',
  },
  containedLabel: {
    fontWeight: 'bold',
    textTransform: 'none !important',
    color: 'white',
  },
  outlinedRoot: {
    width: '100%',
    height: '3.7rem',
    // borderRadius: 23,
    marginTop: '1.7rem',
  },
  outlinedLabel: {
    fontWeight: 'bold',
    textTransform: 'none !important',
  },
});

const TextButton = ({ classes, label, onClick, variant = 'outlined', disabled }) => {
  const btnClasses = {
    root: get(classes, `${variant}Root`, ''),
    label: get(classes, `${variant}Label`, ''),
  };

  return (
    <Button
      size="large"
      onClick={onClick}
      variant={variant}
      color="secondary"
      classes={btnClasses}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default withStyles(styles)(TextButton);
