import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import get from 'lodash/get';
import keys from 'lodash/keys';
import forEach from 'lodash/forEach';

const syncValues = (state, action) => {
  let byId = {};

  const form = get(action, ['payload', 'form']);
  byId = { ...state.byId, ...form };
  const byParent = get(state, 'byParent', {});
  const parents = keys(byParent);
  forEach(parents, (parent) => {
    const parentValue = get(byId, parent, '');
    const children = get(byParent, parent);

    forEach(children, (child) => {
      byId[child] = parentValue;
    });
  });

  return omitBy(byId, isEmpty);
};

export default syncValues;
