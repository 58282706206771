import React, { useState } from 'react';

import * as Yup from 'yup';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import CircularProgress from '@material-ui/core/CircularProgress';

import Container from '../../components/Container';
import FormTitle from '../../components/FormTitle';
import TextField from '../../components/TextField';
import SubmitButton from '../../components/SubmitButton';
import TextButton from '../../components/TextButton';
import Stepper from '../../components/Stepper';
import FormText from '../../components/FormText';

import validator from '../../validator';

import { NEW_ACCOUNT_SCREEN, RESET_PASSWORD_SCREEN, VERIFY_EMAIL_SCREEN } from '../../types';

import forgotPassword from '../../actions/forgotPassword';

const ForgotPassword = ({
  handleSubmit,
  submitting,
  setScreen,
  setEmail,
  setCodeDeliveryDetails,
  setPreviousError,
}) => {
  const [error, setError] = useState(null);

  const handleForgotPassword = async ({ email }) => {
    try {
      const user = await forgotPassword(email);
      setEmail(email);
      setCodeDeliveryDetails(user);
      setScreen(RESET_PASSWORD_SCREEN);
    } catch (e) {
      if (e.code === 'InvalidParameterException') {
        setEmail(email);
        setPreviousError(e);
        setScreen(VERIFY_EMAIL_SCREEN);
      } else {
        setError(e);
      }
    }
  };

  return (
    <Container error={error}>
      <Stepper activeStep={0} steps={[1, 2]} />
      <form>
        <FormTitle>Forgot Password</FormTitle>
        <FormText>Get a reset password code in your inbox</FormText>
        <Field autoComplete="email" name="email" label="Email" component={TextField} />
        {!submitting && <SubmitButton label="Send" onClick={handleSubmit(handleForgotPassword)} />}
        {submitting && <CircularProgress />}
        <TextButton
          text="Not a member yet?"
          label="Sign up"
          onClick={() => setScreen(NEW_ACCOUNT_SCREEN)}
          disabled={submitting}
        />
      </form>
    </Container>
  );
};

const schema = Yup.object().shape({
  email: Yup.string().email().trim().required(),
});

export default compose(
  // connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'forgotPasswordForm',
    asyncValidate: validator(schema),
  }),
)(ForgotPassword);
