import map from 'lodash/map';
import join from 'lodash/join';
import isEmpty from 'lodash/isEmpty';

const getFilters = (filters) => {
  const appliedFilters = join(
    map(filters, ({ text, operator, data }) => {
      const selectedValues = join(data, ` ${operator} `);

      return `"${text} | ${selectedValues}"`;
    }),
    ', ',
  );

  const filtersText = isEmpty(appliedFilters) ? '"None"' : appliedFilters;

  return [[`Filters applied: ${filtersText}`]];
};

export default getFilters;
