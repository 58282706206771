import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import flattenDeep from 'lodash/flattenDeep';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import reduce from 'lodash/reduce';
import find from 'lodash/find';

const extractByReference = (databoard) => {
  const filterGroups = get(databoard, 'filterGroups', []);
  const sections = get(databoard, 'sections', []);

  const allFilters = flattenDeep(
    concat(
      map(filterGroups, 'values', []),
      map(sections, 'filters'),
      map(sections, (section) => map(section.cards, 'filters')),
    ),
  );

  const filtersWithParents = filter(allFilters, ({ parent }) => !isEmpty(parent));

  return reduce(
    filtersWithParents,
    (acc, { _id, parent }) => {
      const parentFilter = find(allFilters, { name: parent });
      const parentFilterId = get(parentFilter, '_id');

      if (isEmpty(parentFilterId)) return acc;

      return {
        ...acc,
        [parentFilterId]: concat(get(acc, parentFilterId, []), [_id]),
      };
    },
    {},
  );
};

export default extractByReference;
