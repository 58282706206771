export const defaultFormatter = (value) => value;

const formatters = {
  percentage: (value) => {
    if (!value || value === '-') return 'NaN';
    return { v: Math.round((value + Number.EPSILON) * 100) / 10000, t: 'n', z: '0.00%' };
  },
  score: defaultFormatter,
};

export default formatters;
