import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Layout from './components/Layout';
import Notifications from './components/Notifications';

const styles = (theme) => ({
  root: {
    height: '100%',
  },
  dashboardsContainer: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '90%',
  },
  textSearchContainer: {
    display: 'flex',
    marginBottom: '3rem',
  },
  infoContainer: {
    fontFamily: 'Nunito Sans',
    fontSize: '1rem',
    color: theme.palette.custom.gray4,
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
  },
  textContent: {
    marginLeft: 4,
    fontWeight: 'bold',
    color: theme.palette.custom.gray4,
  },
  text: {
    fontFamily: 'Nunito Sans Black',
    fontWeight: 'bold',
    fontSize: '2rem',
  },
});

const NotificationCenter = ({ classes }) => {
  return (
    <Layout>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.textContainer}>
          <Typography className={classes.text}>Notifications Center</Typography>
        </Grid>
        <Grid item xs={12} className={classes.dashboardsContainer}>
          <Notifications />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default withStyles(styles)(NotificationCenter);
