import omitBy from 'lodash/omitBy';
import indexOf from 'lodash/indexOf';
import get from 'lodash/get';

const getResetGlobalFilterState = (state, hiddenGlobalFilters) => {
  const root = get(state, 'root', []);
  const currentState = get(state, 'byId', {});

  return omitBy(
    currentState,
    (value, key) => indexOf(root, key) !== -1 && indexOf(hiddenGlobalFilters, key) === -1,
  );
};

export default getResetGlobalFilterState;
