import React from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';

import map from 'lodash/map';
import get from 'lodash/get';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NavigateIcon from '@material-ui/icons/NavigateNextOutlined';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';

import isDirty from './utils/isDirty';
import hasAccess from '../../../ContactUs/utils/hasAccess';
import { find, intersection, isEmpty } from 'lodash';
import { Typography } from '@material-ui/core';

const styles = (theme) => ({
  badge: {
    display: 'inline-flex',
    width: '0.6rem',
    height: '0.6rem',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    marginRight: '0.3rem',
  },
  active: {
    backgroundColor: theme.palette.custom.purple,
  },
  title: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
    color: '#0F70E0',
    fontSize: '1.1rem',
    backgroundColor: 'white',
  },
  icon: {
    fill: theme.palette.custom.gray4,
  },
  disabled: {
    color: '#bcc6d2',
  },
  ListItemText: {
    fontSize: '1.1rem',
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    width: '0.5rem',
    height: '0.5rem',
    backgroundColor: theme.palette.custom.blue2,
    borderRadius: 20,
    marginLeft: '0.2rem',
  },
});

const Groups = ({
  classes,
  onClick,
  filterGroups,
  appliedGlobalFilters,
  filtersById,
  hasAccess,
  notifications,
  databoardId,
}) => {
  const disabled = (id) => {
    // Check if the user has a subscription to the dashboard or the filter group is free
    if (hasAccess) return false;
    const notDisabled = find(filterGroups, (filterGroup) => {
      return filterGroup._id === id && filterGroup.license_tier === 'free';
    });
    return !notDisabled;
  };

  const newFilter = (filterModels) => {
    const dashboardNotification = find(notifications, (notification) => {
      return notification.resourceId === databoardId && notification.type === 'NEW_DATA';
    });
    const filterNames = map(filterModels, 'name');
    const changedFilters = get(dashboardNotification, 'changedFilters', []);
    return !isEmpty(intersection(filterNames, changedFilters));
  };

  return (
    <List
      component="nav"
      subheader={
        <ListSubheader component="div" className={classes.title}>
          Filter Groups
        </ListSubheader>
      }
    >
      {map(filterGroups, ({ _id, text, values: filterModels }) => {
        return (
          <ListItem button onClick={() => onClick(_id)} key={`item-${_id}`}>
            <div
              className={cn(classes.badge, {
                [classes.active]: isDirty(filterModels, appliedGlobalFilters, filtersById),
              })}
            />
            <ListItemText
              disableTypography
              primary={
                <Typography
                  className={cn(classes.ListItemText, { [classes.disabled]: disabled(_id) })}
                >
                  {text}
                  {newFilter(filterModels) && <div className={classes.dot}></div>}
                </Typography>
              }
            />
            <ListItemIcon>
              <NavigateIcon className={classes.icon} />
            </ListItemIcon>
          </ListItem>
        );
      })}
    </List>
  );
};

const mapStateToProps = (state) => ({
  filterGroups: get(state, ['databoard', 'filterGroups'], []),
  appliedGlobalFilters: get(state, ['filtersData', 'root'], []),
  filtersById: get(state, ['filtersIndex', 'byId'], {}),
  hasAccess: hasAccess(state),
  notifications: get(state, ['user', 'notifications'], []),
  databoardId: get(state, ['databoard', 'databoardId'], ''),
});

export default connect(mapStateToProps)(withStyles(styles)(Groups));
