import React, { useState } from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';

import map from 'lodash/map';
import get from 'lodash/get';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/CloseOutlined';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { find, head, intersection, isEmpty, some } from 'lodash';

const styles = (theme) => ({
  active: {
    backgroundColor: theme.palette.custom.purple,
  },
  title: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
    color: '#0F70E0',
    fontSize: '1.1rem',
    backgroundColor: 'white',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '0.3rem',
  },
  icon: {
    fill: theme.palette.custom.gray4,
    fontSize: '1.5rem',
  },
  bold: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
  details: {
    boxShadow: 'none',
  },
  label: {
    fontSize: '1.1rem',
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    width: '0.5rem',
    height: '0.5rem',
    backgroundColor: theme.palette.custom.blue2,
    borderRadius: 20,
    marginLeft: '0.2rem',
  },
});

const regex = /^[0-9]+\.[0-9]+ /;

const Sections = ({ classes, sections, notifications, databoardId, handleExit }) => {
  const pathURL = window.location.pathname;
  const [expandedItem, setExpandedItem] = useState(-1);
  const onClick = (id) => {
    const sectionScrolled = document.getElementById(id);
    if (sectionScrolled) {
      window.scrollTo(0, sectionScrolled.offsetTop - 100);
    }
  };

  const scrollToSection = (section, sectionIndex) => {
    if (isEmptySection(section)) {
      const sectionScrolled = document.getElementById(`section-item-${sectionIndex}`);
      if (sectionScrolled) {
        window.scrollTo(0, sectionScrolled.offsetTop - 100);
      }
    }
  };

  const isEmptySection = (section) => {
    return !some(section.cards, (card) =>
      pathURL.includes('/reports') ? get(head(card.content), 'text', '').match(regex) : card.title,
    );
  };

  const newSection = (section) => {
    const dashboardNotification = find(notifications, (notification) => {
      return notification.resourceId === databoardId && notification.type === 'NEW_DATA';
    });
    const cardNames = map(section.cards, 'title');
    const changedCards = get(dashboardNotification, 'changedCards', []);
    return !isEmpty(intersection(cardNames, changedCards));
  };

  const newCard = (card) => {
    const dashboardNotification = find(notifications, (notification) => {
      return notification.resourceId === databoardId && notification.type === 'NEW_DATA';
    });
    const changedCards = get(dashboardNotification, 'changedCards', []);
    if (changedCards.includes(card.title)) {
      return true;
    }
    return false;
  };

  const CloseFormButton = ({ onClick, classes }) => (
    <IconButton onClick={onClick}>
      <CloseIcon className={classes.icon} />
    </IconButton>
  );

  return (
    <List
      component="nav"
      subheader={
        <ListSubheader component="div" className={classes.title}>
          Sections
          <CloseFormButton onClick={handleExit} classes={classes} />
        </ListSubheader>
      }
    >
      {map(sections, (section, sectionIndex) => {
        return (
          <>
            <Accordion
              expanded={expandedItem === sectionIndex}
              onChange={() =>
                expandedItem === sectionIndex ? setExpandedItem(-1) : setExpandedItem(sectionIndex)
              }
              classes={{ root: classes.details }}
            >
              <AccordionSummary
                onClick={() => scrollToSection(section, sectionIndex)}
                expandIcon={
                  !isEmptySection(section) ? <ExpandMoreIcon className={classes.icon} /> : <></>
                }
              >
                <Typography
                  className={cn(classes.label, { [classes.bold]: expandedItem === sectionIndex })}
                >
                  {section.title}
                  {newSection(section) && <div className={classes.dot}></div>}
                </Typography>
              </AccordionSummary>
              {some(section.cards, (card) =>
                pathURL.includes('/reports')
                  ? get(head(card.content), 'text', '').match(regex)
                  : card.title,
              ) && (
                <AccordionDetails classes={{ root: classes.details }}>
                  <List>
                    {map(section.cards, (card, chartIndex) => {
                      if (
                        pathURL.includes('/reports')
                          ? get(head(card.content), 'text', '').match(regex)
                          : card.title
                      ) {
                        return (
                          <ListItem
                            button
                            onClick={() => onClick(`section-item-${sectionIndex}-${chartIndex}`)}
                            key={card._id}
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography className={classes.label}>
                                  {pathURL.includes('/reports')
                                    ? get(head(card.content), 'text', '')
                                    : card.title}
                                  {newCard(card) && <div className={classes.dot}></div>}
                                </Typography>
                              }
                            />
                          </ListItem>
                        );
                      }
                    })}
                  </List>
                </AccordionDetails>
              )}
            </Accordion>
            <div className={classes.dividerContainer}>
              <Divider className={classes.divider} />
            </div>
          </>
        );
      })}
    </List>
  );
};

const mapStateToProps = (state) => ({
  sections: get(state, ['databoard', 'sections'], []),
  notifications: get(state, ['user', 'notifications'], []),
  databoardId: get(state, ['databoard', 'databoardId'], ''),
});

export default connect(mapStateToProps)(withStyles(styles)(Sections));
