import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API } from 'aws-amplify';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import { Alert } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import ReportSidebar from '../components/ReportSidebar';
import { trackBriefingVisit } from '../state/analytics/actions';

const Briefing = ({ briefingId, doTrackBriefingVisit }) => {
  const { userId, jwtToken } = useSelector((state) => state.user);
  const [briefing, setBriefing] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    (async () => {
      setLoader(true);

      try {
        const briefing = await API.get('users', `/users/${userId}/briefings/${briefingId}`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        if (!briefing) throw new Error('briefing not found');

        await doTrackBriefingVisit(briefing._id, briefing.title);

        setBriefing(briefing);
      } catch (e) {
        console.error(e);

        navigate('/reports');
      }

      setLoader(false);
    })();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <ReportSidebar />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {loader ? (
          <>
            <CircularProgress size="80px" thickness={1.5} style={{ margin: 'auto' }} />
          </>
        ) : (
          <>
            {briefing?.recording && (
              <ReactPlayer width={'90%'} height={'90%'} url={briefing.recording} controls={true} />
            )}

            {!briefing?.recording && <Alert severity="warning">No video file specified.</Alert>}
          </>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doTrackBriefingVisit: (id, name) => dispatch(trackBriefingVisit(id, name)),
});

export default connect(null, mapDispatchToProps)(Briefing);
