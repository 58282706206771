import includes from 'lodash/includes';
import filter from 'lodash/filter';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';
import get from 'lodash/get';

const getByReport = (reports, textSearch) => {
  if (isEmpty(textSearch)) return reports;

  return filter(reports, (report) =>
    includes(toLower(get(report, 'title')), trim(toLower(textSearch))),
  );
};

export default getByReport;
