import get from 'lodash/get';
import map from 'lodash/map';
import flattenDeep from 'lodash/flattenDeep';

import { SET_DATABOARD, RESET_DATABOARD, SET_SHOW_CONTENTS, SET_VIEWS } from './types';

export const initialState = {
  databoardId: '',
  title: '',
  text: '',
  category: '',
  sources: [],
  sections: [],
  filterGroups: [],
  showContents: true,
  savedViews: [],
  filterLinks: [],
  pdfUrl: '',
  palette: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATABOARD:
      return {
        ...state,
        databoardId: get(action, ['payload', '_id'], ''),
        title: get(action, ['payload', 'title'], ''),
        name: get(action, ['payload', 'name'], ''),
        text: get(action, ['payload', 'text'], ''),
        category: get(action, ['payload', 'category'], ''),
        sources: flattenDeep(map(get(action, ['payload', 'sources'], []), 'name')),
        sections: get(action, ['payload', 'sections'], []),
        filterGroups: get(action, ['payload', 'filterGroups'], []),
        savedViews: get(action, ['payload', 'savedViews'], []),
        filterLinks: get(action, ['payload', 'filterLinks'], []),
        pdfUrl: get(action, ['payload', 'pdfUrl'], ''),
        palette: get(action, ['payload', 'palette'], []),
        version: get(action, ['payload', 'version'], 1),
      };
    case RESET_DATABOARD:
      return {
        ...state,
        ...initialState,
      };
    case SET_SHOW_CONTENTS:
      return {
        ...state,
        showContents: get(action, ['payload'], false),
      };
    case SET_VIEWS:
      return {
        ...state,
        savedViews: get(action, ['payload'], []),
      };
    default:
      return state;
  }
};

export default reducer;
