import moment from 'moment';
import { groupBy, reduce, filter, toArray, find, omit, get } from 'lodash';

/**
 *
 * @param {*} subscriptions User subscriptions
 * @param {*} organisations User organisations
 */
const isPremium = (subscriptions, organisations) => {
  const freeTrialOrg = find(organisations, { name: 'default' });
  const groups = omit(groupBy(subscriptions, 'organisationId'), get(freeTrialOrg, '_id'));

  const activeSubscriptionByOrg = reduce(
    groups,
    (acc, subscriptions, key) => ({
      ...acc,
      [key]: filter(
        subscriptions,
        (subscription) =>
          moment().isAfter(subscription.startDate) &&
          moment().isBefore(subscription.expirationDate),
      ).length,
    }),
    {},
  );

  // At least one organisation, excluding the default, has active subscription(-s)
  return (
    filter(
      toArray(activeSubscriptionByOrg),
      (countOfActiveSubscriptions) => countOfActiveSubscriptions > 0,
    ).length !== 0
  );
};

export default isPremium;
