import includes from 'lodash/includes';
import filter from 'lodash/filter';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';

const getByEmail = (users, textSearch) => {
  if (isEmpty(textSearch)) return users;

  return filter(users, ({ email }) => includes(toLower(email), trim(toLower(textSearch))));
};

export default getByEmail;
