import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { DatePicker as MaterialDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';
import ITheme from '../../../theme/@types/Theme';

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    marginBottom: 15,
  },
  field: {
    textAlign: 'left',
    borderColor: '#bcc6d2',
    margin: '5px 0',
    height: 46,
    borderRadius: 60,
  },
  label: {
    fontSize: 12,
    color: '#636666',
    transform: 'translate(25px, 22px) scale(1)',
  },
  labelFocused: {
    color: theme.palette.custom.blue2,
    fontSize: 6,
    fontWeight: 'bold',
    transform: 'translate(25px, 11px) !important',
  },
  labelShrink: {
    fontSize: 6,
    transform: 'translate(25px, 11px) !important',
  },
  fieldFocused: {
    borderColor: theme.palette.custom.blue2,
  },
  icon: {
    color: theme.palette.custom.blue2,
  },
  input: {
    paddingLeft: 25,
  },
}));

interface Props {
  label: string;
  value: string;
  onChange: (props: any) => void;
  name?: string;
  type?: string;
  autoComplete?: string;
  required?: boolean;
  touched?: boolean;
  error?: string;
  disableFuture?: boolean;
  onBlur?: (props: any) => void;
}

const DatePicker = ({
  label,
  name = 'text',
  type = 'text',
  required,
  autoComplete,
  value,
  touched,
  error,
  disableFuture = false,
  onChange,
  onBlur,
}: Props) => {
  const classes = useStyles();

  return (
    <MaterialDatePicker
      error={!isEmpty(error) && touched}
      format="DD/MM/YYYY"
      color="primary"
      label={label}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      helperText={!isEmpty(error) && touched ? error : ''}
      autoComplete={autoComplete}
      margin="none"
      variant="inline"
      required={required}
      fullWidth
      className={classes.root}
      disableFuture={disableFuture}
      autoOk
    />
  );
};

export default DatePicker;
