import React from 'react';
import cn from 'classnames';

import isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import { PrismicRichText } from '@prismicio/react';
import PrismicLinkComponent from '../PrismicLinkComponent';

const styles = () => ({
  root: {
    textAlign: 'center',
    width: '75%',
    marginBottom: 0,
    fontFamily: 'Nunito Sans',
    color: '#979797',
    fontSize: 18,
    alignSelf: 'center',
  },
  noMargin: {
    marginBlockStart: '0 !important',
    marginBottom: 0,
    lineHeight: '1.5em',
  },
  ulNoMargin: {
    marginBlockStart: '0 !important',
    marginBottom: 0,
    paddingInlineStart: '30px !important',
  },
  blackFont: {
    color: '#636666',
  },
  isBlured: {
    filter: 'blur(5px)',
    userSelect: 'none',
  },
});

const Subtitle = ({ classes, className, children, authorBio, isBlured }) => {
  const pathURL = window.location.pathname;
  const isReport = pathURL.includes('/reports');

  return isReport && !isBlured ? (
    <div className={classes.root}>
      <PrismicRichText
        field={children}
        components={{
          paragraph: ({ children }) => (
            <p className={cn(classes.noMargin, { [classes.blackFont]: authorBio })}>{children}</p>
          ),
          list: ({ children }) => <ul className={classes.ulNoMargin}>{children}</ul>,
        }}
        externalLinkComponent={({ href, ...props }) => (
          <PrismicLinkComponent href={href} {...props} />
        )}
      />
    </div>
  ) : (
    <div
      className={cn(classes.root, {
        [classes.isBlured]: isBlured,
        [className]: !isEmpty(className),
      })}
    >
      {children}
    </div>
  );
};

export default withStyles(styles)(Subtitle);
