import React from 'react';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '9.5rem',
    height: '2.2rem',
    borderRadius: 100,
    boxShadow: 'none',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    textTransform: 'capitalize !important',
  },
});

const ApplyFiltersBtn = ({ classes, onClick }) => (
  <Button variant="contained" color="primary" onClick={onClick} classes={classes}>
    Apply Filters
  </Button>
);

export default withStyles(styles)(ApplyFiltersBtn);
