import React from 'react';

import { Container, ChartWrapper, SampleWrapper } from '../Container';
import { LineChartEngine } from '../LineChartEngine';

import { getSeries, defaultConfig } from './config';
import Sample from '../Sample';
import { find, get, head } from 'lodash';
import { LOW_SAMPLE_SIZE } from '../../../utils/hasLowSampleSize';
import generateConfig from './generateConfig';
import { getChartLabels } from '../../../state/labels/selectors';
import { connect } from 'react-redux';

const LineChart = ({
  id,
  data,
  series,
  customConfig,
  samples,
  configuration,
  formatter,
  axisTitle,
  domain,
  labels,
  scatter,
}) => {
  const lineSeries = getSeries(series);

  const chartThreshold = get(find(configuration, { key: 'threshold' }), 'value', LOW_SAMPLE_SIZE);

  const mergedConfig = generateConfig(
    defaultConfig,
    formatter,
    axisTitle,
    domain,
    customConfig,
    configuration,
  );

  return (
    <Container>
      <ChartWrapper fullHeight>
        <LineChartEngine
          id={id}
          data={data}
          series={lineSeries}
          {...mergedConfig}
          labels={labels}
          scatter={scatter}
        />
      </ChartWrapper>
      <SampleWrapper>
        <Sample
          name={get(head(samples), 'name', 'n')}
          value={get(head(samples), 'value', 0)}
          chartThreshold={chartThreshold}
        />
      </SampleWrapper>
    </Container>
  );
};

const mapStateToProps = (state, { id }) => ({
  labels: getChartLabels(state, id),
});

export default connect(mapStateToProps)(LineChart);
