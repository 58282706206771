import get from 'lodash/get';
import head from 'lodash/head';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

const getCategoricalSamples = ({ data, table, source }) => {
  const pathURL = window.location.pathname;
  const isDataboard = pathURL.includes('/dashboards');
  if (isEmpty(source) && isDataboard) return {};

  let sampleArr = find(data, { name: table });
  sampleArr = head(get(sampleArr, 'values', []));
  const sample = get(head(get(sampleArr, 'values', [])), 'value', 0);

  return { name: source, value: sample };
};

export default getCategoricalSamples;
