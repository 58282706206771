import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';

import transpose from './transpose';
import { isNumber } from 'lodash';

const normalizer = ({ sources, data, table, sourcesLookup }) => {
  if (isEmpty(sources) || isEmpty(data) || isEmpty(table)) return [];

  const result = map(sources, (source, index) => {
    const sourceData = get(data, [index], []);
    const array = find(sourceData, { name: table });
    if (isNumber(source)) {
      source = get(find(sourcesLookup, { id: source }), 'text', source);
    }
    return { name: source, ...transpose(array) };
  });

  return result;
};

export default normalizer;
