import React from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: { fontSize: '0.9rem', color: '#636666', marginBottom: '1.3rem' },
});

const FormText = ({ classes, children }) => <div className={classes.root}>{children}</div>;

export default withStyles(styles)(FormText);
