import moment from 'moment';

import filter from 'lodash/filter';

const getActiveSubscriptions = (subscriptions) => {
  const now = moment();
  return filter(subscriptions, ({ startDate, expirationDate }) => {
    return moment(expirationDate).isAfter(now) && moment(startDate).isBefore(now);
  });
};

export default getActiveSubscriptions;
