import React from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';

import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import { ReactComponent as DeleteIcon } from './assets/delete.svg';
import getValueLabels from '../shared/utils/getValueLabels';
import getFilterTooltip from '../shared/utils/getFilterTooltip';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.custom.gray2,
    display: 'inline-block',
    paddingLeft: '0.4rem',
    margin: '0.3rem',
    maxWidth: '16.2rem',
    height: '1.6rem',
  },
  container: {
    display: 'flex',
  },
  content: {
    // maxWidth: 150,
    color: theme.palette.custom.gray5,
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  actionableContent: {
    maxWidth: 'calc(100% - 2rem)',
  },
  action: {
    marginLeft: '0.3rem',
    backgroundColor: '#ececf2',
    width: '1.5rem',
    height: '1.6rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
    cursor: 'pointer',
    '& .cls-1': {
      fill: '#636666',
    },
  },
  tooltip: {
    backgroundColor: theme.palette.custom.white,
    boxShadow: '-2px 3px 10px 0 rgba(0, 0, 0, 0.16)',
    border: 'solid 1px #e7e7ef',
    borderRadius: 10,
    padding: '1.1rem',
    color: theme.palette.custom.black,
    fontSize: '0.8rem',
  },
  sources: {
    paddingRight: '0.4rem',
  },
});

const FilterTag = ({ classes, filterTooltip, isSourcesTag, isVisible, onRemove }) => {
  // if (isEmpty(valueLabels)) return null;

  if (isVisible === false && process.env.REACT_APP_DATABOARD_STATUS === 'published') return null;

  return (
    <div className={classes.root}>
      <div className={cn(classes.container, { [classes.sources]: isSourcesTag })}>
        <Tooltip title={filterTooltip} classes={{ tooltip: classes.tooltip }}>
          <span className={cn(classes.content, { [classes.actionableContent]: !isSourcesTag })}>
            {filterTooltip}
          </span>
        </Tooltip>
        {!isSourcesTag && (
          <span className={classes.action} onClick={onRemove}>
            <DeleteIcon />
          </span>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, { _id, values, label }) => ({
  filterTooltip: getFilterTooltip(label, getValueLabels(state, _id, values)),
});

export default connect(mapStateToProps)(withStyles(styles)(FilterTag));
