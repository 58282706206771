import React from 'react';
import { makeStyles, Tooltip, Typography, withStyles } from '@material-ui/core';

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#ffffff',
    border: '1px solid #e7e7ef',
    borderRadius: '5px',
    boxShadow: '-2px 3px 10px 0 rgba(0, 0, 0, 0.16)',
    padding: '0.3rem 1.1rem',
  },
  popper: {
    zIndex: 9999,
  },
}))(Tooltip);

const useStyles = makeStyles({
  text: {
    fontFamily: 'Nunito Sans',
    fontSize: 16,
  },
});

const HintTooltip = (props) => {
  const { title, children } = props;
  const classes = useStyles();

  // Only rendering the tooltip if there is a content in the title
  // If the title is empty then the children is rendered without a tooltip
  if (title) {
    return (
      // eslint-disable-next-line react/react-in-jsx-scope
      <HtmlTooltip title={<Typography className={classes.text}>{title}</Typography>}>
        {children}
      </HtmlTooltip>
    );
  }

  return <>{children}</>;
};

export default HintTooltip;
