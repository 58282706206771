import React, { useEffect, useState, useCallback } from 'react';

import { API } from 'aws-amplify';
import { Field, reduxForm, formValueSelector } from 'redux-form';
// import * as Yup from 'yup';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import CircularProgress from '@material-ui/core/CircularProgress';

import DatePicker from '../DatePicker';
import SubmitButton from '../SubmitButton';
import TextButton from '../TextButton';
// import reduxFormValidator from '../../../../utils/reduxFormValidator';
import { Portlet, PortletHeader, PortletLabel, PortletContent, PortletFooter } from '../../Portlet';
import OrganisationsField from './OrganisationsField/OrganisationsField';
import DataBoardsField from './DataBoardsField';
import getExcludedDataboards from './utils/getExcludedDataboards';
import { SnackBar } from '../../UI';
import DurationField from './DurationField';
import { CUSTOM, INDEFINITE, ONE_MONTH, ONE_YEAR, SIX_MONTHS } from './DurationField/DurationField';

const UPDATE_MODE = 'UPDATE_MODE';
const CREATE_MODE = 'CREATE_MODE';
// This is the maximum date allowed by pandas library that is used in the analytics
export const MAX_DATE = new Date(9223372036000);

const SubscriptionForm = ({
  subscriptionId,
  jwtToken,
  excludeDataboards,
  handleSubmit,
  duration,
  onSuccess,
  databoardId,
  organisationId,
  // onDelete,
}) => {
  const [mode, setMode] = useState('');
  const [formTitle, setFormTitle] = useState('');
  const [submitButtonTitle, setSubmitButtonTitle] = useState('');
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setMode(isEmpty(subscriptionId) ? CREATE_MODE : UPDATE_MODE);
    setFormTitle(isEmpty(subscriptionId) ? 'New Subscription' : 'Edit Subscription');
    setSubmitButtonTitle(isEmpty(subscriptionId) ? 'CREATE' : 'UPDATE');
  }, [subscriptionId]);

  // Calculate the expiration date based on the duration chosen
  const calculateExpirationDate = (startDate, duration, expirationDate) => {
    switch (duration) {
      case ONE_MONTH:
        return moment(new Date(startDate)).add(1, 'M').format('MM/DD/YYYY');
      case SIX_MONTHS:
        return moment(new Date(startDate)).add(6, 'M').format('MM/DD/YYYY');
      case ONE_YEAR:
        return moment(new Date(startDate)).add(1, 'y').format('MM/DD/YYYY');
      case INDEFINITE:
        return MAX_DATE;
      default:
        return expirationDate;
    }
  };

  const updateSubscription = useCallback(
    async ({ organisationId, databoardId, startDate, duration, expirationDate }) => {
      setLoader(true);
      const expDate = calculateExpirationDate(new Date(), duration, expirationDate);
      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
          body: { organisationId, databoardId, startDate, expirationDate: expDate },
        };
        const result = await API.patch('users', `/subscriptions/${subscriptionId}`, params);
        onSuccess(result);
      } catch (e) {
        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );
        setError(message);
      } finally {
        setLoader(false);
      }
    },
    [subscriptionId],
  );

  const saveSubscription = useCallback(
    async ({ organisationId, databoardId, startDate, duration, expirationDate }) => {
      setLoader(true);
      const expDate = calculateExpirationDate(startDate, duration, expirationDate);
      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
          body: { organisationId, databoardId, startDate, expirationDate: expDate },
        };
        const result = await API.post('users', `/subscriptions`, params);
        onSuccess(result);
      } catch (e) {
        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );
        setError(message);
      } finally {
        setLoader(false);
      }
    },
    [],
  );

  const expireSubscription = useCallback(async () => {
    setLoader(true);
    try {
      const params = {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: {
          expirationDate: moment(new Date() - 7)
            .subtract(7, 'd')
            .format('MM/DD/YYYY'),
        },
      };
      const result = await API.patch('users', `/subscriptions/${subscriptionId}`, params);
      onSuccess(result);
    } catch (e) {
      const message = get(
        e,
        ['response', 'data', 'message'],
        'You do not have permission to access',
      );
      setError(message);
    } finally {
      setLoader(false);
    }
  }, [subscriptionId]);

  const saveOrUpdate = useCallback(
    (props) => {
      if (excludeDataboards.includes(databoardId)) {
        setError('Subscription already exists');
      } else {
        if (mode === CREATE_MODE) {
          saveSubscription(props);
        } else if (mode === UPDATE_MODE) {
          updateSubscription(props);
        }
      }
    },
    [mode, excludeDataboards, databoardId],
  );

  return (
    <Portlet>
      <PortletHeader>
        <PortletLabel title={formTitle} />
      </PortletHeader>
      <form onSubmit={handleSubmit(saveOrUpdate)}>
        <PortletContent>
          <Field
            name="organisationId"
            label="Organisation"
            component={OrganisationsField}
            isDisabled={mode === UPDATE_MODE || !isEmpty(databoardId)}
            setError={setError}
          />
          <Field
            name="databoardId"
            label="Resource"
            excludeDataboards={excludeDataboards}
            component={DataBoardsField}
            isDisabled={mode === UPDATE_MODE || isEmpty(organisationId)}
            setError={setError}
          />
          {mode === CREATE_MODE && (
            <Field name="startDate" label="Starting date" component={DatePicker} />
          )}
          <Field name="duration" label="Duration" component={DurationField} />
          {duration === CUSTOM && (
            <Field name="expirationDate" label="Expiration date" component={DatePicker} />
          )}
        </PortletContent>
        <PortletFooter>
          {!loader && <SubmitButton label={submitButtonTitle} />}
          {loader && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )}
          {mode === UPDATE_MODE && (
            <TextButton label="Expire" onClick={expireSubscription} disabled={loader} />
          )}
        </PortletFooter>
      </form>
      <SnackBar open={Boolean(error)} message={error} severity="warning" />
    </Portlet>
  );
};

// const schema = Yup.object().shape({
//   organisationId: Yup.string()
//     .trim()
//     .required(),
//   databoardId: Yup.string()
//     .trim()
//     .required(),
//   startDate: Yup.string()
//     .trim()
//     .required(),
//   expirationDate: Yup.string()
//     .trim()
//     .required(),
// });

const now = new Date();
const aYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

const mapStateToProps = (
  state,
  { _id, organisationId, databoardId, startDate = now, expirationDate = aYearFromNow },
) => ({
  subscriptionId: _id,
  jwtToken: get(state, ['user', 'jwtToken'], ''),
  excludeDataboards: getExcludedDataboards(
    get(
      state,
      [
        'admin',
        'subscriptionsByOrganisation',
        formValueSelector('subscriptionForm')(state, 'organisationId'),
      ],
      [],
    ),
    _id,
    databoardId,
  ),
  duration: formValueSelector('subscriptionForm')(state, 'duration'),
  databoardId: formValueSelector('subscriptionForm')(state, 'databoardId'),
  organisationId: formValueSelector('subscriptionForm')(state, 'organisationId'),
  initialValues: {
    organisationId,
    databoardId,
    startDate,
    duration: databoardId
      ? new Date(expirationDate).getFullYear() === MAX_DATE.getFullYear()
        ? INDEFINITE
        : CUSTOM
      : ONE_YEAR,
    expirationDate,
  },
});

const validate = (values) => {
  const { organisationId, databoardId /* startDate, expirationDate */ } = values;
  const errors = {};

  if (isEmpty(organisationId)) {
    errors.organisationId = 'Organisation can not be empty';
  }
  if (isEmpty(databoardId)) {
    errors.databoardId = 'Resource can not be empty';
  }

  return errors;
};

// FIX: async validation doesn't run on submit. Tried variations, maybe a redux form bug.
// The form is the same as the organisation form where the validation runs as expected.
export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'subscriptionForm',
    // enableReinitialize: true,
    // asyncValidate: reduxFormValidator(schema),
    validate,
  }),
)(SubscriptionForm);
