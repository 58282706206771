import get from 'lodash/get';

const getError = (error) => {
  // ["config", "request", "response", "isAxiosError", "toJSON"]
  const status = get(error, ['response', 'status']);
  const message = get(error, ['response', 'data', 'message']);

  return { status, message };
};

export default getError;
