import React from 'react';
import cn from 'classnames';

import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';

import { withStyles } from '@material-ui/core/styles';

import Sections from './components/Sections';
import { setShowContents } from '../../../state/databoard/actions';

const styles = (theme) => ({
  root: { zIndex: `${theme.zIndex.drawer} !important` },
  paper: {
    backgroundColor: theme.palette.custom.white,
    boxShadow: '4px 0px 18px 0 rgba(188, 198, 210, 0.15)',
  },
  smallPadding: {
    paddingTop: '4.5rem',
  },
  largePadding: {
    paddingTop: '15.5rem',
  },
});

const ContentsDrawer = ({ open, classes, className, handleExit, scrollRef, titleInView }) => {
  return (
    <Drawer
      ModalProps={{
        disablePortal: true,
        keepMounted: true,
        hideBackdrop: true,
        disableScrollLock: true,
      }}
      open={open}
      classes={{
        root: cn(classes.root, className),
        paper: cn(
          classes.paper,
          className,
          { [classes.smallPadding]: !titleInView },
          { [classes.largePadding]: titleInView },
        ),
      }}
    >
      <>
        <Scrollbars ref={scrollRef}>
          <Sections scrollRef={scrollRef} handleExit={handleExit} />
        </Scrollbars>
      </>
    </Drawer>
  );
};

const mapStateToProps = (dispatch) => ({
  handleExit: () => dispatch(setShowContents(false)),
});

export default connect(null, mapStateToProps)(withStyles(styles)(ContentsDrawer));
