import map from 'lodash/map';
import get from 'lodash/get';
import omit from 'lodash/omit';
import filter from 'lodash/filter';
import toArray from 'lodash/toArray';
import indexOf from 'lodash/indexOf';
import reduce from 'lodash/reduce';

import createInputFilterExpression from './createInputFilterExpression';
import createV2FilterExpression from './createV2FilterExpression';
/**
 * @param {*} form The form of the applied filters
 * @param {*} filters All dashboard filtersById
 */
const createBenchmarkFilterExpression = ({
  form,
  filters,
  rejectedSeries,
  version,
  filterLinks,
  selectedSources,
}) => {
  const rejectedFilters = map(
    filter(toArray(filters), (filterModel) => indexOf(rejectedSeries, filterModel.name) !== -1),
    '_id',
  );
  const benchmark = omit(get(form, 'benchmark'), rejectedFilters);

  const expressions = reduce(
    benchmark,
    (result, selectedValues, filterId) => {
      const currentFilterText = get(filters, [filterId, 'text'], '');
      const currentFilterName = get(filters, [filterId, 'name'], '');
      const currentForm = {
        ...omit(form, benchmark),
        [filterId]: get(form, ['benchmark', filterId], []),
      };

      let expression = {};
      if (version === 2) {
        expression = createV2FilterExpression({
          form: currentForm,
          filters,
          selectedSources,
          filterLinks,
        });
      } else {
        expression = createInputFilterExpression({ form: currentForm, filters });
      }

      return {
        ...result,
        [currentFilterName]: {
          text: currentFilterText,
          expression,
        },
      };
    },
    {},
  );

  return expressions;
};

export default createBenchmarkFilterExpression;
