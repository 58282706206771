import { Auth } from 'aws-amplify';

const completeNewPassword = async (user, password, attr) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await Auth.completeNewPassword(user, password, attr);
      const contact = await Auth.verifiedContact(data);
      resolve(contact);
    } catch (error) {
      console.log('An error occurred while completing new password');
      console.log(error);
      reject(error);
    }
  });

export default completeNewPassword;
