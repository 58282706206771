import map from 'lodash/map';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';

import config from './config';
import getAxisDomain from '../helpers/getAxisDomain';
import getAxisTickFormatter from '../helpers/getAxisTickFormatter';
import getFormattedSeries from '../helpers/getFormattedSeries';
import getTooltipFormatter from '../helpers/getTooltipFormatter';
import { LOW_SAMPLE_SIZE } from '../../../utils/hasLowSampleSize';

export const orderSeries = (series) => orderBy(series, [], ['desc']);
export const filterOutNullSeries = (series) => filter(series, ({ dataKey }) => !isEmpty(dataKey));
export const renameSeries = (series) =>
  map(config.series, (item, index) => ({
    ...item,
    dataKey: get(series, index, null),
  }));

export const normalizeSeries = (series) => filterOutNullSeries(renameSeries(series));

// export const overwriteYAxisProps = yAxisProps => ({
//   ...config.yAxisProps,
//   ...yAxisProps,
// });

export const overwriteXAxisProps = (domain, axisTitle = '', formatter) => ({
  ...config.xAxisProps,
  label: { ...config.xAxisProps.label, value: axisTitle },
  domain: getAxisDomain(domain, formatter),
  tickFormatter: getAxisTickFormatter(formatter),
  // If the formatter is not score or population then we add the ticks for each quarter
  ...(!['score', 'population'].includes(formatter) && { ticks: [0, 25, 50, 75, 100] }),
});

const generateConfig = ({
  defaultConfig,
  series,
  /* yAxisProps, */ domain,
  axisTitle,
  formatter,
  configuration,
}) => ({
  ...config,
  series: getFormattedSeries(formatter, normalizeSeries(series)),
  // overwriteYAxisProps: overwriteYAxisProps(yAxisProps),
  tooltipProps: {
    ...get(defaultConfig, 'tooltipProps', {}),
    formatter: getTooltipFormatter(formatter),
  },
  xAxisProps: overwriteXAxisProps(domain, axisTitle, formatter),
  chartThreshold: get(find(configuration, { key: 'threshold' }), 'value', LOW_SAMPLE_SIZE),
});

export default generateConfig;
