import {
  FORMATTER_PERCENTAGE,
  FORMATTER_POPULATION,
  FORMATTER_SCORE,
} from '../RechartBarChartEngine/formatters';

// NOTE: consider removing this util, it was useful in migration but seems useless after it
const getTooltipFormatter = (formatter) => {
  if (formatter === 'percentage') return FORMATTER_PERCENTAGE;
  if (formatter === 'score') return FORMATTER_SCORE;
  if (formatter === 'population') return FORMATTER_POPULATION;

  return FORMATTER_PERCENTAGE;
};

export default getTooltipFormatter;
