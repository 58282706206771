import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import { API } from 'aws-amplify';
import { connect } from 'react-redux';

import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

import AppLoader from '../../../../components/AppLoader';
import { find } from 'lodash';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { navigate } from '@reach/router';
import { setUserNotifications } from '../../../../state/user/actions';

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    paddingRight: '3rem',
  },
  loadingContainer: {
    height: 'calc(100% + 200px)',
  },
  rowCell: {
    cursor: 'pointer',
    fontSize: '1.1rem',
    padding: '0.6rem 0',
    color: theme.palette.custom.black,
  },
  cell: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    padding: '0.6rem 0',
    color: theme.palette.custom.gray5,
  },
  largeCell: { width: '50%' },
  mediumCell: { width: '16.6%' },
  sample: {
    padding: '0.3rem 0.8rem',
    marginLeft: '-0.8rem',
    borderRadius: 7,
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
  limited: {
    color: theme.palette.custom.blue2,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.palette.custom.blue2}`,
    padding: '0.25rem 1.1rem',
  },
  preview: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.custom.blue2,
  },
  bold: {
    fontWeight: 'bold',
  },
  emptyResults: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.3rem',
    color: theme.palette.custom.gray4,
    height: '8.1rem',
  },
  access: {
    marginLeft: '-1.8rem',
  },
  notificationDot: {
    width: '0.7rem',
    height: '0.7rem',
    borderRadius: 60,
    marginRight: '0.3rem',
  },
  subscriptionNotification: {
    backgroundColor: '#f6a300',
  },
  newDataNotification: {
    backgroundColor: theme.palette.custom.blue2,
  },
  readNotificationIcon: {
    backgroundColor: theme.palette.custom.gray3,
  },
  notificationTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  readNotificationText: {
    color: theme.palette.custom.gray6,
    fontFamily: 'Nunito Sans',
  },
  actionCell: {
    color: theme.palette.custom.blue2,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.palette.custom.blue2}`,
    padding: '0.25rem 1.1rem',
    marginLeft: '-0.8rem',
    borderRadius: 50,
    fontSize: '0.8rem',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  actionSubscription: {
    color: '#f6a300',
    border: '1px solid #f6a300',
  },
  actionRead: {
    color: '#bcc6d2',
    border: '1px solid #bcc6d2',
  },
  actionNewData: {
    color: theme.palette.custom.blue2,
    border: `1px solid ${theme.palette.custom.blue2}`,
  },
});

const Notifications = ({
  classes,
  userId,
  email,
  firstName,
  lastName,
  roles,
  jwtToken,
  onSetUserNotifications,
}) => {
  const [allNotifications, setAllNotifications] = useState([]);
  const [allDashboards, setAllDashboards] = useState([]);
  const [loader, setLoader] = useState(false);
  const [, setError] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoader(true);

      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };
        const results = await Promise.all([
          API.get('users', `/users/${userId}/databoards`, params),
          API.get('users', `/users/${userId}/notifications`, params),
        ]);
        const [dashboards, notifications] = results;

        setAllNotifications(notifications);
        setAllDashboards(dashboards);
        setLoader(false);
      } catch (e) {
        const message = get(e, ['response', 'data', 'message'], e.message);
        setError(message);
        setLoader(false);
      }
    };

    fetchNotifications();
  }, []);

  const mailto = 'sales@slashdata.co';
  const cc = 'technology@slashdata.co';

  const openNotification = useCallback(async (notification) => {
    const title = get(find(allDashboards, { _id: notification.resourceId }), 'title', '');
    const params = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    if (notification.type === 'NEW_DATA') {
      navigate(`/dashboards/${notification.resourceId}`);
    } else {
      window.open(
        `mailto:${mailto}?cc=${cc}&subject=${encodeURIComponent(
          `Dashboard Access Renewal: ${title}`,
        )}&body=${encodeURIComponent(
          `Dashboard: ${title}\nEmail: ${email}\nName: ${firstName}\nLast name: ${lastName}\nRole: ${roles}`,
        )}`,
      );
    }
    await API.put('users', `/users/${userId}/notifications/${notification._id}`, params);
    const notifications = await API.get('users', `/users/${userId}/notifications`, params);
    onSetUserNotifications(notifications);
  });

  if (loader) return <AppLoader show={loader} />;

  if (isEmpty(allNotifications) && !loader)
    return <div className={classes.emptyResults}>No notifications to show</div>;

  return (
    <div className={cn(classes.root, { [classes.loadingContainer]: loader })}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} padding="none" />
            <TableCell className={classes.cell} padding="none">
              Action
            </TableCell>
            <TableCell className={classes.cell} padding="none">
              Details
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(allNotifications, (notification, index) => {
            const titleCellClasses = cn(classes.rowCell, classes.bold, classes.largeCell);
            const rowCellClasses = cn(classes.rowCell, classes.mediumCell);

            return (
              <TableRow key={`item-${index}`} className={classes.row}>
                <TableCell className={titleCellClasses} padding="none">
                  <Grid className={classes.notificationTitle}>
                    <Grid
                      className={cn(
                        classes.notificationDot,
                        {
                          [classes.readNotificationIcon]: notification.viewedBy.includes(userId),
                        },
                        {
                          [classes.newDataNotification]:
                            !notification.viewedBy.includes(userId) &&
                            notification.type === 'NEW_DATA',
                        },
                        {
                          [classes.subscriptionNotification]:
                            !notification.viewedBy.includes(userId) &&
                            notification.type === 'SUBSCRIPTION_EXPIRE',
                        },
                      )}
                    />
                    {notification.type === 'NEW_DATA'
                      ? `New Data available for ${get(
                          find(allDashboards, { _id: notification.resourceId }),
                          'title',
                          '',
                        )}`
                      : `Your Subscription on ${get(
                          find(allDashboards, { _id: notification.resourceId }),
                          'title',
                          '',
                        )} is about to expire`}
                  </Grid>
                </TableCell>
                <TableCell
                  className={rowCellClasses}
                  padding="none"
                  onClick={() => openNotification(notification)}
                >
                  <span
                    className={cn(
                      classes.actionCell,
                      {
                        [classes.actionRead]: notification.viewedBy.includes(userId),
                      },
                      {
                        [classes.actionNewData]:
                          !notification.viewedBy.includes(userId) &&
                          notification.type === 'NEW_DATA',
                      },
                      {
                        [classes.actionSubscription]:
                          !notification.viewedBy.includes(userId) &&
                          notification.type === 'SUBSCRIPTION_EXPIRE',
                      },
                    )}
                  >
                    {notification.type === 'NEW_DATA' ? 'See Now' : 'Contact Us'}
                  </span>
                </TableCell>
                <TableCell className={cn(rowCellClasses)} padding="none">
                  {moment(notification.date).format('DD MMM YYYY')}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
  userId: get(state, ['user', 'userId'], []),
  email: get(state, ['user', 'email']),
  firstName: get(state, ['user', 'firstName']),
  lastName: get(state, ['user', 'lastName']),
  roles: get(state, ['user', 'roles']),
});

const mapDispatchToProps = (dispatch) => ({
  onSetUserNotifications: (notifications) => dispatch(setUserNotifications(notifications)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Notifications));
