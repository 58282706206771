import React, { useState, useEffect, useCallback } from 'react';

import { API } from 'aws-amplify';
import moment from 'moment';
import { connect } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { withStyles } from '@material-ui/core/styles';

import { get } from 'lodash';
import Layout from '../../../components/Admin/Layout';
import TextSearch from '../../../components/Admin/TextSearch';
import Subscriptions from './components/Subscriptions';
import SubscriptionForm from '../../../components/Admin/SubscriptionForm';
import FilterDrawer from './components/FilterDrawer';
import normalizeSubscriptionToEdit from './utils/normalizeSubscriptionToEdit';
import { SnackBar } from '../../../components/UI';

const styles = (theme) => ({
  root: {
    height: '100%',
    marginLeft: '6.5rem',
  },
  dashboardsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    color: theme.palette.custom.gray4,
    fontSize: '1.4rem',
  },
  textSearchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2.9rem',
  },
  infoContainer: {
    fontFamily: 'Nunito Sans',
    fontSize: '1rem',
    color: theme.palette.custom.gray4,
    marginBottom: '0.7rem',
    display: 'flex',
    alignItems: 'center',
  },
  textSearchContent: {
    marginLeft: '0.3rem',
    fontWeight: 'bold',
    color: theme.palette.custom.gray4,
  },
  paperAnchorRight: { width: '40.6rem' },
  button: {
    textTransform: 'none',
    color: theme.palette.custom.blue2,
    fontWeight: 'bold',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

const SubscriptionsView = ({ classes, jwtToken }) => {
  const [textSearch, setTextSearch] = useState('');
  const [searchCriteria, setSearchCriteria] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [subscriptionToEdit, setSubscriptionToEdit] = useState({});
  const [forceUpdate, setForceUpdate] = useState(false);
  const [subscriptionsToExpire, setSubscriptionsToExpire] = useState([]);
  const [subscriptionsToActivate, setSubscriptionsToActivate] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setSubscriptionsToExpire([]);
    setSubscriptionsToActivate([]);
  }, [searchCriteria]);

  const handleSelect = (subscriptions) => {
    if (searchCriteria.category === 'active') {
      setSubscriptionsToExpire(subscriptions);
      setSubscriptionsToActivate([]);
    } else if (searchCriteria.category === 'expired') {
      setSubscriptionsToExpire([]);
      setSubscriptionsToActivate(subscriptions);
    }
  };

  const updateSubscriptions = useCallback(async (subscriptions, expirationDate) => {
    setLoader(true);
    setForceUpdate(false);
    try {
      const params = {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: { expirationDate },
      };
      const requests = map(subscriptions, (subscriptionId) =>
        API.patch('users', `/subscriptions/${subscriptionId}`, params),
      );
      await Promise.all(requests);
    } catch (e) {
      const message = get(
        e,
        ['response', 'data', 'message'],
        'You do not have permission to access',
      );
      setError(message);
    } finally {
      setForceUpdate(true);
      setLoader(false);
    }
  }, []);

  const expireSubscriptions = useCallback(async () => {
    const expirationDate = moment().add(-360, 'd').format('YYYY/MM/DD');
    updateSubscriptions(subscriptionsToExpire, expirationDate);
  }, [subscriptionsToExpire]);

  const activateSubscriptions = useCallback(async () => {
    const expirationDate = moment().add(360, 'd').format('YYYY/MM/DD');
    updateSubscriptions(subscriptionsToActivate, expirationDate);
  }, [subscriptionsToActivate]);

  return (
    <Layout>
      <FilterDrawer onChange={setSearchCriteria} />
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.textSearchContainer}>
          <TextSearch
            value={textSearch}
            onChange={setTextSearch}
            placeholder="Type to search by organisation or resource"
          />
          <div className={classes.buttonsContainer}>
            {loader && <CircularProgress size={12} />}
            {!isEmpty(subscriptionsToExpire) && (
              <Button onClick={expireSubscriptions} className={classes.button} disabled={loader}>
                Expire
              </Button>
            )}
            {!isEmpty(subscriptionsToActivate) && (
              <Button onClick={activateSubscriptions} className={classes.button} disabled={loader}>
                Activate
              </Button>
            )}
            <Button
              onClick={() => {
                setShowForm(true);
                setSubscriptionToEdit({});
              }}
              className={classes.button}
            >
              New subscription
            </Button>
          </div>
        </Grid>
        {!isEmpty(textSearch) && (
          <Grid item xs={12} className={classes.infoContainer}>
            <i>Showing results for</i>
            <Typography className={classes.textSearchContent}>{textSearch}</Typography>
          </Grid>
        )}
        <Grid item xs={12} className={classes.dashboardsContainer}>
          <Subscriptions
            textSearch={textSearch}
            searchCriteria={searchCriteria}
            onClick={(model) => {
              setSubscriptionToEdit(model);
              setShowForm(true);
              setForceUpdate(false);
            }}
            onSelect={(subscriptions) => handleSelect(subscriptions)}
            forceUpdate={forceUpdate}
          />
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawerPaper, paperAnchorRight: classes.paperAnchorRight }}
        onClose={() => {
          setShowForm(false);
          setForceUpdate(false);
        }}
        open={showForm}
        variant="temporary"
      >
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            onClick={() => {
              setShowForm(false);
              setForceUpdate(false);
            }}
            variant="text"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <SubscriptionForm
          {...normalizeSubscriptionToEdit(subscriptionToEdit)}
          onSuccess={() => {
            setShowForm(false);
            setForceUpdate(true);
          }}
          onDelete={() => {
            setShowForm(false);
            setForceUpdate(true);
          }}
        />
      </Drawer>
      <SnackBar open={Boolean(error)} message={error} severity="warning" />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
});

export default connect(mapStateToProps)(withStyles(styles)(SubscriptionsView));
