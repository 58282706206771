import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

const getExcludedDataboards = (subscriptions, subscriptionId, databoardId) => {
  let excludeDataboards = subscriptions;
  if (!isEmpty(subscriptionId)) {
    excludeDataboards = filter(excludeDataboards, (id) => id !== databoardId);
  }

  return excludeDataboards;
};

export default getExcludedDataboards;
