import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { DatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    marginBottom: '1.2rem',
  },
  field: {
    textAlign: 'left',
    borderColor: '#bcc6d2',
    margin: '0.4rem 0',
    height: '3.7rem',
    borderRadius: 60,
  },
  label: {
    fontSize: '1rem',
    color: '#636666',
    transform: 'translate(2rem, 1.8rem) scale(1)',
  },
  labelFocused: {
    color: theme.palette.custom.blue2,
    fontSize: '0.5rem',
    fontWeight: 'bold',
    transform: 'translate(2rem, 0.9rem) !important',
  },
  labelShrink: {
    fontSize: '0.5rem',
    transform: 'translate(2rem, 0.9rem) !important',
  },
  fieldFocused: {
    borderColor: theme.palette.custom.blue2,
  },
  icon: {
    color: theme.palette.custom.blue2,
  },
  input: {
    paddingLeft: '2rem',
  },
});

const DatePickerWrapper = ({
  classes,
  label,
  name = 'text',
  type = 'text',
  required = true,
  autoComplete,
  input,
  meta: { touched, error },
}) => (
  <DatePicker
    error={!isEmpty(error) && touched}
    format="MM/DD/YYYY"
    color="primary"
    label={label}
    type={type}
    name={name}
    value={input.value}
    onChange={input.onChange}
    onBlur={input.onBlur}
    helperText={!isEmpty(error) && touched ? error : ''}
    autoComplete={autoComplete}
    margin="none"
    variant="outlined"
    required={required}
    fullWidth
    classes={{ root: classes.root }}
  />
);

export default withStyles(styles)(DatePickerWrapper);
