import palette from '../palette';
import { axisLine, horizontalCartesianGrid } from '../config';
import { map } from 'lodash';
import { FORMATTER_PERCENTAGE } from '../helpers/formatters';

const BAR_CHART_LAYOUT_HORIZONTAL = 'horizontal';
const LEGEND_TYPE_DEFAULT = 'DEFAULT';

export const getSeries = (series) =>
  map(series, (seriesKey, index) => ({
    dataKey: seriesKey,
    stroke: palette[index],
    type: 'monotone',
  }));

export const defaultConfig = {
  rootProps: {
    layout: BAR_CHART_LAYOUT_HORIZONTAL,
    margin: {
      top: 10,
      right: 40,
      left: 40,
      bottom: 20,
    },
  },
  cartesianGridProps: horizontalCartesianGrid,
  legendProps: {
    type: LEGEND_TYPE_DEFAULT,
    hide: true,
    verticalAlign: 'top',
    align: 'right',
    wrapperStyle: {
      paddingBottom: '0px',
    },
  },
  tooltipProps: { hide: false, formatter: FORMATTER_PERCENTAGE },
  xAxisProps: {
    type: 'category',
    dataKey: 'name',
    adjustHeight: true,
    axisLine,
    tickLine: false,
    angle: -45,
    textAnchor: 'end',
    interval: 0,
    showSamplesInAxis: false,
    hide: false,
    ticksProps: {
      type: 'custom',
      width: 180,
    },
    responsive: {
      xs: {
        ticksProps: {
          type: 'custom',
          width: 50,
        },
      },
    },
  },
  yAxisProps: {
    type: 'number',
    tickLine: false,
    allowDecimals: false,
    axisLine,
    label: {
      value: '',
      offset: 0,
      angle: -90,
      position: 'insideBottomLeft',
      style: {
        fill: '#000000',
        fontSize: 13,
        fontWeight: 'bold',
      },
    },
    scale: 'linear',
  },
  brushProps: {
    visibility: false,
    dataKey: '',
    height: 20,
  },
};
