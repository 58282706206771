import React from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';

import omit from 'lodash/omit';
import get from 'lodash/get';
import find from 'lodash/find';

import Text from 'react-svg-text';

import { withStyles } from '@material-ui/core/styles';

import { getChartLabels } from '../../../../../state/labels/selectors';
import HintTooltip from '../HintTooltip';

const styles = (theme) => ({
  root: {
    fontSize: 16,
    color: theme.palette.custom.black,
    fontWeight: 500,
  },
  dialogView: {
    fontSize: 18,
    color: theme.palette.custom.black,
  },
});

const CustomTick = (props) => {
  const { payload, labels, classes, view, width } = props;

  const label = find(labels, { name: payload.value });
  const labelDescription = get(find(labels, { name: payload.value }), 'hint', '');

  return (
    <HintTooltip title={labelDescription}>
      <Text
        lineHeigh={'1.5em'}
        {...omit(props, ['visibleTicksCount', 'dispatch', 'classes'])}
        className={cn(classes.root, { [classes.dialogView]: view === 'dialog' })}
        width={Math.min(width - 15, 365)}
      >
        {get(label, 'text', payload.value)}
      </Text>
    </HintTooltip>
  );
};

const mapStateToProps = (state, { id }) => ({
  labels: getChartLabels(state, id),
});

export default connect(mapStateToProps)(withStyles(styles)(CustomTick));
