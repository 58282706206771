import React from 'react';

import get from 'lodash/get';
import head from 'lodash/head';
import find from 'lodash/find';

import Sample from '../Sample';
import { Container, ChartWrapper, SampleWrapper } from '../Container';
import BarChartEngine from '../RechartBarChartEngine';

import defaultConfig from './config';
import generateConfig from './generateConfig';
import { LOW_SAMPLE_SIZE } from '../../../utils/hasLowSampleSize';

const BarChartSideBySide = ({
  data,
  samples,
  graphLabels,
  formatter,
  id,
  domain,
  view,
  customConfig,
  configuration,
}) => {
  const { rootProps } = defaultConfig;

  if (view === 'dialog') {
    rootProps.margin = {
      top: 26,
      right: 26,
      left: 50,
      bottom: 26,
    };
  }

  const chartThreshold = get(find(configuration, { key: 'threshold' }), 'value', LOW_SAMPLE_SIZE);

  return (
    <Container>
      <ChartWrapper>
        {data &&
          data.map((dataItem, index) => (
            <BarChartEngine
              id={id}
              key={index}
              {...generateConfig(
                defaultConfig,
                formatter,
                get(graphLabels, index, ''),
                domain,
                customConfig,
                configuration,
                index,
              )}
              data={dataItem}
              view={view}
            />
          ))}
      </ChartWrapper>
      <SampleWrapper>
        <Sample
          name={get(head(samples), 'name', 'n')}
          value={get(head(samples), 'value', 0)}
          chartThreshold={chartThreshold}
        />
      </SampleWrapper>
    </Container>
  );
};

export default BarChartSideBySide;
