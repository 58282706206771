import React, { useState } from 'react';
import cn from 'classnames';

import { Grid, Tooltip, Popover, makeStyles } from '@material-ui/core';

import { ReactComponent as FilterIcon } from './assets/filter.svg';
import Options, { OptionsProps } from './Options';
import ITheme from '../../../theme/@types/Theme';

const useStyles = makeStyles((theme: ITheme) => ({
  tooltip: {
    backgroundColor: theme.palette.custom.white,
    boxShadow: '-2px 3px 10px 0 rgba(0, 0, 0, 0.16)',
    border: 'solid 1px #e7e7ef',
    borderRadius: 5,
    padding: '14px 13px',
    color: theme.palette.custom.black,
    fontSize: 10,
    lineHeight: 1.32,
  },
  button: {
    fontSize: 12,
    color: theme.palette.custom.black,
    display: 'flex',
    justifyContent: 'space-between',
  },
  options: {
    height: 272,
    width: 187,
    flexWrap: 'nowrap',
  },
  popover: { marginTop: 5 },
  paper: { borderRadius: 10 },
  badge: {
    width: 19,
    height: 19,
    borderRadius: '50%',
    backgroundColor: theme.palette.custom.purple,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 11,
  },
  label: { marginLeft: 4, marginRight: 4, cursor: 'pointer' },
}));

interface Props {
  label: string;
  tooltip: string;
  className?: string;
  counter: number;
  optionsProps: OptionsProps;
}

const DropDown: React.FC<Props> = ({ tooltip, className, label, counter, optionsProps }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const classes = useStyles();

  return (
    <>
      <Tooltip title={tooltip} classes={{ tooltip: classes.tooltip }}>
        <Grid
          className={cn(classes.button, className)}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <div>
            <FilterIcon />
            <span className={classes.label}>{label}</span>
          </div>
          {counter > 0 && <div className={classes.badge}>{counter}</div>}
        </Grid>
      </Tooltip>
      <Popover
        id="render-props-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ root: classes.popover, paper: classes.paper }}
      >
        <Options {...optionsProps} className={classes.options} />
      </Popover>
    </>
  );
};

export default DropDown;
