import { filter, find, forEach, get } from 'lodash';
import flattenDeep from 'lodash/flattenDeep';
import keys from 'lodash/keys';
import map from 'lodash/map';
import omit from 'lodash/omit';

const createSelectorExpression = ({ form, filters, filterLinks }) => {
  const combinedFilterLinks = filter(filterLinks, { type: 'COMPOUND' });
  const removedFilters = [];
  forEach(combinedFilterLinks, (link) => {
    removedFilters.push(link.source);
    removedFilters.push(...link.destination);
  });
  const filtersArr = map(keys(filters), (key) => filters[key]);
  const removedFiltersIds = map(removedFilters, (filter) => {
    return get(find(filtersArr, { name: filter }), '_id', '');
  });
  forEach(removedFiltersIds, (id) => {
    form = omit(form, id);
  });
  return flattenDeep(map(keys(omit(form, 'benchmark')), (key) => form[key]));
};

export default createSelectorExpression;
