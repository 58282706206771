import React, { useState, useRef } from 'react';

import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import get from 'lodash/get';
import pick from 'lodash/pick';

import { withStyles } from '@material-ui/core/styles';

import FilterWarning from './components/FilterWarning';
import FilterDrawer from './components/FilterDrawer';

import { setActiveBlock, updateFilterValues } from '../../../state/filtersData/actions';
import { trackFilterUsage } from '../../../state/analytics/actions';

const styles = () => ({});

const GlobalFiltersDrawer = ({
  open,
  className,
  dirty,
  onExit,
  handleSubmit,
  titleInView,
  // reset,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const scrollRef = useRef(null);

  const handleExit = () => {
    if (dirty) {
      setShowPopup(true);
    } else {
      onExit();
    }
  };

  const handleDialogSubmit = () => {
    handleSubmit();
    setShowPopup(false);
    onExit();
  };

  const handleDialogDiscard = () => {
    // reset();
    setShowPopup(false);
  };

  return (
    <>
      <FilterDrawer
        open={open}
        className={className}
        handleExit={handleExit}
        scrollRef={scrollRef}
        dirty={dirty}
        handleSubmit={handleSubmit}
        titleInView={titleInView}
      />
      <FilterWarning open={showPopup} submit={handleDialogSubmit} discard={handleDialogDiscard} />
    </>
  );
};

const mapStateToProps = (state) => {
  const initialValues = pick(
    get(state, ['filtersData', 'byId']),
    get(state, ['filtersData', 'root']),
  );

  return { initialValues };
};

const mapDispatchToProps = (dispatch) => ({
  onExit: () => dispatch(setActiveBlock(false)),
  onSubmit: (data) => {
    dispatch(updateFilterValues(data));
    dispatch(trackFilterUsage(data));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    // other redux-form options...
    destroyOnUnmount: false,
    enableReinitialize: true,
    form: 'globalFilters',
  }),
)(withStyles(styles)(GlobalFiltersDrawer));
