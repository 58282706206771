import { find } from 'lodash';

/**
 *
 * @param {*} subscriptions User subscriptions
 * @param {*} organisations User organisations
 * @param {*} dashboardId Dashboard ID
 */
const isFree = (subscriptions, organisations, dashboardId) => {
  const freeTrialOrg = find(organisations, { name: 'default' });

  const subscription = find(subscriptions, { databoardId: dashboardId });
  // If no subscription then a preview or limited is being viewed by the user
  if (subscription) {
    return subscription.organisationId === freeTrialOrg._id;
  }
  return false;
};

export default isFree;
