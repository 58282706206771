import orderBy from 'lodash/orderBy';

const sortByTitle = (dashboards, order) => {
  return orderBy(dashboards, ['title'], order);
};
const sortByVisibility = (dashboards, order) => orderBy(dashboards, ['private'], order);

const sortDashboards = (dashboards, orderByField, order) => {
  switch (orderByField) {
    case 'title':
      return sortByTitle(dashboards, order);

    case 'visibility':
      return sortByVisibility(dashboards, order);

    default:
      return dashboards;
  }
};

export default sortDashboards;
