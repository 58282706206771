import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import head from 'lodash/head';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import filter from 'lodash/filter';
import isNull from 'lodash/isNull';

const getScoreData = ({ data, tableName }) => {
  const table = get(find(data, { name: tableName }), 'values', []);
  const cleanedTable = filter(table, ({ values }) => !isNull(get(head(values), 'value')));

  const unsortedData = map(cleanedTable, ({ name, text, values }) => {
    const value = get(head(values), 'value', 0);
    return {
      name,
      text,
      score: value,
    };
  });

  return reverse(sortBy(unsortedData, 'score'));
};

export default getScoreData;
