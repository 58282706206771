import { map, orderBy } from 'lodash';

import hasDashboardAccess from '../../../../../utils/hasDashboardAccess';

const sortByTitle = (reports, order) => orderBy(reports, ['title'], order);
const sortByAreas = (reports, order) => orderBy(reports, ['areas'], order);
const sortByType = (reports, order) => orderBy(reports, ['type'], order);
const sortBySurveyPeriod = (reports, order) => orderBy(reports, ['period'], order);
const sortByDatePublished = (reports, order) => orderBy(reports, ['published'], order);

const sortReports = (reports, subscriptions, orderByField, order) => {
  const transformedReports = map(reports, (report) => ({
    ...report,
    hasAccess: hasDashboardAccess(subscriptions, report._id),
  }));

  switch (orderByField) {
    case 'title':
      return sortByTitle(transformedReports, order);
    case 'areas':
      return sortByAreas(transformedReports, order);
    case 'type':
      return sortByType(transformedReports, order);
    case 'period':
      return sortBySurveyPeriod(transformedReports, order);
    case 'published':
      return sortByDatePublished(transformedReports, order);
    default:
      return transformedReports;
  }
};

export default sortReports;
