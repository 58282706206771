import React, { useState } from 'react';
import cn from 'classnames';

import ReactMarkdown from 'react-markdown';

import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
//import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { ReactComponent as InfoIcon } from './assets/InfoIcon.svg';
import { Tooltip } from '@material-ui/core';
import { PrismicRichText } from '@prismicio/react';
import PrismicLinkComponent from '../PrismicLinkComponent';

const styles = (theme) => ({
  root: {
    display: 'inline-flex',
  },
  paper: {
    padding: '2.2rem 3.3rem',
    border: '1px solid #ececf2',
    boxShadow: '-9.4px 10.4px 50px 0 rgba(188, 198, 210, 0.4)',
  },
  text: {
    fontSize: '0.9rem',
    color: theme.palette.custom.black,
    maxWidth: '40.6rem',
    columnCount: 2,
    lineHeight: '1.5em',
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  prismicText: {
    columnCount: 2,
    fontSize: '0.9rem',
    color: theme.palette.custom.black,
    maxWidth: '40.6rem',
    lineHeight: '1.5em',
  },
});

const pathURL = window.location.pathname;
const isReport = pathURL.includes('/reports');

const InfoBox = ({ classes, text, className }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div className={cn(classes.root, { [className]: !!className })}>
      <Tooltip title="More Information">
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <InfoIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        {isReport ? (
          <div className={classes.prismicText}>
            <PrismicRichText
              field={text}
              externalLinkComponent={({ href, ...props }) => (
                <PrismicLinkComponent href={href} {...props} />
              )}
            />
          </div>
        ) : (
          <ReactMarkdown
            className={classes.text}
            source={text}
            escapeHtml={false}
            linkTarget="_blank"
          />
        )}
      </Popover>
    </div>
  );
};

export default withStyles(styles)(InfoBox);
