import React from 'react';
import cn from 'classnames';

import get from 'lodash/get';
import find from 'lodash/find';

import { withStyles } from '@material-ui/core/styles';
import { LOW_SAMPLE_SIZE } from '../../../../../utils/hasLowSampleSize';

const styles = () => ({
  text: {
    fill: '#979797',
    fontWeight: 'bold',
    fontSize: 16,
  },
  lowSampleText: {
    fill: 'red',
  },
});

const YAxisSample = ({
  x,
  y,
  payload,
  samples,
  data,
  classes,
  chartThreshold = LOW_SAMPLE_SIZE,
}) => {
  const key = get(data, [payload.value, 'name'], '');
  const sample = find(samples, { name: key });

  return (
    <g>
      <text
        textAnchor="start"
        x={x}
        y={y}
        className={cn(classes.text, {
          [classes.lowSampleText]: get(sample, 'value', '') < chartThreshold,
        })}
      >
        {`n = ${get(sample, 'value', '').toLocaleString()}`}
      </text>
    </g>
  );
};

export default withStyles(styles)(YAxisSample);
