import React, { useEffect } from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import find from 'lodash/find';
import get from 'lodash/get';
import pick from 'lodash/pick';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { removeFilter } from '../../../state/filtersData/actions';

import FilterTag from '../FilterTag';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.custom.gray1,
    display: 'flex',
    height: '4.1rem',
    alignItems: 'center',
  },
  last: {
    boxShadow: '0 5px 29px 0 rgba(188, 198, 210, 0.41)',
  },
  divider: {
    borderBottom: '1px solid #e7e7ef',
  },
});

const SectionFilters = ({
  classes,
  className = '',
  data,
  filters,
  title,
  activeSectionId,
  onRemoveFilter,
  notify,
}) => {
  useEffect(() => {
    if (!isEmpty(data)) {
      notify();
    }
  }, [data]);

  if (isEmpty(data)) {
    return null;
  }

  return (
    <>
      <div key={`sector-${activeSectionId}`}>
        <div className={classes.divider} />
        <div
          className={cn(classes.root, className, {
            // [classes.last]: sectionIndex + 1 === data.length,
            [classes.last]: true,
          })}
        >
          <Typography variant="h6">{title}</Typography>
          <div>
            {map(keys(data), (filterId, filterIndex) => (
              <FilterTag
                key={`section-filter-${filterIndex}`}
                _id={filterId}
                label={get(filters, [filterId, 'text'], '')}
                values={data[filterId]}
                onRemove={() => onRemoveFilter(filterId, activeSectionId)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const getSectionTitles = (state, activeSectionId) => {
  const sections = get(state, ['databoard', 'sections']);
  const section = find(sections, { _id: activeSectionId });

  return get(section, 'title');
};

const mapStateToProps = (state, { activeSectionId }) => ({
  data: pick(
    get(state, ['filtersData', 'byId']),
    get(state, ['filtersData', 'bySection', activeSectionId]),
  ),
  filters: pick(
    get(state, ['filtersIndex', 'byId']),
    get(state, ['filtersData', 'bySection', activeSectionId]),
  ),
  title: getSectionTitles(state, activeSectionId),
});

const mapDispatchToProps = (dispatch) => ({
  onRemoveFilter: (name, section) => dispatch(removeFilter(name, section)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SectionFilters));
