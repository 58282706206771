import { clone, concat, map, remove } from 'lodash';
import orderBy from 'lodash/orderBy';
import { MAX_DATE } from '../../../../../../components/Admin/SubscriptionForm/SubscriptionForm';

const sortByOrganisation = (subscriptions, order) =>
  orderBy(subscriptions, ['organisation.name'], order);
const sortByResource = (subscriptions, order) => {
  // Adding a name attribute to sort on both the titles of DAAs and Databoards
  const newSubscriptions = map(subscriptions, (subscription) => {
    return {
      ...subscription,
      name: subscription.databoard
        ? subscription.databoard.title
        : subscription.dataanalystaccess
        ? subscription.dataanalystaccess.title
        : subscription.report.title,
    };
  });
  return orderBy(newSubscriptions, ['name'], order);
};
const sortByStartDate = (organisations, order) => orderBy(organisations, ['startDate'], order);
const sortByExpirationDate = (organisations, order) => {
  // Handeling the indefinite MAX_DATE case by removing them then sorting and re-concatenating them
  const clonedOrganisations = clone(organisations);
  const indefiniteSubscriptions = remove(clonedOrganisations, (subscription) => {
    return new Date(subscription.expirationDate).getFullYear() === MAX_DATE.getFullYear();
  });
  const orderedFiniteSubscriptions = orderBy(clonedOrganisations, ['expirationDate'], order);
  if (order === 'asc') {
    return concat(orderedFiniteSubscriptions, indefiniteSubscriptions);
  }
  return concat(indefiniteSubscriptions, orderedFiniteSubscriptions);
};
const sortByType = (organisations, order) => orderBy(organisations, ['databoard'], order);

const sortSubscriptions = (organisations, orderByField, order) => {
  switch (orderByField) {
    case 'organisation':
      return sortByOrganisation(organisations, order);
    case 'resource':
      return sortByResource(organisations, order);
    case 'startDate':
      return sortByStartDate(organisations, order);
    case 'expirationDate':
    case 'timeLeft':
      return sortByExpirationDate(organisations, order);
    case 'type':
      return sortByType(organisations, order);
    default:
      return organisations;
  }
};

export default sortSubscriptions;
