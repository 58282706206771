import map from 'lodash/map';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/orderBy';
import findIndex from 'lodash/findIndex';
import reverse from 'lodash/reverse';
import head from 'lodash/head';
import filter from 'lodash/filter';
import concat from 'lodash/concat';
import indexOf from 'lodash/indexOf';
import omit from 'lodash/omit';

import normalizeCategoricalData from './normalizeCategoricalData';
import { find, forEach, isNil, isNumber } from 'lodash';

const OLD_SURVEY = 'OLD';
const ALL_SURVEYS = 'ALL';

export const getDataPerSource = ({ data, sources, table }) =>
  reduce(
    data,
    (acc, item, index) => {
      return {
        ...acc,
        [sources[index]]: normalizeCategoricalData({
          key: sources[index],
          data: item,
          table,
        }),
      };
    },
    {},
  );

export const getDataPerSourceStatic = ({ data, table }) => {
  const tables = get(find(data, { name: table }, []), 'values', []);
  const arrayValues = map(tables, (table) => {
    return {
      [table.name]: map(table.values, (value) => {
        return { [table.name]: value.value, name: value.name, hint: value.hint };
      }),
    };
  });
  let result = {};
  forEach(arrayValues, (ObjValue) => {
    result[Object.keys(ObjValue)[0]] = Object.values(ObjValue)[0];
  });
  return result;
};

export const transformToRechartsModel = ({ dataPerSource, sources, questionLabelsSurvey }) => {
  // If OLD is specified then we switch the order to get the oldest survey labels
  if (questionLabelsSurvey === OLD_SURVEY) {
    sources = reverse(sources);
  }
  let initial = dataPerSource[head(sources)];
  const appendByNameFunc = ({ name, ...rest }) => {
    const itemIndex = findIndex(initial, { name });
    // We only push items that are not currently present if ALL survey labels is specified
    if (itemIndex === -1 && questionLabelsSurvey === ALL_SURVEYS) {
      initial.push({ ...rest, name });
    } else {
      if (itemIndex !== -1) {
        initial[itemIndex] = {
          ...initial[itemIndex],
          ...rest,
          name,
        };
      }
    }
  };

  let index = 1;
  while (index < sources.length) {
    const next = dataPerSource[sources[index]];
    forEach(next, appendByNameFunc);
    index += 1;
  }

  return initial;
};

export const cleanUpMultiSourceZeros = ({ data, sources }) =>
  filter(data, (entry) => {
    const nonZeroSeries = filter(sources, (source) => {
      const currentValue = entry[source];
      return !isNil(currentValue) && currentValue !== 0;
    });

    return !isEmpty(nonZeroSeries);
  });

export const sortMultiSourceData = ({ data }) => {
  const sortedResults = reverse(
    sortBy(data, (valuesToSortBy) =>
      reduce(omit(valuesToSortBy, ['name', 'hint']), (result, value) => result + value, 0),
    ),
  );

  return sortedResults;
};

export const rearrangeExcludedItems = ({ data, sorting }) => {
  const exclude = get(sorting, 'exclude', []);

  const sortedData = filter(data, (item) => indexOf(exclude, item.name) === -1);
  const excludedData = filter(data, (item) => indexOf(exclude, item.name) !== -1);

  return concat(sortedData, excludedData);
};

const normalizer = ({ sources, data, table, sorting, sourcesLookup, questionLabelsSurvey }) => {
  sources = map(sources, (source) => {
    if (isNumber(source)) {
      return get(find(sourcesLookup, { id: source }), 'name', source);
    } else {
      return source;
    }
  });
  const pathURL = window.location.pathname;
  const isDataboard = pathURL.includes('/dashboards');
  if ((isEmpty(sources) && isDataboard) || isEmpty(data)) return [];

  const dataPerSource = isDataboard
    ? getDataPerSource({ data, sources, table })
    : getDataPerSourceStatic({ data, table });

  const dataSources = isDataboard ? sources : Object.keys(dataPerSource);
  const rechartsData = transformToRechartsModel({
    dataPerSource,
    sources: dataSources,
    questionLabelsSurvey,
  });
  const nonZeroResults = cleanUpMultiSourceZeros({ data: rechartsData, sources: dataSources });

  if (isEmpty(sorting)) return nonZeroResults;

  const sortedResults = sortMultiSourceData({ data: nonZeroResults });
  const finalData = rearrangeExcludedItems({ data: sortedResults, sorting });
  return finalData;
};

export default normalizer;
