import React, { useState } from 'react';
import cn from 'classnames';

import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import BackIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import Groups from '../Groups';
import Sources from '../Sources';
import GroupFilters from '../GroupFilters';
import { resetGlobalFilters } from '../../../../../state/filtersData/actions';

const styles = (theme) => ({
  root: { zIndex: `${theme.zIndex.drawer} !important` },
  paper: {
    backgroundColor: theme.palette.custom.white,
    boxShadow: '4px 0px 18px 0 rgba(188, 198, 210, 0.15)',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '4.5rem',
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    backgroundColor: theme.palette.custom.white,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  left: {
    justifyContent: 'flex-start',
  },
  iconButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  icon: {
    fill: theme.palette.custom.gray4,
    fontSize: '1.5rem',
  },
  button: {
    width: '15.3rem',
    height: '4.1rem',
    margin: '1.7rem 0 0.7rem 0',
    borderRadius: 50,
    fontSize: '1.1rem',
  },
  inactiveBtn: {
    backgroundColor: '#ececf2',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ececf2',
      boxShadow: 'none',
      cursor: 'default',
    },
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'capitalize !important',
  },
  btnLabel: {
    textTransform: 'capitalize',
    marginLeft: '0.2rem',
    fontSize: '1.1rem',
  },
  clearAllButton: {
    width: '15.3rem',
    height: '4.1rem',
    borderRadius: 50,
    '&:hover': {
      boxShadow: 'none',
    },
  },
  clearAllLabel: {
    fontWeight: 'bold',
    textTransform: 'capitalize !important',
    color: theme.palette.custom.gray5,
  },
  smallPadding: {
    paddingTop: '4.5rem',
  },
  largePadding: {
    paddingTop: '15.5rem',
  },
});

const CloseFormButton = ({ onClick, classes }) => (
  <IconButton onClick={onClick}>
    <CloseIcon className={classes.icon} />
  </IconButton>
);

const BackToCategoriesButton = ({ onBack, onExit, classes }) => (
  <div className={classes.iconButtonsContainer}>
    <Button onClick={onBack}>
      <BackIcon className={classes.icon} />
      <span className={classes.btnLabel}>All filters</span>
    </Button>
    <IconButton onClick={onExit}>
      <CloseIcon className={classes.icon} />
    </IconButton>
  </div>
);

const FilterDrawer = ({
  open,
  classes,
  className,
  handleExit,
  scrollRef,
  dirty,
  handleSubmit,
  onClearAll,
  titleInView,
}) => {
  const [activeGroup, setActiveGroup] = useState(null);

  const handleSubmitAction = () => {
    if (dirty) {
      handleSubmit();
    }
  };

  return (
    <Drawer
      ModalProps={{
        disablePortal: true,
        keepMounted: true,
        hideBackdrop: true,
        disableScrollLock: true,
      }}
      open={open}
      classes={{
        root: cn(classes.root, className),
        paper: cn(
          classes.paper,
          className,
          { [classes.smallPadding]: !titleInView },
          { [classes.largePadding]: titleInView },
        ),
      }}
    >
      <>
        <div className={cn(classes.header, { [classes.left]: !isEmpty(activeGroup) })}>
          {isEmpty(activeGroup) ? (
            <CloseFormButton onClick={handleExit} classes={classes} />
          ) : (
            <BackToCategoriesButton
              onBack={() => setActiveGroup(null)}
              onExit={handleExit}
              classes={classes}
            />
          )}
        </div>
        <Scrollbars ref={scrollRef}>
          {isEmpty(activeGroup) ? (
            <>
              <Sources />
              <Groups onClick={setActiveGroup} />
            </>
          ) : (
            <GroupFilters groupId={activeGroup} scrollRef={scrollRef} />
          )}
        </Scrollbars>
      </>
      <div className={classes.footer}>
        <Button
          size="large"
          onClick={handleSubmitAction}
          variant="contained"
          color="primary"
          classes={{
            root: cn(classes.button, { [classes.inactiveBtn]: !dirty }),
            label: classes.label,
          }}
        >
          Apply filters
        </Button>
        <Button
          size="large"
          onClick={onClearAll}
          classes={{
            root: classes.clearAllButton,
            label: classes.clearAllLabel,
          }}
        >
          Clear all
        </Button>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (dispatch) => ({
  onClearAll: () => dispatch(resetGlobalFilters()),
});

export default connect(null, mapStateToProps)(withStyles(styles)(FilterDrawer));
