import filter from 'lodash/filter';
import get from 'lodash/get';
import reverse from 'lodash/reverse';
import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';

import {
  TYPE_BAR_CHART_MULTI,
  TYPE_BAR_CHART_BENCHMARK,
  TYPE_BAR_CHART_STACKED_MULTI,
  TYPE_COLUMN_CHART_MULTI,
  TYPE_COLUMN_CHART_BENCHMARK,
} from '../../components/Graphs/GraphsRenderer/types';

import { FETCH_CHART_DATA_REQUEST, RESET_STATE } from './types';
import {
  SourcesHelper,
  createInputFilterExpression,
  createOutputFilterExpression,
} from './helpers';
import { getChartFiltersByType, getChartFilterSources } from './selectors';
import { DATABOARD_SOURCES_FILTER } from '../filtersData/types';
import createContextExpression from './helpers/createContextExpression';
import createBenchmarkFilterExpression from './helpers/createBenchmarkFilterExpression';

import fetchMultiSurveyChartData from './helpers/api/fetchMultiSurveyChartData';
import fetchMergedSurveyChartData from './helpers/api/fetchMergedSurveyChartData';
import fetchBenchmarkedMultiSurveyChartData from './helpers/api/fetchBenchmarkedMultiSurveyChartData';
import fetchBenchmarkedMergedUnstackedSurveyChartData from './helpers/api/fetchBenchmarkedMergedUnstackedSurveyChartData';
import fetchBenchmarkedMergedSurveyChartData from './helpers/api/fetchBenchmarkedMergedSurveyChartData';
import { find, head, map } from 'lodash';
import createV2FilterExpression from './helpers/createV2FilterExpression';
import createSelectorExpression from './helpers/createSelectorExpression';

export const fetchChartData = (id) => async (dispatch, getState) => {
  dispatch({ type: FETCH_CHART_DATA_REQUEST, payload: { id } });
  const filters = get(getState(), ['filtersIndex', 'byId'], {});
  const version = get(getState(), ['databoard', 'version'], 1);
  const selectedSources = get(getState(), ['filtersData', 'byId', DATABOARD_SOURCES_FILTER], []);
  const chart = get(getState(), ['charts', 'byId', id], {});
  const type = get(chart, 'type', '');
  const query = get(chart, 'query', {});
  const chartSources = get(chart, 'sources', []);
  const reject = get(chart, 'reject', []);
  const benchmarkingRejectedFilters = get(chart, ['benchmarking', 'reject'], []);
  const context = get(chart, 'context', []);
  const inputFilterValues = getChartFiltersByType(getState(), id, 'INPUT');
  const colOutputFilterValues = getChartFiltersByType(getState(), id, 'OUTPUT.COL');
  const rowOutputFilterValues = getChartFiltersByType(getState(), id, 'OUTPUT.ROW');
  const outputFilterValues = getChartFiltersByType(getState(), id, 'OUTPUT');
  const filterLinks = get(getState(), ['databoard', 'filterLinks'], []);
  const pathURL = window.location.pathname;
  const isDataboard = pathURL.includes('/dashboards');

  if (isEmpty(selectedSources) && isDataboard) return;

  // Handle chart.sources.filter and chart.sources.reject
  const sources = SourcesHelper.getAppliedSources({
    sources: reverse(Array.from(selectedSources).sort()),
    chartSources,
    filterSources: getChartFilterSources(getState(), id, 'INPUT'),
  });

  // We handle sources as filters in the UI to force update on sources change
  const filterExpression = createInputFilterExpression({
    form: inputFilterValues,
    filters,
  });
  let benchmarkFilterExpressions = createBenchmarkFilterExpression({
    form: inputFilterValues,
    filters,
    rejectedSeries: [...reject, ...benchmarkingRejectedFilters],
  });
  const rejectExpression = get(chart, 'reject', []);
  const columnExpression = createOutputFilterExpression({ form: colOutputFilterValues });
  const rowExpression = createOutputFilterExpression({ form: rowOutputFilterValues });
  const contextExpression = createContextExpression({
    context,
    appliedFilters: inputFilterValues,
    filters,
  });

  let requestParams = {
    id,
    name: query.name,
    sources,
    filterExpression,
    rejectExpression,
    columnExpression,
    rowExpression,
    contextExpression,
    computation: query.computation,
  };

  if (
    (type === TYPE_BAR_CHART_MULTI ||
      type === TYPE_COLUMN_CHART_MULTI ||
      type === TYPE_BAR_CHART_BENCHMARK ||
      type === TYPE_COLUMN_CHART_BENCHMARK) &&
    isDataboard
  ) {
    requestParams = {
      id,
      name: query.name,
      // reject the sources excluded using the legend
      sources: filter(sources, (source) => indexOf(reject, source) === -1),
      filterExpression,
      contextExpression,
      computation: query.computation,
    };
  }

  if (version === 2) {
    benchmarkFilterExpressions = createBenchmarkFilterExpression({
      form: inputFilterValues,
      filters,
      rejectedSeries: [...reject, ...benchmarkingRejectedFilters],
      version: 2,
      filterLinks,
      selectedSources,
    });
    const selectorExpression = createSelectorExpression({
      form: outputFilterValues,
      filters,
      filterLinks,
    });
    const sourcesArr = get(getState(), ['labels', 'sources'], []);
    const sourcesIds = sources.map((source) => get(find(sourcesArr, { name: source }), 'id', -1));
    const filterV2Expression = map(sources, (source) => {
      return {
        key: get(find(sourcesArr, { name: source }), 'id', -1),
        value: createV2FilterExpression({
          form: inputFilterValues,
          outputFilterValues,
          filters,
          filterLinks,
          selectedSources: [source],
        }),
      };
    });
    query.source = head(sourcesIds);
    query.filter = filterV2Expression;
    query.selector = selectorExpression;
    requestParams = {
      id,
      version: 2,
      query,
      sources: filter(sourcesIds, (source) => indexOf(reject, source) === -1),
    };
  }

  if (isEmpty(benchmarkFilterExpressions)) {
    if (
      (type === TYPE_BAR_CHART_MULTI ||
        type === TYPE_COLUMN_CHART_MULTI ||
        type === TYPE_BAR_CHART_STACKED_MULTI) &&
      isDataboard
    ) {
      dispatch(fetchMultiSurveyChartData({ ...requestParams, type }));
    } else {
      dispatch(fetchMergedSurveyChartData({ ...requestParams, type }));
    }
  } else if (!isEmpty(benchmarkFilterExpressions)) {
    if (
      (type === TYPE_BAR_CHART_MULTI ||
        type === TYPE_COLUMN_CHART_MULTI ||
        type === TYPE_BAR_CHART_BENCHMARK ||
        type === TYPE_COLUMN_CHART_BENCHMARK) &&
      isDataboard
    ) {
      dispatch(
        fetchBenchmarkedMergedUnstackedSurveyChartData({
          ...requestParams,
          benchmarkFilterExpressions,
          type,
        }),
      );
    } else if (type === TYPE_BAR_CHART_STACKED_MULTI && isDataboard) {
      dispatch(
        fetchBenchmarkedMultiSurveyChartData({
          ...requestParams,
          benchmarkFilterExpressions,
          type,
        }),
      );
    } else {
      dispatch(
        fetchBenchmarkedMergedSurveyChartData({
          ...requestParams,
          benchmarkFilterExpressions,
          type,
        }),
      );
    }
  }
};

export const fetchByChartId = (id) => async (dispatch, getState) => {
  dispatch({ type: FETCH_CHART_DATA_REQUEST, payload: { id } });

  const filters = get(getState(), ['filtersIndex', 'byId'], {});
  const version = get(getState(), ['databoard', 'version'], 1);
  const selectedSources = get(getState(), ['filtersData', 'byId', DATABOARD_SOURCES_FILTER], []);
  const chart = get(getState(), ['charts', 'byId', id], {});
  const type = get(chart, 'type', '');
  const query = get(chart, 'query', {});
  const chartSources = get(chart, 'sources', []);
  const reject = get(chart, 'reject', []);
  const benchmarkingRejectedFilters = get(chart, ['benchmarking', 'reject'], []);
  const context = get(chart, 'context', []);
  const inputFilterValues = getChartFiltersByType(getState(), id, 'INPUT');
  const colOutputFilterValues = getChartFiltersByType(getState(), id, 'OUTPUT.COL');
  const rowOutputFilterValues = getChartFiltersByType(getState(), id, 'OUTPUT.ROW');
  const outputFilterValues = getChartFiltersByType(getState(), id, 'OUTPUT');
  const filterLinks = get(getState(), ['databoard', 'filterLinks'], []);
  const pathURL = window.location.pathname;
  const isDataboard = pathURL.includes('/dashboards');

  if (isEmpty(selectedSources) && isDataboard) return;

  // Handle chart.sources.filter and chart.sources.reject
  const sources = SourcesHelper.getAppliedSources({
    sources: reverse(Array.from(selectedSources).sort()),
    chartSources,
    filterSources: getChartFilterSources(getState(), id, 'INPUT'),
  });

  // We handle sources as filters in the UI to force update on sources change
  const filterExpression = createInputFilterExpression({
    form: inputFilterValues,
    filters,
  });
  let benchmarkFilterExpressions = createBenchmarkFilterExpression({
    form: inputFilterValues,
    filters,
    rejectedSeries: [...reject, ...benchmarkingRejectedFilters],
  });
  const rejectExpression = get(chart, 'reject', []);
  const columnExpression = createOutputFilterExpression({ form: colOutputFilterValues });
  const rowExpression = createOutputFilterExpression({ form: rowOutputFilterValues });
  const contextExpression = createContextExpression({
    context,
    appliedFilters: inputFilterValues,
    filters,
  });

  let requestParams = {
    id,
    name: query.name,
    sources,
    filterExpression,
    rejectExpression,
    columnExpression,
    rowExpression,
    contextExpression,
    computation: query.computation,
  };

  if (
    (type === TYPE_BAR_CHART_MULTI ||
      type === TYPE_COLUMN_CHART_MULTI ||
      type === TYPE_BAR_CHART_BENCHMARK ||
      type === TYPE_COLUMN_CHART_BENCHMARK) &&
    isDataboard
  ) {
    requestParams = {
      id,
      name: query.name,
      sources: filter(sources, (source) => indexOf(reject, source) === -1),
      filterExpression,
      contextExpression,
      computation: query.computation,
    };
  }

  if (version === 2) {
    benchmarkFilterExpressions = createBenchmarkFilterExpression({
      form: inputFilterValues,
      filters,
      rejectedSeries: [...reject, ...benchmarkingRejectedFilters],
      version: 2,
      filterLinks,
      selectedSources,
    });
    const selectorExpression = createSelectorExpression({
      form: outputFilterValues,
      filters,
      filterLinks,
    });
    const sourcesArr = get(getState(), ['labels', 'sources'], []);
    const sourcesIds = sources.map((source) => get(find(sourcesArr, { name: source }), 'id', -1));
    const filterV2Expression = map(sources, (source) => {
      return {
        key: get(find(sourcesArr, { name: source }), 'id', -1),
        value: createV2FilterExpression({
          form: inputFilterValues,
          outputFilterValues,
          filters,
          filterLinks,
          selectedSources: [source],
        }),
      };
    });
    query.source = head(sourcesIds);
    query.filter = filterV2Expression;
    query.selector = selectorExpression;
    requestParams = {
      id,
      version: 2,
      query,
      sources: filter(sourcesIds, (source) => indexOf(reject, source) === -1),
    };
  }

  if (isEmpty(benchmarkFilterExpressions)) {
    if (
      (type === TYPE_BAR_CHART_MULTI ||
        type === TYPE_COLUMN_CHART_MULTI ||
        type === TYPE_BAR_CHART_STACKED_MULTI) &&
      isDataboard
    ) {
      dispatch(fetchMultiSurveyChartData({ ...requestParams, type }));
    } else {
      dispatch(fetchMergedSurveyChartData({ ...requestParams, type }));
    }
  } else if (!isEmpty(benchmarkFilterExpressions)) {
    if (
      (type === TYPE_BAR_CHART_MULTI ||
        type === TYPE_COLUMN_CHART_MULTI ||
        type === TYPE_BAR_CHART_BENCHMARK ||
        type === TYPE_COLUMN_CHART_BENCHMARK) &&
      isDataboard
    ) {
      dispatch(
        fetchBenchmarkedMergedUnstackedSurveyChartData({
          ...requestParams,
          benchmarkFilterExpressions,
          type,
        }),
      );
    } else if (type === TYPE_BAR_CHART_STACKED_MULTI && isDataboard) {
      dispatch(
        fetchBenchmarkedMultiSurveyChartData({
          ...requestParams,
          benchmarkFilterExpressions,
          type,
        }),
      );
    } else {
      dispatch(
        fetchBenchmarkedMergedSurveyChartData({
          ...requestParams,
          benchmarkFilterExpressions,
          type,
        }),
      );
    }
  }
};

export const resetData = () => ({
  type: RESET_STATE,
});
