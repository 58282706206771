import React, { useCallback, useState } from 'react';
import qs from 'qs';

import { API } from 'aws-amplify';
import { useSelector } from 'react-redux';

import concat from 'lodash/concat';
import get from 'lodash/get';

import Button from '@material-ui/core/Button';

import export2Csv from '../../../../../utils/export2Csv';
import { ReduxState } from '../../@types/Reducer';

const headers = [
  { key: '_id', label: 'ID' },
  { key: 'sessionId', label: 'sessionId' },
  { key: 'eventGroup', label: 'eventGroup' },
  { key: 'eventName', label: 'eventName' },
  { key: 'dashboard', label: 'dashboard' },
  { key: 'subscription', label: 'subscription' },
  { key: 'userId', label: 'userId' },
  { key: 'user', label: 'user' },
  { key: 'account', label: 'account' },
  { key: 'resource', label: 'resource' },
  { key: 'duration', label: 'duration' },
  { key: 'idleTime', label: 'idleTime' },
  { key: 'loadingTime', label: 'loadingTime' },
  { key: 'values', label: 'filterValues' },
  { key: 'scope', label: 'filterScope' },
  { key: 'operator', label: 'filterOperator' },
  { key: 'query', label: 'dataquery' },
  { key: 'table', label: 'dataqueryTable' },
  { key: 'utm_source', label: 'utm_source' },
  { key: 'utm_medium', label: 'utm_medium' },
  { key: 'utm_campaign', label: 'utm_campaign' },
  { key: 'browser', label: 'browser' },
  { key: 'os', label: 'os' },
  { key: 'platform', label: 'platform' },
  { key: 'engine', label: 'engine' },
  { key: 'viewportWidth', label: 'viewportWidth' },
  { key: 'viewportHeight', label: 'viewportHeight' },
  { key: 'country', label: 'country' },
  { key: 'locale', label: 'locale' },
  { key: 'createdAt', label: 'createdAt' },
];

const ExportDataDump = () => {
  const [isExporting, setIsExporting] = useState(false);
  const [error, setError] = useState(null);
  const jwtToken = useSelector((state: ReduxState) => state.user.jwtToken);
  const startDate = useSelector((state: ReduxState) => state.analytics.filters.startDate);
  const endDate = useSelector((state: ReduxState) => state.analytics.filters.endDate);
  const excludedAccounts = useSelector(
    (state: ReduxState) => state.analytics.filters.excludedAccounts,
  );

  const exportDataDump = useCallback(async () => {
    setIsExporting(true);

    const params = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    const limit = 1000;
    let skip = 0;
    let total = 0;
    let allEvents: any[] = [];

    try {
      do {
        const queryParams = {
          skip,
          limit,
          endDate,
          startDate,
          excluded: { accounts: excludedAccounts },
        };

        // eslint-disable-next-line no-await-in-loop
        const events = await API.get(
          'dashboards',
          `/analytics?${qs.stringify(queryParams)}`,
          params,
        );
        skip += limit;
        allEvents = concat(allEvents, events.data);
        total = get(events, ['metadata', 0, 'total'], 0);

        // as we unwound the context of events, we can't compare against the length of allEvents
      } while (total > skip);
    } catch (e) {
      console.log(error);
      setError(e);
    } finally {
      setIsExporting(false);
      if (error === null) {
        export2Csv(allEvents, headers, 'analytics-data-dump.csv');
      }
    }
  }, [jwtToken, error, excludedAccounts, startDate, endDate]);

  return (
    <Button color="primary" disabled={isExporting} onClick={exportDataDump}>
      Export
    </Button>
  );
};

export default ExportDataDump;
