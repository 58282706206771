import React from 'react';

import get from 'lodash/get';
import head from 'lodash/head';
import find from 'lodash/find';

import Sample from '../Sample';
import { Container, ChartWrapper, SampleWrapper } from '../Container';
import BarChartEngine from '../RechartBarChartEngine';

import defaultConfig from './config';
import generateConfig from './generateConfig';
import { LOW_SAMPLE_SIZE } from '../../../utils/hasLowSampleSize';

const BarChart = ({
  data,
  samples,
  axisTitle,
  formatter,
  id,
  domain,
  view,
  customConfig,
  configuration,
}) => {
  const { rootProps } = defaultConfig;

  if (view === 'dialog') {
    rootProps.margin = {
      top: 26,
      right: 26,
      left: 50,
      bottom: 26,
    };
  }

  const mergedConfig = generateConfig(
    defaultConfig,
    formatter,
    axisTitle,
    domain,
    customConfig,
    configuration,
  );

  const chartThreshold = get(find(configuration, { key: 'threshold' }), 'value', LOW_SAMPLE_SIZE);

  return (
    <Container>
      <ChartWrapper>
        <BarChartEngine id={id} {...mergedConfig} data={data} view={view} />
      </ChartWrapper>
      <SampleWrapper>
        <Sample
          name={get(head(samples), 'name', 'n')}
          value={get(head(samples), 'value', 0)}
          chartThreshold={chartThreshold}
        />
      </SampleWrapper>
    </Container>
  );
};

export default BarChart;
