import React from 'react';

import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';

import map from 'lodash/map';
import get from 'lodash/get';
import pick from 'lodash/pick';

import Grid from '@material-ui/core/Grid';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import { updateFilterValues } from '../../../state/filtersData/actions';

import DropdownFilter from '../DropdownFilter';
import ResetFiltersBtn from '../shared/components/ResetFiltersBtn/ResetFiltersBtn';
import ApplyFiltersBtn from '../shared/components/ApplyFiltersBtn/ApplyFiltersBtn';
import { trackFilterUsage } from '../../../state/analytics/actions';

const styles = (theme) => ({
  container: {
    marginBottom: '2.0rem',
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
  },
  item: {
    width: '16.5rem',
    height: '2.4rem',
    cursor: 'pointer',
    backgroundColor: theme.palette.custom.gray2,
    marginRight: '1rem',
    marginBottom: '0.7rem',
    padding: '0.7rem 0 0.7rem 0.8rem',
    borderRadius: 10,
  },
});

const InvisibleFilter = (props) => <DropdownFilter {...props} hide />;

const InvisibleDraftFilter = withStyles((theme) => ({
  icon: {
    fill: theme.palette.custom.gray4,
  },
}))(({ classes, ...props }) => (
  <>
    <DropdownFilter {...props} />
    <Tooltip title="Filter not shown once published">
      <VisibilityOffIcon className={classes.icon} />
    </Tooltip>
  </>
));

const getFilterComponent = (visible, status) => {
  if (visible) return DropdownFilter;
  if (!visible && status === 'draft') return InvisibleDraftFilter;

  return InvisibleFilter;
};

const SectionFiltersForm = ({ classes, items, dirty, reset, onSubmit, handleSubmit }) => {
  return (
    <Grid container spacing={2} className={classes.container}>
      {map(items, ({ visible, _id, ...filter }, index) => (
        <Field
          key={`filter-${index}`}
          className={classes.item}
          {...filter}
          _id={_id}
          name={_id}
          label={get(filter, 'text', get(filter, 'name', 'DEFINE FILTER LABEL'))}
          component={getFilterComponent(visible, process.env.REACT_APP_DATABOARD_STATUS)}
        />
      ))}
      {dirty && <ApplyFiltersBtn onClick={handleSubmit((data) => onSubmit(data))} />}
      {dirty && <ResetFiltersBtn onClick={reset} />}
    </Grid>
  );
};

const mapStateToProps = (state, { form }) => {
  const initialValues = pick(
    get(state, ['filtersData', 'byId']),
    get(state, ['filtersData', 'bySection', form]),
  );

  return { form, initialValues };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (data) => {
    dispatch(updateFilterValues(data));
    dispatch(trackFilterUsage(data));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    // other redux-form options...
    destroyOnUnmount: false,
    enableReinitialize: true,
  }),
)(withStyles(styles)(SectionFiltersForm));
