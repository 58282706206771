import isEmpty from 'lodash/isEmpty';
import clamp from 'lodash/clamp';
import ceil from 'lodash/ceil';
import floor from 'lodash/floor';

const getAxisDomain = (domain, formatter) => {
  if (!isEmpty(domain)) return domain;

  // if (formatter === 'percentage') return [0, dataMax => clamp(ceil(dataMax, -1), 0, 100)];
  if (formatter === 'score') {
    return [
      (dataMin) => clamp(floor(dataMin, -1), -100, 0),
      (dataMax) => clamp(ceil(dataMax, -1), 0, 100),
    ];
  }
  if (formatter === 'population') return [0, (dataMax) => ceil(dataMax)];

  return [0, (dataMax) => clamp(ceil(dataMax, -1), 0, 100)];
};

export default getAxisDomain;
