import { isEmpty } from 'lodash';

const getNumericalIndex = (cellIndex) => {
  // Removing the numbers from the cellIndex so that only letters remain
  let cellLetters = cellIndex.replace(/[0-9]/g, '');
  let numericalIndex = 0;
  let counter = 0;
  while (!isEmpty(cellLetters)) {
    // Getting the index in the alphabet of the last letter in the cellIndex
    const letterIndex = cellLetters.slice(-1).charCodeAt(0) - 65;
    // Multiplying the letterIndex based on its position in the cellIndex i.e AAB, BC, ...etc
    numericalIndex += letterIndex + 26 * counter * (letterIndex + 1);
    // Removing the last letter from the cellIndex
    cellLetters = cellLetters.slice(0, -1);
    // Increasing the counter by 1 to indicate the we moved on character further.
    counter++;
  }
  return numericalIndex;
};

export default getNumericalIndex;
