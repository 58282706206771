import get from 'lodash/get';
import map from 'lodash/map';

import getAllFilters from '../../utils/getAllFilters';
import { SET_DATABOARD } from '../databoard/types';
import { SET_FILTER_LABELS } from './types';
import extractByGroup from './utils/extractByGroup';
import extractById from './utils/extractById';
import extractByChart from './utils/extractByChart';
import extractBenchmarkingByChart from './utils/extractBenchmarkingByChart';

export const initialState = {
  byId: {},
  byChart: {},
  all: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATABOARD: {
      const databoard = get(action, 'payload', {});
      const sections = get(databoard, 'sections', []);
      const sourceLabels = get(databoard, 'labels', []);
      const filterGroups = get(databoard, 'filterGroups', []);

      const byId = extractById(databoard, sourceLabels);
      const byChart = extractByChart(filterGroups, sections);
      const byGroup = extractByGroup(filterGroups);
      const benchmarkingByChart = extractBenchmarkingByChart(sections);
      const all = map(getAllFilters(filterGroups, sections), '_id').sort();

      return {
        ...state,
        byId,
        byChart,
        byGroup,
        benchmarkingByChart,
        all,
      };
    }
    case SET_FILTER_LABELS: {
      const id = get(action, ['payload', 'id'], '');
      const allOptions = get(action, ['payload', 'allOptions'], '');
      const labels = get(action, ['payload', 'labels'], get(state, ['byId', id, 'labels'], []));
      const defaultAdapter = get(
        action,
        ['payload', 'defaultAdapter'],
        get(state, ['byId', id, 'defaultAdapter'], []),
      );

      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...get(state, ['byId', id], {}),
            labels,
            text: get(state, ['byId', id, 'text'], ''),
            defaultAdapter,
            allOptions,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
