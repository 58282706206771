import flattenDeep from 'lodash/flattenDeep';
import map from 'lodash/map';

// cloud-serverless-platform.default.amazon.currently_using
// cloud-serverless-platform.default.amazon.aware
// involvement.default.hobby_only.default
const getExpressionValues = ({ actualValues, adapterValues, query, table }) => {
  const values = flattenDeep(
    map(adapterValues, (adapterValue) =>
      map(actualValues, (filterValue) => `${query}.${table}.${filterValue}.${adapterValue}`),
    ),
  );

  return values;
};

export default getExpressionValues;
