import map from 'lodash/map';
import reduce from 'lodash/reduce';
import flatten from 'lodash/flatten';
import concat from 'lodash/concat';
import get from 'lodash/get';
import difference from 'lodash/difference';
import find from 'lodash/find';

export default (filterGroups, sections) => {
  const globalFilters = flatten(map(filterGroups, 'values', []));

  const byChart = reduce(
    sections,
    (acc, section) => {
      const byCardChart = reduce(
        section.cards,
        (cur, card) => {
          const cardFilters = get(card, 'filters');
          const chartId = get(card, ['chart', '_id']);
          const allFilters = concat(globalFilters, section.filters, cardFilters);
          const parents = map(flatten(map(cardFilters, 'parent')), (parentName) => {
            const parentFilter = find(allFilters, { name: parentName });
            return get(parentFilter, '_id');
          });
          const allFilterId = map(allFilters, '_id');
          const chartFilters = difference(allFilterId, parents);

          return {
            ...cur,
            [chartId]: chartFilters,
          };
        },
        {},
      );

      return {
        ...acc,
        ...byCardChart,
      };
    },
    {},
  );

  return byChart;
};
