import React from 'react';
import cn from 'classnames';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = () => ({
  root: {
    marginLeft: 27,
    marginBottom: 10,
    paddingBottom: 10,
    display: 'flex',
    alignItems: 'center',
  },
  noBottomMargin: {
    marginBottom: 0,
  },
  checkbox: {
    padding: 0,
    borderRadius: 0,
    '& svg': {
      width: '1.3rem',
      height: '1.3rem',
      marginRight: '1.3rem',
      boxSizing: 'border-box',
    },
  },
  label: {
    fontSize: '1.1rem',
  },
});

const CheckboxWrapper = ({ classes, name = '', label, checked, noBottomMargin, onChange }) => (
  <FormControlLabel
    className={cn(classes.root, { [classes.noBottomMargin]: noBottomMargin })}
    control={
      <Checkbox
        name={name}
        checked={checked}
        color="primary"
        onChange={(e) => onChange(e.target.checked, e.target.name)}
        value="all"
        classes={{ root: classes.checkbox }}
      />
    }
    label={<Typography className={classes.label}>{label}</Typography>}
  />
);

export default withStyles(styles)(CheckboxWrapper);
