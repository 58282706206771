import { find, get, head } from 'lodash';
import { SERIES } from './types';

const getParams = ({
  name,
  sources,
  filterExpression,
  columnExpression,
  rowExpression,
  rejectExpression,
  contextExpression,
  jwtToken,
  computation = SERIES,
  pathURL,
  version,
  query,
}) => {
  if (pathURL && pathURL.includes('/dashboards') && version === 2) {
    query.source = head(sources);
    // Getting the corresponding filters for the source
    const filter = get(find(query.filter, { key: head(sources) }), 'value', []);
    return {
      body: {
        version: 2,
        query: { ...query, filter },
        queryType: 'dashboard',
      },
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
  } else {
    return {
      body: {
        name,
        sources,
        input: { filter: filterExpression },
        output: { filter: { col: columnExpression, row: rowExpression }, reject: rejectExpression },
        context: contextExpression,
        computation,
        queryType:
          pathURL && pathURL.includes('/daa')
            ? 'daa'
            : pathURL && pathURL.includes('/reports')
            ? 'report'
            : 'dashboard',
      },
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
  }
};

export default getParams;
