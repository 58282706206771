import { Auth } from 'aws-amplify';

import signIn from './signIn';

const forgotPasswordSubmit = async (username, code, password) =>
  new Promise(async (resolve, reject) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      await signIn(username, password);
      resolve();
    } catch (error) {
      console.log('An error occurred while submitting forgot password');
      console.log(error);
      reject(error);
    }
  });

export default forgotPasswordSubmit;
