import React from 'react';

import map from 'lodash/map';

import { withStyles } from '@material-ui/core/styles';

import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

import CustomLegend from './components/CustomLegend';
import CustomTooltip from './components/CustomTooltip';
import { percentage } from '../helpers/formatters';

const styles = () => ({
  chart: {
    fontFamily: 'Nunito Sans',
  },
  pie: {
    transform: 'translateX(5.8rem)',
  },
});

const PieChartWrapper = ({ id, classes, data, pieProps, colors, series }) => {
  return (
    <ResponsiveContainer height="90%">
      <PieChart className={classes.chart}>
        <Pie {...pieProps} data={data} className={classes.pie}>
          {map(data, (entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip formatter={percentage} content={<CustomTooltip id={id} />} />
        <Legend
          verticalAlign="top"
          layout="vertical"
          align="right"
          content={<CustomLegend id={id} series={series} />}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default withStyles(styles)(PieChartWrapper);
