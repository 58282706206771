import React from 'react';
import cn from 'classnames';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    height: '100%',
    flexGrow: 1,
    marginLeft: '21.5rem',
    paddingLeft: '8.1rem',
    [theme.breakpoints.down('xl')]: {
      paddingTop: theme.spacing(9),
      paddingRight: theme.spacing(15),
      paddingBottom: theme.spacing(4),
      paddingLeft: 0,
    },
    [theme.breakpoints.down('lg')]: {
      paddingTop: theme.spacing(9),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: 0,
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
});

const Layout = ({ classes, children }) => {
  return (
    <div className={classes.root}>
      <Grid
        container
        className={cn(classes.content, {
          'animated fadeIn': true,
        })}
      >
        {children}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Layout);
