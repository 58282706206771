import React, { useCallback } from 'react';

import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as DownloadDataIcon } from './assets/download.svg';
import { Tooltip } from '@material-ui/core';
import { GRAPH_DOWNLOAD_DATA_EVENT, trackGraphAction } from '../../../../state/analytics/actions';
import { connect } from 'react-redux';

const DownloadDataButton = ({ onClick, chartName, chartQuery, trackDownload }) => {
  const trackAndDownload = useCallback(() => {
    trackDownload(chartName, chartQuery);
    onClick();
  }, [chartName, chartQuery]);

  return (
    <Tooltip title="Download Graph">
      <IconButton onClick={trackAndDownload}>
        <DownloadDataIcon />
      </IconButton>
    </Tooltip>
  );
};

const mapDispatchToProps = (dispatch) => ({
  trackDownload: (chartName, chartQuery) =>
    dispatch(trackGraphAction(GRAPH_DOWNLOAD_DATA_EVENT, chartName, chartQuery)),
});

export default connect(null, mapDispatchToProps)(DownloadDataButton);
