import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: theme.zIndex.chartLoader,
  },
  backdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#ffffff',
    opacity: 0.4,
  },
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loaderMessage: {
    marginTop: '1rem',
    color: '#fefefe',
  },
});

const Loader = ({ classes, show /*message*/ }) => {
  if (!show) return null;

  return (
    <div className={classes.root}>
      <div className={classes.backdrop} />
      <CircularProgress />
    </div>
  );
};

export default withStyles(styles)(Loader);
