import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import indexOf from 'lodash/indexOf';
import lodashFilter from 'lodash/filter';

// [ml_saas] [ml_saas] [{name: 'ml_saas', text: "ML SaaS"}]
export const normalizeFilterValues = ({ filter, reject, labels, version }) => {
  if (!isEmpty(filter)) {
    return lodashFilter(labels, (label) => {
      if (version === 2) return indexOf(filter, label.text) !== -1;
      return indexOf(filter, label.name) !== -1;
    });
  }

  if (!isEmpty(reject)) {
    return lodashFilter(labels, (label) => {
      if (version === 2) return indexOf(filter, label.text) === -1;
      return indexOf(filter, label.name) === -1;
    });
  }

  return labels;
};

export const extractFilterValues = ({ adapter, data }) =>
  isEmpty(adapter) ? data.rows : data.cols;

export const extractFilterDefaultAdapter = ({ adapter, data }) =>
  isEmpty(adapter) ? get(data, ['cols', 0, 'name'], []) : [];
