import React, { useState, useCallback, useEffect } from 'react';
import qs from 'qs';

import get from 'lodash/get';

import { Grid, Tabs, Tab, makeStyles } from '@material-ui/core';

import ExportDataDump from './components/ExportDataDump';
import BasicKPI from './screens/BasicKPI';
import { GlobalFilters } from './components';

const qsParams = { comma: true, ignoreQueryPrefix: true, encode: true };

const useStyles = makeStyles({
  root: { position: 'relative' },
  title: { textAlign: 'center' },
  header: {
    paddingTop: 10,
    position: 'sticky',
    top: 60,
    zIndex: 99999,
    backgroundColor: '#ffffff',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
  },
  headerContainer: { display: 'flex', justifyContent: 'flex-end' },
});

const TABS = { BASIC_KPI: 'basic-kpi', DEFAULT: 'basic-kpi' };

const Analytics = () => {
  const [tab, setTab] = useState<string>(TABS.DEFAULT);
  const classes = useStyles();

  useEffect(() => {
    const tabView = get(
      qs.parse(window.location.search, qsParams),
      'tabView',
      TABS.DEFAULT,
    ) as string;
    if (tabView) setTab(tabView);
  }, []);

  const changeTab = useCallback((event, activeTab) => {
    setTab(activeTab);
  }, []);

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.header}>
          <div className={classes.headerContainer}>
            <GlobalFilters />
            <ExportDataDump />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Tabs value={tab} onChange={changeTab}>
            <Tab label="Basic KPI" value={TABS.BASIC_KPI} />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          {tab === TABS.BASIC_KPI && <BasicKPI />}
        </Grid>
      </Grid>
    </>
  );
};

export default Analytics;
