import filter from 'lodash/filter';
import toArray from 'lodash/toArray';
import get from 'lodash/get';
import map from 'lodash/map';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';

const getByTopic = (reports, searchCriteria) => {
  const topicFilters = toArray(get(searchCriteria, ['filters', 'topics'], []));
  const topics = filter(topicFilters, ({ checked }) => checked);

  if (topicFilters.length === topics.length) return reports;
  if (topics.length === 0) return filter(reports, (report) => isEmpty(report.topics));

  const selectedTopics = map(topics, 'name');

  return filter(reports, (report) => !isEmpty(intersection(report.topics, selectedTopics)));
};

export default getByTopic;
