import { useEffect, useRef, useState } from 'react';
import { findDOMNode } from 'react-dom';

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 */
const useInViewport = (target, props) => {
  const { onEnterViewport, onLeaveViewport } = props;
  const [, forceUpdate] = useState();

  const observer = useRef();

  const inViewportRef = useRef(false);
  const intersected = useRef(false);

  function startObserver() {
    if (target.current && observer.current) {
      // eslint-disable-next-line react/no-find-dom-node
      const node = findDOMNode(target.current);
      if (node) {
        observer.current.observe(node);
      }
    }
  }

  function stopObserver() {
    if (target.current && observer.current) {
      // eslint-disable-next-line react/no-find-dom-node
      const node = findDOMNode(target.current);
      if (node) {
        observer.current.unobserve(node);
        observer.current.disconnect();
        observer.current = null;
      }
    }
  }

  function handleIntersection(entries) {
    const entry = entries[0] || {};
    const { isIntersecting, intersectionRatio } = entry;
    const isInViewport =
      typeof isIntersecting !== 'function' ? isIntersecting : intersectionRatio > 0;

    // enter
    if (!intersected.current && isInViewport) {
      intersected.current = true;
      if (onEnterViewport) {
        onEnterViewport(intersectionRatio);
      }
      inViewportRef.current = isInViewport;
      forceUpdate(isInViewport);
    }

    // leave
    if (intersected.current && !isInViewport) {
      intersected.current = false;
      if (onLeaveViewport) {
        onLeaveViewport(intersectionRatio);
      }
      inViewportRef.current = isInViewport;
      forceUpdate(isInViewport);
    }
  }

  function initIntersectionObserver() {
    if (!observer.current) {
      observer.current = new IntersectionObserver(handleIntersection, {
        // Margin around the root (top, right, bottom, left)
        rootMargin: '-200px 0px 0px 0px',
        // Number between 0 and 1 indicating the percentage that should be visible before triggering
        threshold: 0.5,
      });
    }
  }

  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    initIntersectionObserver();
    startObserver();

    return () => {
      stopObserver();
    };
  }, [target, onEnterViewport, onLeaveViewport]);

  return {
    inViewport: inViewportRef.current,
  };
};

export default useInViewport;
