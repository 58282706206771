import map from 'lodash/map';
import flattenDeep from 'lodash/flattenDeep';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import concat from 'lodash/concat';

const concatCols = ({ data, labels }) => {
  const matrices = uniq(map(flattenDeep(data), 'name'));

  return map(matrices, (matrixName) => {
    const matricesToConcat = filter(flattenDeep(data), (matrix) => matrix.name === matrixName);
    const mergedMatrix = reduce(
      matricesToConcat,
      (result, matrix) => {
        return {
          ...result,
          ...matrix,
          values: concat(get(result, 'values', []), get(matrix, 'values', [])),
        };
      },
      {},
    );

    const groupedRows = groupBy(mergedMatrix.values, (value) => value.name);
    const values = reduce(
      groupedRows,
      (result, rows) => {
        const mergedRow = reduce(
          rows,
          (row, value, index) => {
            const updatedValues = map(get(value, 'values', []), (col) => ({
              name: get(labels, [index, 'name'], ''),
              text: get(labels, [index, 'text'], ''),
              value: get(col, 'value', 0),
            }));

            return {
              name: get(value, 'name', ''),
              text: get(value, 'text', ''),
              values: concat(get(row, 'values', []), updatedValues),
            };
          },
          {},
        );

        return [...result, mergedRow];
      },
      [],
    );

    return {
      ...mergedMatrix,
      values,
    };
  });
};

export default concatCols;
