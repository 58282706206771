import { filter, flatten, forEach, get, head, isEmpty, map } from 'lodash';

const EXCLUSIVE_FILTER_LINK = 'EXCLUSIVE';

const disabledByLink = (filterGroups, filterName, selection, links, formValues) => {
  if (isEmpty(links)) return;
  let foundLink = false;
  const sourceLinks = filter(
    links,
    (link) => link.source === filterName && link.type === EXCLUSIVE_FILTER_LINK,
  );
  const destinationLinks = filter(
    links,
    (link) => link.destination.includes(filterName) && link.type === EXCLUSIVE_FILTER_LINK,
  );
  forEach(sourceLinks, (link) => {
    forEach(link.destination, (destination) => {
      const destinationFilter = head(
        filter(flatten(map(filterGroups, 'values')), {
          name: destination,
        }),
      );
      if (destinationFilter) {
        const destinationValues = get(formValues, destinationFilter._id, []);
        if (isEmpty(link.mapping)) {
          if (destinationValues.indexOf(selection) !== -1) {
            foundLink = true;
          }
        } else {
          forEach(link.mapping, (mapping) => {
            if (mapping.name === '*' || mapping.name === selection) {
              forEach(mapping.values, (mappingValue) => {
                if (
                  destinationValues.indexOf(mappingValue) !== -1 ||
                  (mappingValue === '*' && !isEmpty(destinationValues))
                ) {
                  foundLink = true;
                }
              });
            }
          });
        }
      }
    });
  });
  forEach(destinationLinks, (link) => {
    const sourceFilter = head(filter(flatten(map(filterGroups, 'values')), { name: link.source }));
    const sourceValues = get(formValues, sourceFilter._id, []);
    if (isEmpty(link.mapping)) {
      if (sourceValues.indexOf(selection) !== -1) {
        foundLink = true;
      }
    } else {
      forEach(link.mapping, (mapping) => {
        if (mapping.values.includes('*') || mapping.values.includes(selection)) {
          if (sourceValues.indexOf(mapping.name) !== -1) {
            foundLink = true;
          }
        }
      });
    }
  });
  return foundLink;
};

export default disabledByLink;
