import { forEach, get } from 'lodash';
import PptxGenJS from 'pptxgenjs';
import styleSheet from './styleSheet';

const createPresentation = (report) => {
  console.log(report);
  const pptx = new PptxGenJS();
  // Report Cover
  const coverSlide = pptx.addSlide();
  coverSlide.addText(get(report, 'title', ''), styleSheet.reportTitle);

  const info = get(report, 'text', '');

  // Report info if exists
  if (info) {
    const infoSlide = pptx.addSlide();
    infoSlide.addText('Information', styleSheet.slideTitle);
    infoSlide.addText(info, styleSheet.slideText);
  }

  // Table of contents
  const tocSlide = pptx.addSlide();
  tocSlide.addText('Table of contents', styleSheet.slideTitle);
  const sections = get(report, 'sections', []);
  let tocText = info ? 'Information\n' : '';
  forEach(sections, (section) => {
    tocText += get(section, 'title', '') + '\n';
    const cards = get(section, 'cards', []);
    forEach(cards, (card) => {
      const cardTitle = get(card, 'title', '');
      if (cardTitle) {
        tocText += cardTitle + '\n';
      }
    });
  });
  tocSlide.addText(tocText, styleSheet.slideText);
  // Report slides
  forEach(sections, (section) => {
    // Section slide
    const sectionSlide = pptx.addSlide();
    sectionSlide.addText(get(section, 'title', ''), styleSheet.sectionTitle);
    sectionSlide.addText(get(section, 'text', ''), styleSheet.sectionText);
    const cards = get(section, 'cards', []);
    // Cards slides
    forEach(cards, (card) => {
      const cardTitle = get(card, 'title', '');
      const cardText = get(card, 'text', '');
      const chartName = get(card, ['chart', 'name'], '');
      const chartType = get(card, ['chart', 'type'], '');
      const heading = get(card, 'heading', '');
      const content = get(card, 'content', '');
      const footnote = get(card, 'footnote', '');
      const chartImageUrl = get(card, 'chartImageUrl', '');
      const authorImageUrl = get(card, 'authorImageUrl', '');
      const cardSlide = pptx.addSlide();
      cardSlide.addText(cardTitle, styleSheet.cardTitle);
      cardSlide.addText(cardText, styleSheet.cardText);
      cardSlide.addText(
        chartName ? chartName + ' / ' + chartType : authorImageUrl ? authorImageUrl : chartImageUrl,
        styleSheet.chartText,
      );
      cardSlide.addText(heading, styleSheet.heading);
      cardSlide.addText(content, styleSheet.content);
      cardSlide.addText(footnote, styleSheet.footnote);
    });
  });

  pptx.writeFile({ fileName: get(report, 'title', 'presentation') });
};

export default createPresentation;
