import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import get from 'lodash/get';

const CookieBot = () => {
  useEffect(() => {
    const initServicesWithCookies = () => {
      if (get(window, ['Cookiebot', 'consent', 'statistics'], false)) {
        // initializeGA();
      }
    };

    const removeCookies = () => {
      // handleGACookies();
    };

    window.addEventListener('CookiebotOnAccept', initServicesWithCookies);
    window.addEventListener('CookiebotOnDecline', removeCookies);

    return function cleanup() {
      window.removeEventListener('CookiebotOnAccept', initServicesWithCookies);
      window.removeEventListener('CookiebotOnDecline', removeCookies);
    };
  });

  return (
    <Helmet
      title="Research Space - SlashData"
      meta={[
        { name: 'description', content: 'Dashboard slashdata' },
        { name: 'keywords', content: 'dashboard, slashdata' },
      ]}
      script={[
        {
          id: 'Cookiebot',
          src: 'https://consent.cookiebot.com/uc.js',
          'data-cbid': process.env.REACT_APP_COOKIEBOT_ID,
          type: 'text/javascript',
          async: true,
        },
      ]}
    />
  );
};

export default CookieBot;
