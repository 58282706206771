import get from 'lodash/get';
import map from 'lodash/map';
import flattenDeep from 'lodash/flattenDeep';
import filter from 'lodash/filter';

const getGlobalHiddenFilters = (state) =>
  map(
    filter(
      flattenDeep(map(get(state, ['databoard', 'filterGroups']), 'values')),
      (filterModel) => get(filterModel, 'visible') === false,
    ),
    '_id',
  );

export default getGlobalHiddenFilters;
