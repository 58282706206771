import filter from 'lodash/filter';
import toArray from 'lodash/toArray';
import get from 'lodash/get';
import map from 'lodash/map';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';

const getByProduct = (reports, searchCriteria) => {
  const productFilters = toArray(get(searchCriteria, ['filters', 'products'], []));
  const products = filter(productFilters, ({ checked }) => checked);

  if (productFilters.length === products.length) return reports;
  if (products.length === 0) return filter(reports, (report) => isEmpty(report.focuses));

  const selectedProducts = map(products, 'name');

  return filter(reports, (report) => !isEmpty(intersection(report.focuses, selectedProducts)));
};

export default getByProduct;
