import React, { useState } from 'react';
import cx from 'classnames';
import { ReactComponent as MessageIcon } from './message.svg';

import {
  CloseButton,
  Container,
  Content,
  FormElement,
  Header,
  HeaderLabel,
  Label,
  SlackFeedback as StyledSlackFeedback,
  SubmitButton,
  Trigger,
} from './styles';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import Backdrop from '@material-ui/core/Backdrop';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { useRef } from 'react';

const SlackFeedback = ({ userState }) => {
  // Some state variables for the
  const [open, setOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const slackFeedbackRef = useRef();

  // Handeling changes in the message textArea
  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  // Toggles the state of the dialog when the trigger button is pressed
  const toggle = () => {
    open ? close() : activate();
  };

  // Setting the dialog open and adding a listener for click event
  const activate = () => {
    setOpen(true);
    document.addEventListener('click', handleClickOutside);
  };

  // Closing the dialog if a click is done outside the feedback dialog
  const handleClickOutside = (event) => {
    if (event.defaultPrevented) return;
    // Closing only if the click is outside the feedback dialog
    if (
      slackFeedbackRef &&
      slackFeedbackRef.current &&
      !slackFeedbackRef.current.contains(event.target)
    ) {
      close();
    }
  };

  // Clearing the message, closing the dialog and removing the click listener
  const close = () => {
    setMessage('');
    setOpen(false);
    document.removeEventListener('click', handleClickOutside);
  };

  // Reseting the message and sent button state
  const resetSentState = () => {
    setMessage('');
    setSent(false);
  };

  // Show sent message after message delivered successfully
  const onSubmitSuccess = () => {
    setSent(true);
    setError(false);
    setSending(false);
    setTimeout(() => {
      resetSentState();
    }, 2500);
  };

  // Showing an error message if message delivery fails
  const onSubmitError = () => {
    setSending(false);
    setError('UNEXPECTED ERROR!');
    setTimeout(() => {
      setError(null);
    }, 2500);
  };

  // Preparing the payload and sending it to the slack hook
  const send = async () => {
    setSending(true);
    const payload = {
      // Returning anonymous if the user is not logged in
      author: isEmpty(userState.userId) ? 'Anonymous' : userState.email,
      // The url from where the user is sending the feedback
      link: document.location.href,
      text: message,
    };
    try {
      const result = await fetch(process.env.REACT_APP_SLACK_WEBHOOK_URL, {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      if (result.status === 200) {
        onSubmitSuccess();
      } else {
        onSubmitError();
      }
    } catch (e) {
      onSubmitError();
    }
  };

  // Choosing the message on the submit button
  let submitText = 'SUBMIT FEEDBACK';
  if (sent) submitText = 'SENT!';
  if (sending && !sent) submitText = 'SENDING';
  if (error) submitText = error;

  return (
    <StyledSlackFeedback className={cx({ active: open })} ref={slackFeedbackRef}>
      <Trigger className={cx({ active: open })} onClick={toggle}>
        <MessageIcon
          style={{ transform: 'rotate(90deg)', marginRight: '5px', color: 'white !important' }}
        />
        {'FEEDBACK'}
      </Trigger>

      <Container className={cx('fadeInLeft', { active: open })}>
        <Header>
          <HeaderLabel>{'Tell us your thoughts'}</HeaderLabel>
          <CloseButton className="close" onClick={close}>
            <CloseOutlined />
          </CloseButton>
        </Header>

        <Content>
          <Label>{'How can we improve?'}</Label>
          <FormElement
            as="textarea"
            name="message"
            value={message}
            placeholder={'Tell us your thoughts...'}
            onChange={handleChange}
          />

          <SubmitButton
            disabled={sending || !message}
            className={cx({
              sent,
              error,
            })}
            onClick={send}
          >
            {submitText}
          </SubmitButton>
        </Content>
      </Container>
      <Backdrop open={open} onClick={close} />
    </StyledSlackFeedback>
  );
};

const mapStateToProps = (state) => ({
  userState: get(state, 'user'),
});

export default connect(mapStateToProps)(SlackFeedback);
