import find from 'lodash/find';
import reduce from 'lodash/reduce';
import concat from 'lodash/concat';

const concatRows = ({ data, destinationTableName, sourceTables, tableName }) => {
  const destination = find(data, { name: destinationTableName });

  const result = reduce(
    sourceTables,
    (acc, name) => {
      const source = find(data, { name });
      if (source === undefined) return acc;

      return {
        ...acc,
        values: concat(acc.values, source.values),
      };
    },
    destination || [],
  );

  result.name = tableName === undefined ? destinationTableName : tableName;

  return result;
};

export default concatRows;
