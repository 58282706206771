import filter from 'lodash/filter';
import toArray from 'lodash/toArray';
import get from 'lodash/get';
import map from 'lodash/map';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';

const getBySector = (dashboards, searchCriteria) => {
  const sectorFilters = toArray(get(searchCriteria, ['filters', 'sectors'], []));
  const sectors = filter(sectorFilters, ({ checked }) => checked);

  if (sectorFilters.length === sectors.length) return dashboards;
  if (sectors.length === 0) return filter(dashboards, (dashboard) => isEmpty(dashboard.sectors));

  const selectedSectors = map(sectors, 'name');

  return filter(
    dashboards,
    (dashboard) => !isEmpty(intersection(dashboard.sectors, selectedSectors)),
  );
};

export default getBySector;
