import React from 'react';

import { connect } from 'react-redux';

import get from 'lodash/get';

import DropDownField from '../../../../../../components/Admin/DropDownField';

const PrivateField = ({ ...rest }) => {
  const options = [
    { value: false, label: 'Public' },
    { value: true, label: 'Private' },
  ];

  return <DropDownField suggestions={options} {...rest} />;
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
});

export default connect(mapStateToProps)(PrivateField);
