import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';
import isNull from 'lodash/isNull';
import findIndex from 'lodash/findIndex';
import omit from 'lodash/omit';

const normalizeTableData = ({ data, table }) => {
  const dataTable = get(find(data, { name: table }), 'values', []);
  const keysWithNullValues = [];

  const result = map(dataTable, ({ name: rowName, values }) => {
    const columns = reduce(
      values,
      (acc, { name: colName, value }) => {
        let colValue = value;
        if (isNull(value)) {
          keysWithNullValues.push(colName);
          colValue = 'NA_NAN';
        }

        return {
          ...acc,
          [colName]: colValue,
        };
      },
      {},
    );

    return {
      name: rowName,
      ...columns,
    };
  });

  const keysWithAllValuesNull = filter(uniq(keysWithNullValues), (key) => {
    const index = findIndex(result, (item) => item[key] !== 'NA_NAN');

    return index === -1;
  });

  const resultWithoutNullColumns = map(result, (item) => omit(item, keysWithAllValuesNull));

  return resultWithoutNullColumns;
};

export default normalizeTableData;
