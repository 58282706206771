import { REJECT_SERIES, SET_SERIES } from './types';

export const rejectSeries = (chartId, series) => ({
  type: REJECT_SERIES,
  payload: { chartId, series },
});

export const setSeries = (chartId, series) => ({
  type: SET_SERIES,
  payload: { chartId, series },
});
