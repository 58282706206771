import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import get from 'lodash/get';
import map from 'lodash/map';
import toArray from 'lodash/toArray';
import pick from 'lodash/pick';
import filter from 'lodash/filter';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { compact, find, isEmpty } from 'lodash';
import getData from '../../../utils/xlsx/utils/getData';
import getSample from '../../../utils/xlsx/utils/getSample';
import getFilters from '../../../utils/xlsx/utils/getFilters';
import getAppliedSources from '../../../utils/xlsx/utils/getAppliedSources';
import createSheet from '../../../utils/xlsx/exporter/createSheet';
import createWorkbook from '../../../utils/xlsx/exporter/createWorkbook';
import { getCardsByChart } from '../../../state/databoard/selectors';
import { finalizeDataboardExport } from '../../../state/dataExport/actions';
import { getFiltersByChart } from '../../../state/dataExport/selectors';
import { getSampleLabels } from '../../../state/labels/selectors';
import { TYPE_HEATMAP_CHART } from '../../Graphs/GraphsRenderer/types';
import createPresentation from '../../../utils/pptx/exporter/createPresentation';

const DownloadAction = ({
  dashboard,
  isExportingData,
  pptxExport,
  chartIds,
  charts,
  data,
  cardsByChart,
  filtersByChart,
  sourceLabels,
  onDone,
  version,
  sourcesDictionary,
}) => {
  useEffect(() => {
    if (isExportingData && pptxExport) {
      createPresentation(dashboard);
      onDone();
    }

    const activeLoaders = filter(map(toArray(data), 'loader'), (loader) => loader);

    if (
      isExportingData &&
      chartIds.length === toArray(data).length &&
      activeLoaders.length === 0 &&
      !pptxExport
    ) {
      const sections = get(dashboard, 'sections', []);
      const sectionCharts = map(sections, (section) => {
        const sectionCards = get(section, 'cards', []);
        return map(sectionCards, (card) => get(card, ['chart', '_id'], ''));
      });
      let emptySection = 0;
      const sheets = map(sectionCharts, (localCharts, sectionIndex) => {
        const sectionData = map(localCharts, (chartId) => {
          const type = get(charts, [chartId, 'type'], '');
          let graphTable = '';
          let sampleTable = '';
          if (type === TYPE_HEATMAP_CHART) {
            const chartData = get(charts, [chartId, 'data'], {});
            graphTable = get(find(chartData, { key: 'data' }), 'value', '');
            sampleTable = get(find(chartData, { key: 'sample' }), 'value', '');
          } else {
            graphTable = get(charts, [chartId, 'graph'], '');
            sampleTable = get(charts, [chartId, 'sample'], '');
          }
          const formatter = get(charts, [chartId, 'formatter'], '');
          const sorting = get(charts, [chartId, 'sorting'], {});
          const axisTitle = get(charts, [chartId, 'axis', 'label'], '');
          const chartData = get(data, chartId, {});
          const cardTitle = get(cardsByChart, [chartId, 'title'], '');
          const cardSubtitle = get(cardsByChart, [chartId, 'subtitle'], '');
          const chartFilters = get(filtersByChart, chartId, []);
          const extraGraphs = get(charts, [chartId, 'extraGraphs'], []);
          const localSamples = get(
            find(get(charts, [chartId, 'config'], []), { key: 'showLocalSamples' }),
            'value',
            false,
          );
          return {
            data: {
              title: [get(dashboard, 'title', '')],
              sources: getAppliedSources({
                data: chartData,
                sourceLabels,
                version,
                sourcesDictionary,
              }),
              name: [cardTitle],
              subtitle: [cardSubtitle],
              // formatter,
              filters: getFilters(chartFilters),
              legend: ['', axisTitle],
              data: getData({
                data: chartData,
                dataTable: graphTable,
                sampleTable,
                type,
                sorting,
                sourceLabels,
                formatter,
                extraGraphs,
                localSamples,
              }),
              sample: getSample({ data: chartData, tableName: sampleTable, type }),
            },
          };
        });
        if (!isEmpty(localCharts)) {
          return {
            name: `Section ${sectionIndex + 1 - emptySection}`,
            data: createSheet(sectionData),
          };
        } else {
          emptySection++;
        }
      });

      createWorkbook({ title: get(dashboard, 'title', ''), sheets: compact(sheets) });
      onDone();
    }
  }, [chartIds, data, dashboard]);

  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={isExportingData}>
      <MuiAlert elevation={6} variant="filled" severity="info">
        Exporting dashboard data
      </MuiAlert>
    </Snackbar>
  );
};

const mapStateToProps = (state) => ({
  chartIds: get(state, ['charts', 'all'], []),
  charts: get(state, ['charts', 'byId'], []),
  data: pick(get(state, ['data', 'byChart'], {}), get(state, ['charts', 'all'])),
  dashboard: get(state, 'databoard', {}),
  sourcesDictionary: get(state, ['labels', 'sources'], []),
  version: get(state, ['databoard', 'version'], 1),
  cardsByChart: getCardsByChart(state),
  isExportingData: get(state, ['dataExport', 'databoard', 'isExportingData'], false),
  pptxExport: get(state, ['dataExport', 'databoard', 'pptxExport'], false),
  filtersByChart: getFiltersByChart(state),
  sourceLabels: getSampleLabels(state),
});

const mapDispatchToProps = (dispatch) => ({
  onDone: () => dispatch(finalizeDataboardExport()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAction);
