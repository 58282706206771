const reduxFormValidator = (schema) => async (values) =>
  new Promise(async (resolve, reject) => {
    try {
      await schema.validateSync(values, { abortEarly: false });
      resolve({});
    } catch (e) {
      const validationErrors = e.inner.reduce(
        (errors, err) => ({
          ...errors,
          [err.path]: err.message,
        }),
        {},
      );
      reject(validationErrors);
    }
  });

export default reduxFormValidator;
