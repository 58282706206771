import flatten from 'lodash/flatten';
import flattenDeep from 'lodash/flattenDeep';
import map from 'lodash/map';
import get from 'lodash/get';
import concat from 'lodash/concat';

export default (filterGroups, sections) => {
  const cards = map(sections, 'cards', []);

  const globalFilters = flatten(map(filterGroups, (group) => get(group, 'values', [])));
  const sectionFilters = flatten(map(sections, (section) => get(section, 'filters', [])));
  const benchmarkingFilters = flattenDeep(
    map(sections, (section) => get(section, 'benchmarking', [])),
  );
  const chartFilters = flatten(map(flatten(cards), (card) => get(card, 'filters', [])));

  const filters = concat(globalFilters, sectionFilters, benchmarkingFilters, chartFilters);

  return filters;
};
