import get from 'lodash/get';
import head from 'lodash/head';
import find from 'lodash/find';

const getCategoricalSamples = ({ data, table }) => {
  let sampleArr = find(data, { name: table });
  sampleArr = head(get(sampleArr, 'values', []));
  const sample = get(head(get(sampleArr, 'values', [])), 'value', 0);
  const samples = [{ name: 'n', value: sample }];

  return samples;
};

export default getCategoricalSamples;
