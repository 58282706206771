import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';

const getLocalSamples = ({ data, table }) => {
  const sampleArr = find(data, { name: table });
  const samples = map(get(sampleArr, 'values'), ({ name, values }) => {
    return {
      name,
      value: get(values, [0, 'value'], 0),
    };
  });

  return samples;
};

export default getLocalSamples;
