import get from 'lodash/get';
import map from 'lodash/map';
import flattenDeep from 'lodash/flattenDeep';

import { DATABOARD_SOURCES_FILTER } from '../types';

const extractRootFilters = (databoard) => {
  const filterGroups = get(databoard, ['filterGroups']);

  return [
    DATABOARD_SOURCES_FILTER,
    ...flattenDeep(map(filterGroups, (group) => map(group.values, '_id'))),
  ];
};

export default extractRootFilters;
