import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = {
  typography,
  palette,
  overrides,
  zIndex: {
    modal: 1700,
    appLoader: 1600,
    sidebar: 1500,
    drawer: 140,
    appBar: 1300,
    chartLoader: 1200,
    backDrop: 1,
  },
};

export default theme;
