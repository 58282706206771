import React from 'react';
import { render } from 'react-dom';

import Amplify from 'aws-amplify';
import { Provider } from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import App from './app';
import store from './store';
import config from './config';
import theme from './theme';

Amplify.configure(config);

const target = document.querySelector('#root');

render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <CssBaseline />
        <App />
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  </Provider>,
  target,
);
