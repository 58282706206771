import map from 'lodash/map';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';

import config from './config';
import getAxisDomain from '../helpers/getAxisDomain';
import getAxisTickFormatter from '../helpers/getAxisTickFormatter';
import getFormattedSeries from '../helpers/getFormattedSeries';
import getTooltipFormatter from '../helpers/getTooltipFormatter';
import { LOW_SAMPLE_SIZE } from '../../../utils/hasLowSampleSize';

const SAMPLE_OFFSET = 80;

export const orderSeries = (series) => orderBy(series, [], ['asc']);
export const filterOutNullSeries = (series) => filter(series, ({ dataKey }) => !isEmpty(dataKey));
export const renameSeries = (series) =>
  map(config.series, (item, index) => ({
    ...item,
    dataKey: get(series, index, null),
  }));

export const normalizeSeries = (series) => filterOutNullSeries(renameSeries(series));

// export const overwriteYAxisProps = yAxisProps => ({
//   ...config.yAxisProps,
//   ...yAxisProps,
// });

export const overwriteYAxisProps = (domain, axisTitle = '', formatter, customConfig) => ({
  ...config.yAxisProps,
  label: {
    ...config.yAxisProps.label,
    value: get(customConfig, 'yAxisProps.axisTitle', axisTitle),
  },
  domain: getAxisDomain(get(customConfig, 'yAxisProps.domain', domain), formatter),
  allowDecimals: get(customConfig, 'yAxisProps.allowDecimals', true),
  tickFormatter: getAxisTickFormatter(formatter),
  // If the formatter is not score or population then we add the ticks for each quarter
  ...(!['score', 'population'].includes(formatter) && { ticks: [0, 25, 50, 75, 100] }),
});

export const overwriteXAxisProps = (samples, customConfig) => ({
  ...config.xAxisProps,
  angle: get(customConfig, 'xAxisProps.angle', get(config.xAxisProps, 'angle', 0)),
  textAnchor: get(
    customConfig,
    'xAxisProps.textAnchor',
    get(config.xAxisProps, 'textAnchor', 'middle'),
  ),
  interval: get(customConfig, 'xAxisProps.interval', get(config.xAxisProps, 'interval', 0)),
  ticksProps: {
    ...config.xAxisProps.ticksProps,
    samples: samples,
    sampleOffset: get(customConfig, 'sample.offset', SAMPLE_OFFSET),
  },
});

const generateConfig = ({
  defaultConfig,
  series,
  domain,
  axisTitle,
  formatter,
  samples,
  customConfig,
  configuration,
  /* yAxisProps, */
}) => ({
  ...config,
  series: getFormattedSeries(formatter, normalizeSeries(series)),
  // overwriteYAxisProps: overwriteYAxisProps(yAxisProps),
  tooltipProps: {
    ...get(defaultConfig, 'tooltipProps', {}),
    formatter: getTooltipFormatter(formatter),
  },
  xAxisProps: overwriteXAxisProps(samples, customConfig),
  yAxisProps: overwriteYAxisProps(domain, axisTitle, formatter, customConfig),
  brush: {
    visibility: get(customConfig, 'brush.visibility', false),
    dataKey: get(customConfig, 'brush.dataKey', false),
  },
  chartThreshold: get(find(configuration, { key: 'threshold' }), 'value', LOW_SAMPLE_SIZE),
});

export default generateConfig;
