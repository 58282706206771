import {
  FORMATTER_PERCENTAGE,
  FORMATTER_PERCENTAGE_NO_SIGN,
} from '../RechartBarChartEngine/formatters';
import palette from '../palette';
import { axisLine, verticalCartesianGrid, numericalXAxis } from '../config';

const BAR_CHART_LAYOUT_VERTICAL = 'vertical';
const LEGEND_TYPE_DEFAULT = 'DEFAULT';

/**
 * Diff:
 * - stackId
 * - layout
 * - swap axis
 * - cartesian
 * - series
 * - maxBarSize
 */
export default {
  rootProps: {
    layout: BAR_CHART_LAYOUT_VERTICAL,
    margin: {
      top: 10,
      right: 25,
      left: 0,
      bottom: 0,
    },
  },
  series: [
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[0],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[1],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[2],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[3],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[4],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[5],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[6],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[7],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[8],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[9],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[10],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[11],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[12],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[13],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[14],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[15],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[16],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[17],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[18],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[19],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
    {
      // MUST BE DEFINED
      dataKey: null,
      fill: palette[20],
      stackId: 'a',
      maxBarSize: 60,
      label: {
        position: 'center',
        formatter: FORMATTER_PERCENTAGE_NO_SIGN,
      },
    },
  ],
  cartesianGridProps: verticalCartesianGrid,
  legendProps: {
    type: LEGEND_TYPE_DEFAULT,
    hide: false,
    verticalAlign: 'top',
    align: 'right',
    wrapperStyle: {
      paddingBottom: '20px',
    },
  },
  tooltipProps: { hide: false, formatter: FORMATTER_PERCENTAGE },
  yAxisProps: {
    type: 'category',
    dataKey: 'name',
    height: 30,
    axisLine,
    tickLine: false,
    angle: 0,
    textAnchor: 'end',
    interval: 0,
    showSamplesInAxis: true,
    hide: false,
    // Calc width based on content
    adjustWidth: true,
    ticksProps: {
      type: 'custom',
    },
    responsive: {
      xs: {
        ticksProps: {
          type: 'custom',
          width: 50,
        },
      },
    },
  },
  xAxisProps: {
    ...numericalXAxis,
    domain: [0, 100],
    tickFormatter: (value) => `${Math.floor(value)}%`,
    scale: 'linear',
  },
};
