import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';

import {
  SECTOR_DESK,
  SECTOR_MOB,
  SECTOR_WEB,
  SECTOR_CLD,
  SECTOR_GAM,
  SECTOR_IOT,
  SECTOR_ML,
  SECTOR_ARVR,
  SECTOR_EMB,
  SECTOR_APP,
  SECTOR_CORE,
  PRODUCT_REPORT_DEI,
  PRODUCT_REPORT_DPB,
  SECTOR_DEVOPS,
  PRODUCT_REPORT_MARKET,
  PRODUCT_REPORT_DEVELOPERS,
  PRODUCT_REPORT_SATISFACTION,
  PRODUCT_REPORT_SEGMENTATION,
} from '../../../../Home/data';

import FilterGroup from './components/FilterGroup';

const styles = (theme) => ({
  root: {
    width: '21.1rem',
  },
  drawerPaper: {
    paddingTop: '5.3rem',
    backgroundColor: theme.palette.custom.gray1,
    boxShadow: '4px 0px 18px 0 rgba(188, 198, 210, 0.15)',
    width: '21.1rem',
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2.3rem 0',
  },
  item: {
    paddingLeft: 0,
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.custom.blue2,
      color: theme.palette.custom.white,
      fill: theme.palette.custom.white,
      '& $icon': {
        '& path': {
          fill: theme.palette.custom.white,
        },
      },
    },
  },
  activeListItem: {
    color: theme.palette.custom.white,
    fill: theme.palette.custom.white,
    backgroundColor: theme.palette.custom.blue3,
    '& $listText span': {
      fontWeight: 'bold',
    },
    '& $icon': {
      '& path': {
        fill: theme.palette.custom.white,
      },
    },
  },
  listIcon: {
    margin: 0,
    color: 'inherit',
  },
  icon: {
    width: '1.1rem',
    height: '1.1rem',
    '& path': {
      fill: theme.palette.custom.blue2,
    },
    marginLeft: '2.2rem',
    marginRight: '1.4rem',
  },
  listText: {
    '& span': {
      color: 'inherit',
    },
  },
});

const data = {
  sectors: {
    [SECTOR_DESK.TYPE]: { checked: true, label: SECTOR_DESK.LABEL, name: SECTOR_DESK.TYPE },
    [SECTOR_MOB.TYPE]: { checked: true, label: SECTOR_MOB.LABEL, name: SECTOR_MOB.TYPE },
    [SECTOR_WEB.TYPE]: { checked: true, label: SECTOR_WEB.LABEL, name: SECTOR_WEB.TYPE },
    [SECTOR_CLD.TYPE]: { checked: true, label: SECTOR_CLD.LABEL, name: SECTOR_CLD.TYPE },
    [SECTOR_GAM.TYPE]: { checked: true, label: SECTOR_GAM.LABEL, name: SECTOR_GAM.TYPE },
    [SECTOR_IOT.TYPE]: { checked: true, label: SECTOR_IOT.LABEL, name: SECTOR_IOT.TYPE },
    [SECTOR_ML.TYPE]: { checked: true, label: SECTOR_ML.LABEL, name: SECTOR_ML.TYPE },
    [SECTOR_ARVR.TYPE]: { checked: true, label: SECTOR_ARVR.LABEL, name: SECTOR_ARVR.TYPE },
    [SECTOR_EMB.TYPE]: { checked: true, label: SECTOR_EMB.LABEL, name: SECTOR_EMB.TYPE },
    [SECTOR_APP.TYPE]: { checked: true, label: SECTOR_APP.LABEL, name: SECTOR_APP.TYPE },
    [SECTOR_DEVOPS.TYPE]: { checked: true, label: SECTOR_DEVOPS.LABEL, name: SECTOR_DEVOPS.TYPE },
    [SECTOR_CORE.TYPE]: { checked: true, label: SECTOR_CORE.LABEL, name: SECTOR_CORE.TYPE },
  },
  products: {
    [PRODUCT_REPORT_DEI.CATEGORY]: {
      checked: true,
      label: PRODUCT_REPORT_DEI.LABEL,
      name: PRODUCT_REPORT_DEI.CATEGORY,
    },
    [PRODUCT_REPORT_MARKET.CATEGORY]: {
      checked: true,
      label: PRODUCT_REPORT_MARKET.LABEL,
      name: PRODUCT_REPORT_MARKET.CATEGORY,
    },
    [PRODUCT_REPORT_DEVELOPERS.CATEGORY]: {
      checked: true,
      label: PRODUCT_REPORT_DEVELOPERS.LABEL,
      name: PRODUCT_REPORT_DEVELOPERS.CATEGORY,
    },
    [PRODUCT_REPORT_DPB.CATEGORY]: {
      checked: true,
      label: PRODUCT_REPORT_DPB.LABEL,
      name: PRODUCT_REPORT_DPB.CATEGORY,
    },
    [PRODUCT_REPORT_SATISFACTION.CATEGORY]: {
      checked: true,
      label: PRODUCT_REPORT_SATISFACTION.LABEL,
      name: PRODUCT_REPORT_SATISFACTION.CATEGORY,
    },
    [PRODUCT_REPORT_SEGMENTATION.CATEGORY]: {
      checked: true,
      label: PRODUCT_REPORT_SEGMENTATION.LABEL,
      name: PRODUCT_REPORT_SEGMENTATION.CATEGORY,
    },
  },
};

const FilterDrawer = ({ classes, className, user, onChange }) => {
  const [filters, setFilters] = useState(data);

  useEffect(() => {
    onChange({
      filters,
    });
  }, [filters]);

  const onChangeSectors = (sectors) => setFilters({ ...filters, sectors: { ...sectors } });
  const onChangeProducts = (products) => setFilters({ ...filters, products: { ...products } });

  if (isEmpty(user.userId)) return null;

  return (
    <Drawer
      variant="persistent"
      open
      classes={{
        root: cn(classes.root, className),
        paper: cn(classes.drawerPaper, className),
      }}
    >
      <Scrollbars>
        <div className={classes.drawerHeader} />
        <FilterGroup
          title="Sector"
          allLabel="All Sectors"
          className={classes.item}
          data={filters.sectors}
          onChange={onChangeSectors}
        />
        <FilterGroup
          title="Category"
          allLabel="All Categories"
          className={classes.item}
          data={filters.products}
          onChange={onChangeProducts}
        />
      </Scrollbars>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  user: get(state, 'user'),
});

export default connect(mapStateToProps)(withStyles(styles)(FilterDrawer));
