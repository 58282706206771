import flattenDeep from 'lodash/flattenDeep';
import map from 'lodash/map';
import omit from 'lodash/omit';
import concat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { FETCH_CHART_DATA_SUCCESS } from '../data/types';
import { SET_DATABOARD } from '../databoard/types';

export const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATABOARD: {
      const sources = get(action, ['payload', 'labels'], []);
      return {
        ...state,
        sources,
      };
    }
    case FETCH_CHART_DATA_SUCCESS: {
      const chartId = get(action, ['payload', 'id']);
      if (isEmpty(chartId)) return state;

      const tables = flattenDeep(map(flattenDeep(action.payload.data), 'values'));
      const tableLabels = map(tables, (table) => omit(table, 'values'));
      const tableData = flattenDeep(map(tables, 'values'));

      const chartLabels = uniqBy(
        map(concat(get(state, chartId, []), tableLabels, tableData), (item) => omit(item, 'value')),
        'name',
      );

      return {
        ...state,
        [chartId]: chartLabels,
      };
    }
    default:
      return state;
  }
};

export default reducer;
