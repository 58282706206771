import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import user from './user/reducer';
import filtersData from './filtersData/reducer';
import data from './data/reducer';
import charts from './charts/reducer';
import databoard from './databoard/reducer';
import dataExport from './dataExport/reducer';
import filtersIndex from './filtersIndex/reducer';
import labels from './labels/reducer';
import admin from './admin/reducer';
import analytics from './analytics/reducer';
import reports from './reports/reducer';

export default combineReducers({
  user,
  filtersData,
  filtersIndex,
  databoard,
  charts,
  data,
  labels,
  admin,
  dataExport,
  analytics,
  reports,
  form: formReducer,
});
