import filter from 'lodash/filter';
import toArray from 'lodash/toArray';
import get from 'lodash/get';
import map from 'lodash/map';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';

const getBySector = (subscriptions, searchCriteria) => {
  const sectorFilters = toArray(get(searchCriteria, ['filters', 'sectors'], []));
  const sectors = filter(sectorFilters, ({ checked }) => checked);

  if (sectorFilters.length === sectors.length) return subscriptions;
  if (sectors.length === 0)
    return filter(subscriptions, ({ databoard }) => isEmpty(get(databoard, 'sectors')));

  const selectedSectors = map(sectors, 'name');

  return filter(
    subscriptions,
    ({ databoard }) => !isEmpty(intersection(get(databoard, 'sectors'), selectedSectors)),
  );
};

export default getBySector;
