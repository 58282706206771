import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import { Scrollbars } from 'react-custom-scrollbars';

import map from 'lodash/map';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import trim from 'lodash/trim';
import toLower from 'lodash/toLower';
import isEmpty from 'lodash/isEmpty';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core';

import ITheme from '../../../theme/@types/Theme';
import { ReactComponent as SearchIcon } from './assets/search.svg';

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    width: '100%',
  },
  searchRoot: {
    padding: 12,
  },
  search: {
    position: 'relative',
    borderRadius: 12,
    backgroundColor: theme.palette.custom.white,
    marginLeft: `0px !important`,
    width: 192,
    height: 28,
    border: 'none',
    paddingLeft: 8,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: 12,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingLeft: 26,
    color: theme.palette.custom.gray4,
    fontSize: 12,
  },
  option: {
    paddingLeft: 33,
    width: '100%',
    marginRight: 0,
  },
  label: {
    color: theme.palette.custom.gray5,
    fontSize: 12,
  },
  active: {
    fontWeight: 'bold',
  },
  checkbox: {
    color: theme.palette.custom.gray4,
    '& svg': {
      width: 14,
      height: 14,
    },
  },
  withBorder: {
    border: '1px solid #ececf2',
  },
}));

const FILTER_MULTI_SELECTION = 'MULTI';
const FILTER_SINGLE_SELECTION = 'SINGLE';

export type Option = {
  name: string;
  text: string;
};

export interface OptionsProps {
  className?: string;
  selection: 'MULTI' | 'SINGLE';
  options: Option[];
  selectedOptions: string[];
  withScroll: boolean;
  withBorder: boolean;
  onChange: (values: string[]) => void;
}

const allowSelection = (currentValues: string[], selection: string) =>
  selection === FILTER_MULTI_SELECTION ||
  (selection === FILTER_SINGLE_SELECTION && currentValues.length === 0);

const Options: React.FC<OptionsProps> = ({
  className,
  options,
  selectedOptions,
  selection,
  withScroll,
  withBorder,
  onChange,
}: OptionsProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [filterToDisplay, setFilterToDisplay] = useState<Option[]>([]);
  const classes = useStyles();

  useEffect(() => {
    setFilterToDisplay(options);
  }, [options]);

  // Handle search in filter values
  useEffect(() => {
    const subset = filter(options, ({ text }) =>
      includes(toLower(text), trim(toLower(searchValue))),
    );
    setFilterToDisplay(subset);
  }, [searchValue]);

  const Container = withScroll ? Scrollbars : React.Fragment;

  return (
    <FormGroup className={cn(classes.root, { [className as string]: !isEmpty(className) })}>
      <div className={classes.searchRoot}>
        <div className={cn(classes.search, { [classes.withBorder]: withBorder })}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </div>
      <Container>
        {map(filterToDisplay, ({ name, text }, index) => {
          const isChecked = selectedOptions.indexOf(name) !== -1;

          return (
            <FormControlLabel
              name={`filter-option-${index}`}
              key={`filter-option-${index}`}
              className={classes.option}
              classes={{
                label: cn(classes.label, { [classes.active]: isChecked }),
              }}
              control={
                <Checkbox
                  checked={isChecked}
                  value={name}
                  className={classes.checkbox}
                  color="primary"
                  size="small"
                  onChange={(event) => {
                    let newValues = [...selectedOptions];

                    if (event.target.checked) {
                      if (allowSelection(newValues, selection)) {
                        newValues.push(name);
                      } else {
                        newValues = [name];
                      }
                    } else {
                      newValues.splice(newValues.indexOf(name), 1);
                    }

                    onChange(newValues);
                  }}
                />
              }
              label={text}
            />
          );
        })}
      </Container>
    </FormGroup>
  );
};

export default Options;
