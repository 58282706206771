import map from 'lodash/map';

import {
  FORMATTER_PERCENTAGE_NO_SIGN,
  FORMATTER_POPULATION,
  FORMATTER_SCORE,
} from '../RechartBarChartEngine/formatters';

const getFormattedSeries = (formatter, series) => {
  if (formatter === 'percentage') {
    return map(series, ({ label, ...rest }) => ({
      ...rest,
      label: { ...label, formatter: FORMATTER_PERCENTAGE_NO_SIGN },
    }));
  }

  if (formatter === 'score') {
    return map(series, ({ label, ...rest }) => ({
      ...rest,
      label: { ...label, formatter: FORMATTER_SCORE },
    }));
  }

  if (formatter === 'population') {
    return map(series, ({ label, ...rest }) => ({
      ...rest,
      label: { ...label, formatter: FORMATTER_POPULATION },
    }));
  }

  return series;
};

export default getFormattedSeries;
