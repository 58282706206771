import filter from 'lodash/filter';
import toArray from 'lodash/toArray';
import get from 'lodash/get';
import map from 'lodash/map';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';

const getBySector = (reports, searchCriteria) => {
  const sectorFilters = toArray(get(searchCriteria, ['filters', 'sectors'], []));
  const sectors = filter(sectorFilters, ({ checked }) => checked);

  if (sectorFilters.length === sectors.length) return reports;
  if (sectors.length === 0) return filter(reports, ({ report }) => isEmpty(get(report, 'sectors')));

  const selectedSectors = map(sectors, 'name');

  return filter(
    reports,
    (report) => !isEmpty(intersection(get(report, 'sectors'), selectedSectors)),
  );
};

export default getBySector;
