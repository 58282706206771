import React, { useEffect, useState } from 'react';

import { API } from 'aws-amplify';
import { connect } from 'react-redux';

import get from 'lodash/get';
import map from 'lodash/map';

import DropDownField from '../../../../../../components/Admin/DropDownField';

const DataBoardsField = ({ jwtToken, isDisabled, setError, ...rest }) => {
  const [databoards, setDataboards] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchDataboards = async () => {
      setLoader(true);

      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };

        const result = await API.get('dashboards', `/databoards`, params);

        setDataboards(result);
        setLoader(false);
      } catch (e) {
        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );
        setError(message);
        setLoader(false);
      }
    };

    fetchDataboards();
  }, []);

  const options = map(databoards, ({ _id, title }) => ({ value: _id, label: title }));

  return (
    <DropDownField
      suggestions={options}
      {...rest}
      isDisabled={loader || isDisabled}
      placeholder={loader ? 'Loading...' : 'Type or select...'}
    />
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
});

export default connect(mapStateToProps)(DataBoardsField);
