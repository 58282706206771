import React, { useState, useEffect } from 'react';

import { navigate } from '@reach/router';
import { API } from 'aws-amplify';
import { connect } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import get from 'lodash/get';
import merge from 'lodash/merge';

import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseOutlined';
// import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';

import AppLoader from '../../../components/AppLoader';
import Layout from '../../../components/Admin/Layout';
import OrganisationForm from '../../../components/Admin/OrganisationForm';
import SubscriptionForm from '../../../components/Admin/SubscriptionForm';
import TextSearch from '../../../components/Admin/TextSearch';
import Subscriptions from './components/Subscriptions';
import normalizeSubscriptionToEdit from './utils/normalizeSubscriptionToEdit';
import { SnackBar } from '../../../components/UI';

const styles = (theme) => ({
  root: {
    height: '100%',
  },
  dashboardsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  textSearchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1.2rem',
  },
  infoContainer: {
    fontFamily: 'Nunito Sans',
    fontSize: '1rem',
    color: theme.palette.custom.gray4,
    marginBottom: '0.7rem',
    display: 'flex',
    alignItems: 'center',
  },
  textSearchContent: {
    marginLeft: '0.3rem',
    fontWeight: 'bold',
    color: theme.palette.custom.gray4,
  },
  paperAnchorRight: { width: '40.6rem' },
  primaryInfoContainer: { marginBottom: '1.7rem' },
  navigationButton: {
    textTransform: 'none',
    marginBottom: '1.2rem',
    marginLeft: '-0.8rem',
    color: theme.palette.custom.gray4,
  },
  groupTitle: {
    fontWeight: 'bold',
    fontSize: '1.3rem',
    display: 'flex',
    alignItems: 'center',
  },
  actionButton: { textTransform: 'none', color: theme.palette.custom.blue2, fontWeight: 'bold' },
});

const Organisation = ({ classes, organisationId, jwtToken }) => {
  const [showForm, setShowForm] = useState(false);
  const [currentForm, setCurrentForm] = useState('organisation');
  const [organisation, setOrganisation] = useState({});
  const [subscriptionToEdit, setSubscriptionToEdit] = useState({});
  const [textSearch, setTextSearch] = useState('');
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrganisation = async () => {
      setLoader(true);
      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };

        const results = await API.get('users', `/organisations/${organisationId}`, params);

        setOrganisation(results);
        setLoader(false);
      } catch (e) {
        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );
        setError(message);
        setLoader(false);
      }
    };

    if (!isEmpty(organisationId)) {
      fetchOrganisation();
    }
  }, [organisationId]);

  useEffect(() => {
    const fetchOrganisation = async () => {
      setLoader(true);
      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };

        const results = await API.get('users', `/organisations/${organisationId}`, params);

        setOrganisation(results);
        setLoader(false);
      } catch (e) {
        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );
        setError(message);
        setLoader(false);
      }
    };

    if (!isEmpty(organisationId) && forceUpdate) {
      fetchOrganisation();
    }
  }, [forceUpdate]);

  if (loader) return <AppLoader show={loader} />;

  return (
    <Layout>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Button
            onClick={() => navigate('/admin/organisations')}
            className={classes.navigationButton}
          >
            View all organisations
          </Button>
        </Grid>
        <div className={classes.primaryInfoContainer}>
          <div className={classes.groupTitle}>
            Primary info
            <Button
              onClick={() => {
                setCurrentForm('organisation');
                setShowForm(true);
              }}
              className={classes.actionButton}
            >
              Edit
            </Button>
          </div>
          <Grid item xs={12}>
            <div>{`Name: ${organisation.name}`}</div>
          </Grid>
          <Grid item xs={12}>
            <div>{`Domains: ${join(organisation.domains, ', ')}`}</div>
          </Grid>
        </div>
        <Grid item xs={12}>
          <div className={classes.groupTitle}>Subscriptions</div>
        </Grid>
        <Grid item xs={12} className={classes.textSearchContainer}>
          <TextSearch
            value={textSearch}
            placeholder="Type to search by resource name"
            onChange={setTextSearch}
          />
          <div>
            <Button
              onClick={() => {
                setSubscriptionToEdit({});
                setCurrentForm('subscription');
                setShowForm(true);
              }}
              className={classes.actionButton}
            >
              New subscription
            </Button>
          </div>
        </Grid>
        {!isEmpty(textSearch) && (
          <Grid item xs={12} className={classes.infoContainer}>
            <i>Showing results for</i>
            <Typography className={classes.textSearchContent}>{textSearch}</Typography>
          </Grid>
        )}
        <Grid item xs={12} className={classes.dashboardsContainer}>
          <Subscriptions
            data={organisation.subscriptions}
            textSearch={textSearch}
            onClick={(model) => {
              setSubscriptionToEdit(model);
              setCurrentForm('subscription');
              setShowForm(true);
            }}
          />
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawerPaper, paperAnchorRight: classes.paperAnchorRight }}
        onClose={() => setShowForm(false)}
        open={showForm}
        variant="temporary"
      >
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            onClick={() => {
              setShowForm(false);
            }}
            variant="text"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {currentForm === 'subscription' ? (
          <SubscriptionForm
            {...normalizeSubscriptionToEdit(subscriptionToEdit)}
            organisationId={organisation._id}
            onSuccess={() => {
              setForceUpdate(true);
              setShowForm(false);
            }}
          />
        ) : (
          <OrganisationForm
            {...organisation}
            onSuccess={(model) => {
              setOrganisation(merge(organisation, model));
              setShowForm(false);
            }}
            onDelete={() => navigate('/admin/organisations')}
          />
        )}
      </Drawer>
      <SnackBar open={Boolean(error)} message={error} severity="warning" />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
  userId: get(state, ['user', 'userId'], []),
});

export default connect(mapStateToProps)(withStyles(styles)(Organisation));
