import get from 'lodash/get';

import getBookmarked from './getBookmarked';
import { CATEGORY_BOOKMARKED } from '../../../data';
import getByName from './getByName';
import getBySector from './getBySector';

const getFilteredDataTables = (dataTables, searchCriteria, textSearch, bookmarks) => {
  const category = get(searchCriteria, 'category');
  let filteredDataTables = [];
  if (category === CATEGORY_BOOKMARKED.CATEGORY) {
    filteredDataTables = getBookmarked(dataTables, bookmarks);
  } else {
    filteredDataTables = dataTables;
  }
  filteredDataTables = getBySector(filteredDataTables, searchCriteria);
  filteredDataTables = getByName(filteredDataTables, textSearch);

  return filteredDataTables;
};

export default getFilteredDataTables;
