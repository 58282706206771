import React from 'react';
import cn from 'classnames';

import { Paper, makeStyles } from '@material-ui/core';

import Title from './Title';
import Actions, { Action } from './Actions';

export interface CardPaperProps {
  height?: number;
  className?: string;
  title?: string;
  actions?: Action[];
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '50px 30px',
    boxShadow: '-10px 11.1px 50px 0 rgba(188, 198, 210, 0.31)',
    borderRadius: 10,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: (props: CardPaperProps) => props.height,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const CardPaper: React.FC<CardPaperProps> = ({
  className = '',
  children,
  height = 600,
  title = '',
  actions,
}) => {
  const classes = useStyles({ height });

  return (
    <Paper className={cn(classes.root, { [className]: className !== '' })}>
      <div className={classes.header}>
        <Title>{title}</Title>
        <Actions actions={actions} />
      </div>
      {children}
    </Paper>
  );
};

export default CardPaper;
