import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';
import get from 'lodash/get';
import { find, forEach, isNumber, map } from 'lodash';

const getChartSources = ({ sources, chartSources, data, table, sourcesLookup }) => {
  const pathURL = window.location.pathname;
  const isDataboard = pathURL.includes('/dashboards');
  if (!isDataboard) {
    let result = [];
    const tables = get(find(data, { name: table }, []), 'values', []);
    forEach(tables, (table) => {
      result.push(table.name);
    });
    return result;
  }
  if (isEmpty(sources)) return [];
  if (isEmpty(chartSources)) return sources;
  sources = map(sources, (source) => {
    if (isNumber(source)) {
      return get(find(sourcesLookup, { id: source }), 'name', source);
    } else {
      return source;
    }
  });

  const filter = get(chartSources, 'filter', []);
  const reject = get(chartSources, 'reject', []);

  if (!isEmpty(filter)) {
    // [de14, de15, de16], [de15, de16] => [de15, de16]
    return intersection(sources, filter);
  }

  if (!isEmpty(reject)) {
    // [de14, de15, de16], [de15, de16] => [de14]
    return difference(sources, reject);
  }
  return sources;
};

export default getChartSources;
