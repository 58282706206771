import { Auth } from 'aws-amplify';

class NewPasswordError extends Error {
  constructor(props) {
    super(props);
    this.code = 'NewPasswordRequiredException';
    this.message = 'New password is required';
  }

  setUser = (user) => {
    this.user = user;
  };
}

const signIn = async (username, password) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await Auth.signIn(username, password);
      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        const error = new NewPasswordError('NEW_PASSWORD_REQUIRED');
        error.setUser(response);
        throw error;
      }
      const contact = await Auth.verifiedContact(response);
      resolve(contact);
    } catch (error) {
      console.log('error signing in');
      console.log(error);
      reject(error);
    }
  });

export default signIn;
