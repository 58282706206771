import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import findIndex from 'lodash/findIndex';
import reverse from 'lodash/reverse';
import head from 'lodash/head';
import omit from 'lodash/omit';
import keys from 'lodash/keys';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';

// TODO: remove Math.floor & percentage
const normalizeCategoricalData = ({ key, data, table, sorting, omitZeros }) => {
  const pathURL = window.location.pathname;
  const isDataboard = pathURL.includes('/dashboards');
  const dataTable = find(data, { name: table });
  const values = get(dataTable, ['values', 0, 'values'], []);
  let series = [];
  let dataToUse = [];
  if (isDataboard) {
    const rawData = map(values, ({ value, ...rest }) => ({
      [key]: value,
      ...rest,
    }));
    series = keys(omit(head(rawData), ['name', 'text', 'hint']));
    dataToUse = map(rawData, (item) => omit(item, 'text'));
  } else {
    series = keys(omit(head(values), ['name', 'text', 'hint']));
    dataToUse = map(values, (item) => omit(item, 'text'));
  }
  if (omitZeros) {
    dataToUse = filter(dataToUse, (entry) => {
      const nonZeroSeries = filter(series, (seriesItem) => entry[seriesItem] !== 0);

      return !isEmpty(nonZeroSeries);
    });
  }

  if (isEmpty(sorting)) return dataToUse;

  const sortedResults = reverse(
    sortBy(dataToUse, (valuesToSortBy) =>
      reduce(omit(valuesToSortBy, ['name', 'hint']), (result, value) => result + value, 0),
    ),
  );
  const index = findIndex(sortedResults, { name: head(get(sorting, 'exclude')) });

  if (index === -1) return sortedResults;

  const item = head(sortedResults.splice(index, 1));

  sortedResults.push(item);

  return sortedResults;
};

export default normalizeCategoricalData;
