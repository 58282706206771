import { orderBy } from 'lodash';

const sortByTitle = (reports, order) => orderBy(reports, ['title'], order);
const sortByType = (reports, order) => orderBy(reports, ['type'], order);
const sortByStatus = (reports, order) => orderBy(reports, ['status'], order);

const sortReports = (reports, orderByField, order) => {
  switch (orderByField) {
    case 'title':
      return sortByTitle(reports, order);

    case 'type':
      return sortByType(reports, order);

    case 'status':
      return sortByStatus(reports, order);

    default:
      return reports;
  }
};

export default sortReports;
