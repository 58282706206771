import indexOf from 'lodash/indexOf';

const removeBookmark = (state, action) => {
  const { bookmarks } = state;
  const index = indexOf(bookmarks, action.payload);

  if (index === -1) return bookmarks;

  bookmarks.splice(index, 1);

  return Array.from(bookmarks);
};

export default removeBookmark;
