import React from 'react';
import cn from 'classnames';

import { navigate } from '@reach/router';

import { connect } from 'react-redux';
import List from '@material-ui/core/List';

import MenuItem from './components/SearchFilters/components/SidebarMenuItem';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';

import SearchFilters from './components/SearchFilters';
import { ReactComponent as DashboardsIcon } from './assets/dashboards.svg';
import { ReactComponent as DAAsIcon } from './assets/daa.svg';
import { ReactComponent as ReportsIcon } from './assets/reports.svg';

const styles = (theme) => ({
  root: {
    width: '3.75rem',
  },
  drawerPaper: {
    marginTop: '4rem',
    backgroundColor: theme.palette.custom.blue2,
  },
  container: {
    backgroundColor: theme.palette.custom.blue2,
  },
  selected: {
    backgroundColor: theme.palette.custom.blue4,
  },
});

const Icons = {
  dashboards: DashboardsIcon,
  daa: DAAsIcon,
  reports: ReportsIcon,
};

const Sidebar = ({ classes, className, user, InnerDrawerProps }) => {
  if (isEmpty(user.userId)) return null;
  const path = window.location.pathname;

  return (
    <>
      <Drawer
        variant="permanent"
        open
        classes={{
          root: cn(classes.root, className),
          paper: cn(classes.drawerPaper, className),
        }}
      >
        <div className={cn(classes.root, className)}>
          <div className={classes.container}>
            <List component="ul" disablePadding>
              <MenuItem
                className={cn({ [classes.selected]: path === '/dashboards' })}
                Icon={Icons.dashboards}
                name="dashboards"
                onClick={() => navigate('/dashboards')}
              />
              <MenuItem
                className={cn({ [classes.selected]: path === '/daa' })}
                Icon={Icons.daa}
                name="custom data cuts"
                onClick={() => navigate('/daa')}
              />
              <MenuItem
                className={cn({
                  [classes.selected]: path.includes('/reports') || path.includes('/briefings'),
                })}
                Icon={Icons.reports}
                name="reports"
                onClick={() => navigate('/reports')}
              />
            </List>
          </div>
        </div>
        {InnerDrawerProps && <SearchFilters {...InnerDrawerProps} />}
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: get(state, 'user'),
  databoardCategory: get(state, 'category'),
});

export default connect(mapStateToProps)(withStyles(styles)(Sidebar));
