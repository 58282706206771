import React from 'react';
import cn from 'classnames';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { PrismicRichText } from '@prismicio/react';
import { get, head, isEmpty, tail } from 'lodash';
import PrismicLinkComponent from '../PrismicLinkComponent';

const sampleHeading = 'This is a dummy title for this text';
const sampleContent = `If you want to access the real text. Contact us by clicking on "contact us" above in the heading.
  If you want to access the real text. Contact us by clicking on "contact us" above in the heading.
  If you want to access the real text. Contact us by clicking on "contact us" above in the heading.
  If you want to access the real text. Contact us by clicking on "contact us" above in the heading.`;

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '55%',
    maxWidth: 1050,
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  bluredRoot: {
    width: '55%',
    maxWidth: 1050,
    display: 'flex',
    flexDirection: 'row',
  },
  heading: {
    flexDirection: 'column !important',
    color: '#0f70e0',
    fontFamily: 'Nunito Sans Black',
    paddingLeft: '3.2rem',
    marginLeft: '2rem',
    minWidth: '50%',
    width: '100%',
    textAlign: 'left',
    borderLeft: '2px solid #0f70e0',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    marginBottom: '1rem',
  },
  text: {
    flexDirection: 'column !important',
    color: '#636666',
    'white-space': 'pre-line',
    fontSize: 18,
    lineHeight: '1.5em',
  },
  textColumns: {
    columnCount: 2,
    columnGap: '10%',
    marginBottom: '1.75rem',
  },
  blur: {
    filter: 'blur(8px)',
    userSelect: 'none',
  },
  footnote: {
    flexDirection: 'column !important',
    fontSize: '0.8rem',
    color: '#636666',
    'white-space': 'pre-line',
    marginTop: '-1.75rem',
    marginBottom: '1.75rem',
  },
  noMargin: {
    marginBlockStart: '0 !important',
    marginBottom: 0,
    lineHeight: '1.5em',
  },
  ulNoMargin: {
    marginBlockStart: '0 !important',
    marginBottom: 0,
    paddingInlineStart: '30px !important',
  },
  subSection: {
    color: 'black',
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Nunito Sans Black',
    paddingTop: '1rem',
    marginBottom: '1.6rem',
    width: '100%',
  },
  marginTop: {
    marginTop: '1rem',
  },
  headingText: {
    fontFamily: 'Nunito Sans',
    fontSize: '1.5rem',
    fontWeight: 600,
    marginBlockStart: '0 !important',
    marginBottom: 0,
    lineHeight: '1.5em',
  },
});

const regExp = new RegExp('[0-9]+.[0-9]+');

const Text = ({ id, classes, heading, content, footnote, locked }) => {
  if (locked) {
    return (
      <div className={cn(classes.bluredRoot, { [classes.blur]: locked })}>
        <Typography className={classes.content}>{sampleContent}</Typography>
        <Typography className={classes.heading}>{sampleHeading}</Typography>
      </div>
    );
  }
  if (isEmpty(heading) && isEmpty(content) && isEmpty(footnote)) return <></>;
  return (
    <div className={classes.container}>
      {!isEmpty(heading) && !isEmpty(get(head(heading), 'text', '')) && (
        <div className={classes.root}>
          {!isEmpty(content) && !isEmpty(get(head(content), 'text', '')) && (
            <div className={classes.root} id={id}>
              <div className={classes.text}>
                <PrismicRichText
                  field={[head(content)]}
                  components={{
                    paragraph: ({ children }) => <p className={classes.noMargin}>{children}</p>,
                    list: ({ children }) => <ul className={classes.ulNoMargin}>{children}</ul>,
                  }}
                  externalLinkComponent={({ href, ...props }) => (
                    <PrismicLinkComponent href={href} {...props} />
                  )}
                />
              </div>
            </div>
          )}
          <div className={classes.heading}>
            <PrismicRichText
              field={heading}
              components={{
                heading1: ({ children }) => <h1 className={classes.headingText}>{children}</h1>,
                paragraph: ({ children }) => <p className={classes.noMargin}>{children}</p>,
                list: ({ children }) => <ul className={classes.ulNoMargin}>{children}</ul>,
              }}
              externalLinkComponent={({ href, ...props }) => (
                <PrismicLinkComponent href={href} {...props} />
              )}
            />
          </div>
        </div>
      )}
      {!isEmpty(content) && !isEmpty(get(head(content), 'text', '')) && (
        <div
          className={cn(classes.root, {
            [classes.marginTop]: !isEmpty(heading) && !isEmpty(get(head(heading), 'text', '')),
          })}
          id={id}
        >
          <div
            className={cn(classes.text, {
              [classes.textColumns]: !regExp.test(get(head(content), 'text', '').split(' ')[0]),
              [classes.subSection]: regExp.test(get(head(content), 'text', '').split(' ')[0]),
            })}
          >
            <PrismicRichText
              field={
                !isEmpty(heading) && !isEmpty(get(head(heading), 'text', ''))
                  ? tail(content)
                  : content
              }
              components={{
                paragraph: ({ children }) => <p className={classes.noMargin}>{children}</p>,
                list: ({ children }) => <ul className={classes.ulNoMargin}>{children}</ul>,
              }}
              externalLinkComponent={({ href, ...props }) => (
                <PrismicLinkComponent href={href} {...props} />
              )}
            />
          </div>
        </div>
      )}
      {!isEmpty(footnote) && !isEmpty(get(head(footnote), 'text', '')) && (
        <div className={classes.root}>
          <div className={classes.footnote}>
            <PrismicRichText
              field={footnote}
              components={{
                paragraph: ({ children }) => <p className={classes.noMargin}>{children}</p>,
                list: ({ children }) => <ul className={classes.ulNoMargin}>{children}</ul>,
              }}
              externalLinkComponent={({ href, ...props }) => (
                <PrismicLinkComponent href={href} {...props} />
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(Text);
