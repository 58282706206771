import {
  EXPORT_DATABOARD_DATA_REQUEST,
  EXPORT_DATABOARD_DATA_SUCCESS,
  EXPORT_CHART_DATA_REQUEST,
  EXPORT_CHART_DATA_SUCCESS,
  EXPORT_DATABOARD_DATA_PPTX,
} from './types';

const initialState = {
  databoard: { isExportingData: false, pptxExport: false },
  charts: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EXPORT_DATABOARD_DATA_REQUEST:
      return {
        ...state,
        databoard: { isExportingData: true },
      };
    case EXPORT_DATABOARD_DATA_PPTX:
      return {
        ...state,
        databoard: { isExportingData: true, pptxExport: true },
      };
    case EXPORT_DATABOARD_DATA_SUCCESS:
      return {
        ...state,
        databoard: { isExportingData: false, pptxExport: false },
      };
    case EXPORT_CHART_DATA_REQUEST:
      return {
        ...state,
        charts: { ...state.charts, [action.payload]: { isExportingData: true } },
      };
    case EXPORT_CHART_DATA_SUCCESS:
      return {
        ...state,
        charts: { ...state.charts, [action.payload]: { isExportingData: false } },
      };
    default:
      return state;
  }
};

export default reducer;
