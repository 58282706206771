import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import findIndex from 'lodash/findIndex';
import reverse from 'lodash/reverse';
import head from 'lodash/head';
import omit from 'lodash/omit';
import keys from 'lodash/keys';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import { tail } from 'lodash';

// TODO: remove Math.floor & percentage
const normalizeCombinedlData = ({ data, table, extraGraphs, sorting, omitZeros }) => {
  const ColumnDataTable = find(data, { name: table });
  const lineDataTable = find(data, { name: head(tail(extraGraphs)) });
  const columnValues = get(ColumnDataTable, ['values', 0, 'values'], []);
  const lineValues = get(lineDataTable, ['values', 0, 'values'], []);
  const columnSeries = keys(omit(head(columnValues), ['name', 'text']));
  let columnDataToUse = map(columnValues, (item, index) => {
    omit(item, 'text');
    item.value2 = lineValues[index].value;
    return item;
  });

  if (omitZeros) {
    columnDataToUse = filter(columnDataToUse, (entry) => {
      const nonZeroSeries = filter(columnSeries, (seriesItem) => entry[seriesItem] !== 0);

      return !isEmpty(nonZeroSeries);
    });
  }

  if (isEmpty(sorting)) return columnDataToUse;

  const sortedResults = reverse(
    sortBy(columnDataToUse, (valuesToSortBy) =>
      reduce(omit(valuesToSortBy, ['name', 'hint']), (result, value) => result + value, 0),
    ),
  );
  const index = findIndex(sortedResults, { name: head(get(sorting, 'exclude')) });

  if (index === -1) return sortedResults;

  const item = head(sortedResults.splice(index, 1));

  sortedResults.push(item);

  return sortedResults;
};

export default normalizeCombinedlData;
