import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import omit from 'lodash/omit';
import isNull from 'lodash/isNull';
import filter from 'lodash/filter';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import findIndex from 'lodash/findIndex';
import head from 'lodash/head';
import union from 'lodash/union';
import indexOf from 'lodash/indexOf';
import flattenDeep from 'lodash/flattenDeep';

export const sortByColumn = ({ data, sorting }) => {
  const key = head(get(sorting, 'by', []));
  let sortedResults = data;

  if (!isEmpty(key)) {
    sortedResults = reverse(
      sortBy(data, (valuesToSortBy) => {
        return get(omit(valuesToSortBy, ['name', 'hint']), key);
      }),
    );
  }

  const index = findIndex(sortedResults, { name: head(get(sorting, 'exclude')) });

  if (index === -1) return sortedResults;

  const item = head(sortedResults.splice(index, 1));
  sortedResults.push(item);

  return sortedResults;
};

export const transformData = ({ data }) => {
  const transformArr = map(data, ({ name: rowName, text: rowText, values: columnValues }) => {
    const values = reduce(
      columnValues,
      (cur, { name: itemName, value: itemValue }) => {
        return { ...cur, [itemName]: itemValue };
      },
      {},
    );

    if (isEmpty(omitBy(values, isNull))) return {};

    const series = reduce(
      columnValues,
      (cur, { name: seriesName, text: seriesText }) => {
        return [...cur, { name: seriesName, text: seriesText }];
      },
      [],
    );

    return {
      name: rowName,
      text: rowText,
      ...values,
      series,
    };
  });

  const dataToUse = filter(transformArr, (item) => !isEmpty(item));

  return dataToUse;
};

export const omitZeroCols = ({ data }) => {
  const zeroCols = map(data, (entry) => {
    return reduce(
      entry,
      (zeroColsPerEntry, value, key) => {
        if (value !== 0) return zeroColsPerEntry;

        return [...zeroColsPerEntry, key];
      },
      [],
    );
  });

  let universalZeroCols = [];
  // TODO: add tests related to the export feat
  if (zeroCols.length > 1) {
    const zeroColsUnion = flattenDeep(union(zeroCols));
    const counts = reduce(
      zeroColsUnion,
      (acc, value) => {
        return {
          ...acc,
          [value]: get(acc, value, 0) + 1,
        };
      },
      {},
    );

    universalZeroCols = reduce(
      counts,
      (acc, value, key) => {
        if (value === zeroCols.length) return [...acc, key];

        return acc;
      },
      [],
    );
  } else {
    universalZeroCols = head(zeroCols);
  }

  return map(data, (entry) => {
    return {
      ...omit(entry, universalZeroCols),
      series: filter(
        entry.series,
        (series) => indexOf(universalZeroCols, get(series, 'name')) === -1,
      ),
    };
  });
};

export const omitZeroRows = ({ data }) =>
  filter(data, (values) =>
    reduce(omit(values, ['name', 'text', 'series']), (sum, value) => sum + value, 0),
  );

/**
 * Stacked distribution transformer.
 * Even the data are from multiple sources, the distribution shows them merged
 */
const getStackedData = ({ data, table, sorting, omitZeros }) => {
  const dataTable = find(data, { name: table });
  const dataArr = get(dataTable, 'values', []);

  let transformedData = transformData({ data: dataArr });

  if (!isEmpty(sorting)) {
    const sortedData = sortByColumn({ data: transformedData, sorting });
    transformedData = sortedData;
  }

  if (omitZeros) {
    const nonZeroData = omitZeroCols({ data: omitZeroRows({ data: transformedData }) });
    transformedData = nonZeroData;
  }

  return transformedData;
};

export default getStackedData;
