import React from 'react';
import cn from 'classnames';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    flexGrow: 1,
  },
  noPadding: {
    padding: 0,
  },
});

const PortletContent = ({ classes, className, children, noPadding, ...rest }) => {
  const rootClassName = cn(
    {
      [classes.root]: true,
      [classes.noPadding]: noPadding,
    },
    className,
  );

  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  );
};

export default withStyles(styles)(PortletContent);
