import orderBy from 'lodash/orderBy';

const sortByName = (organisations, order) => orderBy(organisations, ['name'], order);
const sortByDomains = (organisations, order) => orderBy(organisations, ['domains'], order);
const sortByUsers = (organisations, order) => orderBy(organisations, ['usersCount'], order);
const sortBySubscriptions = (organisations, order) =>
  orderBy(organisations, ['subscriptionsCount'], order);

const sortOrganisations = (organisations, orderByField, order) => {
  switch (orderByField) {
    case 'name':
      return sortByName(organisations, order);
    case 'domains':
      return sortByDomains(organisations, order);
    case 'users':
      return sortByUsers(organisations, order);
    case 'subscriptions':
      return sortBySubscriptions(organisations, order);
    default:
      return organisations;
  }
};

export default sortOrganisations;
