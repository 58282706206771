import { Auth, API } from 'aws-amplify';

import get from 'lodash/get';

import buildUserModel from '../../../utils/buildUserModel';

const currentAuthenticatedUser = async () =>
  new Promise(async (resolve, reject) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser({
        // send a request to Cognito to get the latest user data
        bypassCache: true,
      });

      const userId = get(cognitoUser, ['signInUserSession', 'accessToken', 'payload', 'sub']);

      const mongoUser = await API.get('users', `/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${get(
            cognitoUser,
            ['signInUserSession', 'idToken', 'jwtToken'],
            '',
          )}`,
        },
      });
      const model = buildUserModel(cognitoUser, mongoUser);
      resolve(model);
    } catch (error) {
      // console.log('An error occurred while fetching current authenticated user');
      // console.log(error);
      reject(error);
    }
  });

export default currentAuthenticatedUser;
