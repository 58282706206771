import toArray from 'lodash/toArray';
import map from 'lodash/map';
import head from 'lodash/head';
import filter from 'lodash/filter';
import get from 'lodash/get';

// 1-1 relationship
const findPlugFilters = ({ adapters, filters }) => {
  const filtersArray = toArray(filters);
  return map(adapters, ({ adapter }) => {
    const result = head(filter(filtersArray, { name: adapter }));

    return get(result, '_id', '');
  });
};

export default findPlugFilters;
