import reduce from 'lodash/reduce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

import getAllFilters from '../../../utils/getAllFilters';

export default (databoard, sourceLabels) => {
  const allFilters = getAllFilters(databoard.filterGroups, databoard.sections);

  return reduce(
    allFilters,
    (acc, filterModel) => {
      const filterId = get(filterModel, '_id');
      const parent = get(filterModel, 'parent');
      let inheritedProps = {};
      if (!isEmpty(parent)) {
        const parentFilter = find(allFilters, { name: parent });

        const childTable = get(filterModel, 'table');
        const table = isEmpty(childTable) ? get(parentFilter, 'table') : childTable;
        const childQuery = get(filterModel, 'query');
        const query = isEmpty(childQuery) ? get(parentFilter, 'query') : childQuery;

        inheritedProps = {
          defaultValues: get(parentFilter, 'defaultValues'),
          query,
          table,
          text: get(parentFilter, 'text'),
          operator: get(parentFilter, 'operator'),
          selection: get(parentFilter, 'selection'),
          filter: get(parentFilter, 'filter', []),
          reject: get(parentFilter, 'reject', []),
        };
      }

      return {
        ...acc,
        [filterId]: { ...filterModel, ...inheritedProps },
      };
    },
    {
      sources: { labels: sourceLabels },
    },
  );
};
