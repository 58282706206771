import themePalette from '../../../theme/palette';

export const axisLine = { stroke: '#BCC6D2', strokeWidth: 1, opacity: 1 };

export const horizontalCartesianGrid = {
  hide: false,
  stroke: '#BCC6D2',
  strokeWidth: 0.5,
  horizontal: true,
  vertical: false,
};

export const verticalCartesianGrid = {
  hide: false,
  stroke: '#BCC6D2',
  strokeWidth: 0.5,
  horizontal: false,
  vertical: true,
};

export const numericalXAxis = {
  type: 'number',
  customHeight: true,
  height: 50,
  dataKey: null,
  hide: false,
  axisLine,
  tickLine: false,
  angle: 0,
  textAnchor: 'middle',
  interval: 0,
  showSamplesInAxis: false,
  label: {
    value: '',
    offset: 5,
    angle: 0,
    position: 'insideBottomLeft',
    style: {
      fill: themePalette.custom.black,
      fontSize: 13,
      fontWeight: 'bold',
    },
  },
};

export const numericalYAxis = {
  type: 'number',
  dataKey: null,
  hide: false,
  axisLine,
  tickLine: false,
  angle: 0,
  textAnchor: 'end',
  interval: 0,
  showSamplesInAxis: false,
  label: {
    value: '',
    offset: 20,
    angle: -90,
    position: 'insideBottomLeft',
    style: {
      fill: themePalette.custom.black,
      fontSize: 13,
      fontWeight: 'bold',
    },
  },
};

export const xAxisLabel = {
  value: '',
  offset: 0,
  angle: 0,
  position: 'insideBottomLeft',
  style: {
    fill: themePalette.custom.black,
    fontSize: 13,
    fontWeight: 'bold',
  },
};
