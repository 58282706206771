import React from 'react';

import { Button, Grid, Typography, withStyles } from '@material-ui/core';
// import Footer from '../../components/Footer';
import { NEW_ACCOUNT_SCREEN } from '../../types';

import { ReactComponent as FirstIllustration } from './assets/img1.svg';
import { ReactComponent as SecondIllustration } from './assets/img2.svg';

const styles = (theme) => ({
  root: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  firstSection: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '38rem',
  },
  leftSection: {
    minWidth: '45%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginRight: '5%',
    paddingLeft: '10%',
    height: '100%',
    flexDirection: 'column',
  },
  rightSection: {
    minWidth: '45%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: '5%',
    paddingRight: '10%',
    height: '100%',
    flexDirection: 'column',
  },
  heading: {
    fontSize: '3rem',
    fontFamily: 'Nunito Sans Black',
    lineHeight: '3rem',
    marginBottom: '1rem',
    textAlign: 'left',
  },
  text: {
    color: theme.palette.custom.gray5,
    fontSize: '1rem',
    textAlign: 'left',
  },
  secondSection: {
    backgroundColor: 'rgba(236, 236, 242, 0.63)',
    height: '16rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading2: {
    fontSize: '1.5rem',
    fontFamily: 'Nunito Sans Black',
    color: theme.palette.custom.blue2,
    textAlign: 'right',
  },
  text2: {
    textAlign: 'left',
  },
  specialWord: {
    fontFamily: 'Nunito Sans Black',
    color: theme.palette.custom.blue2,
  },
  thirdSection: {
    backgroundColor: theme.palette.custom.blue2,
    height: '30rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading3: {
    fontSize: '2.25rem',
    color: 'white',
    fontFamily: 'Nunito Sans Black',
    textAlign: 'left',
    width: '100%',
  },
  text3: {
    color: 'white',
    fontSize: '1rem',
    textAlign: 'left',
  },
  specialWord3: {
    color: theme.palette.custom.blue4,
    fontFamily: 'Nunito Sans Black',
  },
  fourthSection: {
    height: '30rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: '30%',
    paddingRight: '30%',
  },
  heading4: {
    fontSize: '3rem',
    fontFamily: 'Nunito Sans Black',
    lineHeight: '3rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  text4: {
    fontSize: '1rem',
  },
  specialWord4: {
    color: theme.palette.custom.blue2,
    fontFamily: 'Nunito Sans Black',
    fontSize: '1.5rem',
    marginTop: '1rem',
  },
  button: {
    backgroundColor: theme.palette.custom.blue2,
    borderRadius: 20,
    color: 'white',
    fontFamily: 'Nunito Sans Black',
    padding: '0.5rem 2.5rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    fontSize: '0.7rem',
    '&:hover': {
      backgroundColor: theme.palette.custom.blue2,
    },
  },
});

const Welcome = ({ classes, setScreen }) => {
  return (
    <Grid className={classes.root}>
      <Grid className={classes.firstSection}>
        <Grid className={classes.leftSection}>
          <FirstIllustration />
        </Grid>
        <Grid className={classes.rightSection}>
          <Typography className={classes.heading}>
            Your first step to understanding the developer ecosystem
          </Typography>
          <Typography className={classes.text}>
            Developers use complicated tools and processes. Understanding their needs and giving
            them the support to create should not be complicated.
          </Typography>
          <Button className={classes.button} onClick={() => setScreen(NEW_ACCOUNT_SCREEN)}>
            Sign Up
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.secondSection}>
        <Grid className={classes.leftSection}>
          <Typography className={classes.heading2}>
            The SlashData Research Space is here to help you with that, by proving the data you need
            to understand developers.
          </Typography>
        </Grid>
        <Grid className={classes.rightSection}>
          <Typography className={classes.text2}>
            Access <span className={classes.specialWord}>data</span>,{' '}
            <span className={classes.specialWord}>analysis</span>,{' '}
            <span className={classes.specialWord}>reports</span> and{' '}
            <span className={classes.specialWord}>insights</span> on developer needs and preferences
            across multiple development areas: Web, Desktop, Cloud, Mobile, Industrial IoT, AR/VR,
            Machine Learning and Data Science, Games, Consumer Electronics and Apps/Extensions for
            3rd party ecosystems.
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        <Grid className={classes.thirdSection}>
          <Grid className={classes.leftSection}>
            <Typography className={classes.heading3}>What you will find inside</Typography>
            <Typography className={classes.text3}>
              <span className={classes.specialWord3}>Interactive data dashboards:</span> Use filter
              combinations to narrow down results to match your audience’s profile.
              <br />
              <span className={classes.specialWord3}>Reports:</span> In-depth analysis and insights
              on key developer-focused topics and trends.
              <br />
              <span className={classes.specialWord3}>Analyst Access:</span> Bespoke data analysis to
              answer your questions on the developer ecosystem.
            </Typography>
          </Grid>
          <Grid className={classes.rightSection}>
            <SecondIllustration />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.fourthSection}>
        <Typography className={classes.heading4}>Data you can count on</Typography>
        <Typography className={classes.text4}>
          SlashData has been surveying developers for more than 15 years and reaches more than
          21,000 developers globally on an annual basis. Leading tech brands rely on our insights
          for their developer-facing strategy. Now you can segment, grow and engage your developer
          community by addressing their needs based on the deep insights that our rich developer
          ecosystem data provides.
        </Typography>
        <Typography className={classes.specialWord4}>
          Invest in the activities that matter to developers.
        </Typography>
        <Button className={classes.button} onClick={() => setScreen(NEW_ACCOUNT_SCREEN)}>
          Sign Up
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Welcome);
