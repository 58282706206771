import React from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    fontFamily: 'Nunito Sans Black',
    fontSize: '1.5rem',
    color: '#2b2a29',
    marginBottom: '0.8rem',
  },
});

const FormTitle = ({ classes, children }) => <div className={classes.root}>{children}</div>;

export default withStyles(styles)(FormTitle);
