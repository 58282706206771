import React, { useState } from 'react';

import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Admin/Layout';
import TextSearch from '../../../components/Admin/TextSearch';
import FilterDrawer from './components/FilterDrawer';
import Reports from './components/Reports';

const styles = (theme) => ({
  root: {
    height: '100%',
    marginLeft: '6.5rem',
  },
  reportsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    color: theme.palette.custom.gray4,
    fontSize: '1.4rem',
  },
  textSearchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2.9rem',
  },
  infoContainer: {
    fontFamily: 'Nunito Sans',
    fontSize: '1rem',
    color: theme.palette.custom.gray4,
    marginBottom: '0.7rem',
    display: 'flex',
    alignItems: 'center',
  },
  textSearchContent: {
    marginLeft: '0.3rem',
    fontWeight: 'bold',
    color: theme.palette.custom.gray4,
  },
  paperAnchorRight: { width: '40.6rem' },
  button: {
    textTransform: 'none',
    color: theme.palette.custom.blue2,
    fontWeight: 'bold',
  },
});

const ReportsView = ({ classes }) => {
  const [textSearch, setTextSearch] = useState('');
  const [searchCriteria, setSearchCriteria] = useState({});

  return (
    <Layout>
      <FilterDrawer onChange={setSearchCriteria} />
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.textSearchContainer}>
          <TextSearch
            value={textSearch}
            onChange={setTextSearch}
            placeholder="Type to search by Report name"
          />
        </Grid>
        {!isEmpty(textSearch) && (
          <Grid item xs={12} className={classes.infoContainer}>
            <i>Showing results for</i>
            <Typography className={classes.textSearchContent}>{textSearch}</Typography>
          </Grid>
        )}
        <Grid item xs={12} className={classes.reportsContainer}>
          <Reports textSearch={textSearch} searchCriteria={searchCriteria} />
        </Grid>
      </Grid>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
});

export default connect(mapStateToProps)(withStyles(styles)(ReportsView));
