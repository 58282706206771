import React, { useEffect, useState } from 'react';

import { API } from 'aws-amplify';
import { connect } from 'react-redux';

import get from 'lodash/get';
import map from 'lodash/map';

import DropDownField from '../../DropDownField';
import { setSubscriptionsByOrganisation } from '../../../../state/admin/actions';

const OrganisationsField = ({
  jwtToken,
  //organisationId,
  isDisabled,
  doSetSubscriptionsByOrganisation,
  setError,
  ...rest
}) => {
  const [organisations, setOrganisations] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchOrganisations = async () => {
      setLoader(true);

      try {
        const params = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };

        const results = await API.get('users', `/organisations`, params);

        setOrganisations(results);
        doSetSubscriptionsByOrganisation(results);
        setLoader(false);
      } catch (e) {
        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );
        setError(message);
        setLoader(false);
      }
    };

    fetchOrganisations();
  }, []);

  const options = map(organisations, ({ _id, name }) => ({ value: _id, label: name }));

  return (
    <DropDownField
      suggestions={options}
      {...rest}
      placeholder={loader ? 'Loading...' : 'Type or select...'}
      isDisabled={loader || isDisabled}
    />
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
});

const mapDispatchToProps = (dispatch) => ({
  doSetSubscriptionsByOrganisation: (organisations) =>
    dispatch(setSubscriptionsByOrganisation(organisations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationsField);
