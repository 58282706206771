import React from 'react';
import cn from 'classnames';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  listItem: {
    paddingTop: '1.1rem',
    height: '4rem',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listIcon: {
    margin: 0,
    justifyContent: 'center',
  },
  icon: {
    width: '1.2rem',
    height: '1.2rem',
    '& path': {
      fill: theme.palette.custom.white,
    },
  },
  listText: {
    padding: 0,
    marginTop: '0.5rem',
    '& span': {
      width: '3.8rem',
      height: '1.5rem',
      fontSize: '0.55rem',
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.custom.white,
      textTransform: 'uppercase',
    },
  },
});

const MenuItem = ({ classes, className = '', name, Icon, onClick }) => (
  <ListItem key={name} className={cn(classes.listItem, className)} disableGutters onClick={onClick}>
    <ListItemIcon className={classes.listIcon}>
      <Icon className={classes.icon} />
    </ListItemIcon>
    <ListItemText primary={name} className={classes.listText} />
  </ListItem>
);

export default withStyles(styles)(MenuItem);
