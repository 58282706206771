import React from 'react';

import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';

import { Container, ChartWrapper, SampleWrapper } from '../Container';
import TableGrid from './TableGrid';
import Sample from '../Sample';
import { LOW_SAMPLE_SIZE } from '../../../utils/hasLowSampleSize';

const TableChart = ({ samples, data, series, id, configuration, formatter }) => {
  const chartThreshold = get(find(configuration, { key: 'threshold' }), 'value', LOW_SAMPLE_SIZE);

  return (
    <Container>
      <ChartWrapper>
        <TableGrid rows={data} series={series} id={id} formatter={formatter} />
      </ChartWrapper>
      <SampleWrapper>
        {map(samples, (sample, index) => (
          <Sample key={`sample-size-${index}`} {...sample} chartThreshold={chartThreshold} />
        ))}
      </SampleWrapper>
    </Container>
  );
};

export default TableChart;
