import { withStyles } from '@material-ui/styles';
import React from 'react';

const styles = () => ({
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginBottom: '-2rem',
    marginTop: '2rem',
  },
  image: {
    maxHeight: 300,
    width: 'calc(100% - 4rem)',
    borderRadius: 20,
    objectFit: 'cover',
    objectPosition: '50% 50%',
  },
});

const AuthorImage = ({ url, classes }) => {
  return (
    <div className={classes.container}>
      <img src={url} className={classes.image} />
    </div>
  );
};

export default withStyles(styles)(AuthorImage);
