import XLSX from 'sheetjs-style';

import map from 'lodash/map';

const createWorkbook = ({ title, sheets }) => {
  // Create a new book
  const wb = XLSX.utils.book_new();

  map(sheets, ({ data, name }) => XLSX.utils.book_append_sheet(wb, data, name));

  return XLSX.writeFile(wb, `${title}.xlsx`);
};

export default createWorkbook;
