import { SET_DATABOARD, RESET_DATABOARD, SET_SHOW_CONTENTS, SET_VIEWS } from './types';

export const setDataboard = (board) => ({
  type: SET_DATABOARD,
  payload: board,
});

export const resetDataboard = () => ({ type: RESET_DATABOARD });

export const setShowContents = (showContents) => ({
  type: SET_SHOW_CONTENTS,
  payload: showContents,
});

export const setViews = (savedViews) => ({
  type: SET_VIEWS,
  payload: savedViews,
});
