import React from 'react';
import cn from 'classnames';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {},
  2: {
    lineHeight: 3,
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
  },
  4: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
  },
});

const Heading = ({ level, children, classes }) => (
  <Typography
    variant={`h${level}`}
    color="primary"
    className={cn(classes.root, { [get(classes, level, '')]: !isEmpty(get(classes, level, '')) })}
  >
    {children}
  </Typography>
);

export default withStyles(styles)(Heading);
