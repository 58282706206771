import { useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import get from 'lodash/get';

/**
 * Hook to calculate the time the page has been hidden
 * https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 */
const usePageHiddenDetection = () => {
  const idleTimerStart = useRef();
  const [delta, setDelta] = useState(0);

  const handleVisibilityChange = useCallback(() => {
    if (get(document, ['visibilityState', 'hidden'], get(document, 'hidden', false))) {
      idleTimerStart.current = moment();
    } else {
      setDelta(moment().diff(idleTimerStart.current));
      idleTimerStart.current = null;
    }
  }, [idleTimerStart, delta]);

  useEffect(() => {
    let visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      visibilityChange = 'webkitvisibilitychange';
    }

    document.addEventListener(visibilityChange, handleVisibilityChange);

    return () => document.removeEventListener(visibilityChange, handleVisibilityChange);
  }, []);

  return {
    idleTime: delta,
    clearIdleTime: () => setDelta(0),
  };
};

export default usePageHiddenDetection;
