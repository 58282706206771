import React from 'react';

import { connect } from 'react-redux';

import get from 'lodash/get';

import { withStyles } from '@material-ui/core/styles';

import { ReactComponent as EnvelopeIcon } from './assets/envelope.svg';
import hasAccess from './utils/hasAccess';

const styles = (theme) => ({
  root: {
    width: '32rem',
    height: '4rem',
    border: 'solid 1px #ececf2',
    boxShadow: '-4.7px 5.2px 21px 0 rgba(188, 198, 210, 0.38)',
    borderRadius: 10,
    position: 'absolute',
    right: '2.1rem',
    top: '1rem',
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem',
  },
  access: {
    marginLeft: '1.4rem',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: theme.palette.custom.blue2,
  },
  cta: {
    marginLeft: '1.5rem',
    width: '9rem',
    height: '2.5rem',
    borderRadius: 36,
    backgroundColor: theme.palette.custom.blue2,
    textTransform: 'capitalize',
    textDecoration: 'none',
    color: theme.palette.custom.white,
    fontSize: '1rem',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const mailto = 'sales@slashdata.co';
const cc = 'technology@slashdata.co';

const ContactUs = ({ classes, hide, title, email, firstName, lastName, roles }) => {
  if (hide) return null;
  const pathURL = window.location.pathname;
  const isReport = pathURL.includes('/reports');

  return (
    <div className={classes.root}>
      <EnvelopeIcon />
      <span>
        <span className={classes.access}>Get full access </span>
        to the {isReport ? 'report' : 'dashboard'}
      </span>
      <a
        href={`mailto:${mailto}?cc=${cc}&subject=${encodeURIComponent(
          `${isReport ? 'Report' : 'Dashboard'} Access Request: ${title}`,
        )}&body=${encodeURIComponent(
          `${
            isReport ? 'Report' : 'Dashboard'
          }: ${title}\nEmail: ${email}\nName: ${firstName}\nLast name: ${lastName}\nRole: ${roles}`,
        )}`}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.cta}
      >
        Contact Us
      </a>
    </div>
  );
};

const mapStateToProps = (state) => ({
  email: get(state, ['user', 'email']),
  firstName: get(state, ['user', 'firstName']),
  lastName: get(state, ['user', 'lastName']),
  roles: get(state, ['user', 'roles']),
  title: get(state, ['databoard', 'title']),
  hide: hasAccess(state),
});

export default connect(mapStateToProps)(withStyles(styles)(ContactUs));
