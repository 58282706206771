import React, { useState, useRef } from 'react';

import { Field } from 'redux-form';
import { connect } from 'react-redux';

import map from 'lodash/map';
import get from 'lodash/get';

import { withStyles } from '@material-ui/core/styles';

import AccordionFilter from '../../../AccordionFilter';
import { getGroupFilters } from '../../../../../state/filtersIndex/selectors';
import { getFilterGroupName } from '../../../../../state/databoard/selectors';
import { find } from 'lodash';

const styles = (theme) => ({
  category: {
    paddingLeft: '0.3rem',
  },
  dot: {
    width: '0.5rem',
    height: '0.5rem',
    backgroundColor: theme.palette.custom.blue2,
    borderRadius: 20,
    marginLeft: '0.2rem',
  },
});

const InvisibleFilter = (props) => <AccordionFilter {...props} hide />;

const NewFilter = (props) => <AccordionFilter {...props} newFilter />;

// TODO: Write tests. Jest needs config for testing functions returning components
const getFilterComponent = (visible, newFilter) => {
  if (visible && !newFilter) return AccordionFilter;
  if (visible && newFilter) return NewFilter;
  return InvisibleFilter;
};

const CategoryFilters = ({ classes, name, filters, scrollRef, notifications, databoardId }) => {
  const [activeFilter, setActiveFilter] = useState(null);
  const fieldRef = useRef(null);

  const changeActiveFilter = (filter) => {
    if (activeFilter === filter) {
      setActiveFilter(null);
    } else {
      setActiveFilter(filter);
      // NOTE:
      // Scrolling to a particular point is not possible.
      // Each time there is an expansion, simultaneously a collapsion is in progress
      // and we can't calculate the exact point. If scroll to zero doesn't work
      // when we've to run this action once the panel transition ended.
      scrollRef.current.scrollTop(0);
    }
  };

  const newFilter = (filter) => {
    const filterName = get(filter, 'name', 'DEFINE FILTER LABEL');
    const dashboardNotification = find(notifications, (notification) => {
      return notification.resourceId === databoardId && notification.type === 'NEW_DATA';
    });
    const changedFilters = get(dashboardNotification, 'changedFilters', []);
    if (changedFilters.includes(filterName)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className={classes.category}>
        <b>{`${name} filters`}</b>
      </div>
      {map(filters, ({ visible, _id, ...filter }) => {
        return (
          <div ref={fieldRef} key={`filter-${_id}`}>
            <Field
              _id={_id}
              {...filter}
              name={_id}
              label={get(filter, 'text', get(filter, 'name', 'DEFINE FILTER LABEL'))}
              component={getFilterComponent(visible, newFilter(filter))}
              onClick={changeActiveFilter}
              expanded={activeFilter === _id}
            />
          </div>
        );
      })}
    </>
  );
};

const mapStateToProps = (state, { groupId }) => ({
  filters: getGroupFilters(state, groupId),
  name: getFilterGroupName(state, groupId),
  notifications: get(state, ['user', 'notifications'], []),
  databoardId: get(state, ['databoard', 'databoardId'], ''),
});

export default connect(mapStateToProps)(withStyles(styles)(CategoryFilters));
