import React, { useCallback, useState } from 'react';

import { isEmpty, map } from 'lodash';
import { IconButton, makeStyles } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

type ActionType = 'EXPORT';

export type Action = {
  type: ActionType;
  action: () => any;
};

export interface ActionsProps {
  actions?: Action[];
}

const ExportAction: React.FC<Action> = ({ action }) => {
  const [loader, setLoader] = useState(false);

  const handleClick = useCallback(async () => {
    setLoader(true);

    try {
      await action();
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  }, [action]);

  return (
    <IconButton aria-label="download" size="small" onClick={handleClick} disabled={loader}>
      <ArrowDownwardIcon fontSize="inherit" />
    </IconButton>
  );
};

const Actions: React.FC<ActionsProps> = ({ actions }) => {
  const classes = useStyles();

  if (isEmpty(actions)) return null;

  return (
    <div className={classes.root}>
      {map(actions, ({ type, action }) => {
        if (type === 'EXPORT') {
          return <ExportAction key={type} type={type} action={action} />;
        }

        return null;
      })}
    </div>
  );
};

export default Actions;
