import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import { ReactComponent as LoadFiltersIcon } from './assets/add.svg';
import { ReactComponent as UpdateFiltersIcon } from './assets/update.svg';
import { ReactComponent as DeleteFiltersIcon } from './assets/delete.svg';
import AddIcon from '@material-ui/icons/Add';
import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { isEmpty, map } from 'lodash';

const styles = (theme) => ({
  button: {
    '&:hover .download-path': {
      fill: theme.palette.custom.blue2,
    },
    '& svg': {
      width: '1.6rem',
      height: '1.6rem',
    },
  },
  smallButton: {
    '&:hover .download-path': {
      fill: theme.palette.custom.blue2,
    },
    '& svg': {
      width: '0.8rem',
      height: '0.8rem',
    },
  },
  tooltip: {
    backgroundColor: theme.palette.custom.white,
    boxShadow: '-2px 3px 10px 0 rgba(0, 0, 0, 0.16)',
    border: 'solid 1px #e7e7ef',
    borderRadius: 10,
    padding: '1.1rem',
    color: theme.palette.custom.black,
    fontSize: '0.8rem',
  },
  tooltip2: {
    backgroundColor: theme.palette.custom.white,
    boxShadow: '-2px 3px 10px 0 rgba(0, 0, 0, 0.16)',
    border: `solid 1px ${theme.palette.custom.blue2}`,
    borderRadius: 10,
    padding: '1.1rem',
    color: theme.palette.custom.blue2,
    fontSize: '0.8rem',
  },
  paper: {
    width: '24.4rem',
    padding: '0.6rem',
  },
  title: {
    padding: '1.3rem 1.3rem 0px 1.3rem',
    fontSize: '1.1rem',
    fontWeight: 800,
    color: theme.palette.custom.gray4,
  },
  viewItem: {
    padding: '0.2rem 1.3rem',
    height: '3.7rem',
  },
  text: {
    height: '3.7rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
  },
  viewItemsContainer: {
    paddingBottom: '0.8rem',
    maxHeight: '24.4rem',
    overflow: 'scroll',
  },
  saveButton: {
    borderTop: `1px solid ${theme.palette.custom.gray3}`,
    color: theme.palette.custom.blue2,
    fontSize: '1.3rem',
    fontWeight: 800,
    cursor: 'pointer',
    padding: '1.3rem 1.3rem 0.7rem 1.3rem',
    display: 'flex',
    alignItems: 'center',
  },
  popover: {
    zIndex: '1 !important',
  },
  noViews: {
    padding: '0.2rem 1.3rem',
  },
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: '0.8rem',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(1.1rem, 1rem) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(1.1rem, -0.5rem) scale(0.75) !important',
    },
  },
});

const LoadFiltersButton = ({
  classes,
  loading,
  savedViews,
  onDeleteFilter,
  onUpdateFilter,
  onCreateFilter,
  onRenameFilter,
  onLoadView,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewHovered, setViewHovered] = useState(null);
  const [addingView, setAddingView] = useState(false);
  const [name, setName] = useState('');
  const [clickTimeout, setClickTimeout] = useState(null);
  const [editView, setEditView] = useState(null);

  const submitValue = (event) => {
    if (event.keyCode === 13) {
      onCreateFilter(name);
      setName('');
      setAddingView(false);
    }
  };

  const editValue = (event) => {
    if (event.keyCode === 13) {
      onRenameFilter(editView, name);
      setName('');
      setEditView(null);
      setAddingView(false);
    }
  };

  const handleClick = (view) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      setEditView(view);
      setName(view.name);
    } else {
      const timeout = setTimeout(() => {
        setClickTimeout(null);
        onLoadView(view.filters);
      }, 250);
      setClickTimeout(timeout);
    }
  };

  return (
    <>
      <Tooltip title="Save/Load Filters" classes={{ tooltip: classes.tooltip }} placement="right">
        <IconButton
          className={classes.button}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <LoadFiltersIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id="render-props-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setName('');
          setAddingView(false);
          setEditView(null);
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ root: classes.popover, paper: classes.paper }}
      >
        <div>
          <Typography className={classes.title}>SAVED FILTERING</Typography>
          {!isEmpty(savedViews) && !loading && (
            <List className={classes.viewItemsContainer}>
              {map(savedViews, (view) =>
                editView && view._id === editView._id ? (
                  <ListItem>
                    <TextField
                      classes={{ root: classes.textField }}
                      label="View Name"
                      variant="outlined"
                      onChange={(event) => setName(event.target.value)}
                      onKeyDown={editValue}
                      value={name}
                      key={view._id}
                    />
                  </ListItem>
                ) : (
                  <ListItem
                    button
                    className={classes.viewItem}
                    key={view._id}
                    onMouseEnter={() => setViewHovered(view._id)}
                    onMouseLeave={() => setViewHovered(null)}
                  >
                    <ListItemText
                      onClick={() => handleClick(view)}
                      disableTypography
                      primary={<Typography className={classes.text}>{view.name}</Typography>}
                    />
                    {view._id === viewHovered && (
                      <div>
                        <Tooltip
                          title="Update with current filters applied"
                          classes={{ tooltip: classes.tooltip2 }}
                          placement="right"
                        >
                          <IconButton
                            className={classes.smallButton}
                            onClick={() => {
                              onUpdateFilter(view);
                            }}
                          >
                            <UpdateFiltersIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Delete saved filters selection"
                          classes={{ tooltip: classes.tooltip2 }}
                          placement="right"
                        >
                          <IconButton
                            className={classes.smallButton}
                            onClick={() => {
                              onDeleteFilter(view._id);
                            }}
                          >
                            <DeleteFiltersIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </ListItem>
                ),
              )}
            </List>
          )}
          {isEmpty(savedViews) && !loading && !addingView && (
            <Typography className={classes.noViews}>No saved views</Typography>
          )}
          {addingView && (
            <ListItem>
              <TextField
                classes={{ root: classes.textField }}
                label="View Name"
                variant="outlined"
                onChange={(event) => setName(event.target.value)}
                onKeyDown={submitValue}
              />
            </ListItem>
          )}
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '0.8rem' }}>
              <CircularProgress />
            </div>
          )}
          <Typography className={classes.saveButton} onClick={() => setAddingView(true)}>
            <AddIcon style={{ fontWeight: 800 }} />
            Save Current Filters
          </Typography>
        </div>
      </Popover>
    </>
  );
};

export default withStyles(styles)(LoadFiltersButton);
