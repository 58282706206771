import React from 'react';
import cn from 'classnames';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import Footer from '../../../../components/Footer';
import Sidebar from './components/Sidebar';

const drawerWidth = '3.75rem';
const innerDrawerWidth = '21.5rem';

const styles = (theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.down('xl')]: {
      paddingTop: theme.spacing(7),
      paddingRight: theme.spacing(12),
      paddingBottom: theme.spacing(3),
      paddingLeft: 0,
    },
    [theme.breakpoints.down('lg')]: {
      paddingTop: theme.spacing(7),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: 0,
    },
    marginBottom: 8,
  },
  contentShift: {
    paddingLeft: '5rem',
  },
  drawer: {
    width: drawerWidth,
  },
  innerDrawer: {
    width: innerDrawerWidth,
    left: drawerWidth,
  },
});

const Layout = ({ classes, children, onChange }) => {
  return (
    <div className={classes.root}>
      <Sidebar
        className={classes.drawer}
        InnerDrawerProps={{
          className: classes.innerDrawer,
          onChange,
        }}
      />
      <Grid
        container
        className={cn(classes.content, {
          [classes.contentShift]: true,
          'animated fadeIn': true,
        })}
      >
        {children}
      </Grid>
      <Footer />
    </div>
  );
};

export default withStyles(styles)(Layout);
