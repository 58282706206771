import React from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '19.5rem',
    marginBottom: '4.1rem',
  },
});

const Container = ({ classes, children, error }) => {
  const message = get(error, 'message');
  return (
    <div className={classes.root}>
      {children}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!isEmpty(message)}
        // onClose={handleClose}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          {message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default withStyles(styles)(Container);
