import React, { useState } from 'react';

import * as Yup from 'yup';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import CircularProgress from '@material-ui/core/CircularProgress';

import Container from '../../components/Container';
import FormTitle from '../../components/FormTitle';
import TextField from '../../components/TextField';
import SubmitButton from '../../components/SubmitButton';
import TextButton from '../../components/TextButton';
import Stepper from '../../components/Stepper';
import PasswordField from '../../components/PasswordField';

import validator from '../../validator';

import { SUCCESS_SCREEN } from '../../types';

import forgotPasswordSubmit from '../../actions/forgotPasswordSubmit';
import forgotPassword from '../../actions/forgotPassword';

const ResetPassword = ({ handleSubmit, email, submitting, setScreen, setPassword }) => {
  const [error, setError] = useState(null);
  const [sendingConfirmation, setSendingConfirmation] = useState(false);

  const handleResetPassword = async ({ code, password }) => {
    try {
      await forgotPasswordSubmit(email, code, password);
      setPassword(password);
      setScreen(SUCCESS_SCREEN);
    } catch (e) {
      setError(e);
    }
  };

  const handleSendConfirmationCode = async () => {
    setSendingConfirmation(true);
    try {
      await forgotPassword(email);
    } catch (e) {
      setError(e);
    } finally {
      setSendingConfirmation(false);
    }
  };

  return (
    <Container error={error}>
      <Stepper activeStep={1} steps={[1, 2]} />
      <form onSubmit={handleSubmit(handleResetPassword)}>
        <FormTitle>Reset Password</FormTitle>
        <Field
          autoComplete="one-time-code"
          name="code"
          label="Verification code"
          component={TextField}
        />
        <Field
          autoComplete="new-password"
          name="password"
          label="New password"
          component={PasswordField}
        />
        <TextButton
          text="Resend code?"
          label="Send"
          onClick={handleSendConfirmationCode}
          disabled={submitting}
          loading={sendingConfirmation}
        />
        {!submitting && <SubmitButton label="Done" />}
        {submitting && <CircularProgress />}
      </form>
    </Container>
  );
};

const schema = Yup.object().shape({
  password: Yup.string().trim().required(),
});

export default compose(
  // connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'resetPasswordForm',
    asyncValidate: validator(schema),
  }),
)(ResetPassword);
