import map from 'lodash/map';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/orderBy';
import findIndex from 'lodash/findIndex';
import reverse from 'lodash/reverse';
import head from 'lodash/head';
import filter from 'lodash/filter';
import concat from 'lodash/concat';
import indexOf from 'lodash/indexOf';
import omit from 'lodash/omit';

import getSourceData from './getSourceData';

export const getDataPerSource = ({ data, sources, table }) =>
  reduce(
    data,
    (acc, item, index) => {
      return {
        ...acc,
        [sources[index]]: getSourceData({
          source: sources[index],
          data: item,
          table,
        }),
      };
    },
    {},
  );

export const mergeSourcesData = ({ dataPerSource, sources }) => {
  let initial = dataPerSource[head(sources)];
  const appendByNameFunc = ({ name, ...rest }) => {
    const itemIndex = findIndex(initial, { name });

    return {
      ...initial[itemIndex],
      ...rest,
      name,
    };
  };

  let index = 1;
  while (index < sources.length) {
    const next = dataPerSource[sources[index]];
    initial = map(next, appendByNameFunc);
    index += 1;
  }

  return initial;
};

export const sortMultiSourceData = ({ data }) => {
  const sortedResults = reverse(
    sortBy(data, (valuesToSortBy) =>
      reduce(omit(valuesToSortBy, ['name', 'text', 'hint']), (result, value) => result + value, 0),
    ),
  );

  return sortedResults;
};

export const rearrangeExcludedItems = ({ data, sorting }) => {
  const exclude = get(sorting, 'exclude', []);

  const sortedData = filter(data, (item) => indexOf(exclude, item.name) === -1);
  const excludedData = filter(data, (item) => indexOf(exclude, item.name) !== -1);

  return concat(sortedData, excludedData);
};

export const omitZeroValues = ({ data }) =>
  filter(data, (values) =>
    reduce(omit(values, ['name', 'text', 'hint']), (sum, value) => sum + value, 0),
  );

/**
 * The multi source data transformer is used from distributions per source
 * The charts using it show unmerged data for more than one source
 */
const getMultiSourceData = ({ sources, data, table, sorting, omitZeros }) => {
  if (isEmpty(sources) || isEmpty(data)) return [];

  const dataPerSource = getDataPerSource({ data, sources, table });
  const mergedData = mergeSourcesData({ dataPerSource, sources });

  if (isEmpty(sorting)) return mergedData;

  const sortedResults = sortMultiSourceData({ data: mergedData });
  const rearrangedData = rearrangeExcludedItems({ data: sortedResults, sorting });

  if (!omitZeros) return rearrangedData;

  const nonZeroData = omitZeroValues({ data: rearrangedData });

  return nonZeroData;
};

export default getMultiSourceData;
