import React, { useEffect, useMemo } from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { map, join, get, find } from 'lodash';
import {
  SECTOR_APP,
  SECTOR_ARVR,
  SECTOR_CLD,
  SECTOR_CORE,
  SECTOR_DESK,
  SECTOR_DEVOPS,
  SECTOR_EMB,
  SECTOR_GAM,
  SECTOR_IOT,
  SECTOR_ML,
  SECTOR_MOB,
  SECTOR_WEB,
  TYPE_BRIEFINGS,
  TYPE_PREVIEW,
} from '../../../data';
import cn from 'classnames';
import BookmarkButton from './BookmarkButton';
import { navigate } from '@reach/router';
import getQuarter from '../../../../../utils/getQuarter';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import getFilteredReports from '../utils/getFilteredReports';
import { connect } from 'react-redux';
import sortReports from '../utils/sortReports';
import { useHits, useSearchBox } from 'react-instantsearch';
import { setInitialReportsSearch } from '../../../../../state/reports/actions';

const styles = (theme) => ({
  rowCell: {
    cursor: 'pointer',
    fontSize: '1.1rem',
    padding: '0.6rem 0',
    color: theme.palette.custom.black,
  },
  largeCell: { width: '50%', padding: '10px' },
  mediumCell: { width: '16.6%' },
  preview: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.custom.blue2,
    padding: '0.3rem 0.8rem',
    marginLeft: '-0.8rem',
    borderRadius: 7,
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold',
  },
  title: {
    margin: 0,
  },
  description: {
    margin: 0,
    color: theme.palette.custom.gray5,
    fontSize: '0.85rem',
    fontWeight: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    lineHeight: '1rem',
    maxHeight: '2rem',
  },
  sentence: {
    margin: 0,
    marginTop: '5px',
    fontSize: '12px',
    fontWeight: 'normal',
    color: theme.palette.custom.gray5,
  },
});

const sectors = [
  SECTOR_DESK,
  SECTOR_MOB,
  SECTOR_WEB,
  SECTOR_CLD,
  SECTOR_GAM,
  SECTOR_IOT,
  SECTOR_ML,
  SECTOR_ARVR,
  SECTOR_EMB,
  SECTOR_APP,
  SECTOR_CORE,
  SECTOR_DEVOPS,
];

const CATEGORY_BRIEFING = 'briefing';
const CATEGORY_REPORT   = 'report';

const TableBodyComponent = ({
  classes,
  allReports,
  allBriefings,
  searchCriteria,
  orderBy,
  order,
  textSearch,
  setTextSearch,
  initialReportsSearch,
  setInitialReportsSearch,
  subscriptions,
  bookmarks,
}) => {
  const { refine } = useSearchBox();
  const { hits } = useHits();

  useEffect(() => {
    if (initialReportsSearch) setTextSearch(initialReportsSearch);

    setInitialReportsSearch('');
  }, []);

  useEffect(() => {
    refine(textSearch);
  }, [textSearch, refine]);

  const filteredReports = useMemo(() => {
    let reports = getFilteredReports(
      allReports,
      subscriptions,
      searchCriteria,
      '', //textSearch
      bookmarks,
    );

    if (textSearch) {
      // Use the order provided by the search results
      const matches = hits.map((hit) => {
        const report = find(reports, { documentId: hit.resourceId });

        return {
          ...report,
          title: hit?._highlightResult?.title?.value || report.title,
          abstract: hit?._highlightResult?.abstract?.value || report?.abstract,
          sentence: hit?._highlightResult?.sentence?.value,
        };
      });

      reports = matches;
    }

    const getResearchType = (researchType, reportPDF) => {
      if (reportPDF) {
        if (researchType === 'SYNDICATED') return 'Syndicated Research';
        if (researchType === 'CUSTOM') return 'Custom Research';
        if (researchType === 'FREE') return 'Free Report';
        return 'Full';
      }

      return 'PREVIEW';
    };

    const transformedReports = reports.map((report) => ({
      ...report,
      areas: join(
        map(report.areas, (area) => get(find(sectors, { TYPE: area }), 'LABEL', area)),
        ', ',
      ),
      type: getResearchType(report.type, report.report),
    }));

    return sortReports(transformedReports, subscriptions, orderBy, order).map((report) => ({
      ...report,
    }));
  }, [allReports, searchCriteria, orderBy, order, textSearch, subscriptions, bookmarks, hits]);

  const filteredBriefings = useMemo(() => {
    let briefings = getFilteredReports(
      allBriefings,
      subscriptions,
      searchCriteria,
      textSearch,
      bookmarks,
    );

    if (textSearch) {
      // Use the order provided by the search results
      const matches = hits.map((hit) => {
        const briefing = find(briefings, { documentId: hit.resourceId });

        return {
          ...briefing,
          title: hit?._highlightResult?.title?.value || briefing.title,
          abstract: hit?._highlightResult?.abstract?.value || briefing?.abstract,
          sentence: hit?._highlightResult?.sentence?.value,
        };
      });

      briefings = matches;
    }

    return sortReports(briefings, subscriptions, orderBy, order);
  }, [allBriefings, searchCriteria, orderBy, order, textSearch, subscriptions, bookmarks, hits]);

  const openResource = (id, name, category) => {
    setInitialReportsSearch(textSearch);

    switch (category) {
      case CATEGORY_BRIEFING:
        return navigate(`/briefings/${id}`);
      
      case CATEGORY_REPORT:
        return navigate(`/reports/${id}`);
      
      default:
        return;
    }
  };

  return (
    <TableBody>
      {map(
        searchCriteria.category === TYPE_BRIEFINGS.TYPE ? filteredBriefings : filteredReports,
        (
          { _id, type, title, abstract, report, areas, published, period, recording, sentence },
          index,
        ) => {
          const titleCellClasses = cn(classes.rowCell, classes.bold, classes.largeCell);
          const rowCellClasses = cn(classes.rowCell, classes.mediumCell);

          const category = recording ? CATEGORY_BRIEFING : CATEGORY_REPORT;

          return (
            <TableRow key={`item-${index}`}>
              {searchCriteria.category !== TYPE_BRIEFINGS.TYPE && (
                <TableCell padding="none" size="medium">
                  <BookmarkButton databoardId={_id} />
                </TableCell>
              )}

              <TableCell
                onClick={() => openResource(_id, title, category)}
                className={titleCellClasses}
                padding="none"
              >
                <p className={classes.title} dangerouslySetInnerHTML={{ __html: title }} />
                <p className={classes.description} dangerouslySetInnerHTML={{ __html: abstract}} />
                <p className={classes.sentence} dangerouslySetInnerHTML={{ __html: sentence }} />
              </TableCell>

              {searchCriteria.category !== TYPE_BRIEFINGS.TYPE && (
                <TableCell
                  onClick={() => openResource(_id, title, category)}
                  className={rowCellClasses}
                  padding="none"
                >
                  {areas}
                </TableCell>
              )}

              {searchCriteria.category !== TYPE_BRIEFINGS.TYPE && (
                <TableCell
                  onClick={() => openResource(_id, title, category)}
                  className={rowCellClasses}
                  padding="none"
                >
                  {getQuarter(period)}
                </TableCell>
              )}

              <TableCell
                onClick={() => openResource(_id, title, category)}
                className={cn(rowCellClasses)}
                padding="none"
              >
                {moment(published).format('MMMM YYYY')}
              </TableCell>

              {searchCriteria.category !== TYPE_BRIEFINGS.TYPE && (
                <TableCell
                  onClick={() => openResource(_id, title, category)}
                  className={cn(rowCellClasses)}
                  padding="none"
                >
                  <span
                    className={cn({
                      [classes.preview]: type === TYPE_PREVIEW.TYPE,
                    })}
                  >
                    {type}
                  </span>
                </TableCell>
              )}
            </TableRow>
          );
        },
      )}
    </TableBody>
  );
};

const mapStateToProps = (state) => ({
  initialReportsSearch: get(state, ['reports', 'initialReportsSearch'], ''),
  subscriptions: get(state, ['user', 'subscriptions'], []),
  bookmarks: get(state, ['user', 'bookmarks'], []),
});

const mapDispatchToProps = (dispatch) => ({
  setInitialReportsSearch: (initialSearchKey) =>
    dispatch(setInitialReportsSearch(initialSearchKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TableBodyComponent));
