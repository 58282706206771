import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import head from 'lodash/head';

import { concat, isEmpty, keys, remove, set } from 'lodash';
import {
  TYPE_BAR_CHART,
  TYPE_BAR_CHART_MULTI,
  TYPE_BAR_CHART_BENCHMARK,
  TYPE_BAR_CHART_STACKED,
  TYPE_BAR_CHART_STACKED_MULTI,
  TYPE_BAR_CHART_SCORE,
  TYPE_COLUMN_CHART,
  TYPE_COLUMN_CHART_MULTI,
  TYPE_COLUMN_CHART_BENCHMARK,
  TYPE_COLUMN_CHART_STACKED,
  TYPE_COLUMN_CHART_STACKED_LOCAL,
  TYPE_PIE_CHART,
  TYPE_DONUT_CHART,
  TYPE_TABLE_CHART,
  TYPE_HEATMAP_CHART,
  TYPE_SCATTER_CHART,
  TYPE_LINE_CHART,
  TYPE_BAR_CHART_SIDE_BY_SIDE,
  TYPE_COLUMN_CHART_LINE,
} from '../../../components/Graphs/GraphsRenderer/types';

import getHeaders from './table/getHeaders';
import getRows from './table/getRows';
import getNullColumnIndexes from './table/getNullColumnIndexes';
import getNullRowIndexes from './table/getNullRowIndexes';
import removeColumn from './table/removeColumn';
import removeRow from './table/removeRow';

import getSourceData from '../../data/getSourceData';
import getMultiSourceData from '../../data/getMultiSourceData';
import getStackedDataObject from '../../data/getStackedData';
import getScoreData from '../../data/getScoreData';
import formatters, { defaultFormatter } from '../../data/formatters';

import getSourceSample from '../../sample/getSourceSample';
import getMultiSourceSample from '../../sample/getMultiSourceSample';
import getMergedSurveySamples from '../../sample/getMergedSurveySamples';
import getLocalSamples from '../../sample/getLocalSamples';
import getBenchmarkedData from '../../data/getBenchmarkedData';
import getBenchmarkedSamples from '../../sample/getBenchmarkedSamples';

export const applySourceLabels = ({ headers, sourceLabels }) => {
  if (isEmpty(sourceLabels)) return headers;

  return map(headers, (header) => {
    if (!header) return header;

    let label = find(sourceLabels, { name: header });
    // If the label is empty then we search by ID for version 2 dashboards
    if (!label) {
      label = find(sourceLabels, { id: header });
    }

    return get(label, 'text', header);
  });
};

// NOTE: When this chart type is used in TAM, it shows aggregated data for more than one source
export const getUnstackedData = ({
  data,
  dataTable,
  sampleTable,
  sorting,
  sourceLabels,
  formatter,
}) => {
  // TODO: Extend this functionality to show aggregated data or single source
  const source = head(get(data, 'sources', []));
  const valueFormatter = get(formatters, formatter, defaultFormatter);
  let headers = ['', source];

  const sampleObject = getSourceSample({
    data: get(data, 'data', []),
    table: sampleTable,
    source,
  });
  const sampleRowArray = ['', get(sampleObject, 'value') ? `n=${get(sampleObject, 'value')}` : ''];

  const rowsObject = getSourceData({
    source,
    data: get(data, 'data', []),
    table: dataTable,
    sorting,
    omitZeros: true,
  });

  const rowsArray = map(rowsObject, (row) => [get(row, 'text'), valueFormatter(get(row, source))]);

  headers = applySourceLabels({ headers, sourceLabels });

  return [headers, sampleRowArray, ...rowsArray];
};

export const getUnstackedMultiSourceData = ({
  data,
  dataTable,
  sampleTable,
  sorting,
  sourceLabels,
  formatter,
}) => {
  // The order of sources should remain as is because source data are stored in the same index
  const sources = get(data, 'sources', []);
  const sortedSources = Array.from(sources).sort();
  const valueFormatter = get(formatters, formatter, defaultFormatter);
  let headers = ['', ...sortedSources];

  const sampleObjectsArray = getMultiSourceSample({
    data: get(data, 'data', []),
    table: sampleTable,
    sources,
  });
  const sampleRowArray = [
    '',
    ...map(sortedSources, (source) => {
      const sourceSample = find(sampleObjectsArray, { name: source });

      return get(sourceSample, 'value') ? `n=${get(sourceSample, 'value')}` : '';
    }),
  ];

  const rowsObject = getMultiSourceData({
    sources,
    data: get(data, 'data'),
    table: dataTable,
    sorting,
    omitZeros: true,
  });

  const rowsArray = map(rowsObject, (row) => [
    get(row, 'text'),
    ...map(sortedSources, (source) => valueFormatter(get(row, source))),
  ]);

  headers = applySourceLabels({ headers, sourceLabels });

  return [headers, sampleRowArray, ...rowsArray];
};

export const getBenchmarkedDataToExport = ({
  data,
  dataTable,
  sampleTable,
  sorting,
  sourceLabels,
  formatter,
}) => {
  // The order of sources should remain as is because source data are stored in the same index
  const sources = get(data, 'sources', []);
  const valueFormatter = get(formatters, formatter, defaultFormatter);
  let headers = ['', ...sources];

  const sampleObjectsArray = getBenchmarkedSamples({
    data: get(data, 'data', []),
    table: sampleTable,
    sources,
  });

  const sampleRowArray = [
    '',
    ...map(sources, (source) => {
      const sourceSample = find(sampleObjectsArray, { name: source });

      return get(sourceSample, 'value') ? `n=${get(sourceSample, 'value')}` : '';
    }),
  ];

  const rowsObject = getBenchmarkedData({
    data: get(data, 'data'),
    table: dataTable,
    sorting,
  });

  const rowsArray = map(rowsObject, (row) => [
    get(row, 'text'),
    ...map(sources, (source) => valueFormatter(get(row, source))),
  ]);

  headers = applySourceLabels({ headers, sourceLabels });

  return [headers, sampleRowArray, ...rowsArray];
};

export const getStackedData = ({ data, dataTable, sampleTable, sorting, formatter }) => {
  const rowsObject = getStackedDataObject({
    data: get(data, 'data'),
    table: dataTable,
    sorting,
    omitZeros: true,
  });
  const valueFormatter = get(formatters, formatter, defaultFormatter);
  const headers = map(rowsObject, 'text');

  const sampleObjectsArray = getLocalSamples({
    data: get(data, 'data', []),
    table: sampleTable,
  });
  const sampleRowArray = [
    '',
    ...map(headers, (header) => {
      const columnSample = find(sampleObjectsArray, { text: header });

      return get(columnSample, 'value') ? `n=${get(columnSample, 'value')}` : '';
    }),
  ];

  const rowNames = map(get(head(rowsObject), 'series'), 'text');
  const rowKeys = map(get(head(rowsObject), 'series'), 'name');
  const dataRows = map(rowKeys, (rowKey, index) => [
    get(rowNames, index),
    ...map(rowsObject, (row) => valueFormatter(get(row, rowKey))),
  ]);

  return [['', ...headers], sampleRowArray, ...dataRows];
};

export const getScoreDataToExport = ({ data, dataTable, sampleTable, sourceLabels }) => {
  const sources = get(data, 'sources', []);
  let headers = ['', head(sources)];

  const rowsObject = getScoreData({
    data: get(data, 'data', []),
    tableName: dataTable,
  });
  const rowsArray = map(rowsObject, (row) => [get(row, 'text'), get(row, 'score')]);

  const sampleObjectArray = getMergedSurveySamples({
    data: get(data, 'data', []),
    table: sampleTable,
  });
  const sampleRowArray = [
    '',
    get(head(sampleObjectArray), 'value') ? `n=${get(head(sampleObjectArray), 'value')}` : '',
  ];
  headers = applySourceLabels({ headers, sourceLabels });

  return [headers, sampleRowArray, ...rowsArray];
};

export const getTableData = ({ data, dataTable, formatter, sampleTable = '' }) => {
  const table = find(data.data, { name: dataTable });
  const headers = ['', ...getHeaders(table)];
  const rows = getRows(table, formatter);
  let cleanData = [];
  if (!isEmpty(sampleTable)) {
    const sampleObjectsArrays = get(find(data.data, { name: sampleTable }), 'values', []);
    const sampleRowsArrays = map(sampleObjectsArrays, (sampleArray) => {
      return [
        '',
        ...map(getHeaders(table), (header) => {
          const columnSample = find(get(sampleArray, 'values', []), { text: header });
          return get(columnSample, 'value') ? `n=${get(columnSample, 'value')}` : '';
        }),
      ];
    });
    cleanData = [headers, ...sampleRowsArrays, ...rows];
  } else {
    cleanData = [headers, ...rows];
  }

  const nullColumns = getNullColumnIndexes(cleanData);
  const nullRows = getNullRowIndexes(cleanData);

  forEach(nullColumns, (nullColumnIndex, index) => {
    // Each time a column is removed the index shifts
    const indexToDelete = nullColumnIndex - index;
    cleanData = removeColumn(cleanData, indexToDelete);
  });

  forEach(nullRows, (nullRowIndex, index) => {
    // Each time a column is removed the index shifts
    const indexToDelete = nullRowIndex - index;
    cleanData = removeRow(cleanData, indexToDelete);
  });

  return cleanData;
};

export const getDonutData = ({ data, dataTable, sampleTable, sorting, formatter }) => {
  const source = 'merged';
  const valueFormatter = get(formatters, formatter, defaultFormatter);
  const sampleObjectArray = getMergedSurveySamples({
    data: get(data, 'data', []),
    table: sampleTable,
  });
  const sampleRowArray = [
    '',
    get(head(sampleObjectArray), 'value') ? `n=${get(head(sampleObjectArray), 'value')}` : '',
  ];

  const rowsObject = getSourceData({
    source,
    data: get(data, 'data', []),
    table: dataTable,
    sorting,
  });

  const rowsArray = map(rowsObject, (row) => [get(row, 'text'), valueFormatter(get(row, source))]);

  return [['', 'Survey waves combined'], sampleRowArray, ...rowsArray];
};

const formatLineData = (data, dataTable) => {
  const dataTableValues = get(
    head(get(find(get(data, 'data', []), { name: dataTable }), 'values', [])),
    'values',
    [],
  );
  const tableKeys = remove(
    keys(head(dataTableValues)),
    (key) => !['name', 'hint', 'text'].includes(key),
  );
  const updatedValues = map(tableKeys, (key) => {
    const text = get(
      find(get(find(get(data, 'data', []), { name: 'labels' }), 'values', []), { name: key }),
      'text',
      key,
    );
    const hint = get(
      find(get(find(get(data, 'data', []), { name: 'labels' }), 'values', []), { name: key }),
      'hint',
      key,
    );
    const values = map(dataTableValues, (item) => {
      return {
        text: item.text,
        hint: item.hint,
        name: item.name,
        value: item[key],
      };
    });
    return {
      name: key,
      text,
      hint,
      values,
    };
  });
  const newDataTable = { name: dataTable, values: updatedValues };
  const dataTables = get(data, 'data', []);
  const removedTables = remove(dataTables, (table) => table.name !== dataTable);
  const updatedTables = concat(removedTables, [newDataTable]);
  set(data, 'data', updatedTables);
  return data;
};

const formatCombinedData = (data, extraGraphs) => {
  const combinedTable = {
    name: 'combinedTable',
    values: map(extraGraphs, (table) => {
      return head(get(find(get(data, 'data', []), { name: table }), 'values', []));
    }),
  };
  const dataTables = get(data, 'data', []);
  const updatedTables = concat(dataTables, [combinedTable]);
  set(data, 'data', updatedTables);
  return data;
};

const getData = ({
  data,
  type,
  dataTable,
  sampleTable,
  sorting,
  sourceLabels,
  formatter,
  extraGraphs,
  localSamples,
}) => {
  const pathURL = window.location.pathname;
  const isDataboard = pathURL.includes('/dashboards');
  switch (type) {
    case TYPE_BAR_CHART:
    case TYPE_COLUMN_CHART:
    case TYPE_SCATTER_CHART:
      return getUnstackedData({ data, dataTable, sampleTable, sorting, sourceLabels, formatter });
    case TYPE_BAR_CHART_MULTI:
    case TYPE_COLUMN_CHART_MULTI:
    case TYPE_BAR_CHART_STACKED_MULTI:
      if (isDataboard) {
        return getUnstackedMultiSourceData({
          data,
          dataTable,
          sampleTable,
          sorting,
          sourceLabels,
          formatter,
        });
      }
    // eslint-disable-next-line no-fallthrough
    case TYPE_BAR_CHART_STACKED:
    case TYPE_COLUMN_CHART_STACKED:
    case TYPE_COLUMN_CHART_STACKED_LOCAL:
      return getStackedData({ data, dataTable, sampleTable, sorting, formatter });
    case TYPE_PIE_CHART:
    case TYPE_DONUT_CHART:
      return getDonutData({ data, dataTable, sampleTable, sorting, formatter });
    case TYPE_BAR_CHART_SCORE:
      if (localSamples) {
        return getStackedData({ data, dataTable, sampleTable, sorting, formatter });
      }
      return getScoreDataToExport({
        data,
        dataTable,
        sampleTable,
        sorting,
        sourceLabels,
      });
    case TYPE_TABLE_CHART:
      return getTableData({ data, dataTable, formatter });
    case TYPE_HEATMAP_CHART:
      return getTableData({ data, dataTable, formatter, sampleTable });
    case TYPE_BAR_CHART_BENCHMARK:
    case TYPE_COLUMN_CHART_BENCHMARK: {
      return getBenchmarkedDataToExport({
        data,
        dataTable,
        sampleTable,
        sorting,
        formatter,
        sourceLabels,
      });
    }
    case TYPE_LINE_CHART: {
      const formatedData = formatLineData(data, dataTable);
      return getStackedData({
        data: formatedData,
        dataTable,
        sampleTable,
        sorting,
        sourceLabels,
        formatter,
      });
    }
    case TYPE_BAR_CHART_SIDE_BY_SIDE:
    case TYPE_COLUMN_CHART_LINE: {
      const formatedData = formatCombinedData(data, extraGraphs);
      return getStackedData({
        data: formatedData,
        dataTable: 'combinedTable',
        sampleTable,
        sorting,
        sourceLabels,
        formatter,
      });
    }
    default:
      return [];
  }
};

export default getData;
