import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import flattenDeep from 'lodash/flattenDeep';
import reduce from 'lodash/reduce';

export const getFilterGroupName = (state, groupId) => {
  const filterGroups = get(state, ['databoard', 'filterGroups'], []);
  const group = find(filterGroups, { _id: groupId });

  return get(group, 'text', 'Undefined Label');
};

export const getCardsByChart = (state) => {
  const sections = get(state, ['databoard', 'sections'], []);
  const allCards = flattenDeep(map(sections, 'cards'));
  const cardsByChart = reduce(
    allCards,
    (result, card) => {
      const chartId = get(card, ['chart', '_id'], '');
      const title = get(card, 'title', '');
      const subtitle = get(card, 'text', '');
      return {
        ...result,
        [chartId]: { title, subtitle },
      };
    },
    {},
  );

  return cardsByChart;
};
