import indexOf from 'lodash/indexOf';
import get from 'lodash/get';
import concat from 'lodash/concat';
import slice from 'lodash/slice';

const toggleSubscription = (subscription, selectedSubscriptions) => {
  const subscriptionId = get(subscription, '_id');
  const selectedIndex = indexOf(selectedSubscriptions, subscriptionId);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = concat(newSelected, selectedSubscriptions, subscriptionId);
  } else if (selectedIndex === 0) {
    newSelected = concat(newSelected, slice(selectedSubscriptions, 1));
  } else if (selectedIndex === selectedSubscriptions.length - 1) {
    newSelected = concat(newSelected, slice(selectedSubscriptions, 0, -1));
  } else if (selectedIndex > 0) {
    newSelected = concat(
      newSelected,
      slice(selectedSubscriptions, 0, selectedIndex),
      slice(selectedSubscriptions, selectedIndex + 1),
    );
  }

  return newSelected;
};

export default toggleSubscription;
