import React, { useState, useCallback } from 'react';

import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import Container from '../../components/Container';
import Stepper from '../../components/Stepper';
import FormTitle from '../../components/FormTitle';
import FormText from '../../components/FormText';
import SubmitButton from '../../components/SubmitButton';

import { ReactComponent as TickIcon } from './assets/tick.svg';

import signIn from '../../actions/signIn';
import currentAuthenticatedUser from '../../actions/currentAuthenticatedUser';
import { setCurrentUser } from '../../../../state/user/actions';

const styles = () => ({
  icon: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 28.8,
  },
});

const SuccessScreen = ({ classes, email, password, doSetUser }) => {
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const handleSignIn = useCallback(async () => {
    setSubmitting(true);
    try {
      await signIn(email, password);
      const model = await currentAuthenticatedUser();
      doSetUser(model);
    } catch (e) {
      setError(e);
    } finally {
      setSubmitting(false);
    }
  }, [email, password]);

  return (
    <Container error={error}>
      <Stepper activeStep={2} />
      <div className={classes.icon}>
        <TickIcon />
      </div>
      <FormTitle>Congratulations!</FormTitle>
      <FormText>Welcome to the Data World</FormText>
      {!submitting && <SubmitButton label="Beam me up!" onClick={handleSignIn} />}
      {submitting && <CircularProgress />}
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doSetUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(SuccessScreen));
